import { ITaxModelDetail } from "../aon";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class TaxModelDetail implements ITaxModelDetail, IModel{

    private expression: string;
    private description: string;
    private type: string;
    private acumulated_amount: number;
    private declared_amount: number;
    private resultamount: number;
    private adjust_amount: number;
    private amount: number;
    private key: string;
    private apiObject: any;

    constructor(){
        this.key = KeyGenerator.generate(15);
        this.apiObject = {};
        this.amount = 0;
        this.adjust_amount = 0;
        this.resultamount = 0;
        this.declared_amount = 0;
        this.acumulated_amount = 0;
        this.type = "";
        this.description = "";
        this.expression = "";
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    public get Key(): string {
        return this.key;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set Key(value: string) {
        this.key = value;
    }
    
    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Expression(): string{
        return this.expression;
    }

    public get Description(): string{
        return this.description;
    }

    public get Type(): string{
        return this.type;
    }

    public get Acumulated_amount(): number{
        return this.acumulated_amount;
    }

    public get Declared_amount(): number{
        return this.declared_amount;
    }

    public get Resultamount(): number{
        return this.resultamount;
    }

    public get Adjust_amount(): number{
        return this.adjust_amount;
    }

    public get Amount(): number{
        return this.amount;
    }

    public set Expression(value: string){
        this.expression = value;
    }

    public set Description(value: string){
        this.description = value;
    }

    public set Type(value: string){
        this.type = value;
    }

    public set Acumulated_amount(value: number){
        this.acumulated_amount = value;
    }

    public set Declared_amount(value: number){
        this.declared_amount = value;
    }

    public set Resultamount(value: number){
        this.resultamount = value;
    }

    public set Adjust_amount(value: number){
        this.adjust_amount = value;
    }

    public set Amount(value: number){
        this.amount = value;
    }

    public getExpression(): string{
        return this.expression;
    }

    public getDescription(): string{
        return this.description;
    }

    public getType(): string{
        return this.type;
    }

    public getAcumulated_amount(): number{
        return this.acumulated_amount;
    }

    public getDeclared_amount(): number{
        return this.declared_amount;
    }

    public getResultamount(): number{
        return this.resultamount;
    }

    public getAdjust_amount(): number{
        return this.adjust_amount;
    }

    public getAmount(): number{
        return this.amount;
    }

    public setExpression(value: string): ITaxModelDetail{
        this.expression = value;
        return this;
    }

    public setDescription(value: string): ITaxModelDetail{
        this.description = value;
        return this;
    }

    public setType(value: string): ITaxModelDetail{
        this.type = value;
        return this;
    }

    public setAcumulated_amount(value: number): ITaxModelDetail{
        this.acumulated_amount = value;
        return this;
    }

    public setDeclared_amount(value: number): ITaxModelDetail{
        this.declared_amount = value;
        return this;
    }

    public setResultamount(value: number): ITaxModelDetail{
        this.resultamount = value;
        return this;
    }

    public setAdjust_amount(value: number): ITaxModelDetail{
        this.adjust_amount = value;
        return this;
    }

    public setAmount(value: number): ITaxModelDetail{
        this.amount = value;
        return this;
    }
}

export class TaxModelDetailJSON {
    static parseDataToReceive(data: any): TaxModelDetail{
        let taxModelDetail = new TaxModelDetail();
        taxModelDetail.Acumulated_amount = data.acumulated_amount ? data.acumulated_amount : 0;
        taxModelDetail.Adjust_amount = data.adjust_amount ? data.adjust_amount : 0;
        taxModelDetail.Amount = data.amount ? data.amount : 0;
        taxModelDetail.Declared_amount = data.declared_amount ? data.declared_amount : 0;
        taxModelDetail.Resultamount = data.resultamount ? data.resultamount : 0;
        taxModelDetail.Description = data.description ? data.description : "";
        taxModelDetail.Expression = data.expression ? data.expression : "";
        taxModelDetail.Type = data.type ? data.type : "";
        taxModelDetail.ApiObject = data;
        taxModelDetail.Key = data.type ? data.type : KeyGenerator.generate(15);
        return taxModelDetail;
    }
}