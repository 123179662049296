<div id="layaut-componet-views">
  <div id="layaut-componet-nav">
    <ng-content select="[nav]"></ng-content>
  </div>
<!--body-->
  <div id="layaut-componet-body">
  <!--head-->
    <div id="layaut-componet-header">
      <ng-content select="[header]"></ng-content>
    </div>
    <!--content-->
    <div id="layaut-componet-content">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
<!--detail-->
  <div id="layaut-componet-detail">
    <ng-content select="[detail]"></ng-content>
  </div>
</div>
