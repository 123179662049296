import { IAddress, ICountryAon, IStreetType } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";
import { CountryAon, CountryAonJSON } from "./CountryAon";
import { StreetType, StreetTypeJSON } from "./StreetType";

export class Address implements IAddress, IModel {
    private type: IStreetType;
    private direction: string;
    private number: string;
    private restOfDirection: string;
    private country: ICountryAon;
    private postalCode: string;
    private city: string;
    private province: string;
    private fullDirection: string;
    private apiObject: any;
    private key: string;

    constructor(type?: IStreetType, direction?: string, number?:string, restOfDirection?: string, country?: ICountryAon, postalCode?: string, city?: string, province?: string, fullDirection?: string) {
        this.type = type || new StreetType();
        this.direction = direction || '';
        this.number = number || '';
        this.restOfDirection = restOfDirection || '';
        this.country = country || new CountryAon();
        this.postalCode = postalCode || '';
        this.city = city || '';
        this.province = province || '';
        this.fullDirection = fullDirection || '';
        this.key = KeyGenerator.generate(15);
    }

    public get Type() : IStreetType {
        return this.type;
    }

    public get Direction() : string {
        return this.direction;
    }

    public get Number() : string {
        return this.number;
    }

    public get RestOfDirection() : string {
        return this.restOfDirection;
    }

    public get Country() : ICountryAon {
        return this.country;
    }

    public get PostalCode() : string {
        return this.postalCode;
    }

    public get City() : string {
        return this.city;
    }

    public get Province() : string {
        return this.province;
    }

    public get FullDirection() : string {
        return this.fullDirection;
    }

    public get ApiObject() : any {
        return this.apiObject;
    }

    public get Key() : string {
        return this.key;
    }

    public set Type(value: IStreetType) {
        this.type = value;
    }

    public set Direction(value: string) {
        this.direction = value;
    }

    public set Number(value: string) {
        this.number = value;
    }

    public set RestOfDirection(value: string) {
        this.restOfDirection = value;
    }

    public set Country(value: ICountryAon) {
        this.country = value;
    }

    public set PostalCode(value: string) {
        this.postalCode = value;
    }

    public set City(value: string) {
        this.city = value;
    }

    public set Province(value: string) {
        this.province = value;
    }

    public set FullDirection(value: string) {
        this.fullDirection = value;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public set Key(value: string) {
        this.key = value;
    }

    getType(): IStreetType {
        return this.type
    }

    setType(value: IStreetType): IAddress {
        this.type = value;
        return this;
    }

    getDirection(): string {
        return this.direction;
    }

    setDirection(value: string): IAddress {
        this.direction = value;
        return this;
    }

    getNumber(): string {
        return this.number;
    }

    setNumber(value: string): IAddress {
        this.number = value;
        return this;
    }

    getRestOfDirection(): string {
        return this.restOfDirection;
    }

    setRestOfDirection(value: string): IAddress {
        this.restOfDirection = value;
        return this;
    }

    getCountry(): ICountryAon {
        return this.country;
    }
    
    setCountry(value: ICountryAon): IAddress {
        this.country = value;
        return this;
    }

    getPostalCode(): string {
        return this.postalCode;
    }

    setPostalCode(value: string): IAddress {
        this.postalCode = value;
        return this;
    }

    getCity(): string {
        return this.city;
    }

    setCity(value: string): IAddress {
        this.city = value;
        return this;
    }
    
    getProvince(): string {
        return this.province;
    }

    setProvince(value: string): IAddress {
        this.province = value;
        return this;
    }

    getFullDirection(): string {
        return this.fullDirection;
    }

    setFullDirection(value: string): IAddress {
        this.fullDirection = value;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class AddressJSON {
    static parseDataToSend(address: Address): any {
        throw new Error('Not implemented.');
    }

    static parseDataToReceive(data: any){
        let address = new Address();
        address.ApiObject = data;
        address.City = data.city ? data.city : '';
        address.Country = address.Country ? CountryAonJSON.parseDataToReceive(data.country) : new CountryAon();
        address.Type = address.Type ? StreetTypeJSON.parseDataToReceive(data.streetType) : new StreetType();
        address.Key = data.id ? data.id : '';
        address.Direction = data.address ? data.address : '';
        address.FullDirection = data.fullAddress ? data.fullAddress : '';
        address.Number = data.number ? data.number : '';
        address.PostalCode = data.zip ? data.zip : '';
        address.Province = data.province ? data.province : '';
        address.RestOfDirection = data.address2 ? data.address2 : '';
        return address;
    }
}