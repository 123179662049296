import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector    : 'app-error-snack-bar',
  templateUrl : './error-snack-bar.component.html',
  styleUrls   : ['./error-snack-bar.component.scss']
})
export class ErrorSnackBarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any, 
    public _snackRef: MatSnackBarRef<ErrorSnackBarComponent>
  ){
    
  }

  ngOnInit(): void {
    
  }

  destroy(){
    this.data.preClose();
    this.data.close();
  }

}
