import { IMessage, TypeMessage, StatusMessage, ApiTypeMessage, ApiStatusMessage } from "../interfaces/modelsInterfaces";
import { ICollection } from "../interfaces/utilitiesInterfaces";
import { Message, MessageJSON } from "../models/Message";
import { ApiHttpRequest } from "../utils/Http";
import { ErrorResponse } from "../utils/Response";
import { BASE_URL } from "../utils/Environment";
import { Collection } from "../utils/Collection";
import { MESSAGE_URL } from "../utils/ApiUrls";
import { GenericRepository } from "./GenericRepository";
import { IMessageSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { MessageFields, MessageIntervalFields } from "../utils/ModelsFields";
import { MessageFilter } from "../utils/ModelsFilters";

export class MessageRepository extends GenericRepository<Message> implements IMessageSpecificMethodsRepository, IMultipleObjectCrudRepository<Message>, ISingleObjectCrudRepository<Message> {

    async get(key: string): Promise<Message> {
        const keydecoded = decodeURIComponent(key).split(';');
        // Todo correcto
        const params = { id: keydecoded[0] };
        const type   = keydecoded[1] == TypeMessage.NOTIFICACION ? MESSAGE_URL.GET_ONE_NOTIFICATION : MESSAGE_URL.GET_ONE_MESSAGE;
        // llamamos
        const response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(type, params), {}, {});
        if(response?.type !== 'error'){
            return MessageJSON.parseDataToReceive(response);
        } else {
            throw new Error();
        }
    }

    async create(message: Message): Promise<Message> {
        let cauInfo = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_CAU, {}, {})
        let sender = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_ONE + '?id=' + localStorage.getItem('registry'), {}, {})
        let taskHolder = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_ONE + '?id=' + message.TaskHolder.Id, {}, {})
        let domain = {
            id: localStorage.getItem('domainId'),
            name: localStorage.getItem('domainName')
        }
        let workflow = {
            comment: "",
            domain: localStorage.getItem('domainId'),
            task_holder: sender,
            type: "opened",
            email: cauInfo.auth.email || ''
        }
        let description = JSON.stringify({
            observation: message.Description || '',
            cauinfo: cauInfo,
        })
        let json = {
            domain: domain,
            sender: sender,
            task_holder: taskHolder,
            workgroup: {
                active: true,
                description: "",
                dirty: false,
                domain: localStorage.getItem('domainId'),
                removed: false
            },
            title: message.Title,
            registry: {},
            workflow: [workflow],
            description: description,
            status: 'pending',
            source: 'query',
            source_id: null,
            files: [],
            project: {},
            tags: [],
            childs: [],
            domaintmp: domain,
            workflowtmp: workflow,
            mytaskholder: sender,
            auth: cauInfo.auth,
            domaincompany: cauInfo.company.domain
        }
        let newMessage: Message = MessageJSON.parseDataToReceive(await ApiHttpRequest.post(BASE_URL + MESSAGE_URL.GET_TASK_QUERY_LIST, {}, json));
        return newMessage;
    }

    async update(element: Message): Promise<Message> {
        let jsonData = MessageJSON.parseDataToSend(element)
        return MessageJSON.parseDataToReceive(await ApiHttpRequest.post(BASE_URL + MESSAGE_URL.SAVE_ONE_MESSAGE, {}, jsonData))
    }

    async getCollection(filter?: MessageFilter | undefined): Promise<ICollection<Message>> {
        let url: any = "";
        let perPage = filter?.pageItems ? filter?.pageItems : 15;
        let pageNum = filter?.pageNum ? filter?.pageNum : 1;
        let typeMessage = filter?.fields?.get(MessageFields.TYPE) ? filter?.fields?.get(MessageFields.TYPE)[0] : '';
        let statusMessage = filter?.fields?.get(MessageFields.STATUS);
        if(typeMessage == TypeMessage.CONSULTA || typeMessage == TypeMessage.TAREA || typeMessage == TypeMessage.TRAMITE){
            url = ApiHttpRequest.makeURL(MESSAGE_URL.GET_TASK_QUERY_LIST, generateParams(filter || {}, pageNum, perPage, typeMessage, statusMessage))
        }else{
            url = ApiHttpRequest.makeURL(MESSAGE_URL.GET_TASK_QUERY_LIST, generateParams(filter || {}, pageNum, perPage, TypeMessage.NULL, statusMessage))
        }
        let collection: ICollection<Message> = new Collection<Message>();
        let response = await ApiHttpRequest.get(BASE_URL + url, {}, {});
        response.forEach((element: any) => {
            collection.add(MessageJSON.parseDataToReceive(element))
        });
        return collection;
    }

    async archiveMessage(element: Message): Promise<IMessage> {
        if(element.Type != TypeMessage.NOTIFICACION){
            element.Status = element.Type == TypeMessage.CONSULTA ? StatusMessage.CERRADA : StatusMessage.REALIZADA;
            let updatedMessage: IMessage = await this.update(element);
            return updatedMessage;
        }
        throw new ErrorResponse('0199');
    }


    async reopenMessage(element: Message): Promise<IMessage> {
        if(element.Type != TypeMessage.NOTIFICACION){
            element.Status = element.Type == TypeMessage.CONSULTA ? StatusMessage.ABIERTA : StatusMessage.PENDIENTE;
            let updatedMessage: IMessage = await this.update(element);
            return updatedMessage;
        }
        throw new ErrorResponse('0199');
    }

    async getMessageCount(filter?: MessageFilter): Promise<number> {
        // if(filter?.fields?.get(MessageFields.TYPE) == TypeMessage.NOTIFICACION){
            // let result = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_COUNT_NOTIFICATION, {}, {})
            // return result.notification
        // } else 
        if(filter?.fields?.get(MessageFields.TYPE) == TypeMessage.TAREA){
            return (await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(MESSAGE_URL.GET_COUNT_TASK_QUERY, generateParams(filter || {}, 1, 100, TypeMessage.TAREA, filter?.fields?.get(MessageFields.STATUS))), {}, {}))
        } else if(filter?.fields?.get(MessageFields.TYPE) == TypeMessage.CONSULTA){
            return (await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(MESSAGE_URL.GET_COUNT_TASK_QUERY, generateParams(filter || {}, 1, 100, TypeMessage.CONSULTA, filter?.fields?.get(MessageFields.STATUS))), {}, {}))
        } else if(filter?.fields?.get(MessageFields.TYPE) == TypeMessage.TRAMITE){
            return (await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(MESSAGE_URL.GET_COUNT_TASK_QUERY, generateParams(filter || {}, 1, 100, TypeMessage.TRAMITE, filter?.fields?.get(MessageFields.STATUS))), {}, {}))
        } else {
            return (await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(MESSAGE_URL.GET_COUNT_TASK_QUERY, generateParams(filter || {}, undefined, undefined, undefined, filter?.fields?.get(MessageFields.STATUS))), {}, {}));
        }
    }

    async markAsReadNotification(element: Message): Promise<boolean> {
        if(element.Type == TypeMessage.NOTIFICACION){
            let result = await ApiHttpRequest.post(BASE_URL + MESSAGE_URL.GET_MARK_READ_NOTIFICATION, {}, {source_id: element.Id});
            if(result.success && result.success == true)
                return true;
            else
                return false;
        }
        else
            throw new ErrorResponse('0199');
    }

}


let generateParams = (filter?: MessageFilter, pageNum?: number, perPageItems?: number, source?: string, status?: any): any => {
    let params = {
        workgroups: 0
    }
    if(pageNum)
        Object.defineProperty(params, 'page', {
            value: pageNum ? pageNum : 1,
            enumerable : true,
        })
    if(perPageItems)
        Object.defineProperty(params, 'perPage', {
            value: perPageItems ? perPageItems : !source ? 10 : 30,
            enumerable : true,
        })
    let statusString = '';
    if(status){
        if(typeof status == 'object'){
            status.forEach((element: any) => {
                statusString += ApiStatusMessage[element as keyof typeof ApiStatusMessage] + ","
            })
            statusString = statusString.substring(0, statusString.length - 1);
        }
        else
            statusString = ApiStatusMessage[status as keyof typeof ApiStatusMessage]
        Object.defineProperties(params, {
            'status': {
                value: statusString,
                enumerable : true,
            },
        })
    }else if(source == TypeMessage.CONSULTA || source == TypeMessage.TAREA || source == TypeMessage.TRAMITE){
        Object.defineProperties(params, {
            'status': {
                value: ApiStatusMessage.allTask,
                enumerable : true,
            },
        })
    }
    if(source! != TypeMessage.NOTIFICACION){
        Object.defineProperties(params, {
            'task_holder': {
                value: localStorage.getItem('registry'),
                enumerable : true,
            },
            'sender': {
                value: localStorage.getItem('registry'),
                enumerable : true,
            }
        })
    }
    if(source && source != TypeMessage.NULL)
        Object.defineProperty(params, 'source', {
            value: ApiTypeMessage[source as keyof typeof ApiTypeMessage],
            enumerable : true,
        })
    if(filter?.intervalFields?.get(MessageIntervalFields.DATE)){
        Object.defineProperty(params, 'startDate', {
            value: new Date(filter?.intervalFields?.get(MessageIntervalFields.DATE).start).toISOString(),
            enumerable : true,
        })
        Object.defineProperty(params, 'endDate', {
            value: new Date(filter?.intervalFields?.get(MessageIntervalFields.DATE).end).toISOString(),
            enumerable : true,
        })
    }
    if(filter?.fields?.get(MessageFields.REQUEST_TYPE)){
        Object.defineProperty(params, 'source_id', {
            value: filter?.fields?.get(MessageFields.REQUEST_TYPE),
            enumerable : true,
        })
    }
    if(filter?.fields?.get(MessageFields.GLOBAL)){
        Object.defineProperty(params, 'search', {
            value: filter?.fields?.get(MessageFields.GLOBAL),
            enumerable : true,
        })
    }
    return params;
}