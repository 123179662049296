import { AonPermission } from "../interfaces/modelsInterfaces";

export class Permission {
    protected permissions: string [];

    constructor(){
        this.permissions = [];
    }

    public get Permissions(): any {
        return this.permissions;
    }

    public set Permissions(value: any) {
        this.permissions = value;
    }

    checkPermission(permission: AonPermission): boolean {
        let check: boolean = false;
        this.permissions.forEach((element: any) => {
            if(permission == element)
                check = true;
        });
        return check;
    }
}

export class PermissionJSON {
    static parseDataToReceive(data: any): Permission{
        let permission = new Permission();
        permission.Permissions = data;
        return permission;
    }
}