import { ITaskHolder } from "../interfaces/modelsInterfaces";
import { IFilter, IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class TaskHolder implements ITaskHolder, IModel {
    protected id: string;
    protected name: string;
    protected key: string;
    protected apiObject: any;

    constructor(name?: string) {
        this.name = name || '';
        this.id = KeyGenerator.generate(15);
        this.key = this.id;
    }
    getName(): string {
        return this.name;
    }
    setName(value: string): ITaskHolder {
        this.name = value;
        return this
    }

    public get Id(): string {
        return this.id;
    }

    public set Id(value: string) {
        this.id = value;
    }

    public get Name(): string {
        return this.name;
    }

    public set Name(value: string) {
        this.name = value;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    getKey(): string {
        return this.id;
    }
    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

}

export class TaskHolderJSON {
    static parseDataToSend(data: any, currentMethod?: string, filter?: IFilter | undefined) {
        throw new Error("Method not implemented.");
    }

    static parseDataToReceive(data: any, currentMethod?: string, filter?: IFilter | undefined) {
        let taskHolder = new TaskHolder();
        taskHolder.ApiObject = data;
        taskHolder.Id = data.id;
        taskHolder.Key = data.id;
        taskHolder.Name = data.name;
        return taskHolder;
    }
}