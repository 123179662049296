import { IBank, ICertificate, IContact, IContract, IDocument, IDocumentTag, IEmployee, IEnterprise, IFolder, IInvoice, IMark, IMarkDetail, IMessage, IMessageChat, IProduct, ISalary, ITaskHolder, ITaxModel, IUser } from "../interfaces/modelsInterfaces";
import { IAddressSpecificMethods, IAuthenticationManager, IBankNordigenSpecificMethods, ICertificateSpecificMethods, IContactSpecificMethods, IContractSpecificMethods, IDocumentSpecificMethods, IDomainSpecificMethods, IEnterpriseSpecificMethods, IFolderEspecificMethods, IInvoiceSpecificMethods, IMarkDetailSpecificMethods, IMarkSpecificMethods, IMessageSpecificMethods, IMultipleObjectCrud, IMultipleObjectReader, IMultipleObjectRemover, IProductSpecificMethods, IReportingDataAccess, ISalarySpecificMethods, ISingleObjectCrud, ISingleObjectReader, ITaskHolderSpecificMethods, ITaxModelSpecificMethods, IUserSpecificMethods } from "../interfaces/serviceInterfaces";
import { Address } from "../models/Address";
import { Bank } from "../models/Bank";
import { Certificate } from "../models/Certificate";
import { Contact } from "../models/Contact";
import { Contract } from "../models/Contract";
import { Document } from "../models/Document";
import { DocumentTag } from "../models/DocumentTag";
import { Employee } from "../models/Employee";
import { Enterprise } from "../models/Enterprise";
import { Folder } from "../models/Folder";
import { Invoice } from "../models/Invoice";
import { Mark } from "../models/Mark";
import { MarkDetail } from "../models/MarkDetail";
import { Message } from "../models/Message";
import { MessageChat } from "../models/MessageChat";
import { Product } from "../models/Product";
import { Salary } from "../models/Salary";
import { TaskHolder } from "../models/TaskHolder";
import { TaxModel } from "../models/TaxModel";
import { User } from "../models/User";
import { AddressRepository } from "../repositorys/AddressRepository";
import { APIAuthenticationRepository } from "../repositorys/AuthenticationRepository";
import { BankRepository } from "../repositorys/BankRepository";
import { CertificateRepository } from "../repositorys/CertificateRepository";
import { ContactRepository } from "../repositorys/ContactRepository";
import { ContractRepository } from "../repositorys/ContractRepository";
import { DocumentRepository } from "../repositorys/DocumentRepository";
import { DocumentTagRepository } from "../repositorys/DocumentTagRepository";
import { EnterpriseRepository } from "../repositorys/EnterpriseRepository";
import { FolderRepository } from "../repositorys/FolderRepository";
import { GenericRepository } from "../repositorys/GenericRepository";
import { InvoiceRepository } from "../repositorys/InvoiceRepository";
import { MarkDetailRepository } from "../repositorys/MarkDetailRepository";
import { MarkRepository } from "../repositorys/MarkRepository";
import { MessageChatRepository } from "../repositorys/MessageChatRepository";
import { MessageRepository } from "../repositorys/MessageRepository";
import { ProductRepository } from "../repositorys/ProductRepository";
import { ApiReportingRepository } from "../repositorys/ReportingRepository";
import { SalaryRepository } from "../repositorys/SalaryRepository";
import { TaskHolderRepository } from "../repositorys/TaskHolderRepository";
import { TaxModelRepository } from "../repositorys/TaxModelRepository";
import { UserRepository } from "../repositorys/UserRepository";
import { AddressService } from "../services/AddressService";
import { AuthenticationManager } from "../services/AuthenticationService";
import { BankService } from "../services/BankNordigenService";
import { CertificateService } from "../services/CertificateService";
import { ContactService } from "../services/ContactService";
import { ContractService } from "../services/ContractService";
import { DocumentService } from "../services/DocumentService";
import { DomainSpecificMethods } from "../services/DomainService";
import { EnterpriseService } from "../services/EnterpriseService";
import { FolderService } from "../services/FolderService";
import { GenericService } from "../services/GenericCrudService";
import { InvoiceService } from "../services/InvoiceService";
import { MarkDetailService } from "../services/MarkDetailService";
import { MarkService } from "../services/MarkService";
import { MessageService } from "../services/MessageService";
import { ProductService } from "../services/ProductService";
import { ReportingDataAccess } from "../services/ReportingService";
import { SalaryService } from "../services/SalaryService";
import { TaskHolderService } from "../services/TaskHolderService";
import { TaxModelService } from "../services/TaxModelService";
import { UserService } from "../services/UserService";
import { SalaryFields } from "../utils/ModelsFields";
import { BankFilter, ContactFilter, ContractFilter, DocumentFilter, FolderFilter, InvoiceFilter, MarkFilter, MessageChatFilter, MessageFilter, ProductFilter, SalaryFilter, TaskHolderFilter, TaxModelFilter } from "../utils/ModelsFilters";

export class ServiceFactory {
    static createAddressService(): IAddressSpecificMethods {
        return new AddressService(new AddressRepository(), Address, new AddressRepository());
    }

    static createAuthenticationManager(): IAuthenticationManager {
        return new AuthenticationManager(new APIAuthenticationRepository());
    }

    static createBankService(): ISingleObjectCrud<IBank> & IMultipleObjectCrud<IBank, BankFilter> & IBankNordigenSpecificMethods {
        return new BankService(new BankRepository(), Bank, new BankRepository());
    }

    static createCertificateService(): ISingleObjectCrud<ICertificate> & IMultipleObjectCrud<ICertificate, never> & ICertificateSpecificMethods {
        return new CertificateService(new CertificateRepository(), Certificate, new CertificateRepository());
    }

    static createContactService(): IMultipleObjectReader<IContact, ContactFilter> & ISingleObjectCrud<IContact> & IContactSpecificMethods {
        return new ContactService(new ContactRepository(), Contact, new ContactRepository());
    }

    static createContractService(): IMultipleObjectReader<IContract, ContractFilter> & ISingleObjectCrud<IContract> & IContractSpecificMethods {
        return new ContractService(new ContractRepository(), Contract, new ContractRepository());
    }

    static createDocumentService(): IMultipleObjectReader<IDocument, DocumentFilter> & ISingleObjectCrud<IDocument> & IDocumentSpecificMethods {
        return new DocumentService(new DocumentRepository(), Document, new DocumentRepository());
    }

    static createDocumentTagService(): IMultipleObjectReader<IDocumentTag, never> & ISingleObjectCrud<IDocumentTag> {
        return new GenericService<DocumentTag, never>(new DocumentTagRepository(), DocumentTag);
    }

    static createSpecificMethods(): IDomainSpecificMethods {
        return new DomainSpecificMethods();
    }

    static createEmployeeService(): ISingleObjectCrud<IEmployee> & IMultipleObjectCrud<IEmployee, never> {
        return new GenericService<Employee, never>(new GenericRepository(), Employee);
    }

    static createEnterpriseService(): IMultipleObjectReader<IEnterprise, never> & ISingleObjectCrud<IEnterprise> & IEnterpriseSpecificMethods {
        return new EnterpriseService(new EnterpriseRepository(), Enterprise, new EnterpriseRepository());
    }

    static createFolderService(): IMultipleObjectReader<IFolder, FolderFilter> & ISingleObjectCrud<IFolder> & IFolderEspecificMethods {
        return new FolderService (new FolderRepository(), Folder, new FolderRepository());
    }

    static createInvoiceService(): IMultipleObjectReader<IInvoice, InvoiceFilter> & IMultipleObjectRemover<IInvoice> & ISingleObjectCrud<IInvoice> & IInvoiceSpecificMethods {
        return new InvoiceService(new InvoiceRepository(), Invoice, new InvoiceRepository());
    }

    static createMarkDetailService(): IMultipleObjectReader<IMarkDetail, never> & ISingleObjectCrud<IMarkDetail> & IMarkDetailSpecificMethods {
        return new MarkDetailService(new MarkDetailRepository(), MarkDetail, new MarkDetailRepository());
    }

    static createMarkService(): IMultipleObjectReader<IMark, MarkFilter> & ISingleObjectCrud<IMark> & IMarkSpecificMethods {
        return new MarkService(new MarkRepository(), Mark, new MarkRepository());
    }

    static createMessageChatService(): IMultipleObjectReader<IMessageChat, MessageChatFilter> & ISingleObjectCrud<IMessageChat> {
        return new GenericService(new MessageChatRepository(), MessageChat);
    }

    static createMessageService(): IMultipleObjectReader<IMessage, MessageFilter> & ISingleObjectCrud<IMessage> & IMessageSpecificMethods {
        return new MessageService(new MessageRepository(), Message, new MessageRepository());
    }

    static createProductService(): IMultipleObjectReader<IProduct, ProductFilter> & ISingleObjectCrud<IProduct> & IProductSpecificMethods {
        return new ProductService(new ProductRepository(), Product, new ProductRepository());
    }

    static createReportingDataAccess(): IReportingDataAccess {
        return new ReportingDataAccess(new ApiReportingRepository());
    }

    static createTaskHolderService(): IMultipleObjectReader<ITaskHolder, TaskHolderFilter> & ISingleObjectCrud<ITaskHolder> & ITaskHolderSpecificMethods {
        return new TaskHolderService(new TaskHolderRepository(), TaskHolder, new TaskHolderRepository());
    }

    static createTaxModelService(): IMultipleObjectReader<ITaxModel, TaxModelFilter> & ISingleObjectCrud<ITaxModel> & ITaxModelSpecificMethods {
        return new TaxModelService(new TaxModelRepository(), TaxModel, new TaxModelRepository());
    }

    static createUserService(): IMultipleObjectReader<IUser, never> & ISingleObjectCrud<IUser> & IUserSpecificMethods {
        return new UserService(new UserRepository(), User, new UserRepository());
    }

    static createSalaryService(): IMultipleObjectReader<ISalary, SalaryFilter> & ISalarySpecificMethods & ISingleObjectReader<ISalary> {
        return new SalaryService(new SalaryRepository(), Salary, new SalaryRepository());
    }
} 