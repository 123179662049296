import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResultSnackBarComponent } from 'src/app/shared/components/result-snack-bar/result-snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  snackBarRef       : any;

  constructor(
    public  snackBar: MatSnackBar,
    private zone    : NgZone) 
  {
    
  }

//  async displaysucces(result: string, duration: number = 10000){
  async displaysucces(result: string, duration: number = 2000){
    this.zone.run(() => {
      this.snackBar.openFromComponent(ResultSnackBarComponent, {
        panelClass: ['succes-snack-bar'],
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: {
          duration: duration,
          result: result,
          preClose: () => {
            this.dismiss();
          },
        },
      });
    });
  }

  dismiss(){
    this.snackBar.dismiss();
  }

}
