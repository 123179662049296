import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UploadModalComponent } from './components/upload-modal/upload-modal.component';
import { Factory } from 'libraries/AonSDK/src/aon';
import { AuthService } from 'src/app/core/services/auth.service';
import { AonPermission } from 'libraries/AonSDK/src/aon';
import { DocumentService } from 'src/app/core/services/document.service';

@Component({
  selector    : 'app-file-upload-button',
  templateUrl : './file-upload-button.component.html',
  styleUrls   : ['./file-upload-button.component.scss'],
})

export class FileUploadButtonComponent implements OnInit {
  objectFactory = Factory;
  @ViewChild('modalUploadFile') modalUploadFileComponent: any = '';
  functionUploadFile        : any     = (result:any) => this.afterModalUploadFileClosed(result);
  @Input() isOpenUploadModal: boolean = false;
  uploadPermissions         : boolean = false;
  width                     : string  = '100%';
  height                    : string  = '100%';
  isDropOver                : boolean = false;
  filesAndFolderUploaded    : any[]   = [];
  filesAndFolderUploadedView: boolean = true;
  uploadedError             : number  = 0;
  uploadedCorrect           : number  = 0;

  constructor(
    private authService     : AuthService,
    private documentService : DocumentService,
  ) {
    this.authService.getAllPermissions().then((permissions) => {
      //
      // Tiene el modulo = AonPermission.DOCUMENTAL
      //
      if(permissions.Permissions.includes(AonPermission.DOCUMENTAL)){
        this.uploadPermissions = true;
      }
    });
  }

  ngOnInit(): void {}

  // Modal para subir el archivo
  modalUploadDocument(event: Event, data?: any) {
    if(!this.isOpenUploadModal){
      event.preventDefault();
      this.modalUploadFileComponent.openDialog(
        UploadModalComponent,
        this.functionUploadFile,
        data
      );
    }
  }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      // Si arrastramos files se lo pasamos al modal
      this.modalUploadDocument(event, target.files);
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files) {
      // Si arrastramos files se lo pasamos al modal
      this.modalUploadDocument(event, event.dataTransfer.files);
    }

    this.isDropOver = false;
  }

  onDragOver(event: any) {
    event.preventDefault();
    this.isDropOver = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    this.isDropOver = false;
  }

/*
  Subir documento
*/
  afterModalUploadFileClosed(result?: any){
    if(result !== undefined && result.length > 0)
      this.uploadDocument(result)
  }
  // Guardamos los documentos y la carpeta seleccionada
  uploadDocument(filesAndFolder: any) {
    this.uploadedCorrect  = 0;
    this.uploadedError    = 0;
    //Si se ha seleccionado un documento
    if (filesAndFolder.length > 0) {
      this.filesAndFolderUploadedView = true;
      this.filesAndFolderUploaded     = filesAndFolder;
      // Guardamos el documento en la carpeta seleccionada
      Object.keys(filesAndFolder).forEach(key => {
        const file = filesAndFolder[key];
        // Montamos el documento, para enviar
          const document = this.objectFactory.createDocument(
            "",
            file.document.name,
            file.document.size,
            file.document.type,
            new Date(),
            file.path
          );
        // FIN Montamos el documento, para enviar
        // Subimos el documento
          this.documentService.uploadDocument(document, file.document).then((response) => {
            // Guardado correctamente, response = true
            if(response){
              filesAndFolder[key].uploaded = 'up';
              this.uploadedCorrect++;
            }else{
              filesAndFolder[key].uploaded = 'error';
              this.uploadedError++;
            }
          }).catch((err) => {
            // Tenemos algun tipo de error
            filesAndFolder[key].uploaded  = 'error';
            filesAndFolder[key].error     = err.result == undefined ? "ERROR!\n During the process of uploading." : err.result;
            this.uploadedError++;
          });
        // FIN Subimos el documento
      });
    }
  }
/*
  FIN - Subir documento
*/
/*
  Cerrar subir documento
*/
  closeUploadingFiles(){
    this.filesAndFolderUploadedView = false;
  }
/*
  FIN subir documento
*/
}
