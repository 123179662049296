import { IMark, MarkStatus } from "../interfaces/modelsInterfaces";
import { IFilter, IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";
import { Location } from "./Location";

export class Mark implements IMark, IModel {
    private key: string;
    private apiObject: any;
    private name: string;
    private idUser: string;
    private date: Date;
    private entryDate: Date;
    private exitDate: Date;
    private time: number;
    private location: Location;
    private status: MarkStatus;
    private hasPause: boolean;
    private timeString: string;

    public get TimeString(): string {
        return this.timeString;
    }

    public set TimeString(value: string) {
        this.timeString = value;
    }

    public getTimeString(): string {
        return this.timeString;
    }

    public setTimeString(value: string): IMark {
        this.timeString = value;
        return this;
    }

    public get HasPause(): boolean {
        return this.hasPause;
    }

    public set HasPause(value: boolean) {
        this.hasPause = value;
    }

    public getHasPause(): boolean {
        return this.hasPause;
    }

    public setHasPause(value: boolean): IMark {
        this.hasPause = value;
        return this;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Name(): string {
        return this.name;
    }

    public set Name(value: string) {
        this.name = value;
    }

    public get IdUser(): string {
        return this.idUser;
    }

    public set IdUser(value: string) {
        this.idUser = value;
    }

    public get Date(): Date {
        return this.date;
    }

    public set Date(value: Date) {
        this.date = value;
    }

    public get EntryDate(): Date {
        return this.entryDate;
    }

    public set EntryDate(value: Date) {
        this.entryDate = value;
    }

    public get ExitDate(): Date {
        return this.exitDate;
    }

    public set ExitDate(value: Date) {
        this.exitDate = value;
    }

    public get Time(): number {
        return this.time;
    }

    public set Time(value: number) {
        this.time = value;
    }

    public get Location(): Location {
        return this.location;
    }

    public set Location(value: Location) {
        this.location = value;
    }

    public get Status(): MarkStatus {
        return this.status;
    }

    public set Status(value: MarkStatus) {
        this.status = value;
    }

    constructor(name?: string, idUser?: string, date?: Date, entryDate?: Date, exitDate?: Date, time?: number, location?: any, status?: MarkStatus ) {
      this.name = name || '';
      this.idUser = idUser || '';
      this.key = (idUser) ? idUser : KeyGenerator.generate(15);
      this.date = date || new Date(0);
      this.entryDate = entryDate || new Date(0);
      this.exitDate = exitDate || new Date(0);
      this.time = time || 0;
      this.location = location || '';
      this.status = status || MarkStatus.ENTRADA;
      this.hasPause = false;
      this.timeString = '';
    }
    getName(): string {
        return this.name;
    }
    setName(value: string): IMark {
        this.name = value;
        return this;
    }
    getIdUser(): string {
        return this.idUser;
    }
    setIdUser(value: string): IMark {
        this.idUser = value;
        return this;
    }
    getDate(): Date {
        return this.date;
    }
    setDate(value: Date): IMark {
        this.date = value;
        return this;
    }
    getEntryDate(): Date {
        return this.entryDate;
    }
    setEntryDate(value: Date): IMark {
        this.entryDate = value;
        return this;
    }
    getExitDate(): Date {
        return this.exitDate;
    }
    setExitDate(value: Date): IMark {
        this.exitDate = value;
        return this;
    }
    getTime(): number {
        return this.time;
    }
    setTime(value: number): IMark {
        this.time = value;
        return this;
    }
    getLocation(): Location {
        return this.location;
    }
    setLocation(value: Location): IMark {
        this.location = value;
        return this;
    }
    getStatus(): MarkStatus {
        return this.status;
    }
    setStatus(value: MarkStatus): IMark {
        this.status = value;
        return this;
    }

    getKey(): string {
        return this.Key;
    }

    getFilterableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('name', this.name);
        map.set('idUser', this.idUser);
        map.set('date', this.date);
        map.set('entryDate', this.entryDate);
        map.set('exitDate', this.exitDate);
        map.set('time', this.time);
        map.set('location', this.location);
        map.set('status', this.status);
        return map;
    }

    getSortableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('name', this.name);
        map.set('idUser', this.idUser);
        map.set('date', this.date);
        map.set('entryDate', this.entryDate);
        map.set('exitDate', this.exitDate);
        map.set('time', this.time);
        map.set('location', this.location);
        map.set('status', this.status);
        return map;
    }
}

export class MarkJSON {
    static parseDataToSend(data: any, currentMethod?: string, filter?: IFilter | undefined) {
        throw new Error("Method not implemented.");
    }

    static parseDataToReceive(data: any, currentMethod?: string, filter?: IFilter | undefined) {
        if(currentMethod == 'user'){
            let mark = new Mark();
            mark.ApiObject = data;
            mark.Key = data.task_holder.id
            mark.IdUser = data.task_holder.id
            mark.Name = data.task_holder.name
            // mark.Location = data.coordinates ? data.coordinates : {};
            let location: Location = new Location();
            location.Coordinates = data.coordinates ? data.coordinates.latitude + ',' + data.coordinates.longitude : '';
            location.Description = data.last_location && data.last_location.id ? data.last_location.name : '';
            location.Key = data.last_location && data.last_location.id? data.last_location.id : '';
            mark.Location = location;
            mark.Status = data.status
            mark.Date = data.last_date ? new Date(data.last_date) : new Date(0);
            mark.TimeString = milisegToString(data.time);
            mark.Time = data.time;
            if(data.detail)
                data.detail.forEach((element: any) => {
                    if(element.status == 'in' && mark.EntryDate.getTime() == new Date(0).getTime()){
                        mark.EntryDate = new Date(element.date)
                    }
                    if(element.status == 'out'){
                        mark.ExitDate = new Date(element.date)
                    }
                    if(element.status == 'pause'){
                        mark.HasPause = true;
                    }
                });
            return mark;
        }else if(currentMethod == 'mark'){
            let mark = new Mark();
            mark.ApiObject = data;
            mark.Key = data.start_date;
            mark.IdUser = data.task_holder.id;
            mark.Name = data.task_holder.name;
            let location: Location = new Location();
            location.Coordinates = data.coordinates ? data.coordinates.latitude + ',' + data.coordinates.longitude : '';
            location.Description = data.last_location  && data.last_location.name ? data.last_location.name : '';
            location.Key = data.last_location && data.last_location.id ? data.last_location.id : '';
            mark.Location = location;
            mark.Date = data.start_date ? new Date(data.start_date) : new Date(0);
            mark.Time = data.time;
            mark.TimeString = milisegToString(data.time);
            if(data.detail)
                data.detail.forEach((element: any) => {
                    if(element.status == 'in' && mark.EntryDate.getTime() == new Date(0).getTime()){
                        mark.EntryDate = new Date(element.date);
                    }
                    if(element.status == 'out'){
                        mark.ExitDate = new Date(element.date);
                    }
                    if(element.status == 'pause'){
                        mark.HasPause = true;
                    }
                });
            return mark;
        }
        throw new Error()
    }
}

let milisegToString = (value: number) => {
    let hours = Math.floor(value / (1000 * 60 * 60));
    let minutes = Math.floor((value - (hours * 1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((value - (hours * 1000 * 60 * 60) - (minutes * 1000 * 60)) / (1000));
    return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
}