import { ErrorResponse, Response } from "../utils/Response";
import { IDomainSpecificMethods } from "../interfaces/serviceInterfaces";
import { ApiDomainSpecificMethodsRepository } from "../repositorys/DomainRepository";
import { IResponse } from "../interfaces/utilitiesInterfaces"

export class DomainSpecificMethods implements IDomainSpecificMethods {
    private repository = new ApiDomainSpecificMethodsRepository();

    getLogo(): IResponse<string> {
        try {
            return new Response<string>(this.repository.getLogo());
        } catch (error) {
            console.log(error)
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0123');
        }
    }

    getFavicon(): IResponse<string> {
        try {
            return new Response<string>(this.repository.getFavicon());
        } catch (error) {
            console.log(error)
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0123');
        }
    }

    getServiceDesk(): IResponse<string> {
        try {
            return new Response<string>(this.repository.getServiceDesk());
        } catch (error) {
            console.log(error)
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0123');
        }
    }

    getTitle(): IResponse<string> {
        try {
            return new Response<string>(this.repository.getTitle());
        } catch (error) {
            console.log(error)
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0123');
        }
    }

    getLoginMessage(): IResponse<string> {
        try {
            return new Response<string>(this.repository.getLoginMessage());
        } catch (error) {
            console.log(error)
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0123');
        }
    }
}