import { Component, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CollectionFactory, Factory, ICollection, IMessage, StatusMessage, TypeMessage } from 'libraries/AonSDK/src/aon';
import { MessageService } from 'src/app/core/services/message.service';
import { ResultService } from 'src/app/core/services/result.service';

export interface Holders {
  Key : string;
  Name: string;
}

@Component({
  selector: 'app-create-query',
  templateUrl: './modal-create.component.html',
  styleUrls: ['./modal-create.component.scss']
})

export class ModalCreateComponent implements OnInit {
  holders: any;
  advisors: any = "";
  advisorsList: any[] = [];
  selectedAdvisor: string | undefined;
  spinner: boolean = true;
  inputValue: string = '';
  newMessageDescription: string = '';
  newMessageAsunto: string = '';
  entityFactory = Factory;
  messagesData: IMessage = this.entityFactory.createMessage();
  collectionFactory = new CollectionFactory();
  isFullScreen: boolean = false;

  constructor(
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalCreateComponent>,
    private translateService: TranslateService,
    private resultService: ResultService,
  ) {
    messageService.getTaskHoldersList().then((response) => {
      this.holders = response;

      console.log(this.holders);

      this.advisorsList = this.holders.toArray().map((element: Holders) =>({
        value : element.Key,
        text  : element.Name
      }))
      // Quitamos el spiner
      this.spinner = false;
    })
  }

  ngOnInit(): void {
    this.dialogRef.addPanelClass('dialog-fullscreen');
    this.dialogRef.updatePosition({bottom: '1rem', right: '1rem'});
  }

  ngAfterViewChecked(){
    // Quitar la opacity del modal
    var classShowing = document.querySelector('.cdk-overlay-backdrop-showing');
    if(classShowing != null){
      classShowing.classList.remove('cdk-overlay-backdrop-showing');
    }
  }

  expandedModal() {
    this.isFullScreen = this.isFullScreen ? false : true;
    if(this.isFullScreen){
      this.dialogRef.updatePosition({bottom: '0', right: '0'});
      this.dialogRef.updateSize('calc(100vw - 21rem)', 'calc(100vh - 3.265rem)');
    } else {
      this.dialogRef.updatePosition({bottom: '1rem', right: '1rem'});
      this.dialogRef.updateSize('auto', 'auto');
    }
  }

  closeModal(result: boolean = false): void {
    // Enviamos que ha sido correcto, para recargar la tabla.
    this.dialogRef.close(result);
  }

  newMessageDescriptionChange(newValue: string) {
    this.newMessageDescription = newValue;
    this.newMessageAsunto = newValue;
  }
  // Cargar los asesores
  loadAdvisors(event: any) {
    this.selectedAdvisor = event;
    if (this.selectedAdvisor) {
      this.messagesData.Id = this.selectedAdvisor;
    }
  }

  // Enviar la consulta creada
  sendMessage() {
    // Verifica si los campos están llenos
    if (this.newMessageDescription.trim() !== '' && this.newMessageAsunto.trim() !== '' && this.selectedAdvisor) {
      // Activamos el spiner
      this.spinner = true;
      // Creamos el mensaje a crear
      const newMessage = this.messageService.objectFactory.createMessage()
        .setName(this.holders.get(+this.messagesData.Id).name)
        .setDescription(this.newMessageDescription)
        .setType(TypeMessage.CONSULTA)
        .setStatus(StatusMessage.CERRADA)
        .setTitle(this.newMessageAsunto)
        .setLastMessageChatOrigin(false)
        .setTaskHolder(this.holders.get(+this.messagesData.Id));

      // Creamos el mensaje
      this.messageService.createMessage(newMessage).then((response) => {
        this.messagesData = response;
        // Mensaje que todo ok
        this.translateService.get(['COMMON.QUERY_SUCCESSFULLY_SENT']).subscribe((result) => {
          // Desactivo el spinner
          this.spinner = false;
          // Limpia el campo de entrada después de enviar el mensaje
          this.newMessageDescription = '';
          this.newMessageAsunto = '';
          // Mensaje
          this.resultService.displaysucces(result['COMMON.QUERY_SUCCESSFULLY_SENT']);
          // Que todo ha sido correcto al cerrar
          this.closeModal(true);
        });
      })
    }
  }
}
