import { IResponse, IBank, ICollection } from "../aon";
import { ITaxModel, ITaxModelDetail } from "../interfaces/modelsInterfaces";
import { IMultipleObjectCrudRepository, ISingleObjectCrudRepository, ITaxModelSpecificMethodsRepository } from "../interfaces/repositoryInterfaces";
import { ITaxModelSpecificMethods } from "../interfaces/serviceInterfaces";
import { TaxModel } from "../models/TaxModel";
import { TaxModelFilter } from "../utils/ModelsFilters";
import { ErrorResponse, Response } from "../utils/Response";
import { GenericService } from "./GenericCrudService";

export class TaxModelService extends GenericService<TaxModel, TaxModelFilter> implements ITaxModelSpecificMethods {
    protected SpecificMethodsRepository: ITaxModelSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<TaxModel> & ISingleObjectCrudRepository<TaxModel>, type: { new (): TaxModel }, SpecificMethodsRepository: ITaxModelSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    /**
    * Realiza el pago del modelo mediante el NRC.
    *
    * @param {ITaxModel} model - El modelo de impuestos a utilizar para el pago.
    * @param {string} nrc - El NRC a utilizar para el pago.
    * @return {Promise<IResponse<boolean>>} - Una promesa que se resuelve en una respuesta que indica si el pago tuvo éxito o no.
    */
    async payTaxModelWithNRC(model: ITaxModel, nrc: string): Promise<IResponse<boolean>> {
        try{
            return new Response<boolean>(await this.SpecificMethodsRepository.payTaxModelWithNRC(model, nrc));
        }catch(e){
            throw e instanceof ErrorResponse ?  e : new ErrorResponse('0601');
        }
    }

    /**
     * Realiza el pago del modelo de impuestos con un banco.
     *
     * @param {ITaxModel} model - El modelo de impuestos a pagar.
     * @param {IBank} bank - El banco para realizar el pago.
     * @return {Promise<IResponse<boolean>>} Una promesa que se resuelve en un objeto de respuesta que indica el éxito o el fracaso del pago.
     */
    async payTaxModelWithBank(model: ITaxModel, bank: IBank): Promise<IResponse<boolean>> {
        try{
            return new Response<boolean>(await this.SpecificMethodsRepository.payTaxModelWithBank(model, bank));
        }catch(e){
            throw e instanceof ErrorResponse ?  e : new ErrorResponse('0602');
        }
    }
    /**
     * Descarga el archivo pdf asociado al modelo
     * @param model 
     */
    async downloadPDFTaxModel(model: ITaxModel): Promise<IResponse<Blob>>{
        try{
            return new Response<Blob>(await this.SpecificMethodsRepository.downloadPDFTaxModel(model));
        }catch(e){
            throw e instanceof ErrorResponse ?  e : new ErrorResponse('0303');
        }
    }

    /**
     * Descarga el archivo excel asociado al modelo
     * @param model 
     */
    async downloadExcelTaxModel(model: ITaxModel): Promise<IResponse<Blob>>{
        try{
            return new Response<Blob>(await this.SpecificMethodsRepository.downloadExcelTaxModel(model));
        }catch(e){
            throw e instanceof ErrorResponse ?  e : new ErrorResponse('0303');
        }
    }

    /**
     * Descarga el archivo excel asociado al modelo
     * @param model 
     */
    async rejectModel(model : ITaxModel, reason: String): Promise<IResponse<boolean>>{
        try{
            return new Response<boolean>(await this.SpecificMethodsRepository.rejectModel(model, reason));
        }catch(e){
            throw e instanceof ErrorResponse ?  e : new ErrorResponse('0303');
        }
    }

    async dateControlTaxModel(model : TaxModel) : Promise<IResponse<boolean>>{
        try{
            return new Response<boolean>( await this.SpecificMethodsRepository.dateControlTaxModel(model));
        }catch(e){
            throw e instanceof ErrorResponse ?  e : new ErrorResponse('0303');
        }
    }

    async getTaxModelDetails(key: string): Promise<IResponse<ICollection<ITaxModelDetail>>>{
        try{
            return new Response<ICollection<ITaxModelDetail>>( await this.SpecificMethodsRepository.getTaxModelDetails(key));
        }catch(e){
            throw e instanceof ErrorResponse ?  e : new ErrorResponse('0303');
        }
    }
}
