import { ErrorResponse } from "../aon";
import { IBankBalances } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class BankBalances implements IBankBalances, IModel {
   private balanceType: string;
   private amount : number;
   private currency: string;
   private referenceDate: Date; 
   protected apiObject: any;
   private key: string;


   constructor (balanceType?: string, amount?: number, currency?: string, referenceDate?: Date , key?: string) {
       this.balanceType = balanceType || '';
       this.amount = amount || 0;
       this.currency = currency || '';
       this.referenceDate = referenceDate || new Date();
       this.apiObject = {} 
       this.key = key || KeyGenerator.generate(15);
   }


   public get BalanceType(): string {
       return this.balanceType;
    }

   public set BalanceType(value: string) {
       this.balanceType = value;
    }

   public getBalanceType(): string {
       return this.balanceType;
    }

   public setBalanceType(value: string): BankBalances {
       this.balanceType = value;
       return this;
    }

   public get Amount(): number {
       return this.amount;
    }

   public set Amount(value: number) {
       this.amount = value;
    }

   public getAmount(): number {
       return this.amount;
    }

   public setAmount(value: number): BankBalances {
       this.amount = value;
       return this;
    }

   public get Currency(): string {
       return this.currency;
    }

   public set Currency(value: string) {
       this.currency = value;
    }

   public getCurrency(): string {
       return this.currency;
    }

   public setCurrency(value: string): BankBalances {
       this.currency = value;
       return this;
    }

   public get ReferenceDate(): Date {
       return this.referenceDate;
    }    

   public set ReferenceDate(value: Date) {
       this.referenceDate = value;
    }

   public getReferenceDate(): Date {
       return this.referenceDate;
    }

   public setReferenceDate(value: Date): BankBalances {
       this.referenceDate = value;
       return this;
    }

   public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string,any> {
        let map = new Map<string, any>();
  
        return map;
    }

    getSortableFields(): Map<string,any> {
        let map = new Map<string, any>();
  
      return map;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

}

    export class BankBalancesJSON {
        static parseDataToSend(data: any) {
            throw new ErrorResponse('0199')
        }
        static parseDataToReceive(data: any) {
            let bankBalances = new BankBalances();
            bankBalances.ApiObject = data;
            bankBalances.BalanceType = data.balanceType;
            bankBalances.Amount = data.amount;
            bankBalances.Currency = data.currency;
            bankBalances.ReferenceDate = data.referenceDate;
            return bankBalances;
        }
    }