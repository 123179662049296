import { Injectable } from '@angular/core';
import { ICollection, IMessage, ITaskHolder, ServiceFactory } from 'libraries/AonSDK/src/aon';
import { CommonService } from './common.service';
import { MessageFilter, TaskHolderFilter } from 'libraries/AonSDK/src/utils/ModelsFilters';

@Injectable({
  providedIn: 'root',
})
export class MessageService extends CommonService {

  private messageService = ServiceFactory.createMessageService();
  private taskHolderService = ServiceFactory.createTaskHolderService();

  constructor() {
    super();
  }

  async getMessageList(filter?: MessageFilter): Promise<ICollection<IMessage>> {
    return (await this.messageService.getCollection(filter)).result;
  }

  async getMessageCount(filter?: MessageFilter): Promise<number> {
    return (await this.messageService.getMessageCount(filter)).result;
  }

  async getMessage(pkey: any): Promise<IMessage> {
    return (await this.messageService.getElement(pkey)).result;
  }

  async createMessage(message: IMessage): Promise<IMessage> {
    return (await this.messageService.createElement(message)).result;
  }

  async archiveMessage(message: IMessage): Promise<IMessage> {
    return (await this.messageService.archiveMessage(message)).result;
  }

  async reopenMessage(message: IMessage): Promise<IMessage> {
    return (await this.messageService.reopenMessage(message)).result;
  }

  async getTaskHoldersList(filter?: TaskHolderFilter): Promise<ICollection<ITaskHolder>> {
    return (await this.taskHolderService.getCollection(filter)).result;
  }

  async getTaskHoldersListEmployee(filter?: TaskHolderFilter): Promise<ICollection<ITaskHolder>> {
    return (await this.taskHolderService.getTaskHolderListEmployee(filter)).result;
  }

  async getTaskHoldersEmployeeCount(filter?: TaskHolderFilter): Promise<number> {
    return (await this.taskHolderService.getTaskHolderEmployeeCount(filter)).result;
  }

  async getTaskHolder(pkey: any): Promise<ITaskHolder> {
    return (await this.taskHolderService.getElement(pkey)).result;
  }

  async markAsReadNotification(message: IMessage): Promise<boolean> {
    return (await this.messageService.markAsReadNotification(message)).result;
  }

  async getTaskHolderByDocument(document: string): Promise<ITaskHolder> {
    return (await this.taskHolderService.getTaskHolderByDocument(document)).result;
  }

  async getTaskHolderByUserId(userId: string): Promise<ITaskHolder> {
    return (await this.taskHolderService.getTaskHolderByUserId(userId)).result;
  }
}
