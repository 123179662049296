import { Injectable } from '@angular/core';
import { ServiceFactory, ICollection, IFolder } from 'libraries/AonSDK/src/aon';
import { CommonService } from './common.service';
import { FolderFilter } from 'libraries/AonSDK/src/utils/ModelsFilters';

@Injectable({
  providedIn: 'root',
})
export class FolderService extends CommonService {

  private service = ServiceFactory.createFolderService();

  constructor() {
    super();
  }

  async getFolderList(filter?: FolderFilter): Promise<ICollection<IFolder>> {
    return (await this.service.getCollection(filter)).result;
  }

  async getLaboralFolderCount(filter: FolderFilter): Promise<number> {
    return (await this.service.getLaboralFolderCount(filter));
  }
}
