import { Component, Input, OnInit } from '@angular/core';
import { ChartDataset, ChartType, ChartOptions, Color } from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  @Input() chartLabels: string[] = [];
  @Input() chartDataLabel: ChartDataset[] = [];
  @Input() chartData: ChartDataset[] = [];
  @Input() chartType: ChartType = 'doughnut';
  @Input() colors: Color[] = [];
  @Input() chartOptions: ChartOptions = {};
  @Input() legend: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  // events
  chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
