import { IProduct, ITax, IIRPF, IInvoiceLine, IInvoiceExpirationLine, IInvoiceSerie, IContact, IInvoiceTransactionType, IInvoiceActivity, IInvoice, IProductCategory, IDocument, IFolder, ICertificate, IEnterprise, IRegistryEnterprise, IDocumentNote, IBank, ITaxModel, IMessage, IMessageChat, IEmployee, IUser, MediaType, IMedia, IMark, IMarkDetail, IContract, ISalary, ILocation } from "../interfaces/modelsInterfaces";
import { Bank } from "../models/Bank";
import { Certificate } from "../models/Certificate";
import { Contact } from "../models/Contact";
import { DocumentNote } from "../models/DocumentNote";
import { Employee } from "../models/Employee";
import { Enterprise } from "../models/Enterprise";
import { Folder } from "../models/Folder";
import { Invoice } from "../models/Invoice";
import { InvoiceExpirationLine } from "../models/InvoiceExpirationLine";
import { InvoiceLine } from "../models/InvoiceLine";
import { Message } from "../models/Message";
import { MessageChat } from "../models/MessageChat";
import { Product } from "../models/Product";
import { RegistryEnterprise } from "../models/RegistryEnterprise";
import { TaxModel } from "../models/TaxModel";
import { User } from "../models/User";
import { Document } from "../models/Document";
import { Media } from "../models/Media";
import { Mark } from "../models/Mark";
import { MarkDetail } from "../models/MarkDetail";
import { Contract } from "../models/Contract";
import { Tax } from "../models/Tax";
import { IRPF } from "../models/IRPF";
import { InvoiceActivity } from "../models/InvoiceActivity";
import { InvoiceSerie } from "../models/InvoiceSerie";
import { InvoiceTransactionType } from "../models/InvoiceTransactionType";
import { ProductCategory } from "../models/ProductCategory";
import { Salary } from "../models/Salary";
import { Location } from "../models/Location";

export class Factory {

    static createLocation(): ILocation {
        return new Location();
    }

    static createSalary(): ISalary {
        return new Salary();
    }

    static createProductCategory(): IProductCategory {
        return new ProductCategory();
    }

    static createInvoiceTransactionType(): IInvoiceTransactionType {
         return new InvoiceTransactionType();
    }

    static createInvoiceActivity(): IInvoiceActivity {
         return new InvoiceActivity();
    }

    static createSerie(): IInvoiceSerie {
        return new InvoiceSerie();
    }

    static createIRPF(): IIRPF {
        return new IRPF();
    }

    static createTax(): ITax {
        return new Tax();
    }

    static createMark(): IMark {
        return new Mark();
    }

    static createMarkDetail(): IMarkDetail {
        return new MarkDetail();
    }

    static createMedia(mediaType?: MediaType, value?: any): IMedia {
      return new Media(mediaType, value);
    }

    static createInvoiceLine(): IInvoiceLine {
        return new InvoiceLine();
    }

    static createInvoiceExpirationLine(): IInvoiceExpirationLine {
        return new InvoiceExpirationLine();
    }

    static createInvoice(): IInvoice {
        return new Invoice();
    }

    static createProduct(): IProduct {
        return new Product();
    }

    static createContact(): IContact {
        return new Contact();
    }

    static createDocument(file?: string, fileName?: string, fileSize?: number, fileType?: string, date?: Date, path?: string): IDocument {
        return new Document(file, fileName, fileSize, fileType, date, path);
    }

    static createFolder(): IFolder {
        return new Folder();
    }

    static createCertificate(): ICertificate {
        return new Certificate();
    }

    static createEnterprise(): IEnterprise {
        return new Enterprise();
    }

    static createRegistryEnterprise(): IRegistryEnterprise {
      return new RegistryEnterprise();
    }

    static createDocumentNote(): IDocumentNote {
        return new DocumentNote();
    }

    static createBank(): IBank {
        return new Bank();
    }

    static createTaxModel(): ITaxModel {
        return new TaxModel();
    }

    static createMessage(): IMessage {
        return new Message();
    }

    static createMessageChat(): IMessageChat {
        return new MessageChat();
    }

    static createEmployee(): IEmployee {
        return new Employee();
    }

    static createUser(): IUser {
        return new User();
    }

    static createContract(): IContract {
        return new Contract();
    }
}
