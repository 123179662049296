import { ICollectable, ICollection } from "./utilitiesInterfaces";

export interface IDocument extends ICollectable {
    File: string;
    getFile(): string;
    setFile(value: string): IDocument;
    FileName: string;
    getFileName(): string;
    setFileName(value: string): IDocument;
    FileSize: number;
    getFileSize(): number;
    setFileSize(value: number): IDocument;
    FileType: string;
    getFileType(): string;
    setFileType(value: string): IDocument;
    Path: string;
    getPath(): string;
    setPath(value: string): IDocument;
    Date: Date;
    getDate(): Date;
    setDate(value: Date): IDocument;
    Tag: ICollection<IDocumentTag>;
    getTag(): ICollection<IDocumentTag>;
    setTag(value: ICollection<IDocumentTag>): IDocument;
}

export interface IDocumentTag extends ICollectable {
    Name: string;
    getName(): string;
    setName(value: string): IDocumentTag;
}

export enum MainFolders {
    PROFORMA = '/proforma',
    ACONTABILIZAR = '/a_contabilizar',
    CONTABILIZADO = '/contabilizado',
    PAPELERA = '/papelera',
    FISCAL = '/fiscal',
    LABORAL = '/laboral',
    DOCUMENTOS = '/documentos'
}

export interface IFolder extends ICollectable {
    Name: string;
    getName(): string;
    setName(value: string): IFolder;
    Path: string;
    getPath(): string;
    setPath(value: string): IFolder;
    Parent: string;
    getParent(): string;
    setParent(value: string): IFolder;
    FromUser: boolean;
}

export interface ICertificate extends ICollectable {
  Password: string;
  getPassword(): string;
  setPassword(value: string): ICertificate;
  Name: string;
  getName(): string;
  setName(value: string): ICertificate;
  SharedType: ICertificateSharedType;
  getSharedType(): ICertificateSharedType;
  setSharedType(value: ICertificateSharedType): ICertificate;
  ExpeditionDate: Date;
  getExpeditionDate(): Date;
  setExpeditionDate(value: Date): ICertificate;
  ExpirationDate: Date;
  getExpirationDate(): Date;
  setExpirationDate(value: Date): ICertificate;
  Alias: string;
  getAlias(): string;
  setAlias(value: string): ICertificate;
  Type: ICertificateType;
  getType(): ICertificateType;
  setType(value: ICertificateType): ICertificate;
  Tgss: boolean;
  getTgss(): boolean;
  setTgss(value: boolean): ICertificate;
  Sepe: boolean;
  getSepe(): boolean;
  setSepe(value: boolean): ICertificate;
  Aeat: boolean;
  getAeat(): boolean;
  setAeat(value: boolean): ICertificate;
  DocumentUser: string;
  getDocumentUser(): string;
  setDocumentUser(value: string): ICertificate;
  Representation: string;
  getRepresentation(): string;
  setRepresentation(value: string): ICertificate;
  SecondaryUsers: ICollection<ISecondaryUser>;
  getSecondaryUsers(): ICollection<ISecondaryUser>;
  setSecondaryUsers(value: ICollection<ISecondaryUser>): ICertificate;
}

export interface ISecondaryUser extends ICollectable {
  Name: string;
  getName(): string;
  setName(value: string): ISecondaryUser;
  Naf: string;
  getNaf(): string;
  setNaf(value: string): ISecondaryUser;
  Status: string;
  getStatus(): string;
  setStatus(value: string): ISecondaryUser;
  StatusDate: Date;
  getStatusDate(): Date;
  setStatusDate(value: Date): ISecondaryUser;
}

export interface IEnterprise extends ICollectable {
    Id: string;
    getId(): string;
    setId(value: string): IEnterprise;
    Name: string;
    getName(): string;
    setName(value: string): IEnterprise;
    ProfilePhoto: string;
    getProfilePhoto(): string;
    setProfilePhoto(value: string): IEnterprise;
    Address: IAddress;
    getAdress(): IAddress;
    setAdress(value: IAddress): IEnterprise;
    Country: string;
    getCountry(): string;
    setCountry(value: string): IEnterprise;
    Province: string;
    getProvince(): string;
    setProvince(value: string): IEnterprise;
    SocialReason: string;
    getSocialReason(): string;
    setSocialReason(value: string): IEnterprise;
    Email: IMedia [];
    getEmail(): IMedia [];
    setEmail(value: IMedia []): IEnterprise;
    Phone: IMedia[];
    getPhone(): IMedia[];
    setPhone(value: IMedia[]): IEnterprise;
    Website: IMedia[];
    getWebsite(): IMedia[];
    setWebsite(value: IMedia[]): IEnterprise;
    Document: string;
    getDocument(): string;
    setDocument(value: string): IEnterprise;
    DomainName: string;
    getDomainName(): string;
    setDomainName(value: string): IEnterprise;
    DomainId: string;
    getDomainId(): string;
    setDomainId(value: string): IEnterprise;
    Registry: string;
    getRegistry(): string;
    setRegistry(value: string): IEnterprise;
}

export const enum MediaType {
  PHONE = 'fixed_phone',
  EMAIL = 'email',
  WEB = 'web'
}

export enum ContactMediaType {
  'fixed_phone' = 'CELLULAR',
  'email' = 'EMAIL',
  'web' = 'WEB'
}
export interface IMedia extends ICollectable {
  Value: string;
  getValue(): string;
  setValue(value: string) : IMedia;
  Type: MediaType;
  getType(): MediaType;
  setType(value: MediaType): IMedia;
}

export interface IAddress extends ICollectable {
  Type: IStreetType;
  getType(): IStreetType;
  setType(value: IStreetType): IAddress;
  Direction: string;
  getDirection(): string;
  setDirection(value: string): IAddress;
  Number: string;
  getNumber(): string;
  setNumber(value: string): IAddress;
  RestOfDirection: string;
  getRestOfDirection(): string;
  setRestOfDirection(value: string): IAddress;
  Country: ICountryAon;
  getCountry(): ICountryAon;
  setCountry(value: ICountryAon): IAddress;
  PostalCode: string;
  getPostalCode(): string;
  setPostalCode(value: string): IAddress;
  City: string;
  getCity(): string;
  setCity(value: string): IAddress;
  Province: string;
  getProvince(): string;
  setProvince(value: string): IAddress;
  FullDirection: string;
  getFullDirection(): string;
  setFullDirection(value: string): IAddress;
}

export interface IStreetType extends ICollectable {
  Value: string;
  getValue(): string;
  setValue(value: string): IStreetType;
  FullName: string;
  getFullName(): string;
  setFullName(value: string): IStreetType;
}

export interface ICountryAon extends ICollectable {
  Value: string;
  getValue(): string;
  setValue(value: string): ICountryAon;
  FullName: string;
  getFullName(): string;
  setFullName(value: string): ICountryAon;
}

export interface IRegistryEnterprise extends ICollectable {
  IdEnterprise: string;
  Description: string;
  getDescription(): string;
  setDescription(value: string): IRegistryEnterprise;
  DateCreation: Date;
  getDateCreation(): Date;
  setDateCreation(value: Date): IRegistryEnterprise;
  DateRegistration: Date;
  getDateRegistration(): Date;
  setDateRegistration(value: Date): IRegistryEnterprise;
  Notary: string;
  getNotary(): string;
  setNotary(value: string): IRegistryEnterprise;
  Protocol: string;
  getProtocol(): string;
  setProtocol(value: string): IRegistryEnterprise;
  Inscription: string;
  getInscription(): string;
  setInscription(value: string): IRegistryEnterprise;
}

export interface IDocumentNote extends ICollectable {
    Text: string;
    getText(): string;
    setText(value: string): IDocumentNote;
    Path: string;
    getPath(): string;
    setPath(value: string): IDocumentNote;
}

export interface IBank extends ICollectable {
    Active: boolean;
    getActive(): boolean;
    setActive(value: boolean): IBank;
    Id: string;
    getId(): string;
    setId(value: string): IBank;
    Name: string;
    getName(): string;
    setName(value: string): IBank;
    Total: number;
    getTotal(): number;
    setTotal(value: number): IBank;
    Logo: string;
    getLogo(): string;
    setLogo(value: string): IBank;
    SwiftBic: string;
    getSwiftBic(): string;
    setSwiftBic(value: string): IBank;
    Iban: string;
    getIban(): string;
    setIban(value: string): IBank;
    LastUpdate: Date;
    getLastUpdate(): Date;
    setLastUpdate(value: Date): IBank;
    SyncStatus: string;
    getSyncStatus(): string;
    setSyncStatus(value: string): IBank;
    Requisition: string;
    getRequisition(): string;
    setRequisition(value: string): IBank;
}

export interface IBankMovements extends ICollectable {
  OperationDate: Date;
  getOperationDate(): Date;
  setOperationDate(value: Date): IBankMovements;
  Description: string
  getDescription(): string;
  setDescription(value: string): IBankMovements;
  Status: string
  getStatus(): string;
  setStatus(value: string): IBankMovements;
  Amount: number
  getAmount(): number;
  setAmount(value: number): IBankMovements;
}

export interface IBankBalances extends ICollectable {
  BalanceType: string
  getBalanceType(): string;
  setBalanceType(value: string): IBankBalances;
  Amount: number
  getAmount(): number;
  setAmount(value: number): IBankBalances;
  Currency: string
  getCurrency(): string;
  setCurrency(value: string): IBankBalances;
  ReferenceDate: Date
  getReferenceDate(): Date;
  setReferenceDate(value: Date): IBankBalances;
}

export enum statusTaxModel {
  EN_PROCESO = 'en proceso',
  PENDIENTE = 'pendiente',
  RECTIFICADO = 'rectificado',
  CONFIRMADO = 'confirmado',
  PRESENTADO = 'presentado',
  BLOQUEADO = 'bloqueado',
  DESCONOCIDO = 'desconocido'
}

export interface ITaxModel extends ICollectable {
    Name: string;
    getName(): string;
    setName(value: string): ITaxModel;
    TaxType: string;
    getTaxType(): string;
    setTaxType(value: string): ITaxModel;
    Status: statusTaxModel;
    getStatus(): statusTaxModel;
    setStatus(value: statusTaxModel): ITaxModel;
    PaymentMethod: string;
    getPaymentMethod(): string;
    setPaymentMethod(value: string): ITaxModel;
    Result: string;
    getResult(): string;
    setResult(value: string): ITaxModel;
    Trimester: number;
    getTrimester(): number;
    setTrimester(value: number): ITaxModel;
    Year: number;
    getYear(): number;
    setYear(value: number): ITaxModel;
}

export interface ITaxModelDetail extends ICollectable {
  Expression: string;
  Description: string;
  Type: string;
  Acumulated_amount: number;
  Declared_amount: number;
  Resultamount: number;
  Adjust_amount: number;
  Amount: number;
  getExpression(): string;
  getDescription(): string;
  getType(): string;
  getAcumulated_amount(): number;
  getDeclared_amount(): number;
  getResultamount(): number;
  getAdjust_amount(): number;
  getAmount(): number;
  setExpression(value: string): ITaxModelDetail;
  setDescription(value: string): ITaxModelDetail;
  setType(value: string): ITaxModelDetail;
  setAcumulated_amount(value: number): ITaxModelDetail;
  setDeclared_amount(value: number): ITaxModelDetail;
  setResultamount(value: number): ITaxModelDetail;
  setAdjust_amount(value: number): ITaxModelDetail;
  setAmount(value: number): ITaxModelDetail;
}

export enum StatusMessage {
    VISTA = 'vista',
    NUEVA = 'nueva',
    ABIERTA = 'abierta',
    CERRADA = 'cerrada',
    PENDIENTE = 'pendiente',
    REALIZADA = 'realizada'
}

export enum ApiStatusMessage {
  'vista' = 'read',
  'nueva' = 'unread',
  'abierta' = 'pending',
  'cerrada' = 'deleted,finished',
  'pendiente' = 'pending',
  'realizada' = 'finished,deleted',
  'allTask' = 'pending,finished,deleted',
}

export enum TypeMessage {
    CONSULTA = 'consulta',
    TAREA = 'tarea',
    NOTIFICACION = 'notificacion',
    TRAMITE = 'tramite',
    NULL = 'null'
}

export enum MessageRequestType {
  SOLICITUD_VACACIONES = 1,
  ALTA_EMPLEADO = 2,
  MODIFICACION_FICHAJE = 3
}

export enum ApiTypeMessage {
    'consulta' = 'query',
    'tarea' = 'task',
    'notificacion' = 'notification',
    'tramite' = 'request',
    'null' = 'null'
}

export interface IMessage extends ICollectable {
    Id: string;
    Name: string;
    getName(): string;
    setName(value: string): IMessage;
    Title: string;
    getTitle(): string;
    setTitle(value: string): IMessage;
    Description: string;
    getDescription(): string;
    setDescription(value: string): IMessage;
    Date: Date;
    getDate(): Date;
    setDate(value: Date): IMessage;
    Type: TypeMessage;
    getType(): TypeMessage;
    setType(value: TypeMessage): IMessage;
    Status: StatusMessage;
    getStatus(): StatusMessage;
    setStatus(value: StatusMessage): IMessage;
    EndDate: Date;
    getEndDate(): Date;
    setEndDate(value: Date): IMessage;
    LastMessageChatOrigin: boolean;
    getLastMessageChatOrigin(): boolean;
    setLastMessageChatOrigin(value: boolean): IMessage;
    TaskHolder: ITaskHolder;
    getTaskHolder(): ITaskHolder;
    setTaskHolder(value: ITaskHolder): IMessage;
}

export interface IWorkplace extends ICollectable {
    Description: string;
    getDescription(): string;
    setDescription(value: string): IWorkplace;
}

export interface ICcc extends ICollectable {
    Description: string;
    getDescription(): string;
    setDescription(value: string): ICcc;
    Code: string;
    getCode(): string;
    setCode(value: string): ICcc;
    RegimeCode: string;
    getRegimeCode(): string;
    setRegimeCode(value: string): ICcc;
    Geozone: string;
    getGeozone(): string;
    setGeozone(value: string): ICcc;
    Activity: string;
    getActivity(): string;
    setActivity(value: string): ICcc;
}

export interface ITaskHolder extends ICollectable {
    Id: string;
    Name: string;
    getName(): string;
    setName(value: string): ITaskHolder;
}

export interface IGeozone extends ICollectable {
  value: string;
  getValue(): string;
  setValue(value: string): IGeozone;
}

export interface IMessageChat extends ICollectable {
    Id: string;
    IdMessage: string;
    getIdMessage(): string;
    setIdMessage(value: string): IMessageChat;
    Name: string;
    getName(): string;
    setName(value: string): IMessageChat;
    Description: string;
    getDescription(): string;
    setDescription(value: string): IMessageChat;
    Date: Date;
    getDate(): Date;
    setDate(value: Date): IMessageChat;
    Type: string;
    getType(): string;
    setType(value: string): IMessageChat;
}

export interface IEmployee extends ICollectable {
    Name: string;
    getName(): string;
    setName(value: string): IEmployee;
    Lastname: string;
    getLastname(): string;
    setLastname(value: string): IEmployee;
    Document: string;
    getDocument(): string;
    setDocument(value: string): IEmployee;
    Email: string;
    getEmail(): string;
    setEmail(value: string): IEmployee;
    Phone: string;
    getPhone(): string;
    setPhone(value: string): IEmployee;
    Naf: string;
    getNaf(): string;
    setNaf(value: string): IEmployee;
    Active: boolean;
    getActive(): boolean;
    setActive(value: boolean): IEmployee;
}

export interface IContract extends ICollectable {
    Name: string;
    getName(): string;
    setName(value: string): IContract;
    LastName: string;
    getLastName(): string;
    setLastName(value: string): IContract;
    FirstSurname: string;
    getFirstSurname(): string;
    setFirstSurname(value: string): IContract;
    SecondSurname: string;
    getSecondSurname(): string;
    setSecondSurname(value: string): IContract;
    Document: string;
    getDocument(): string;
    setDocument(value: string): IContract;
    Nss: string;
    getNss(): string;
    setNss(value: string): IContract;
    Category: string;
    getCategory(): string;
    setCategory(value: string): IContract;
    GrossCost: number;
    getGrossCost(): number;
    setGrossCost(value: number): IContract;
    StartDate: Date;
    getStartDate(): Date;
    setStartDate(value: Date): IContract;
    EndDate?: Date;
    getEndDate(): Date;
    setEndDate(value: Date): IContract;
    Undefined: boolean;
    getUndefined(): boolean;
    setUndefined(value: boolean): IContract;
    SalaryAccordingAgreement: boolean;
    getSalaryAccordingAgreement(): boolean;
    setSalaryAccordingAgreement(value: boolean): IContract;
    Fulltime: boolean;
    getFulltime(): boolean;
    setFulltime(value: boolean): IContract;
    Active: boolean;
    getActive(): boolean;
    setActive(value: boolean): IContract;
    Status: boolean;
    getStatus(): boolean;
    setStatus(value: boolean): IContract;
    TotalTime: number;
    getTotalTime(): number;
    setTotalTime(value: number): IContract;
    DayType: IDayType;
    getDayType(): IDayType;
    setDayType(value: IDayType): IContract;
    SalaryType: ISalaryType;
    getSalaryType(): ISalaryType;
    setSalaryType(value: ISalaryType): IContract;
    Salary: number;
    getSalary(): number;
    setSalary(value: number): IContract;
    Hours: number;
    getHours(): number;
    setHours(value: number): IContract;
    Coef: number;
    getCoef(): number;
    setCoef(value: number): IContract;
    WorkCenter: string;
    getWorkCenter(): string;
    setWorkCenter(value: string): IContract;
    WorkCenterId: string;
    getWorkCenterId(): string;
    setWorkCenterId(value: string): IContract;
    Type: string;
    getType(): string;
    setType(value: string): IContract;
    TypeId: string;
    getTypeId(): string;
    setTypeId(value: string): IContract;
    QuoteGroup: IQuoteGroup;
    getQuoteGroup(): IQuoteGroup;
    setQuoteGroup(value: IQuoteGroup): IContract;
    Cno: ICno;
    getCno(): ICno;
    setCno(value: ICno): IContract;
    Rlce: IRlce;
    getRlce(): IRlce;
    setRlce(value: IRlce): IContract
    WorkerCollective: IWorkerCollective;
    getWorkerCollective(): IWorkerCollective;
    setWorkerCollective(value: IWorkerCollective): IContract
    Occupation: IOccupation;
    getOccupation(): IOccupation;
    setOccupation(value: IOccupation): IContract
    Ccc: ICcc;
    getCcc(): ICcc;
    setCcc(value: ICcc): IContract;
}

export interface ICno extends ICollectable, ISimpleValue {}
export interface IRlce extends ICollectable, ISimpleValue {}
export interface IWorkerCollective extends ICollectable, ISimpleValue {}
export interface IOccupation extends ICollectable, ISimpleValue {}
export interface IContractType extends ICollectable, ISimpleValue {
  ShortValue: string;
  getShortValue(): string;
  setShortValue(value: string): IContractType;
}

export interface IQuoteGroup extends ICollectable {
    Name: string;
    getName(): string;
    setName(value: string): IQuoteGroup;
    QuoteMonth: boolean;
    getQuoteMonth(): boolean;
    setQuoteMonth(value: boolean): IQuoteGroup;
}

export interface IDayType extends ICollectable {
    Name: string;
    getName(): string;
    setName(value: string): IDayType;
}

export interface ISalaryType extends ICollectable {
    Name: string;
    getName(): string;
    setName(value: string): ISalaryType;
}

export interface IMark extends ICollectable {
    Name: string, // nombre del usuario que marca
    getName(): string;
    setName(value: string): IMark;
    IdUser: string, // id del usuario que marca
    getIdUser(): string;
    setIdUser(value: string): IMark;
    Date: Date, // fecha del marcage
    getDate(): Date;
    setDate(value: Date): IMark;
    EntryDate: Date, // hora de entrada del marcaje
    getEntryDate(): Date;
    setEntryDate(value: Date): IMark;
    ExitDate: Date, // hora de salida del marcaje
    getExitDate(): Date;
    setExitDate(value: Date): IMark;
    Time: number; // duracion del marcaje total
    getTime(): number;
    setTime(value: number): IMark;
    Location: ILocation, // localizacion al marcar
    getLocation(): ILocation;
    setLocation(value: ILocation): IMark;
    Status: MarkStatus // entrada, salida, pausa
    getStatus(): MarkStatus;
    setStatus(value: MarkStatus): IMark;
    HasPause: boolean;
    getHasPause(): boolean;
    setHasPause(value: boolean): IMark;
    TimeString: string;
    getTimeString(): string;
    setTimeString(value: string): IMark;
}

export interface IMarkDetail extends ICollectable {
    Date: Date;
    getDate(): Date;
    setDate(value: Date): IMarkDetail;
    Status: MarkStatus;
    getStatus(): MarkStatus;
    setStatus(value: MarkStatus): IMarkDetail;
    Location: ILocation;
    getLocation(): ILocation;
    setLocation(value: ILocation): IMarkDetail;
    IdUser: string;
    getIdUser(): string;
    setIdUser(value: string): IMarkDetail;
}

export interface ILocation extends ICollectable {
    Key: string;
    Coordinates: string;
    getCoordinates(): string;
    setCoordinates(value: string): ILocation;
    Description: string;
    getDescription(): string;
    setDescription(value: string): ILocation;
    Radio: number;
    getRadio(): number;
    setRadio(value: number): ILocation;
    Latitude: number;
    getLatitude(): number;
    setLatitude(value: number): ILocation;
    Longitude: number;
    getLongitude(): number;
    setLongitude(value: number): ILocation;
}

export interface IMarkHistory extends ICollectable {
    User: String;
    getUser(): String;
    setUser(value: String): IMarkHistory;
    LastModification: Date;
    getLastModification(): Date;
    setLastModification(value: Date): IMarkHistory;
    LastRegistry: Date;
    getLastRegistry(): Date;
    setLastRegistry(value: Date): IMarkHistory;
    Location: any;
    getLocation(): any;
    setLocation(value: any): IMarkHistory;
    Status: MarkStatus;
    getStatus(): MarkStatus;
    setStatus(value: MarkStatus): IMarkHistory;
}

export enum MarkStatus {
  ENTRADA = 'entrada',
  PAUSA = 'pausa',
  SALIDA = 'salida'
}

export enum MarkStatusToApi {
  'pausa' = 'pause',
  'salida' = 'out',
  'entrada' = 'in'
}

export interface IPause {
    StartPause: Date,
    EndPause: Date
}

export interface IUser extends ICollectable {
    Name: string,
    getName(): string;
    setName(value: string): IUser;
    Lastname: string,
    getLastname(): string;
    setLastname(value: string): IUser;
    Document: string,
    getDocument(): string;
    setDocument(value: string): IUser;
    Email: string,
    getEmail(): string;
    setEmail(value: string): IUser;
    Password: string,
    getPassword(): string;
    setPassword(value: string): IUser;
    Phone: string,
    getPhone(): string;
    setPhone(value: string): IUser;
    Active: boolean,
    getActive(): boolean;
    setActive(value: boolean): IUser;
    Enterprises: string [],
    getEnterprises(): string [];
    setEnterprises(value: string[]): IUser;
}

export interface IAuth extends ICollectable{
    Email: string;
    Password: string;
}

export interface ICommunity {
  label: string;
  _about: string;
}

export interface ICountry {
  name:         Name;
  tld?:         string[];
  cca2:         string;
  ccn3?:        string;
  cca3:         string;
  cioc?:        string;
  independent?: boolean;
  status:       Status;
  unMember:     boolean;
  currencies?:  Currencies;
  idd:          Idd;
  capital?:     string[];
  altSpellings: string[];
  region:       Region;
  subregion?:   string;
  languages?:   { [key: string]: string };
  translations: { [key: string]: Translation };
  latlng:       number[];
  landlocked:   boolean;
  borders?:     string[];
  area:         number;
  demonyms?:    Demonyms;
  flag:         string;
  maps:         Maps;
  population:   number;
  gini?:        { [key: string]: number };
  fifa?:        string;
  car:          Car;
  timezones:    string[];
  continents:   Continent[];
  flags:        Flags;
  coatOfArms:   CoatOfArms;
  startOfWeek:  StartOfWeek;
  capitalInfo:  CapitalInfo;
  postalCode?:  PostalCode;
}

export interface CapitalInfo {
  latlng?: number[];
}

export interface Car {
  signs?: string[];
  side:   Side;
}

export enum Side {
  Left = "left",
  Right = "right",
}

export interface CoatOfArms {
  png?: string;
  svg?: string;
}

export enum Continent {
  Africa = "Africa",
  Antarctica = "Antarctica",
  Asia = "Asia",
  Europe = "Europe",
  NorthAmerica = "North America",
  Oceania = "Oceania",
  SouthAmerica = "South America",
}

export interface Currencies {
  ZAR?: Aed;
  NOK?: Aed;
  WST?: Aed;
  GMD?: Aed;
  XCD?: Aed;
  EUR?: Aed;
  AWG?: Aed;
  XOF?: Aed;
  KPW?: Aed;
  PYG?: Aed;
  BMD?: Aed;
  XAF?: Aed;
  USD?: Aed;
  GBP?: Aed;
  MZN?: Aed;
  SOS?: Aed;
  SGD?: Aed;
  NIO?: Aed;
  AUD?: Aed;
  PEN?: Aed;
  MXN?: Aed;
  BAM?: BAM;
  BHD?: Aed;
  MOP?: Aed;
  BBD?: Aed;
  UZS?: Aed;
  CNY?: Aed;
  MWK?: Aed;
  ZWL?: Aed;
  KES?: Aed;
  PKR?: Aed;
  FJD?: Aed;
  SZL?: Aed;
  JEP?: Aed;
  TWD?: Aed;
  LKR?: Aed;
  BYN?: Aed;
  AED?: Aed;
  ANG?: Aed;
  CRC?: Aed;
  AOA?: Aed;
  UYU?: Aed;
  CDF?: Aed;
  KWD?: Aed;
  TRY?: Aed;
  MRU?: Aed;
  TVD?: Aed;
  PAB?: Aed;
  EGP?: Aed;
  AZN?: Aed;
  RWF?: Aed;
  INR?: Aed;
  ISK?: Aed;
  SRD?: Aed;
  BGN?: Aed;
  SLL?: Aed;
  TND?: Aed;
  CUC?: Aed;
  CUP?: Aed;
  TTD?: Aed;
  KMF?: Aed;
  SHP?: Aed;
  RON?: Aed;
  NPR?: Aed;
  SAR?: Aed;
  DOP?: Aed;
  DKK?: Aed;
  FOK?: Aed;
  KID?: Aed;
  VUV?: Aed;
  HUF?: Aed;
  YER?: Aed;
  SCR?: Aed;
  LYD?: Aed;
  ILS?: Aed;
  VND?: Aed;
  IRR?: Aed;
  NAD?: Aed;
  LBP?: Aed;
  MYR?: Aed;
  MNT?: Aed;
  GEL?: Aed;
  TJS?: Aed;
  ALL?: Aed;
  TMT?: Aed;
  COP?: Aed;
  VES?: Aed;
  GNF?: Aed;
  SSP?: Aed;
  UAH?: Aed;
  FKP?: Aed;
  HNL?: Aed;
  BRL?: Aed;
  MUR?: Aed;
  THB?: Aed;
  BOB?: Aed;
  SEK?: Aed;
  GGP?: Aed;
  ZMW?: Aed;
  ERN?: Aed;
  KZT?: Aed;
  MAD?: Aed;
  JOD?: Aed;
  MMK?: Aed;
  CZK?: Aed;
  JMD?: Aed;
  KGS?: Aed;
  SDG?: BAM;
  STN?: Aed;
  GIP?: Aed;
  LSL?: Aed;
  PLN?: Aed;
  JPY?: Aed;
  LRD?: Aed;
  CVE?: Aed;
  IMP?: Aed;
  BIF?: Aed;
  PGK?: Aed;
  UGX?: Aed;
  AFN?: Aed;
  XPF?: Aed;
  BWP?: Aed;
  LAK?: Aed;
  GTQ?: Aed;
  CHF?: Aed;
  SBD?: Aed;
  SYP?: Aed;
  BDT?: Aed;
  DJF?: Aed;
  GHS?: Aed;
  OMR?: Aed;
  BSD?: Aed;
  DZD?: Aed;
  HTG?: Aed;
  PHP?: Aed;
  CKD?: Aed;
  NZD?: Aed;
  TOP?: Aed;
  MGA?: Aed;
  CAD?: Aed;
  AMD?: Aed;
  NGN?: Aed;
  BZD?: Aed;
  RUB?: Aed;
  KYD?: Aed;
  MDL?: Aed;
  RSD?: Aed;
  CLP?: Aed;
  IDR?: Aed;
  MVR?: Aed;
  BND?: Aed;
  GYD?: Aed;
  TZS?: Aed;
  KHR?: Aed;
  QAR?: Aed;
  ARS?: Aed;
  IQD?: Aed;
  BTN?: Aed;
  KRW?: Aed;
  HKD?: Aed;
  MKD?: Aed;
  ETB?: Aed;
}

export interface Aed {
  name:   string;
  symbol: string;
}

export interface BAM {
  name: string;
}

export interface Demonyms {
  eng:  Eng;
  fra?: Eng;
}

export interface Eng {
  f: string;
  m: string;
}

export interface Flags {
  png:  string;
  svg:  string;
  alt?: string;
}

export interface Idd {
  root?:     string;
  suffixes?: string[];
}

export interface Maps {
  googleMaps:     string;
  openStreetMaps: string;
}

export interface Name {
  common:      string;
  official:    string;
  nativeName?: { [key: string]: Translation };
}

export interface Translation {
  official: string;
  common:   string;
}

export interface PostalCode {
  format: string;
  regex?: string;
}

export enum Region {
  Africa = "Africa",
  Americas = "Americas",
  Antarctic = "Antarctic",
  Asia = "Asia",
  Europe = "Europe",
  Oceania = "Oceania",
}

export enum StartOfWeek {
  Monday = "monday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export enum Status {
  OfficiallyAssigned = "officially-assigned",
  UserAssigned = "user-assigned",
}

export interface ISimpleValue {
    Value: string;
    getValue(): string;
    setValue(value: string): ISimpleValue;
}

export enum InvoiceType { GASTO = 'gasto', VENTA = 'venta', TICKET = 'ticket' }

export enum InvoiceStatus { A_CONTABILIZAR = 'a_contabilizar', CONTABILIZADO = 'contabilizado' }

export interface IInvoice extends ICollectable{
    /**
     * Numero de referencia de la factura, suele ser <serie>/<numero_de_factura>
     */
    Reference: string;
    getReference(): string;
    setReference(value: string): IInvoice;
    /**
     * Serie de la factura
     */
    Serie: IInvoiceSerie;
    getSerie(): IInvoiceSerie;
    setSerie(value: IInvoiceSerie): IInvoice;
    /**
     * Numero de la factura
     */
    InvoiceNumber: string;
    getInvoiceNumber(): string;
    setInvoiceNumber(value: string): IInvoice;
    /**
     * Fecha
     */
    Date: Date;
    getInvoiceDate(): Date;
    setInvoiceDate(value: Date): IInvoice;
    /**
     * Precio total
     */
    TotalAmount: number;
    getTotalAmount(): number;
    setTotalAmount(value: number): IInvoice;
    /**
     * Contacto a quien va dirigida la factura
     */
    Contact: IContact;
    getContact(): IContact;
    setContact(value: IContact): IInvoice;
    /**
     * Categoria a la que pertenece
     */
    Category: IInvoiceCategory;
    getCategory(): IInvoiceCategory;
    setCategory(value: IInvoiceCategory): IInvoice;
    /**
     * Líneas de detalle de los productos/servicios de una factura almacenados en una colección
     */
    Lines: ICollection<IInvoiceLine>;
    getLines(): ICollection<IInvoiceLine>;
    setLines(value: ICollection<IInvoiceLine>): IInvoice;
    /**
     * Tipo de transacción
     */
    TransactionType: IInvoiceTransactionType;
    getTransactionType(): IInvoiceTransactionType;
    setTransactionType(value: IInvoiceTransactionType): IInvoice;
    /**
     * Actividad a la que pertenece
     */
    Activity: IInvoiceActivity;
    getActivity(): IInvoiceActivity;
    setActivity(value: IInvoiceActivity): IInvoice;
    /**
     * Criterio de caja
     */
    CriCaja: boolean;
    getCriCaja(): boolean;
    setCriCaja(value: boolean): IInvoice;
    /**
     * Recargo de equivalencia
     */
    RE: boolean;
    getRE(): boolean;
    setRE(value: boolean): IInvoice;
    /**
     * Registro agrícola
     */
    RegAgri: boolean;
    getRegAgri(): boolean;
    setRegAgri(value: boolean): IInvoice;
    /**
     * Líneas de impuestos almacenadas en una colección, no incluye el IRPF
     */
    Tax: ICollection<ITax>;
    getTax(): ICollection<ITax>;
    setTax(value: ICollection<ITax>): IInvoice;
    /**
     * IRPF de la factura
     */
    IRPF: ICollection<IIRPF>;
    getIRPF(): ICollection<IIRPF>;
    setIRPF(value: ICollection<IIRPF>): IInvoice;
    /**
     * Tiene o no irpf la factura
     */
    WithIRPF: boolean;
    getWithIRPF(): boolean;
    setWithIRPF(value: boolean): IInvoice;
    /**
     * Líneas de vencimientos de la factura almacenadas en una colección
     */
    InvoiceExpirationLines: ICollection<IInvoiceExpirationLine>;
    getInvoiceExpirationLines(): ICollection<IInvoiceExpirationLine>;
    setInvoiceExpirationLines(value: ICollection<IInvoiceExpirationLine>): IInvoice;
    /**
     * Indica si es una factura rectificada o no
     */
    Rectified: boolean;
    getRectified(): boolean;
    setRectified(value: boolean): IInvoice;
    /**
     * Tipo de la factura, GASTO/VENTA
     */
    Type: InvoiceType;
    getType(): InvoiceType;
    setType(value: InvoiceType): IInvoice;
    /**
     * Indica si la factura esta o no contabilizada
     */
    Status: InvoiceStatus;
    getStatus(): InvoiceStatus;
    setStatus(value: InvoiceStatus): IInvoice;
    /**
     * Indica si la factura es un documento pendiente o ya se ha transformado en factura
     */
    IsInbox: boolean;
    getIsInbox(): boolean;
    setIsInbox(value: boolean): IInvoice;
    /**
     * Ruta para la descarga del documento asociado a la factura
     */
    InvoiceFilePath: string;
    getInvoiceFilePath(): string;
    setInvoiceFilePath(value: string): IInvoice;
    /**
     * Clase de la factura
     */
    InvoiceClass: InvoiceClass;
    getInvoiceClass(): InvoiceClass;
    setInvoiceClass(value: InvoiceClass): IInvoice;
    /**
     * Clase de la factura
     */
    IsEmailSent: boolean;
    getIsEmailSent(): boolean;
    setIsEmailSent(value: boolean): IInvoice;
    /**
     * Base imponible
     */
    TaxableBase: number;
    getTaxableBase(): number;
    setTaxableBase(value: number): IInvoice;
    /**
     * Porcentaje de retención para usar en el listado de facturas.
     */
    RetentionPercentage: number;
    getRetentionPercentage(): number;
    setRetentionPercentage(value: number): IInvoice;
    /**
     * Base de impuestos total
     */
    VatQuote: number;
    getVatQuote(): number;
    setVatQuote(value: number): IInvoice;
    /**
     * Base de recargo de equivalencia total
     */
    SurchargeQuote: number;
    getSurchargeQuote(): number;
    setSurchargeQuote(value: number): IInvoice;
    /**
     * Boolean que establece si una factura es o no simplificada
     */
    IsSimplified: boolean;
    getIsSimplified(): boolean;
    setIsSimplified(value: boolean): IInvoice;
    /**
     * Boolean que establece si una factura tiene nota o no creada.
     */
    HasNote: boolean;
    getHasNote(): boolean;
    setHasNote(value: boolean): IInvoice;
    /**
     * Observaciones de la factura.
     */
    Remarks: string;
    getRemarks(): string;
    setRemarks(remarks: string): IInvoice;
}

export interface IInvoiceLine extends ICollectable {
    /**
     * Descripción del producto de la línea, si se ha elegido un producto la descripcion será la del producto
     */
    Description: string;
    getDescription(): string;
    setDescription(value :string): IInvoiceLine;
    /**
     * Producto elegido para la línea, si se ha elegido un producto la descripción del producto se usará en description
     */
    Product: IProduct;
    getProduct(): IProduct;
    setProduct(value: IProduct): IInvoiceLine;
    /**
     * Cantidad de productos
     */
    Quantity: number;
    getQuantity(): number;
    setQuantity(value: number): IInvoiceLine;
    /**
     * Precio unitario del producto
     */
    Price: number;
    getPrice(): number;
    setPrice(value: number): IInvoiceLine;
    /**
     * Descuento, si hubiese, del producto
     */
    Discount: number;
    getDiscount(): number;
    setDiscount(value: number): IInvoiceLine;
    /**
     * Cantidad total obtenia de multiplicar el precio unitario por la cantidad de productos
     */
    TotalPrice: number;
    getTotalPrice(): number;
    setTotalPrice(value: number): IInvoiceLine;
    /**
     * Valor del impuesto en la linea, por ejemplo si es 21% seria 21
     */
    Tax: number;
    getTax(): number;
    setTax(value: number): IInvoiceLine;
    /**
     * Valor del IRPF, por ejemplo si es 15% seria 15
     */
    IRPF: number;
    getIRPF(): number;
    setIRPF(value: number): IInvoiceLine;
    /**
     * Valor del recargo de equivalencia, por ejemplo si es 5.4% sería 5.4
     */
    Surcharge: number;
    getSurcharge(): number;
    setSurcharge(value: number): IInvoiceLine;
    /**
     * Tipo del irpf
     */
    IrpfKey: string;
    getIrpfKey(): string;
    setIrpfKey(value: string): IInvoiceLine;
}

export interface IInvoiceExpirationLine extends ICollectable{
    /**
     * Fecha del cobro
     */
    Date: Date;
    getDate(): Date;
    setDate(value: Date): IInvoiceExpirationLine;
    /**
     * Método de pago seleccionado
     */
    PaymentMethod: IPaymentMethod;
    getPaymentMethod(): IPaymentMethod;
    setPaymentMethod(value: IPaymentMethod): IInvoiceExpirationLine;
    /**
     * IBAN de la cuenta bancaria si fuera necesario
     */
    BankAccount: string;
    getBankAccount(): string;
    setBankAccount(value: string): IInvoiceExpirationLine;
    /**
     * Cantidad a pagar
     */
    Amount: number;
    getAmount(): number;
    setAmount(value: number): IInvoiceExpirationLine;
}

export interface IInvoiceSerie extends ICollectable {
    Count: number;
    getCount(): number;
    setCount(value: number): IInvoiceSerie;
    Value: string;
    getValue(): string;
    setValue(value: string): IInvoiceSerie;
}

export interface IInvoiceTransactionType extends ICollectable, ISimpleValue{}

export interface IInvoiceActivity extends ICollectable {
    Value: string;
    getValue(): string;
    setValue(value: string): IInvoiceActivity;
    Description: string;
    getDescription(): string;
    setDescription(description: string): IInvoiceActivity;
    Principal: boolean;
    getPrincipal(): boolean;
    setPrincipal(value: boolean): IInvoiceActivity;
}

export interface IInvoiceConfiguration extends ICollectable {
  Active : boolean;
  getActive(): boolean;
  setActive(value: boolean): IInvoiceConfiguration;
  Logo: boolean;
  getLogo(): boolean;
  setLogo(value: boolean): IInvoiceConfiguration;
  EnterpriseData : boolean;
  getEnterpriseData(): boolean;
  setEnterpriseData(value: boolean): IInvoiceConfiguration;
  RegistryData: boolean;
  getRegistryData(): boolean;
  setRegistryData(value: boolean): IInvoiceConfiguration;
  ContactData: boolean;
  getContactData(): boolean;
  setContactData(value: boolean): IInvoiceConfiguration;
  Details: boolean;
  getDetails(): boolean;
  setDetails(value: boolean): IInvoiceConfiguration;
  Border: BorderTypeToJson;
  getBorder(): BorderTypeToJson;
  setBorder(value: BorderTypeToJson): IInvoiceConfiguration;
  Color: string;
  getColor(): string;
  setColor(value: string): IInvoiceConfiguration;
  LegalWarning: string;
  getLegalWarning(): string;
  setLegalWarning(value: string): IInvoiceConfiguration;
  BackGround: boolean;
  getBackGround(): boolean;
  setBackGround(value: boolean): IInvoiceConfiguration;
  Language : LanguageToJson;
  getLanguage(): LanguageToJson;
  setLanguage(value: LanguageToJson): IInvoiceConfiguration;
  Adjust: boolean;
  getAdjust(): boolean;
  setAdjust(value: boolean): IInvoiceConfiguration;
  Header: number
  getHeader(): number;
  setHeader(value: number): IInvoiceConfiguration;
  Footer: number
  getFooter(): number;
  setFooter(value: number): IInvoiceConfiguration;
  BackgroundImage: File
  getBackgroundImage(): File;
  setBackgroundImage(value: File): IInvoiceConfiguration;
  Theme: ThemeToJson
  getTheme(): ThemeToJson
  setTheme(value: ThemeToJson): IInvoiceConfiguration;
  Key : string
  getKey(): string
  setKey(value: string): IInvoiceConfiguration

}

export enum BorderType{
  NO_BORDER = 'Sin borde',
  BORDER_TITTLE = 'Solo titulos',
  BORDER_ALL = 'Titulos y cuerpos'
}

export enum BorderTypeToJson{
  'Sin borde' = 0,
  'Solo titulos' = 1,
  'Titulos y cuerpos' = 2
}

export enum LanguageList{
  ES = 'es',
  EN = 'en',
  DE = 'de',
  EU = 'eu',
  CA = 'ca',
  GL = 'gl',
}

export enum LanguageToJson{
  'es' = 'Español',
  'en' = 'English',
  'de' = 'Deutsch',
  'eu' = 'Euskera',
  'ca' = 'Catalán',
  'gl' = 'Galego',
}

export enum ThemeList{
  BLACK_AND_WHITE = 'BLACK_AND_WHITE',
  AON_BLUE = 'AON_BLUE',
  PERSONALIZED = 'PERSONALIZED',
}
export enum ThemeToJson{
  'BLACK_AND_WHITE' = 'BLACK_AND_WHITE',
  'AON_BLUE' = 'AON_BLUE',
  'PERSONALIZED' = 'PERSONALIZED',
}


export interface IIRPF extends ICollectable, ISimpleValue {
    /**
     * Tipo de IRPF
     */
    Type: string;
    getType(): string;
    setType(value: string): IIRPF;
    /**
     * Valor numerico del porcentaje del irpf
     */
    Percentage: number;
    /**
     * Cuota a pagar de IRPF
     */
    Quota: number;
    /**
     * Base sobre la que aplicar el IRPF
     */
    Base: number;
}

export interface ITax extends ICollectable, ISimpleValue {
    /**
     * Porcentaje de recargo de equivalencia
     */
    Surcharge: number;
    /**
     * Cuota a pagar del recargo de equivalencia
     */
    SurchargeQuota: number;
    /**
     * Porcentaje del impuesto
     */
    Percentage: number;
    /**
     * Cuota a pagar por el impuesto
     */
    Quota: number;
    /**
     * Base sobre la que aplicar el impuesto y el recargo de equivalencia
     */
    Base:number;
}

export interface IPaymentMethod extends ICollectable, ISimpleValue {}

export interface IInvoiceCategory extends ICollectable, ISimpleValue {
  Principal: boolean;
  getPrincipal(): boolean;
  setPrincipal(value: boolean): IInvoiceCategory;
}

export enum InvoiceClass {
  FACTURA = 'factura',
  PROFORMA = 'proforma'
}

export interface IProduct extends ICollectable{
    Code: string;
    getCode(): string;
    setCode(value: string): IProduct;
    Name: string;
    getName(): string;
    setName(value: string): IProduct;
    Category: IProductCategory;
    getCategory(): IProductCategory;
    setCategory(value: IProductCategory): IProduct;
    Class: IProductClass;
    getClass(): IProductClass;
    setClass(value: IProductClass): IProduct;
    Type: IProductType;
    getType(): IProductType;
    setType(value: IProductType): IProduct;
    Status: IProductStatus;
    getStatus(): IProductStatus;
    setStatus(value: IProductStatus): IProduct;
    Tax: ITax;
    getTax(): ITax;
    setTax(value: ITax): IProduct;
    IRPF: IIRPF;
    getIRPF(): IIRPF;
    setIRPF(value: IIRPF): IProduct;
    BarCode: string;
    getBarCode(): string;
    setBarCode(value: string): IProduct;
    Description: string;
    getDescription(): string;
    setDescription(value: string): IProduct;
    CostPrice: number;
    getCostPrice(): number;
    setCostPrice(value: number): IProduct;
    Benefit: number;
    getBenefit(): number;
    setBenefit(value: number): IProduct;
    Price: number;
    getPrice(): number;
    setPrice(value: number): IProduct;
    Pvp: number;
    getPvp(): number;
    setPvp(value: number): IProduct;
}

export interface IProductCategory extends ICollectable, ISimpleValue {}

export enum ProductClassList {
    VENTA = 'Venta',
    GASTO = 'Gasto',
    VENTA_GASTO = 'Venta/Gasto'
}

export enum ProductClassApiReceive {
  'SALE' = 'Venta',
  'PURCHASE' = 'Gasto',
  'SALE_PURCHASE' = 'Venta/Gasto'
}

export enum ProductClassApiSend {
   'Venta' = 'SALE',
   'Gasto' = 'PURCHASE',
   'Venta/Gasto' = 'SALE_PURCHASE'
}

export interface IProductClass extends ICollectable, ISimpleValue {}

export enum ProductTypeList {
    SERVICE = "Servicio",
    COMMERCIAL_PRODUCT = "Producto Comercial",
    PREPAYMENT = "Suplidos",
}

export enum ProductTypeApiSend {
  "Servicio" = 'SERVICE',
  "Producto Comercial" = 'COMMERCIAL_PRODUCT',
  "Suplidos" = 'PREPAYMENT',
}

export enum ProductTypeApiReceive {
  'SERVICE' = "Servicio",
  'COMMERCIAL_PRODUCT' = "Producto Comercial",
  'PREPAYMENT' = "Suplidos",
}

export interface IProductType extends ICollectable, ISimpleValue {}

export enum ProductStatusFilter {
    ACTIVE = "ACTIVE",
    INACTIVE = "DISCONTINUED"
}

export enum ProductStatusList {
    ACTIVE = "Activo",
    INACTIVE = "Inactivo"
}

export enum ProductStatusApiReceive {
    'ACTIVE' = 'Activo',
    'DISCONTINUED' = 'Inactivo',
}

export enum ProductStatusApiSend {
  'Activo' = 'ACTIVE',
  'Inactivo' = 'DISCONTINUED',
}

export interface IProductStatus extends ICollectable, ISimpleValue {}

export enum ContactType {
    CLIENTE = 'customer',
    ACREEDOR = 'creditor',
    PROVEEDOR = 'supplier',
    NULL = 'null',
}

export interface IContact extends ICollectable{
    Type: ContactType;
    getType(): ContactType;
    setType(value: ContactType): IContact;
    Name: string;
    getName(): string;
    setName(value: string): IContact;
    ComercialName: string;
    getComercialName(): string;
    setComercialName(value: string): IContact;
    Country: ICountryAon;
    getCountry(): ICountryAon;
    setCountry(value: ICountryAon): IContact;
    Document: string;
    getDocument(): string;
    setDocument(value: string): IContact;
    Address: IAddress;
    getAddress(): IAddress;
    setAddress(value: IAddress): IContact;
    Email: IMedia[];
    getEmail(): IMedia[];
    setEmail(value: IMedia[]): IContact;
    Phone: IMedia[];
    getPhone(): IMedia[];
    setPhone(value: IMedia[]): IContact;
    Web: IMedia[];
    getWeb(): IMedia[];
    setWeb(value: IMedia[]): IContact;
    BankAccount: IBank[];
    getBankAccount(): IBank[];
    setBankAccount(value: IBank[]): IContact;
    PaymentMethod: IPaymentMethod;
    getPaymentMethod(): IPaymentMethod;
    setPaymentMethod(value: IPaymentMethod): IContact;
    TransactionType: IInvoiceTransactionType;
    getTransactionType(): IInvoiceTransactionType;
    setTransactionType(value: IInvoiceTransactionType): IContact;
    IRPF: boolean;
    getIRPF(): boolean;
    setIRPF(value: boolean): IContact;
    RE: boolean; //
    getRE(): boolean;
    setRE(value: boolean): IContact;
    CashBasis: boolean;
    getCashBasis(): boolean;
    setCashBasis(value: boolean): IContact;
    ALFRegister: boolean; // registro agricola, gan. y pesca
    getALFRegister(): boolean;
    setALFRegister(value: boolean): IContact;
    Status: ContactStatus;
    getStatus(): ContactStatus;
    setStatus(value: ContactStatus): IContact;
}

export enum ContactStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export interface ICertificateType extends ICollectable, ISimpleValue {}

export interface ICertificateSharedType extends ICollectable, ISimpleValue {}

export enum CertificateTypeList {
  PUBLICO = "publico",
  PRIVADO = "privado"
}

export enum CertificateSharedTypeList {
  PERSONAL = "personal",
  COMPARTIDO = "compartido"
}

export enum CertificateTypeListFromApi {
  "PUBLIC" = "publico",
  "PRIVATE" = "privado"
}

export enum CertificateSharedTypeListFromApi {
  "USER" = "personal",
  "ENTERPRISE" = "compartido"
}

export enum CertificateTypeListToApi {
  "publico" = "PUBLIC",
  "privado" = "PRIVATE"
}

export enum CertificateSharedTypeListToApi {
  "personal" = "USER",
  "compartido" = "ENTERPRISE"
}

export enum AonPermission {
	ACCOUNTING = 'ACCOUNTING',
	ACCOUNTING_MANAGER = 'ACCOUNTING_MANAGER',
	FISCAL = 'FISCAL',
	FISCAL_MANAGER = 'FISCAL_MANAGER',
	PAYROLL = 'PAYROLL',
	PAYROLL_MANAGER = 'PAYROLL_MANAGER',
	PAYROLL_PORTAL = 'PAYROLL_PORTAL',
	DOCUMENTAL = 'DOCUMENTAL',
	DOCUMENTAL_MANAGER = 'DOCUMENTAL_MANAGER',
	COMUNICA = 'COMUNICA',
	COMUNICA_MANAGER = 'COMUNICA_MANAGER',
	COMUNICA_PORTAL = 'COMUNICA_PORTAL',
	TIMECONTROL = 'TIMECONTROL',
	TIMECONTROL_MANAGER = 'TIMECONTROL_MANAGER',
	TIMECONTROL_PORTAL = 'TIMECONTROL_PORTAL',
	MESSENGER = 'MESSENGER',
	MESSENGER_MANAGER = 'MESSENGER_MANAGER',
	INVOICE = 'INVOICE',
	INVOICE_MANAGER = 'INVOICE_MANAGER',
	INVOICE_PORTAL = 'INVOICE_PORTAL',
	MANAGEMENT = 'MANAGEMENT',
	MANAGEMENT_MANAGER = 'MANAGEMENT_MANAGER',
	ALMA = 'ALMA',
	OCR = 'OCR',
	BANK = 'BANK',
	CONVENIOS = 'CONVENIOS',
	AIO = 'AIO',
	BIDOQ = 'BIDOQ',
	DOCUMENTAL_PORTAL = 'DOCUMENTAL_PORTAL',
	CONFIDENTIALITY = 'CONFIDENTIALITY',
	ALPHA = 'ALPHA',
	BETA = 'BETA',
	AON_AIO = 'AON_AIO',
	AON_SMB = 'AON_SMB',
	DEV = 'DEV',
	SELFCONTA = 'SELFCONTA',
	MESSENGER_PORTAL = 'MESSENGER_PORTAL',
	NOTES = 'NOTES',
	SALTRA = 'SALTRA',
	SALTRA_MANAGER = 'SALTRA_MANAGER',
	SALTRA_PORTAL = 'SALTRA_PORTAL',
	COMMERCIAL = 'COMMERCIAL',
	WAREHOUSE = 'WAREHOUSE',
	TREASURY = 'TREASURY',
	MARKETING = 'MARKETING',
	GROUPWARE = 'GROUPWARE',
	CONSOLE = 'CONSOLE',
	INVOFOX = 'INVOFOX',
	SERES = 'SERES',
}

export interface ISalary extends ICollectable {
  File: string;
  getFile(): string;
  setFile(value: string): ISalary;
  Month: number;
  getMonth(): number;
  setMonth(value: number): ISalary;
  StartDate: Date;
  getStartDate(): Date;
  setStartDate(value: Date): ISalary;
  EndDate: Date;
  getEndDate(): Date;
  setEndDate(value: Date): ISalary;
  Gross: number;
  getGross(): number;
  setGross(value: number): ISalary;
  Deductions: number;
  getDeductions(): number;
  setDeductions(value: number): ISalary;
  Liquid: number;
  getLiquid(): number;
  setLiquid(value: number): ISalary;
}
