import { IProductType } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class ProductType extends SimpleValue implements IProductType, IModel {
  constructor(value?: any){
    super(value);
    this.key = value ||'';
  }
}
