import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private loadingCreateGeolocation = new BehaviorSubject<{ name: string; coordinates: L.LatLngExpression }>({ name: '', coordinates: [0, 0] });
  isCreateGeolocation = this.loadingCreateGeolocation.asObservable();

  // Método para actualizar la geolocalización
  updateGeolocation(newCoordinates: { name: string; coordinates: L.LatLngExpression }): void {
    this.loadingCreateGeolocation.next(newCoordinates);
  }
}
