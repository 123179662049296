import { ErrorResponse } from "../aon";
import { IBankMovements } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class BankMovements implements IBankMovements, IModel {
    private operationDate: Date;
    private description: string;
    private status : string;
    private amount : number;
    private totalAmount : number;
    private iban : string;
    protected apiObject: any;
    private key: string;

    constructor (operationDate?: Date, description?: string, status?: string, amount?: number, totalAmount?: number, iban?: string, key?: string) {
        this.key = key || KeyGenerator.generate(15);
        this.operationDate = operationDate || new Date();
        this.description = description || '';
        this.status = status || '';
        this.amount = amount || 0;
        this.totalAmount = totalAmount || 0;
        this.iban = iban || '';
        this.apiObject = {}
    }

    
    public get OperationDate(): Date {
        return this.operationDate;
    }

    public set OperationDate(value: Date) {
        this.operationDate = value;
    }

    public getOperationDate(): Date {
        return this.operationDate;
    }

    public setOperationDate(value: Date): BankMovements {
        this.operationDate = value;
        return this;
    }

    public get Description(): string {
        return this.description;
    }

    public set Description(value: string) {
        this.description = value;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(value: string): BankMovements {
        this.description = value;
        return this;
    }

    public get Status(): string {
        return this.status;
    }

    public set Status(value: string) {
        this.status = value;
    }

    public getStatus(): string {
        return this.status;
    }

    public setStatus(value: string): BankMovements {
        this.status = value;
        return this;
    }

    public get Amount(): number {
        return this.amount;
    }

    public set Amount(value: number) {
        this.amount = value;
    }

    public getAmount(): number {
        return this.amount;
    }

    public setAmount(value: number): BankMovements {
        this.amount = value;
        return this;
    }

    public get TotalAmount(): number {
        return this.totalAmount;
    }

    public set TotalAmount(value: number) {
        this.totalAmount = value;
    }

    public getTotalAmount(): number {
        return this.totalAmount;
    }

    public setTotalAmount(value: number): BankMovements {
        this.totalAmount = value;
        return this;
    }

    public get Iban(): string {
        return this.iban;
    }

    public set Iban(value: string) {
        this.iban = value;
    }

    public getIban(): string {
        return this.iban;
    }

    public setIban(value: string): BankMovements {  
        this.iban = value;
        return this;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string,any> {
        let map = new Map<string, any>();
      
        return map;
    }

    getSortableFields(): Map<string,any> {
        let map = new Map<string, any>();
      
        return map;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }
}

    export class BankMovementsJSON {
        static parseDataToSend(data: any) {
            throw new ErrorResponse('0199')
        }

        static parseDataToReceive(data: any) {
            let bankMovements = new BankMovements();
            bankMovements.ApiObject = data;
            bankMovements.Iban = data.iban;
            bankMovements.Amount = data.amount;
            bankMovements.TotalAmount = data.totalAmount;
            bankMovements.Description = data.description;
            bankMovements.OperationDate = new Date(data.operationDate);
            bankMovements.Status = data.status;
            return bankMovements;
        }
}