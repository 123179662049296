<!-- INPUT TIPO CHECKBOK -->
<mat-checkbox
  *ngIf="type === 'checkbox'"
  [id]="id"
  [(ngModel)]="value"
  (ngModelChange)="returnValue($event)"
  indeterminate="false"
  labelPosition="after"
  [disabled]="disabled"
  [class]="color"
  [matTooltip]="toolTip"
  disableRipple
>
  {{label}}
</mat-checkbox>
<!-- FIN INPUT TIPO CHECKBOK -->

<!-- INPUT TIPO RADIO -->
<mat-radio-group
  *ngIf="type === 'radio'"
  disableRipple="true"
  aria-label="Select an option"
  [id]="id"
  [(ngModel)]="value"
  (ngModelChange)="returnValue($event)"
  indeterminate="false"
  labelPosition="after"
  [disabled]="disabled"
  [class]="color"
>
  <mat-radio-button *ngFor="let option of radioOptions" [value]="option.value">{{ option.label }}</mat-radio-button>
</mat-radio-group>
<!-- FIN INPUT TIPO RADIO -->

<!-- INPUT TIPO TOGGLE -->
<mat-slide-toggle
  *ngIf="type === 'toggle'"
  [id]="id"
  [(ngModel)]="value"
  (ngModelChange)="returnValue($event)"
  [class]="color"
  disableRipple
  [disabled]="disabled"
>
  {{label}}: {{options[value]}}
</mat-slide-toggle>
<!-- FIN INPUT TIPO TOGGLE -->

<!-- INPUTS TIPO AUTOCOMPLETE -->
<mat-form-field
  *ngIf="type === 'autocomplete'"
  [appearance]="appearance"
  [ngStyle]="{
    'width': width
  }"
  [class]="appearanceDetail"
  floatLabel="always"
>
  <mat-label *ngIf="label != ''">{{label}}</mat-label>
  <input
    [id]="id"
    type="text"
    matInput
    [placeholder]="placeholder"
    [formControl]="myControl"
    [matAutocomplete]="auto"
    (ngModelChange)="returnValue($event)"
    [required]="required"
    [class]="appearanceDetail"
  >
  <mat-autocomplete
    autoActiveFirstOption
    [displayWith]="displayFn"
    #auto="matAutocomplete"
  >
    <mat-option
      *ngFor="let option of filteredOptions | async"
      [value]="option"
    >
      {{option[optionName]}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="required == 'required'">
    {{label}}&nbsp;<span [innerHTML]="'COMMON.INPUT.REQUIRE' | translate"></span>
  </mat-error>
</mat-form-field>
<!-- FIN INPUTS TIPO AUTOCOMPLETE -->

<mat-form-field
  *ngIf="type !== 'checkbox' && type != 'radio' && type !== 'autocomplete' && type != 'toggle'"
  [appearance]="appearance"
  [ngStyle]="{
    'width': width
  }"
  [class]="appearanceDetail"
  floatLabel="always"
>
  <mat-label *ngIf="type != 'search'" [hidden]="label === ''">{{label}}</mat-label>
  <span matPrefix><ng-content select="[prefix]"></ng-content></span>

  <!--[ngClass]="['ng-pending']"-->
  <input
    *ngIf="intputType.includes(type)"
    matInput
    [type]="type"
    [(ngModel)]="value"
    [disabled]="disabled"
    [required]="required"
    [placeholder]="placeholder"
    (ngModelChange)="returnValue($event)"
    [class]="appearanceDetail"
  />

  <!-- INPUT TIPO NUMBER -->
  <input
    *ngIf="type == 'number' && minNumber === '' && maxNumber === ''"
    matInput
    [(ngModel)]="value"
    [ngClass]="['ng-pending']"
    [disabled]="disabled"
    [required]="required"
    [type]="type"
    [placeholder]="placeholder"
    [class]="appearanceDetail"
    (ngModelChange)="returnValue($event)"
    (keypress)="validateInputFormatNumber($event)"
    (paste)="validateInputFormatNumber($event)"
  />
  <!-- INPUT TIPO NUMBER MIN  -->
  <input
    *ngIf="type == 'number' && minNumber !== '' && maxNumber === ''"
    matInput
    [(ngModel)]="value"
    [ngClass]="['ng-pending']"
    [disabled]="disabled"
    [required]="required"
    [type]="type"
    [placeholder]="placeholder"
    [class]="appearanceDetail"
    (ngModelChange)="returnValueInputNumber($event)"
    (change)= "this.value < minNumber ? this.value = minNumber : this.value"
    [min]="minNumber"
    (keypress)="validateInputFormatNumber($event)"
    (paste)="validateInputFormatNumber($event)"
  />
  <!-- INPUT TIPO NUMBER MAX -->
  <input
    *ngIf="type == 'number' && minNumber === '' && maxNumber !== ''"
    matInput
    [(ngModel)]="value"
    [ngClass]="['ng-pending']"
    [disabled]="disabled"
    [required]="required"
    [type]="type"
    [placeholder]="placeholder"
    [class]="appearanceDetail"
    (ngModelChange)="returnValueInputNumber($event)"
    (change)= "this.value > maxNumber ? this.value = maxNumber : this.value"
    [max]="maxNumber"
    (keypress)="validateInputFormatNumber($event)"
    (paste)="validateInputFormatNumber($event)"
  />
  <!-- INPUT TIPO NUMBER MIN AND MAX -->
  <input
    *ngIf="type == 'number' && minNumber !== '' && maxNumber !== ''"
    matInput
    [(ngModel)]="value"
    [ngClass]="['ng-pending']"
    [disabled]="disabled"
    [required]="required"
    [type]="type"
    [placeholder]="placeholder"
    [class]="appearanceDetail"
    (ngModelChange)="returnValueInputNumber($event)"
    [min]="minNumber"
    [max]="maxNumber"
    (change)= "this.value < minNumber ? this.value = minNumber : this.value; this.value > maxNumber ? this.value = maxNumber : this.value"
    (keypress)="validateInputFormatNumber($event)"
    (paste)="validateInputFormatNumber($event)"
  />

  <div
    class='search-componet'
    *ngIf="type == 'search'"
  >
    <app-icon [shape]="'search'"></app-icon>
    <input
      matInput
      [(ngModel)]="value"
      [ngClass]="['ng-pending']"
      [disabled]="disabled"
      [required]="required"
      [type]="type"
      [placeholder]="placeholder"
      (ngModelChange)="returnValue($event)"
      [class]="appearanceDetail"
    />
  </div>

  <!-- INPUTS TIPO DATEPICKER -->
  <div
    *ngIf="type=='date' || type=='datetime-local'"
    class='datepicker'
  >
    <input
      matInput
      [matDatepicker]="picker"
      [(ngModel)]="value"
      (keyup)="onKeyup($event)"
      [ngClass]="['ng-pending']"
      [disabled]="disabled"
      [required]="required"
      [placeholder]="placeholder"
      (ngModelChange)="returnValue($event)"
      [class]="appearanceDetail"
      [min]="minDate"
      [max]="maxDate"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </div>

  <!-- INPUTS TIPO SELECT -->
    <mat-select
      *ngIf="type=='select'"
      [(ngModel)]="value"
      [disabled]="disabled"
      [required]="required"
      [class]="appearanceDetail"
      (ngModelChange)="returnValue($event)"
      placeholder="{{ 'COMMON.INPUT.SELECT' | translate }}"
    >
      <mat-option *ngFor="let option of options" value="{{option.value}}">
        {{option.text}}
      </mat-option>
    </mat-select>

    <mat-select
      *ngIf="type=='selectMultiple'"
      [(ngModel)]="value"
      [disabled]="disabled"
      [required]="required"
      [class]="appearanceDetail"
      (ngModelChange)="returnValue($event)"
      multiple
      placeholder="{{ 'COMMON.INPUT.SELECT_MULTIPLE' | translate }}"
    >
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{option.text}}
      </mat-option>
    </mat-select>

  <!-- INPUT TIPO TEXTAREA -->
  <textarea
    *ngIf="type=='textarea'"
    matInput
    cdkTextareaAutosize
    cdkAutosizeMinRows="3"
    [cdkAutosizeMaxRows]="maxRow"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (ngModelChange)="returnValue($event)"
    [disabled]="disabled"
    [required]="required"
  ></textarea>

  <mat-error *ngIf="required == 'required' && !errorDateFormat">
    {{label}}&nbsp;<span [innerHTML]="'COMMON.INPUT.REQUIRE' | translate"></span>
  </mat-error>

  <mat-error *ngIf="type === 'date' && errorDateFormat">
    <span [innerHTML]="'COMMON.INPUT.FORMAT' | translate"></span>
  </mat-error>

  <span
    matSuffix
    *ngIf="suffixShowIcon"
    [ngClass]="classSuffix"
    (click)="selectFunction($event)"
  >
    <span class="icon-only">
      <app-icon
        *ngIf="suffixBehavior === 'showPass'"
        [shape]="suffixIcon"
        suffix
      ></app-icon>
      <ng-content *ngIf="suffixBehavior !== 'showPass'" select="[suffix]"></ng-content>
    </span>
  </span>
  <mat-hint *ngIf="hint != ''">{{hint}}</mat-hint>
</mat-form-field>
