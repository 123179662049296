import { ISingleObjectCrudRepository, IMultipleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { ISingleObjectCrud, IMultipleObjectCrud } from "../interfaces/serviceInterfaces";
import { IResponse, IFilter, ICollection, IModel } from "../interfaces/utilitiesInterfaces";
import { CLASS_NAMES } from "../utils/Environment";
import { ErrorResponse } from "../utils/Response";
import { Response } from "../utils/Response";

export class GenericService<T extends IModel, F extends IFilter<string, string, string>> implements IMultipleObjectCrud<T, F>, ISingleObjectCrud<T> {
    private repository: IMultipleObjectCrudRepository<T> & ISingleObjectCrudRepository<T>;
    private type: { new (): T };

    /**
     * Construye una nueva instancia de la clase.
     *
     * @param {IMultipleObjectCrudRepository<T>} repository - El repositorio para la clase.
     * @param {{ new (): T }} type - El tipo de la clase.
     */
    constructor(repository: IMultipleObjectCrudRepository<T> & ISingleObjectCrudRepository<T>, type: { new (): T }){
        this.repository = repository;
        this.type = type;
    }

     /**
     * Crea un nuevo elemento de forma asíncrona y devuelve la respuesta.
     *
     * @param {T} element - El elemento a crear.
     * @return {Promise<IResponse<T>>} Una promesa que se resuelve con la respuesta de la operación de creación.
     */
     async createElement(element: T): Promise<IResponse<T>> {
        try {
            return new Response<T>(await this.repository.create(element));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0201', CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES] ? CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES].result : '');
        }
    }

    /**
     * Actualiza un elemento.
     *
     * @param {T} elemento - El elemento a actualizar.
     * @return {Promise<IResponse<T>>} Una promesa que se resuelve con la respuesta del elemento actualizado.
     */
    async updateElement(element: T): Promise<IResponse<T>> {
        try {
            return new Response<T>(await this.repository.update(element));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0202' , CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES] ? CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES].result : '');
        }
    }

    /**
     * Elimina un elemento con la clave proporcionada.
     *
     * @param {string} key - La clave del elemento a eliminar.
     * @return {Promise<IResponse<boolean>>} - Una promesa que se resuelve a un booleano que indica si el elemento se eliminó correctamente.
     */
    async deleteElement(key: string): Promise<IResponse<boolean>> {
        try {
            await this.repository.delete(key);
            return new Response<boolean>(true);
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0203' , CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES] ? CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES].result : '');
        }
    }

    /**
     * Recupera un elemento basado en la clave proporcionada.
     *
     * @param {string} key - La clave utilizada para recuperar el elemento.
     * @return {Promise<IResponse<T>>} Una promesa que se resuelve con el elemento recuperado .
     */
    async getElement(key: string): Promise<IResponse<T>> {
        try {
            return new Response<T>(await this.repository.get(key));
        } catch (error) {
            console.log(error);
            
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0206', CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES] ? CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES].result : '');
        }
    }

    /**
     * Recupera una colección de elementos.
     *
     * @param {IFilter} [filter] - Filtro opcional para aplicar a la colección.
     * @return {Promise<IResponse<ICollection<T>>>} - Una promesa que se resuelve en una respuesta que contiene la colección.
     */
    async getCollection(filter?: F): Promise<IResponse<ICollection<T>>> {
        try {
          return new Response<ICollection<T>>(await this.repository.getCollection(filter));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1901', CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES] ? CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES].result : "");
        }
    }

    /**
     * Crea una colección.
     *
     * @param {ICollection<T>} collection - La colección a crear.
     * @return {Promise<IResponse<ICollection<T>>>} Una promesa que se resuelve en la respuesta que contiene la colección creada.
     */
    async createCollection(collection: ICollection<T>): Promise<IResponse<ICollection<T>>> {
        try {
            return new Response<ICollection<T>>(await this.repository.createCollection(collection));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1902', CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES] ? CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES].result : "");
        }
    }

    /**
     * Actualiza una colección.
     *
     * @param {ICollection<T>} collection - La colección a actualizar.
     * @return {Promise<IResponse<ICollection<T>>>} - Una promesa que se resuelve en la colección actualizada.
     */
    async updateCollection(collection: ICollection<T>): Promise<IResponse<ICollection<T>>> {
        try {
            return new Response<ICollection<T>>(await this.repository.updateCollection(collection));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1903', CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES] ? CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES].result : "");
        }
    }

    /**
     * Elimina una colección.
     *
     * @param {string[]} keys - Las claves a eliminar.
     * @return {Promise<IResponse<boolean>>} Una promesa que se resuelve en una respuesta booleana.
     */
    async deleteCollection(keys: string[]): Promise<IResponse<boolean>> {
        try {
            this.repository.deleteCollection(keys);
            return new Response<boolean>(true);
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1904', CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES] ? CLASS_NAMES[this.type.name.toUpperCase() as keyof typeof CLASS_NAMES].result : "");
        }
    }    
}