import { ISingleObjectCrudRepository, IMultipleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IFilter, ICollection, IModel } from "../interfaces/utilitiesInterfaces";
import { ErrorResponse } from "../utils/Response";

export class APIGenericSingleObjectCrudRepository<T extends IModel> implements ISingleObjectCrudRepository<T> {
    protected type: { new (): T };
    protected model: IModel;
    protected apiModel: any;

    constructor(apiModel: any, type: { new (): T }){
        this.type = type;
        this.model = new this.type();
        this.apiModel = apiModel;
    }

    async get(key: string, type?: string): Promise<T> {
        console.log(this.type, '<= type from Generic method getsingle')
        throw new ErrorResponse('0199')
    }

    async create(element: T): Promise<T> {
        console.log(this.type, '<= type from Generic method createsingle')
        throw new ErrorResponse('0199')
    }

    async update(element: T): Promise<T> {
        console.log(this.type, '<= type from Generic method updatesingle')
        throw new ErrorResponse('0199')
    }

    async delete(key: string): Promise<void> {
        console.log(this.type, '<= type from Generic method deletesingle')
        throw new ErrorResponse('0199')
    }

}

// REPOSITORIO PARA LAS LLAMADAS GLOBALES A LA API PARA OPERACIONES CRUD SOBRE UN CONJUNTO DE OBJETOS,
// SI SE NECESITA UN COMPORTAMIENTO ESPECIFICO HEREDAR Y SOBREESCRIBIR DICHO MÉTODO
export class APIGenericMultipleObjectCrudRepository<T extends IModel> implements IMultipleObjectCrudRepository<T> {

    protected type: { new (): T };
    protected model: IModel;
    protected apiModel: any;

    constructor(apiModel: any, type: { new (): T }){
        this.type = type;
        this.model = new this.type();
        this.apiModel = apiModel;
    }

    async getCollection(filter?: IFilter): Promise<ICollection<T>> {
        console.log(this.type, '<= type from Generic method getmultiple')
        throw new ErrorResponse('0199')
    }

    async createCollection(collection: ICollection<T>): Promise<ICollection<T>> {
        console.log(this.type, '<= type from Generic method createmultiple')
        throw new ErrorResponse('0199')
    }

    async updateCollection(collection: ICollection<T>): Promise<ICollection<T>> {
        console.log(this.type, '<= type from Generic method updatemultiple')
        throw new ErrorResponse('0199')
    }

    async deleteCollection(keys: string[]): Promise<void> {
        console.log(this.type, '<= type from Generic method deletemultiple')
        throw new ErrorResponse('0199')
    }

}

export class GenericRepository<T extends IModel> implements IMultipleObjectCrudRepository<T>, ISingleObjectCrudRepository<T> {
    getCollection(filter?: IFilter<string, string, string> | undefined): Promise<ICollection<T>> {
        throw new Error("Method not implemented.");
    }
    createCollection(collection: ICollection<T>): Promise<ICollection<T>> {
        throw new Error("Method not implemented.");
    }
    updateCollection(collection: ICollection<T>): Promise<ICollection<T>> {
        throw new Error("Method not implemented.");
    }
    deleteCollection(keys: string[]): Promise<void> {
        throw new Error("Method not implemented.");
    }
    get(key: string): Promise<T> {
        throw new Error("Method not implemented.");
    }
    create(element: T): Promise<T> {
        throw new Error("Method not implemented.");
    }
    update(element: T): Promise<T> {
        throw new Error("Method not implemented.");
    }
    delete(key: string): Promise<void> {
        throw new Error("Method not implemented.");
    }

}