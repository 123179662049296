import { Injectable } from '@angular/core';
import { IUser, ServiceFactory } from 'libraries/AonSDK/src/aon';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends CommonService {
  private service = ServiceFactory.createUserService();

  constructor() {
    super();
  }

  async getCurrentUserData(): Promise<IUser> {
    return (await this.service.getCurrentUserData()).result;
  }

  async updateCurrentUserData(user: IUser): Promise<IUser> {
    return (await this.service.updateCurrentUserData(user)).result;
  }

  async getUserByDocument(document: string): Promise<IUser> {
    return (await this.service.getUserByDocument(document)).result;
  }

  async updateUserPwd(oldPass: string, newPass: string): Promise<any> {
    return (await this.service.updateUserPwd(oldPass, newPass)).result;
  }

  async updateUser(user: IUser): Promise<IUser> {
    return (await this.service.updateUser(user)).result;
  }

}
