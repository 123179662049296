import { MessageChat, MessageChatJSON } from "../models/MessageChat";
import { ApiHttpRequest } from "../utils/Http";
import { ErrorResponse } from "../utils/Response";
import { BASE_URL, GET_MULTIPLE } from "../utils/Environment";
import { GenericRepository } from "./GenericRepository";
import { IFilter, ICollection } from "../aon";
import { Collection } from "../utils/Collection";
import { IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { MESSAGE_URL } from "../utils/ApiUrls";
import { MessageChatFields } from "../utils/ModelsFields";
import { MessageChatFilter } from "../utils/ModelsFilters";

export class MessageChatRepository extends GenericRepository<MessageChat> implements IMultipleObjectCrudRepository<MessageChat>, ISingleObjectCrudRepository<MessageChat> {
    async create(messageChat: MessageChat): Promise<MessageChat> {
        let sender = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_ONE + '?id=' + localStorage.getItem('registry'), {}, {})
        let json = {
            comment: messageChat.Description,
            domain: localStorage.getItem('domainId'),
            email: '',
            task: messageChat.IdMessage.split(';')[0],
            task_holder: sender,
            type: 'comment'
        }
        let newMessage: MessageChat = MessageChatJSON.parseDataToReceive(await ApiHttpRequest.post(BASE_URL + MESSAGE_URL.WORKFLOW, {}, json));
        return newMessage;
        throw new ErrorResponse('0199')
    }

    async getCollection(filter?: MessageChatFilter | undefined): Promise<ICollection<MessageChat>> {
        let url;
        if(filter && filter.fields && filter.fields.get(MessageChatFields.ID_MESSAGE))
            url = MESSAGE_URL.WORKFLOW + '?task='+filter.fields.get(MessageChatFields.ID_MESSAGE)[0].split(';')[0]+'&domainId='+localStorage.getItem('domainId')+'&domainName='+localStorage.getItem('domainName');
        else
            throw new Error('No se ha introducido el id')
        let response = await ApiHttpRequest.get(BASE_URL + url, {}, {});
        let collection: ICollection<MessageChat> = new Collection<MessageChat>();
        response.forEach((element: any) => {
            collection.add(MessageChatJSON.parseDataToReceive(element))
        })
        return collection;
    }
}