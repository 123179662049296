import { ISalary } from "../aon";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class Salary implements ISalary, IModel {
    private file: string;
    private month: number;
    private startDate: Date;
    private endDate: Date;
    private gross: number;
    private deductions: number;
    private liquid: number;
    private key: string;
    private apiObject: any;

    constructor(){
        this.endDate = new Date(0);
        this.startDate = new Date(0);
        this.deductions = 0;
        this.gross = 0;
        this.liquid = 0;
        this.month = 0;
        this.file = '';
        this.key = KeyGenerator.generate(15);
        this.apiObject = {}
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    public get Key(): string {
        return this.key;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set Key(value: string)  {
        this.key = value;
    }

    public set ApiObject(value: any)  {
        this.apiObject = value;
    }

    public get Month(): number {
        return this.month;
    }

    public get StartDate(): Date {
        return this.startDate;
    }

    public get EndDate(): Date {
        return this.endDate;
    }

    public get Gross(): number {
        return this.gross;
    }

    public get Deductions(): number {
        return this.deductions;
    }

    public get Liquid(): number {
        return this.liquid;
    }

    public get File(): string {
        return this.file;
    }

    public getMonth(): number {
        return this.month;
    }

    public getStartDate(): Date {
        return this.startDate;
    }

    public getEndDate(): Date {
        return this.endDate;
    }

    public getGross(): number {
        return this.gross;
    }

    public getDeductions(): number {
        return this.deductions;
    }

    public getLiquid(): number {
        return this.liquid;
    }

    public getFile(): string {
        return this.file;
    }

    public set Month(value: number) {
        this.month = value;
    }

    public set StartDate(value: Date) {
        this.startDate = value;
    }
    
    public set EndDate(value: Date) {
        this.endDate = value;
    }

    public set Gross(value: number) {
        this.gross = value;
    }

    public set Deductions(value: number) {
        this.deductions = value;
    }

    public set Liquid(value: number) {
        this.liquid = value;
    }

    public set File(value: string) {
        this.file = value;
    }

    public setMonth(value: number): ISalary {
        this.month = value;
        return this;
    }

    public setStartDate(value: Date): ISalary {
        this.startDate = value;
        return this;
    }

    public setEndDate(value: Date): ISalary {
        this.endDate = value;
        return this;
    }

    public setGross(value: number): ISalary {
        this.gross = value;
        return this;
    }

    public setDeductions(value: number): ISalary {
        this.deductions = value;
        return this;
    }

    public setLiquid(value: number): ISalary {
        this.liquid = value;
        return this;
    }

    public setFile(value: string) {
        this.file = value;
        return this;
    }

}

export class SalaryJSON {
    static parseDataToReceice(data: any){
        let salary = new Salary();
        salary.File = '/ms/api/contract/salary/pdf?salaryId='+data.id+'&enterpriseId='+localStorage.getItem('enterpriseId')+'&type=SALARY';
        salary.ApiObject = data;
        salary.Key = data.id ? data.id : KeyGenerator.generate(15);
        salary.Deductions = data.totalDeduction ? data.totalDeduction : 0;
        salary.Gross = data.totalPayment ? data.totalPayment : 0;
        salary.Liquid = data.totalLiquid ? data.totalLiquid : 0;
        salary.Month = data.issue_date ? new Date(data.issue_date).getMonth() + 1 : 0;
        salary.EndDate = data.endDate ? new Date(data.endDate) : new Date(0);
        salary.StartDate = data.startDate ? new Date(data.startDate) : new Date(0);
        return salary;
    }
}
