import { IOccupation, IRlce } from "../aon";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class Occupation extends SimpleValue implements IOccupation, IModel {}

export class OccupationJSON {
    static parseDataToReceive(data: any){
        let occupation = new Occupation();
        occupation.ApiObject = data;
        occupation.Key = data.value ? data.value : "";
        occupation.Value = data.name ? data.name : "";
        return occupation;
    }
}