import { ErrorResponse, IFilter } from "../aon";
import { IFolderSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IFolderEspecificMethods } from "../interfaces/serviceInterfaces";
import { Folder } from "../models/Folder";
import { FolderFilter } from "../utils/ModelsFilters";
import { GenericService } from "./GenericCrudService";

export class FolderService extends GenericService<Folder, FolderFilter> implements IFolderEspecificMethods {
    protected SpecificMethodsRepository: IFolderSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<Folder> & ISingleObjectCrudRepository<Folder>, type: { new (): Folder }, SpecificMethodsRepository: IFolderSpecificMethodsRepository) {
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    getLaboralFolderCount(filter: FolderFilter): Promise<number> {
        try{
            return this.SpecificMethodsRepository.getLaboralFolderCount(filter);
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0301');
        }
    }
}