import { ITax, IIRPF, IInvoiceCategory, IInvoiceSerie, IInvoiceTransactionType, IInvoiceActivity, IInvoice, InvoiceStatus, InvoiceType, InvoiceClass, IPaymentMethod, ContactType, IInvoiceLine, IInvoiceExpirationLine, IInvoiceConfiguration } from "../interfaces/modelsInterfaces";
import { IInvoiceSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IFilter, ICollection, IOrderFilter } from "../interfaces/utilitiesInterfaces";
import { IRPFJSON, IRPF } from "../models/IRPF";
import { InvoiceJSON, Invoice } from "../models/Invoice";
import { InvoiceActivityJSON, InvoiceActivity } from "../models/InvoiceActivity";
import { InvoiceCategoryJSON, InvoiceCategory } from "../models/InvoiceCategory";
import { InvoiceSerieJSON } from "../models/InvoiceSerie";
import { InvoiceTransactionTypeJSON, InvoiceTransactionType, Transactions } from "../models/InvoiceTransactionType";
import { PaymentMethod, PaymentMethodJSON } from "../models/PaymenMethod";
import { TaxJSON, TaxType } from "../models/Tax";
import { DOCUMENT_URL, INVOICE_URL, REGISTRY_URL } from "../utils/ApiUrls";
import { Collection } from "../utils/Collection";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { GenericRepository } from "./GenericRepository";
import { ErrorResponse } from '../utils/Response';
import { KeyGenerator } from "../utils/KeyGenerator";
import { FileToBase64 } from "../utils/FileHelper"
import { InvoiceConfigurationJSON, PersonalizedTheme } from "../models/InvoiceConfiguration";
import { ProductRepository } from "./ProductRepository";
import { InvoiceFields, InvoiceOrder, InvoiceSerieFields } from "../utils/ModelsFields";
import { InvoiceFilter, InvoiceSerieFilter } from "../utils/ModelsFilters";
import { formatDate } from "../utils/Date";

export class InvoiceRepository extends GenericRepository<Invoice> implements IInvoiceSpecificMethodsRepository, IMultipleObjectCrudRepository<Invoice>, ISingleObjectCrudRepository<Invoice> {

    async get(key: any): Promise<Invoice> {
        let id = key.split(';')[0];
        let invoiceClass = key.split(';')[1];
        switch(invoiceClass){
            case InvoiceClass.FACTURA: {
                let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(INVOICE_URL.GET_INVOICE__ONE, {id: id}), {}, {});
                if(!(response?.type! == 'error')){
                    return InvoiceJSON.parseOneToReceive(response, InvoiceClass.FACTURA);
                } else {
                    throw new ErrorResponse('1701');
                }
            }
            case InvoiceClass.PROFORMA: {
                let params = {
                    id: id
                };
                let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(INVOICE_URL.GET_RAWDOC_BY_ID, params), {}, {});
                response = InvoiceJSON.parseOneToReceive(response, InvoiceClass.PROFORMA);
                return response;
            }
            default: {
                throw new ErrorResponse('1701');
            }
        }
    }

    async create(element: Invoice): Promise<Invoice> {
        if(element.InvoiceClass == InvoiceClass.PROFORMA){
            let json = await invoiceToJson(element);
            let response = await ApiHttpRequest.post(BASE_URL + INVOICE_URL.GET_INVOICE__ONE, {}, json);
            if(!(response?.type! == 'error')){
                return InvoiceJSON.parseOneToReceive(response, InvoiceClass.PROFORMA);
            } else {
                throw new ErrorResponse('1702');
            }
        }else{
            let json = await invoiceToJson(element);
            let response = await ApiHttpRequest.put(BASE_URL + INVOICE_URL.ACCEPT, {}, json);
            if(!(response?.type! == 'error')){
                return InvoiceJSON.parseOneToReceive(response, InvoiceClass.FACTURA);
            } else {
                throw new ErrorResponse('1702');
            }
        }
    }

    async update(element: Invoice): Promise<Invoice> {
        let json = await invoiceToJson(element);
        if(element.Key.split(';')[1] == InvoiceClass.PROFORMA){
            Object.defineProperty(json, 'id', {
                value: element.Key.split(';')[0],
                enumerable : true,
            })
            let response = await ApiHttpRequest.put(BASE_URL + INVOICE_URL.GET_INVOICE__ONE, {}, json);
            if(!(response?.type! == 'error')){
                return InvoiceJSON.parseOneToReceive(response, InvoiceClass.PROFORMA);
            } else {
                throw new ErrorResponse('1701');
            }
        } else {
            throw new ErrorResponse('1703');
        }
    }

    async delete(key: string): Promise<void> {
        if(key.split(';').length > 1 && key.split(';')[1] == InvoiceClass.PROFORMA){
            let invoice = await new InvoiceRepository().get(key);
            let json = await invoiceToJson(invoice);
            json.status = 'draft';
            Object.defineProperty(json, 'id', {
                value: key.split(';')[0],
                enumerable : true,
            })
            let response = await ApiHttpRequest.post(BASE_URL + INVOICE_URL.GET_INVOICE__ONE, {}, json);
            if((response?.type! == 'error')){
                throw new ErrorResponse('1701');
            }
        } else if(key.split(';').length > 1 && key.split(';')[1] == InvoiceClass.FACTURA){
            let json = {
                id: key.split(';')[0]
            }
            let response = await ApiHttpRequest.delete(BASE_URL + INVOICE_URL.GET_INVOICE__ONE, {}, json);
            if((response?.type! == 'error')){
                throw new ErrorResponse('1704');
            }
        }

    }

    async getCollection(filter?: InvoiceFilter | undefined): Promise<ICollection<Invoice>> {
        let invoiceClass = filter?.fields?.get(InvoiceFields.CLASS)[0]
        let collection = new Collection<Invoice>();
        switch(invoiceClass){
            case InvoiceClass.FACTURA: {
                let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(INVOICE_URL.GET_INVOICE_NEW_PORTAL, generateFiltersFactura(filter)),{}, {});
                response.forEach((element: any) => {
                    collection.add(InvoiceJSON.parseDataToReceive(element, InvoiceClass.FACTURA))
                })
                break;
            }
            case InvoiceClass.PROFORMA: {
                let invoiceType = filter?.fields?.get(InvoiceFields.TYPE)[0]
                if(invoiceType){
                    invoiceType = invoiceType == InvoiceType.GASTO ? 0 : 1;
                    let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(INVOICE_URL.GET_RAWDOC_NEW_PORTAL, generateFiltersFactura(filter)) , {}, {});
                    response.forEach((element: any) => {
                        collection.add(InvoiceJSON.parseDataToReceive(element, InvoiceClass.PROFORMA))
                    })
                }
                break;
            }
            default: {
                throw new ErrorResponse('1701');
            }
        }
        return collection;
    }

    async deleteCollection(keys: string[]): Promise<void> {
        let params = {}
        let ids: number [] = []
        keys.forEach(element => {
            if(element.split(";")[1] == InvoiceClass.FACTURA) throw new ErrorResponse('1703');
            ids.push(parseInt(element.split(";")[0]));
        });
        params = {
            id: ids
        };
        let response = await ApiHttpRequest.delete(BASE_URL + (INVOICE_URL.DELETE_MULTIPLE_RAWDOC), {},params);
        if(response)
        return response;
        throw new ErrorResponse("1703");
    }

    async downloadInvoice(key: string): Promise<Blob> {
        let invoice = await new InvoiceRepository().get(key);
        let url;
        // if(invoice.ApiObject.file && invoice.ApiObject.file.path){
        //     url = invoice.ApiObject.file.path;
        //     if(!url.startsWith('/'))
        //         url = '/' + url;
        // } else {
            url = '/ms/api/download_invoice_pdf?json=' + btoa(
                JSON.stringify({
                    "id": key.split(';')[0],
                    "source": invoice.InvoiceClass == InvoiceClass.FACTURA ? "invoice" : "rawdoc",
                    "domain_id": localStorage.getItem("domainId"),
                    "domain_name": localStorage.getItem("domainName"),
                    "login": localStorage.getItem("login")
                })
            );
        // }
        return await ApiHttpRequest.httpRequestFile(BASE_URL + url, {}, {});
    }

    async getTaxList(type?: TaxType): Promise<ICollection<ITax>> {
        let collection = new Collection<ITax>();
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.TAX, {}, {});
        if(response)
            response.forEach((element: any) => {
                if(type == TaxType.IVA){
                    element.surcharge = 0;
                    collection.add(TaxJSON.parseDataToReceive(element, type));
                }
                if(type == TaxType.SURCHARGE_IVA && (element.surcharge != 0 || (element.surcharge == 0 && element.percentage == 0)))
                    collection.add(TaxJSON.parseDataToReceive(element, type));
            })
        return collection;
    }

    async getIRPFList(filter?: IFilter | undefined): Promise<ICollection<IIRPF>> {
        let collection = new Collection<IIRPF>();
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.WITHHOLDING, {}, {});
        if(response){
            let irpf = new IRPF();
            irpf.Key = '-';
            irpf.Percentage = 0;
            irpf.Value = "No IRPF"
            collection.add(irpf);
            response.forEach((element: any) => {
                collection.add(IRPFJSON.parseDataToReceive(element, true));
            })
        }
        return collection;
    }

    async getProductIRPFList(filter?: IFilter | undefined): Promise<ICollection<IIRPF>> {
        let collection = new Collection<IIRPF>();
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.WITHHOLDING, {}, {});
        if(response){
            let irpf = new IRPF();
            irpf.Key = '-';
            irpf.Percentage = 0;
            irpf.Value = "No IRPF";
            irpf.Type = "No IRPF";
            collection.add(irpf);
            response.forEach((element: any) => {
                collection.add(IRPFJSON.parseDataToReceive(element, false));
            })
        }
        return collection;
    }

    async getInvoicePurchaseCategoryList(filter?: IFilter | undefined): Promise<ICollection<IInvoiceCategory>> {
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.ACCOUNTS + '?type=purchase', {}, {});
        let collection = new Collection<InvoiceCategory>();
        if(response)
            response.forEach((element: any) => {
                collection.add(InvoiceCategoryJSON.parseDataToReceive(element));
            });
        return collection;
    }

    async getInvoiceSalesCategoryList(filter?: IFilter | undefined): Promise<ICollection<IInvoiceCategory>> {
        let configuration = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.GET_INVOICE_CONFIGURATION, {}, {});
        let defaultAcc = configuration.defaultSalesAcc ? configuration.defaultSalesAcc : "";
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.ACCOUNTS + '?type=sales', {}, {});
        let collection = new Collection<InvoiceCategory>();
        if(response)
            response.forEach((element: any) => {
                collection.add(InvoiceCategoryJSON.parseDataToReceive(element));
            });
        if(collection.exists(defaultAcc))
            collection.get(defaultAcc).Principal = true;
        return collection;
    }

    async getInvoiceTicketCategoryList(filter?: IFilter | undefined): Promise<ICollection<IInvoiceCategory>> {
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.ACCOUNTS + '?type=ticket', {}, {});
        let collection = new Collection<InvoiceCategory>();
        if(response)
            response.forEach((element: any) => {
                collection.add(InvoiceCategoryJSON.parseDataToReceive(element));
            });
        return collection;
    }

    async getInvoiceSerieList(filter?: InvoiceSerieFilter | undefined): Promise<ICollection<IInvoiceSerie>> {
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.SERIES, {}, {});
        let collection: ICollection<IInvoiceSerie> = new Collection<IInvoiceSerie>();
        let array: IInvoiceSerie [] = [];
        if(response)
            response.forEach((element: any) => {
                collection.add(InvoiceSerieJSON.parseDataToReceive(element));
            });
        if(filter && filter.fields && filter?.fields?.has(InvoiceSerieFields.VALUE)){
            let value = filter?.fields.get(InvoiceSerieFields.VALUE)[0] || '';
            array = collection.toArray().filter((element: IInvoiceSerie) => {
                return element.Value.includes(value);
            })
            collection = new Collection<IInvoiceSerie>();
            collection.copyArrayToCollection(array);
        }
        return collection;
    }

    async getTransactionTypeList(filter?: IFilter | undefined): Promise<ICollection<IInvoiceTransactionType>> {
        let collection = new Collection<InvoiceTransactionType>();
        Transactions.forEach(element => {
            collection.add(InvoiceTransactionTypeJSON.parseDataToReceive(element));
        })
        return collection;
    }

    async getPaymentMethodList(filter?: IFilter | undefined): Promise<ICollection<IPaymentMethod>> {
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.PAYMETHODS, {}, {});
        let collection = new Collection<PaymentMethod>();
        if(response)
            response.forEach((element: any) => {
                collection.add(PaymentMethodJSON.parseDataToReceive(element));
            });
        return collection;
    }

    async getInvoiceActivityList(filter?: IFilter | undefined): Promise<ICollection<IInvoiceActivity>> {
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.ACTIVITIES, {}, {});
        let collection = new Collection<InvoiceActivity>();
        if(response)
            response.forEach((element: any) => {
                collection.add(InvoiceActivityJSON.parseDataToReceive(element));
            });
        return collection;
    }

    async acceptInvoice(key: string): Promise<IInvoice> {
        let invoice = await this.get(key);
        let json = await invoiceToJson(invoice);
        Object.defineProperty(json, 'id', {
            value: key.split(';')[0],
            enumerable : true,
        })
        let response = await ApiHttpRequest.put(BASE_URL + INVOICE_URL.ACCEPT, {}, json);
        if(!(response?.type! == 'error')){
            return InvoiceJSON.parseOneToReceive(response, InvoiceClass.FACTURA);
        } else {
            throw new ErrorResponse('1711');
        }
    }

    async getInvoiceNote(key: string): Promise<string> {
        let invoice = await this.get(key);
        return invoice.ApiObject.comments ? invoice.ApiObject.comments : '';
    }

    async createInvoiceNote(key: string, note: string): Promise<boolean> {
        if(key.split(';')[1] == InvoiceClass.PROFORMA){
            let invoice = await this.get(key);
            let json = await invoiceToJson(invoice);
            Object.defineProperty(json, 'id', {
                value: invoice.Key.split(';')[0],
                enumerable : true,
            })
            json.comments = note;
            let response = await ApiHttpRequest.put(BASE_URL + INVOICE_URL.GET_INVOICE__ONE, {}, json);
            if(!(response?.type! == 'error')){
                return true;
            }else {
                throw new ErrorResponse('1712');
            }
        }else{
            if(key.split(';')[1] == InvoiceClass.FACTURA){
                let invoice = await this.get(key);
                Object.defineProperty(invoice, 'id', {
                    value: invoice.Key.split(';')[0],
                    enumerable : true,
                })
                Object.defineProperty(invoice, 'note', {
                    value: note,
                    enumerable : true,
                })
                let response = await ApiHttpRequest.post(BASE_URL + INVOICE_URL.UPDATE_INVOICE_NOTE, {}, invoice);
                if(!(response?.type! == 'error')){
                    return true;
                }else{
                    throw new ErrorResponse('1712');
                }
            }
        }
        throw new ErrorResponse('1712');
    }

    async updateInvoiceNote(key: string, note: string): Promise<boolean> {
        if(key.split(';')[1] == InvoiceClass.PROFORMA){
            let invoice = await this.get(key);
            let json = await invoiceToJson(invoice);
            Object.defineProperty(json, 'id', {
                value: invoice.Key.split(';')[0],
                enumerable : true,
            })
            json.comments = note;
            let response = await ApiHttpRequest.put(BASE_URL + INVOICE_URL.GET_INVOICE__ONE, {}, json);
            if(!(response?.type! == 'error')){
                return true;
            }else {
                throw new ErrorResponse('1713');
            }
        }else{
            if(key.split(';')[1] == InvoiceClass.FACTURA){
                let invoice = await this.get(key);
                Object.defineProperty(invoice, 'id', {
                    value: invoice.Key.split(';')[0],
                    enumerable : true,
                })
                Object.defineProperty(invoice, 'note', {
                    value: note,
                    enumerable : true,
                })
                let response = await ApiHttpRequest.post(BASE_URL + INVOICE_URL.UPDATE_INVOICE_NOTE, {}, invoice);
                if(!(response?.type! == 'error')){
                    return true;
                }else{
                    throw new ErrorResponse('1712');
                }
            }
        }
        throw new ErrorResponse('1712');
    }

    async deleteInvoiceNote(key: string): Promise<boolean> {
        if(key.split(';')[1] == InvoiceClass.PROFORMA){
            let invoice = await this.get(key);
            let json = await invoiceToJson(invoice);
            Object.defineProperty(json, 'id', {
              value: invoice.Key.split(';')[0],
              enumerable : true,
          })
            json.comments = '';
            let response = await ApiHttpRequest.put(BASE_URL + INVOICE_URL.GET_INVOICE__ONE, {}, json);
            if(!(response?.type! == 'error')){
                return true;
            }else {
                throw new ErrorResponse('1714');
            }
        }else{
            if(key.split(';')[1] == InvoiceClass.FACTURA){
                let invoice = await this.get(key);
                Object.defineProperty(invoice, 'id', {
                    value: invoice.Key.split(';')[0],
                    enumerable : true,
                })
                Object.defineProperty(invoice, 'note', {
                    value: '',
                    enumerable : true,
                })
                let response = await ApiHttpRequest.post(BASE_URL + INVOICE_URL.UPDATE_INVOICE_NOTE, {}, invoice);
                if(!(response?.type! == 'error')){
                    return true;
                }else{
                    throw new ErrorResponse('1712');
                }
            }
        }
        throw new ErrorResponse('1712');
    }

    async duplicateInvoice(key: string): Promise<IInvoice> {
        let invoice = await this.get(key);
        delete invoice.ApiObject.id;
        invoice.Key = KeyGenerator.generate(15) + ';' + InvoiceClass.PROFORMA;
        invoice.ApiObject.status = 'inbox';
        invoice.Date = new Date();
        invoice.InvoiceNumber = '';
        invoice.Reference = invoice.Serie.Value + '/';
        return invoice;
    }

    async createCorrectiveInvoice(key: string): Promise<IInvoice> {
        let invoice = await this.get(key);
        delete invoice.ApiObject.id;
        invoice.Key = KeyGenerator.generate(15) + ';' + InvoiceClass.PROFORMA;
        invoice.ApiObject.status = 'inbox';
        invoice.Date = new Date();
        invoice.InvoiceNumber = '';
        invoice.Reference = invoice.Serie.Value + '/';
        invoice.TotalAmount = -invoice.TotalAmount;
        invoice.IRPF.forEach((element: IIRPF) => {
            element.Base = -element.Base;
            element.Quota = -element.Quota;
        })
        invoice.Tax.forEach((element: ITax) => {
            element.Base = -element.Base;
            element.Quota = -element.Quota;
        })
        invoice.Lines.forEach((element: IInvoiceLine) => {
            element.Quantity = -element.Quantity;
            element.TotalPrice = -element.TotalPrice;
        })
        invoice.InvoiceExpirationLines.forEach((element: IInvoiceExpirationLine) => {
            element.Amount = -element.Amount;
        })
        return invoice;
    }

    async getInvoiceConfiguration(): Promise<IInvoiceConfiguration> {
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.GET_INVOICE_CONFIGURATION, {}, {});
        if(!(response?.type! == 'error')){
            return InvoiceConfigurationJSON.parseDataToReceive(response.data);
        }else {
            throw new ErrorResponse('1705');
        }
    }

    async updateInvoiceConfiguration(element: IInvoiceConfiguration): Promise<IInvoiceConfiguration> {
        let confi = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.GET_INVOICE_PRINT_CONFIGURATION, {}, {});
        let base64;
        let pTheme = new PersonalizedTheme();
        if(!(confi?.type! == 'error')){
            confi.logo = element.Logo
            confi.company = element.EnterpriseData
            confi.recordData =  element.RegistryData
            confi.contactData = element.ContactData
            confi.detailed = element.Details
            confi.border =  element.Border
            confi.color = element.Color
            confi.background = element.BackGround
            confi.language = element.Language
            confi.legal = element.LegalWarning
            confi.adjust = element.Adjust
            confi.header = element.Header
            confi.footer = element.Footer
            confi.theme = element.Theme

            let file = element.BackgroundImage
            base64 = await FileToBase64(file);
            let json = {
                    "domain":{
                        "id":localStorage.getItem("domainId"),
                        "name": localStorage.getItem("domainName")
                    },
                    "name":file.name,
                    "size":file.size,
                    "date":new Date(),
                    "content": base64,
                    "contentType":file.type,
                    "contentName":file.name,
                    "contentEncoding":"base64",
                    "contentSize":file.size
            }
            if(confi.backgroundAttach)
                confi.backgroundAttach = json
            else
                Object.defineProperty(confi, 'backgroundAttach', {
                    value: json,
                    enumerable: true
                });
                let jsonTheme ={
                    "tittleTextColor":'#404040',
                    "boxBorderColor":'#808080',
                    "theme":element.getTheme(),
                    "boxTitleBackgroundColor":'',
                    "boxTitleTextColor":'#ffffff',
                    "boxBodyBackgroundColor":'#ffffff',
                    "textColor":'#404040',
                    "customerBackgroundColor":'',
                }
                if(element.getTheme() == 'AON_BLUE')
                    jsonTheme.boxTitleBackgroundColor = '#002469'
                    jsonTheme.customerBackgroundColor = '#CDDBF3'

                if(element.getTheme
                    () == 'BLACK_AND_WHITE')
                    jsonTheme.boxTitleBackgroundColor = '#404040'
                    jsonTheme.customerBackgroundColor = '#E7E7E7'

                if(element.getTheme
                    () == 'PERSONALIZED')
                    pTheme.setPersonalizedTheme(jsonTheme);
                if(confi.theme)
                    confi.theme = jsonTheme

                else
                    Object.defineProperty(confi, 'theme', {
                        value: jsonTheme,
                        enumerable: true
                    });
           return await ApiHttpRequest.post(BASE_URL + INVOICE_URL.SAVE_INVOICE_CONFIGURATION, {}, confi);


        }else{
            throw new ErrorResponse('1706');
        }
    }
    async getInvoicePrintConfiguration(): Promise <IInvoiceConfiguration>{
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.GET_INVOICE_PRINT_CONFIGURATION, {}, {});
        let response2 = await ApiHttpRequest.get(BASE_URL + DOCUMENT_URL.ATTACH + '?attachType=data&source=17&file=true', {}, {});
        response.attach = response2;
        if(!(response?.type! == 'error')){
        return InvoiceConfigurationJSON.parseDataToReceive(response);
        }else {
            throw new ErrorResponse('1705');
        }
    }

    async visualizeInvoicePrintPdf(): Promise <any>{
        const jsonData = {
                "domain_id": localStorage.getItem("domainId"),
                "domain_name": localStorage.getItem("domainName"),
                "login": localStorage.getItem("email"),
            };
        const base64Json = btoa(JSON.stringify(jsonData));
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.GET_INVOICE_PDF+'?json='+base64Json, {}, {});

        if(!(response?.type! == 'error')){
            return response;
        }else {
            throw new ErrorResponse('1707');
        }
    }

    async multipleDownloadInvoicePDF(keys: string[]): Promise <any>{
        let newKeys = [];
        if(keys.length > 500){
            throw new ErrorResponse('1737')
        }
        if(keys.length > 0){
            for(let i = 0; i < keys.length; i++){
                newKeys.push(keys[i].split(';')[0])
            }
            const jsonData = {
                "domainId": localStorage.getItem("domainId"),
                "domainName": localStorage.getItem("domainName"),
                "domainLogin": localStorage.getItem("login"),
                "ids": newKeys,
                "status": keys[0].split(';')[1] == InvoiceClass.FACTURA ? "notInbox" : "inbox"
            }
            const base64Json = btoa(JSON.stringify(jsonData));
            const a = document.createElement('a')
            a.href = BASE_URL + INVOICE_URL.MULTIPLE_DOWNLOAD + '?json='+base64Json
            a.download ='a';
            a.click();
            URL.revokeObjectURL(a.href);
        }
    }

    async multipleDownloadInvoiceExcel(keys: string[]): Promise <any>{
        let newKeys = [];
        if(keys.length > 500){
            throw new ErrorResponse('1737')
        }
        if(keys.length > 0){
            for(let i = 0; i < keys.length; i++){
                newKeys.push(keys[i].split(';')[0])
            }
            const jsonData = {
                "domainId": localStorage.getItem("domainId"),
                "domainName": localStorage.getItem("domainName"),
                "domainLogin": localStorage.getItem("login"),
                "ids": newKeys,
                "status": keys[0].split(';')[1] == InvoiceClass.FACTURA ? "notInbox" : "inbox"
            }
            const base64Json = btoa(JSON.stringify(jsonData));
            const a = document.createElement('a')
            a.href = BASE_URL + INVOICE_URL.EXCEL + '?json='+base64Json
            a.download ='a';
            a.click();
            URL.revokeObjectURL(a.href);
        }
    }

    async sendInvoiceEmail(key: string, email: string){
        let invoice = await this.get(key);
        let json = {
            invoice: invoice.ApiObject,
            to: email
        }
        if(invoice.InvoiceClass == InvoiceClass.PROFORMA){
            let response = await ApiHttpRequest.post(BASE_URL + INVOICE_URL.SEND_EMAIL_PROFORMA, {}, json);
            if((response?.type! == 'error'))
                throw new ErrorResponse('1710');

        }else if(invoice.InvoiceClass == InvoiceClass.FACTURA && invoice.Type == "ticket"){
            let response = await ApiHttpRequest.post(BASE_URL + INVOICE_URL.SEND_EMAIL_TICKET, {}, json);
            if((response?.type! == 'error'))
                throw new ErrorResponse('1710');
            
        }else if(invoice.InvoiceClass == InvoiceClass.FACTURA){
            let response = await ApiHttpRequest.post(BASE_URL + INVOICE_URL.SEND_EMAIL, {}, json);
            if((response?.type! == 'error'))
                throw new ErrorResponse('1710');
            else if(key.split(';')[1] == InvoiceClass.PROFORMA){
                if(invoice.ApiObject.email){
                    invoice.ApiObject.email = true;
                    await this.update(invoice.ApiObject);
                } else {
                    Object.defineProperty(invoice.ApiObject, 'email', {
                        value: true,
                        enumerable : true,
                    });
                    await this.update(invoice);
                }
            }
        }
    }


    async getInvoiceStatSales(from: Date): Promise<any>{
        let date = new Date();
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.REPORTING + '?from='+from.toISOString() + '&to='+date.toISOString(), {}, {});
        if((response?.type! == 'error'))
            throw new ErrorResponse('1717');
        if(response.data){
            response.data.forEach((element: any) => {
                if(element.Ventas == 0) delete element.Ventas;
            });
        }
        let keys = response.data;
        let datasets: any[] = [], ventas: any[] = [], fechas: any[] = [];
        for(let i = 0; i < keys.length; i++){
            if(keys[i].Ventas) fechas.push(keys[i].date)
            if(keys[i].Ventas) ventas.push(keys[i].Ventas)
        }
        datasets.push({data:ventas, label:'SALES'})
        return { datasets: datasets, label: fechas };
    }

    async getInvoiceStatExpenses(from: Date): Promise<any>{
        let date = new Date();
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.REPORTING + '?from='+from.toISOString() + '&to='+date.toISOString(), {}, {});
        if((response?.type! == 'error'))
            throw new ErrorResponse('1718');
        if(response.data){
            response.data.forEach((element: any) => {
                if(element.Gastos == 0) delete element.Gastos;
            });
        }
        let keys = response.data;
        let datasets: any[] = [], gastos: any[] = [], fechas: any[] = [];
        for(let i = 0; i < keys.length; i++){
            if(keys[i].Gastos) fechas.push(keys[i].date)
            if(keys[i].Gastos) gastos.push(keys[i].Gastos)
        }
        datasets.push({data:gastos, label:'EXPENSES'})
        return { datasets: datasets, label: fechas };
    }

    async getInvoiceStatReport(from: Date): Promise<any>{
        let date = new Date();
        let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.REPORTING + '?from='+from.toISOString() + '&to='+date.toISOString(), {}, {});
        if((response?.type! == 'error'))
            throw new ErrorResponse('1719');
        if(response.data){
            response.data.forEach((element: any) => {
                if(element.resultado) delete element.Resultado;
            });
        }
        let keys = response.data;
        let datasets: any[] = [];
        let sales: number = 0;
        let expenses: number = 0;
        for(let i = 0; i < keys.length; i++){
            sales += keys[i].Ventas ? keys[i].Ventas : 0;
            expenses += keys[i].Gastos ? keys[i].Gastos : 0;
        }
        let data = [sales, expenses];
        let labels = [ 'SALES' , 'EXPENSES' ]
        datasets.push({data: data, label: 'SALES/EXPENSES'})
        return { datasets: datasets, label: labels };
    }

    async getInvoiceCount(filter?: InvoiceFilter): Promise<any>{
        let invoiceClass = filter?.fields?.get(InvoiceFields.CLASS)[0];
        let invoiceType;
        if(filter?.fields?.get(InvoiceFields.CLASS)[0] == InvoiceClass.FACTURA)
            invoiceType = filter.fields?.get(InvoiceFields.TYPE)[0] == InvoiceType.GASTO ? 'purchase,expenses' : (filter.fields?.get(InvoiceFields.TYPE)[0] == InvoiceType.VENTA ? "sales" : "ticket");
        else
            invoiceType = filter?.fields?.get(InvoiceFields.TYPE)[0]  == InvoiceType.GASTO ? '0' : "1";
        let params = generateFiltersFactura(filter);
        Object.defineProperty(params, 'ticket', {
            value: InvoiceType.TICKET ? true : false,
            enumerable : true,
        })
        Object.defineProperty(params, 'status', {
            value: 'inbox',
            enumerable : true,
        })
        switch(invoiceClass){
            case InvoiceClass.FACTURA: {
                let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(INVOICE_URL.GET_INVOICE_NEW_PORTAL_COUNT , params), {}, {});
                return response;
            }
            case InvoiceClass.PROFORMA: {
                let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(INVOICE_URL.GET_RAWDOC_NEW_PORTAL_COUNT , params), {}, {});
                return response;
            }
        }
    }
}

let generateFiltersFactura = (filter?: InvoiceFilter) => {
    let params = {};
    if(filter?.fields?.has(InvoiceFields.STATUS))
        Object.defineProperty(params, 'recorded', {
            value: filter.fields?.get(InvoiceFields.STATUS) == InvoiceStatus.CONTABILIZADO ? "scored" : "pending",
            enumerable : true,
        })
    if(filter?.fields?.has(InvoiceFields.TYPE))
        if(filter?.fields?.get(InvoiceFields.CLASS)[0] == InvoiceClass.FACTURA)
            Object.defineProperty(params, 'type', {
                value: filter.fields?.get(InvoiceFields.TYPE) == InvoiceType.GASTO ? 'purchase,expenses' : (filter.fields?.get(InvoiceFields.TYPE) == InvoiceType.VENTA ? "sales" : "ticket"),
                enumerable : true,
            })
        else
            Object.defineProperty(params, 'type', {
                value: filter.fields?.get(InvoiceFields.TYPE) == InvoiceType.GASTO ? '0' : "1",
                enumerable : true,
            })
    if(filter?.fields?.has(InvoiceFields.FROM)){
        Object.defineProperty(params, 'from', {
            value: formatDate(new Date(filter.fields?.get(InvoiceFields.FROM))),
            enumerable : true,
        })
    }
    if(filter?.fields?.has(InvoiceFields.TO)){
        Object.defineProperty(params, 'to', {
            value: formatDate(new Date(filter.fields?.get(InvoiceFields.TO))),
            enumerable : true,
        })
    }
    Object.defineProperty(params, 'per_page', {
        value: filter?.pageItems ? filter.pageItems : 2000000,
        enumerable : true,
    })
    Object.defineProperty(params, 'page', {
        value: filter?.pageNum ? filter.pageNum : 1,
        enumerable : true,
    })
    if(filter?.fields?.has(InvoiceFields.GLOBAL))
        Object.defineProperty(params, InvoiceFields.GLOBAL, {
            value: filter?.fields?.get(InvoiceFields.GLOBAL),
            enumerable: true
        })
    if(filter?.fields?.has(InvoiceFields.NUMBER)){
        Object.defineProperty(params, InvoiceFields.NUMBER, {
            value: filter?.fields?.get(InvoiceFields.NUMBER),
            enumerable: true
        })
    }
    if(filter?.fields?.has(InvoiceFields.CONTACT)){
        Object.defineProperty(params, InvoiceFields.CONTACT, {
            value: filter?.fields?.get(InvoiceFields.CONTACT),
            enumerable: true
        })
    }
    if(filter?.fields?.has(InvoiceFields.TOTAL)){
        Object.defineProperty(params, InvoiceFields.TOTAL, {
            value: filter?.fields?.get(InvoiceFields.TOTAL),
            enumerable: true
        })
    }
    if(!filter?.fields?.has(InvoiceFields.FROM) && !filter?.fields?.has(InvoiceFields.TO)){
        if(filter?.fields?.has(InvoiceFields.TRIMESTER) && filter?.fields?.has(InvoiceFields.YEAR)){
            Object.defineProperty(params, 'from', {
                value: formatDate(new Date(filter?.fields?.get(InvoiceFields.YEAR) * 1, (filter?.fields?.get(InvoiceFields.TRIMESTER) * 3) - 3, 1)),
                enumerable : true,
            })
            Object.defineProperty(params, 'to', {
                value: formatDate(new Date(filter?.fields?.get(InvoiceFields.YEAR) * 1, (filter?.fields?.get(InvoiceFields.TRIMESTER) * 3), 0)),
                enumerable : true,
            })
        } else if(filter?.fields?.has(InvoiceFields.TRIMESTER) && !filter?.fields?.has(InvoiceFields.YEAR)){
            Object.defineProperty(params, 'from', {
                value: formatDate(new Date(new Date().getFullYear(), (filter?.fields?.get(InvoiceFields.TRIMESTER) * 3) - 3, 1)),
                enumerable : true,
            })
            Object.defineProperty(params, 'to', {
                value: formatDate(new Date(new Date().getFullYear(), (filter?.fields?.get(InvoiceFields.TRIMESTER) * 3), 0)),
                enumerable : true,
            })
        } else if(!filter?.fields?.has(InvoiceFields.TRIMESTER) && filter?.fields?.has(InvoiceFields.YEAR)){
            Object.defineProperty(params, 'from', {
                value: formatDate(new Date(filter?.fields?.get(InvoiceFields.YEAR) * 1, 0, 1)),
                enumerable : true,
            })
            Object.defineProperty(params, 'to', {
                value: formatDate(new Date(filter?.fields?.get(InvoiceFields.YEAR) * 1, 12, 0)),
                enumerable : true,
            })
        }
    }
    let checkOrder = (filter: IFilter<any, any, any>, invoiceOrder: InvoiceOrder, order: IOrderFilter): IOrderFilter => {
        if(filter && filter.orderBy && filter.orderBy.has(invoiceOrder)){
            order.orderBy += order.orderBy.length == 0 ? invoiceOrder : ";" + invoiceOrder;
            order.order += order.order.length == 0 ? filter.orderBy.get(invoiceOrder) : ";" + filter.orderBy.get(invoiceOrder);
        }
        return order;
    }
    if(filter && filter.orderBy && filter.orderBy.size > 0){
        let order: IOrderFilter = {
            orderBy: '',
            order: ''
        }
        order = checkOrder(filter, InvoiceOrder.DATE, order);
        order = checkOrder(filter, InvoiceOrder.TOTAL, order);
        order = checkOrder(filter, InvoiceOrder.NAME, order);
        order = checkOrder(filter, InvoiceOrder.REFERENCE, order);
        if(order.orderBy.length > 0){
            Object.defineProperty(params, 'orderBy', {
                value: order.orderBy,
                enumerable : true,
            })
            Object.defineProperty(params, 'order', {
                value: order.order,
                enumerable : true,
            })
        }
    }
    return params;
}

let invoiceToJson = async (element: Invoice) => {
    let irpfList = await new InvoiceRepository().getIRPFList();
    let remarks: any[];
    if(element.Remarks != ''){
        remarks = [{
            "date": new Date(),
            "reason": element.Remarks,
            "user": "",
            "status": ""
        }]
    } else {
        remarks = [];
    }
    let json = {
        "domain": localStorage.getItem('domainId'),
        "type": element.Type == InvoiceType.GASTO ? "recibida" : (element.Type == InvoiceType.VENTA ? "emitida" : "ticket"),
        "series": element.Serie.Value,
        "serie": element.Serie.Value,
        "number": element.InvoiceNumber,
        "reference": element.Reference,
        "date": formatDate(element.Date),
        "transaction": element.TransactionType.getKey(),
        "category": element.Category.getKey(),
        "total": element.TotalAmount,
        "name": element.Contact.Name,
        "status": element.ApiObject.status ? element.ApiObject.status : "inbox",
        "suplidos": element.ApiObject.suplidos ? element.ApiObject.suplidos : {
            "active": false,
            "description": "",
            "total": 0
        },
        "withholdingFarmer": element.RegAgri,
        "comments": element.ApiObject.comments ? element.ApiObject.comments : "",
        "remarks": remarks,
        "selfconta": element.ApiObject.selfconta ? element.ApiObject.selfconta : false,
        "activity": element.Activity.ApiObject,
        "service": element.ApiObject.service ? element.ApiObject.service : false,
        "withholding": +element.IRPF.size() != 0 ? true : false,
        "vatAccrualPayment": element.CriCaja,
        "surcharge": element.RE,
        "creation_user": element.ApiObject.creation_user ? element.ApiObject.creation_user : localStorage.getItem('login'),
        "tbai": element.ApiObject.tbai ? element.ApiObject.tbai : false,
        "tbaiUrl": element.ApiObject.tbaiUrl ? element.ApiObject.tbaiUrl : "",
    };
    if(element.ApiObject.email && element.ApiObject.email == true && element.InvoiceClass == InvoiceClass.PROFORMA){
        Object.defineProperty(json, 'email', {
            value: true,
            enumerable : true,
        });
    }
    let lines: any = []
    for(let line of element.Lines){
        let irpfQuota = 0;
        if(line.irpf != 0){
            let total;
            if(element.RegAgri){
                total = roundNumber(line.TotalPrice * (line.Tax/100)) + roundNumber(line.Surcharge * line.TotalPrice / 100) + line.TotalPrice;
            }
            irpfQuota = total ? roundNumber(line.irpf / 100  * total) : roundNumber(line.irpf / 100  * line.TotalPrice);
        }
        let jsonLine = {
            "description": line.Description,
            "quantity": line.Quantity,
            "price": line.Price,
            "discount": line.Discount,
            "amount": line.TotalPrice,
            "category": element.Category.getKey(),
            "prepayment": line.apiObject.prepayment ? line.apiObject.prepayment : false,
            "percentage": +line.Tax,
            "quota": roundNumber(line.TotalPrice * (line.Tax/100)),
            "surcharge": line.Surcharge,
            "surcharge_quota": roundNumber(line.Surcharge * line.TotalPrice / 100),
            "withholding": line.irpf != 0 ? true : false,
            "withholding_quota": irpfQuota,
            "vat": line.Tax,
            "withholding_type": line.irpf && line.irpf != 0 ? irpfList.get(line.IrpfKey).getValue() : 0,
            "withholding_percentage": line.irpf
        }
        if(line.Product.ApiObject.id){
            let newProduct = await new ProductRepository().get(line.Product.getKey());
            Object.defineProperty(jsonLine, 'item', {
                value: newProduct.ApiObject.item,
                enumerable: true
            })
            jsonLine.description = newProduct.getDescription();
        }else if(line.Product.ApiObject.item){
            Object.defineProperty(jsonLine, 'item', {
                value: line.Product.ApiObject.item,
                enumerable: true
            })
            // Object.defineProperty(jsonLine, 'vat', {
            //     value: line.Tax,
            //     enumerable: true
            // })
            jsonLine.description = jsonLine.description == '' ? line.Product.getDescription() : jsonLine.description;
        }
        lines.push(jsonLine)
    }
    Object.defineProperty(json, 'details', {
        value: lines,
        enumerable: true
    })
    let receiverJson: any;
    if(element.Contact.ApiObject.id){
        let additionalData = {
            'additional_info': ["ADDRESSES","MEDIA","BANKS","PAYMETHOD"],
            id: element.Contact.ApiObject.id
        };
        if(element.Contact.Key.toString().split(';').length > 1){
            let typeContact = element.Contact.Key.toString().split(';')[1];
            switch(typeContact){
                case ContactType.ACREEDOR:
                case ContactType.PROVEEDOR: {
                    receiverJson = element.Contact.ApiObject;
                    let responseContact = await ApiHttpRequest.get(BASE_URL + REGISTRY_URL.ADDRESS + '?registry='+element.Contact.ApiObject.id+'&global=false', {}, {});
                    if(receiverJson.address){
                        Object.assign(receiverJson.address, responseContact);
                    } else {
                        Object.defineProperty(receiverJson, 'address', {
                            value: responseContact,
                            enumerable : true,
                        });
                    }
                    break;
                }
                case ContactType.CLIENTE: {
                    receiverJson = await ApiHttpRequest.post(BASE_URL + REGISTRY_URL.CUSTOMERS + element.Contact.ApiObject.id, {} , additionalData);
                    if(receiverJson.addresses.length > 0)
                        receiverJson.addresses.forEach((address:any) => {
                            if(element.Contact.Address.getKey() == address.id){
                                if(receiverJson.address){
                                    Object.assign(receiverJson.address, address);
                                } else {
                                    Object.defineProperty(receiverJson, 'address', {
                                        value: address,
                                        enumerable : true
                                    });
                                }
                            }
                        });
                    break;
                }
                default: {
                    break;
                }
            }
        } else {
            receiverJson = element.Contact.ApiObject;
        }
    } else {
        receiverJson = {
            "address": {
                "zip": element.Contact.Address.PostalCode,
                "dirty": true,
                "number": element.Contact.Address.Number,
                "country": element.Contact.Address.Country,
                "streetType": element.Contact.Address.Type.Value,
                "address": element.Contact.Address.Direction,
                "province": element.Contact.Address.Province,
                "removed": false,
                "address2": element.Contact.Address.RestOfDirection,
                "city": element.Contact.Address.City
            },
            "document": element.Contact.Document,
            "name": element.Contact.Name
        };
    }
    let jsonContactVoid = {
        "document": "",
        "name": "",
        "address": {
            "country": "ES",
            "address": "",
            "zip": "",
            "city": "",
            "province": ""
        }
    };

    if(element.Type == InvoiceType.GASTO || element.Type == InvoiceType.TICKET){
        Object.defineProperty(json, 'receiver', {
            value: jsonContactVoid,
            enumerable : true,
        });
        Object.defineProperty(json, 'sender', {
            value: receiverJson,
            enumerable : true,
        });
    } else {
        Object.defineProperty(json, 'sender', {
            value: jsonContactVoid,
            enumerable : true,
        });
        Object.defineProperty(json, 'receiver', {
            value: receiverJson,
            enumerable : true,
        });
    }

    let financesJson = []
    for(let finance of element.InvoiceExpirationLines){
        financesJson.push({
            "due_date": formatDate(finance.Date),
            "paymethod": finance.PaymentMethod.getKey(),
            "bank_account": finance.BankAccount,
            "amount": finance.Amount.toString()
        })
    }
    Object.defineProperty(json, 'finances', {
        value: financesJson,
        enumerable : true,
    })

    let taxesJson = []
    if(element.IRPF.size() != 0)
        for(let irpfTax of element.IRPF){
            taxesJson.push({
                "tax": "IRPF",
                "type": "IRPF",
                "percentage": +irpfTax.Percentage,
                "base": irpfTax.Base,
                "quota": irpfTax.Quota,
                "surcharge": 0,
                "surcharge_quota": 0,
                "withholding_type": irpfTax.Value
            })
        }
    for(let taxIterator of element.Tax){
        taxesJson.push({
            "tax": "IVA",
            "type": element.RE == true ? TaxType.SURCHARGE_IVA : TaxType.IVA,
            "percentage": taxIterator.getKey(),
            "base": taxIterator.Base,
            "quota": taxIterator.Quota,
            "surcharge": taxIterator.Surcharge,
            "surcharge_quota": taxIterator.SurchargeQuota
        });
    }

    Object.defineProperty(json, 'taxes', {
        value: taxesJson,
        enumerable : true,
    })

    if(element.Type == InvoiceType.TICKET){
        if(element.IRPF.size() > 0) throw new ErrorResponse('1731')
        for(const tax of element.Tax){
            if(tax.Percentage != 0) throw new ErrorResponse('1730')
        }
        for(const line of element.Lines){
            if(line.IRPF != 0) throw new ErrorResponse('1731')
            if(line.Tax != 0) throw new ErrorResponse('1730')
            if(line.Surcharge != 0) throw new ErrorResponse('1733')
            if(line.Discount != 0) throw new ErrorResponse('1732')
        }
        if(element.CriCaja == true) throw new ErrorResponse('1734')
        if(element.RegAgri == true) throw new ErrorResponse('1735')
    }

    return json;
}

function roundNumber(number: number, decimales: number = 2): number {
    if (typeof number === 'number') {
      const factor  = Math.pow(10, decimales);
      const rounded = Math.round(number * factor);
  
      return (Math.floor(rounded / factor) + rounded % factor / factor);
    } else {
      return 0;
    }
  }
