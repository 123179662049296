import { IUser } from "../interfaces/modelsInterfaces";
import { IMultipleObjectCrudRepository, ISingleObjectCrudRepository, IUserSpecificMethodsRepository } from "../interfaces/repositoryInterfaces";
import { User, UserJSON } from "../models/User";
import { ApiHttpRequest } from "../utils/Http";
import { BASE_URL } from "../utils/Environment";
import { ErrorResponse } from "../utils/Response";
import { COMPANY, LOGIN_URL, USER_URL } from "../utils/ApiUrls";
import { GenericRepository } from "./GenericRepository";
import { APIAuthenticationRepository } from "./AuthenticationRepository";

export class UserRepository extends GenericRepository<User> implements IUserSpecificMethodsRepository, IMultipleObjectCrudRepository<User>, ISingleObjectCrudRepository<User> {
    async getCurrentUserData(): Promise<IUser> {
        const response = await ApiHttpRequest.get(BASE_URL + USER_URL.AUTH, {}, {});
        const userData = await ApiHttpRequest.get(BASE_URL + LOGIN_URL.USER_INFO, {}, {});
        const roles = await ApiHttpRequest.get(BASE_URL + COMPANY.APP_ROLES, {}, {});
        response.id = userData.id;
        response.domainUserRoles = roles.domainUserRoles;
        return UserJSON.parseDataToReceive(response);
    }

    async updateCurrentUserData(user: User): Promise<IUser> {
        let response = await ApiHttpRequest.post(BASE_URL + USER_URL.USER, {}, UserJSON.parseDataToSend(user));
        return user;
    }

    async updateUserPwd(oldPassword: string, newPassword: string): Promise<any> {
        let data = {
            oldPassword: oldPassword,
            newPassword: newPassword
        }
        let response = await ApiHttpRequest.post(BASE_URL + USER_URL.PASSWORD, {}, data);
        return response;
    }

    async getUserByDocument(document: string): Promise<IUser> {
        let response = await ApiHttpRequest.get(BASE_URL + USER_URL.LIST + '?filter=company&page=1&perPage=1&value=' + document, {}, {});
        if(response.length == 0)
            throw new ErrorResponse('1403');
        if(!(response?.type! == 'error'))
            return UserJSON.parseDataToReceive(response[0]);
        else
            throw new ErrorResponse('1401');
    }

    async updateUser(user: User): Promise<IUser> {
        let json = user.ApiObject;
        json.phone = user.Phone;
        let roles = await new APIAuthenticationRepository().getUserPermissionsAndRoles(user.Key);
        json.roles = roles.Permissions;
        let response = await ApiHttpRequest.post(BASE_URL + USER_URL.USER, {}, json);
        return UserJSON.parseDataToReceive(response);
    }

}