import { FilterBuilder } from "../utils/FilterBuilder";
import { IMark } from "../interfaces/modelsInterfaces";
import { IMarkSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IMarkSpecificMethods } from "../interfaces/serviceInterfaces";
import { IFilter, IResponse, ICollection } from "../interfaces/utilitiesInterfaces";
import { Mark } from "../models/Mark";
import { ErrorResponse } from "../utils/Response";
import { Response } from "../utils/Response";
import { GenericService } from "./GenericCrudService";
import { MarkFilter } from "../utils/ModelsFilters";

export class MarkService extends GenericService<Mark, MarkFilter> implements IMarkSpecificMethods {
    protected SpecificMethodsRepository: IMarkSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<Mark> & ISingleObjectCrudRepository<Mark>, type: { new (): Mark }, SpecificMethodsRepository: IMarkSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    /**
     * Recupera los marcajes de un usuario específico según su ID de usuario y un filtro opcional.
     *
     * @param {string} userId - El ID del usuario.
     * @param {IFilter} [filter] - Un filtro opcional para aplicar a la consulta.
     * @return {Promise<IResponse<ICollection<IMark>>>} Una promesa que se resuelve en la respuesta que contiene la colección de marcajes.
     */
    async getMarksOfOneUser(userId: string, filter?: IFilter): Promise<IResponse<ICollection<IMark>>> {
        try{
            return new Response<ICollection<IMark>>(await this.SpecificMethodsRepository.getMarksOfOneUser(userId, filter || new FilterBuilder().getFilter()));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1507');
        }
    }
}
