import { IStreetType } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";


export class StreetType implements IStreetType, IModel {
    private value: string;
    private fullName: string;
    private key: string;
    private apiObject: any;

    constructor(value?: string, fullName?: string){
        this.value = value || '';
        this.fullName = fullName || '';
        this.key = KeyGenerator.generate(15);
        this.apiObject = {}
    }

    public get Value(): string {
        return this.value;
    }

    public get FullName(): string {
        return this.fullName;
    }

    public get Key(): string {
        return this.key;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set Value(value: string) {
        this.value = value;
    }
    
    public set FullName(value: string) {
        this.fullName = value;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    getValue(): string {
        return this.value;
    }

    setValue(value: string): IStreetType {
        this.value = value;
        return this;
    }

    getFullName(): string {
        return this.fullName;
    }

    setFullName(value: string): IStreetType {
        this.fullName = value;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class StreetTypeJSON {
    static parseDataToReceive(data: any){
        let streetType = new StreetType();
        streetType.ApiObject = data;
        Object.values(streetTypeConst).forEach(element => {
            if(element.ineCode == data){
                streetType.Key = element.ineCode;
                streetType.Value = element.ineCode;
                streetType.FullName = element.description;
            }
        })
        return streetType;
    }
}

export const streetTypeConst = {
        ACCE: {
        ineCode: 'ACCE ',
        aeatCode: 'AC',
        description: 'ACCES'
        },
        ACCES: {
        ineCode: 'ACCES',
        aeatCode: 'AC',
        description: 'ACCESO'
        },
        ACEQ: {
        ineCode: 'ACEQ ',
        aeatCode: 'AE',
        description: 'ACEQUIA'
        },
        ACERA: {
        ineCode: 'ACERA',
        aeatCode: 'AA',
        description: 'ACERA'
        },
        ALAM: {
        ineCode: 'ALAM ',
        aeatCode: 'AL',
        description: 'ALAMEDA'
        },
        ALDAP: {
        ineCode: 'ALDAP',
        aeatCode: 'CT',
        description: 'ALDAPA'
        },
        ALDEA: {
        ineCode: 'ALDEA',
        aeatCode: 'AD',
        description: 'ALDEA'
        },
        ALQUE: {
        ineCode: 'ALQUE',
        aeatCode: 'AQ',
        description: 'ALQUERIA'
        },
        ALTO: {
        ineCode: 'ALTO ',
        aeatCode: 'AT',
        description: 'ALTO'
        },
        ANDAD: {
        ineCode: 'ANDAD',
        aeatCode: 'AN',
        description: 'ANDADOR'
        },
        ANGTA: {
        ineCode: 'ANGTA',
        aeatCode: 'AG',
        description: 'ANGOSTA'
        },
        APDRO: {
        ineCode: 'APDRO',
        aeatCode: 'AO',
        description: 'APEADERO'
        },
        APTES: {
        ineCode: 'APTES',
        aeatCode: 'AP',
        description: 'APARTAMENTS'
        },
        APTOS: {
        ineCode: 'APTOS',
        aeatCode: 'AP',
        description: 'APARTAMENTOS'
        },
        ARB: {
        ineCode: 'ARB  ',
        aeatCode: 'AB',
        description: 'ARBOLEDA'
        },
        ARRAL: {
        ineCode: 'ARRAL',
        aeatCode: 'AR',
        description: 'ARRABAL'
        },
        ARREK: {
        ineCode: 'ARREK',
        aeatCode: 'AY',
        description: 'ERREKA'
        },
        ARRY: {
        ineCode: 'ARRY ',
        aeatCode: 'AY',
        description: 'ARROYO'
        },
        ASSEG: {
        ineCode: 'ASSEG',
        aeatCode: 'AS',
        description: 'ASSEGADOR'
        },
        ATAJO: {
        ineCode: 'ATAJO',
        aeatCode: 'AJ',
        description: 'ATAJO'
        },
        ATAL: {
        ineCode: 'ATAL ',
        aeatCode: 'AJ',
        description: 'ATALL'
        },
        ATALL: {
        ineCode: 'ATALL',
        aeatCode: 'AJ',
        description: 'ATALLO'
        },
        ATZUC: {
        ineCode: 'ATZUC',
        aeatCode: 'AZ',
        description: 'ATZUCAT'
        },
        AUTO: {
        ineCode: 'AUTO ',
        aeatCode: 'AU',
        description: 'AUTOPISTA'
        },
        AUTOV: {
        ineCode: 'AUTOV',
        aeatCode: 'AI',
        description: 'AUTOVIA'
        },
        AUZO: {
        ineCode: 'AUZO ',
        aeatCode: 'BO',
        description: 'AUZO'
        },
        AUZOT: {
        ineCode: 'AUZOT',
        aeatCode: 'BA',
        description: 'AUZOTEGI'
        },
        AUZUN: {
        ineCode: 'AUZUN',
        aeatCode: 'BO',
        description: 'AUZUNEA'
        },
        AV: {
        ineCode: 'AV   ',
        aeatCode: 'AV',
        description: 'AV'
        },
        AVDA: {
        ineCode: 'AVDA ',
        aeatCode: 'AV',
        description: 'AVENIDA'
        },
        AVGDA: {
        ineCode: 'AVGDA',
        aeatCode: 'AV',
        description: 'AVINGUDA'
        },
        AVIA: {
        ineCode: 'AVIA ',
        aeatCode: 'AI',
        description: 'AUTOVIA'
        },
        BALNR: {
        ineCode: 'BALNR',
        aeatCode: 'BN',
        description: 'BALNEARIO'
        },
        BARDA: {
        ineCode: 'BARDA',
        aeatCode: 'BA',
        description: 'BARRIADA'
        },
        BARRI: {
        ineCode: 'BARRI',
        aeatCode: 'BO',
        description: 'BARRI'
        },
        BARRO: {
        ineCode: 'BARRO',
        aeatCode: 'BO',
        description: 'BARRIO'
        },
        BDA: {
        ineCode: 'BDA  ',
        aeatCode: 'BJ',
        description: 'BAIXADA'
        },
        BELNA: {
        ineCode: 'BELNA',
        aeatCode: 'BE',
        description: 'BELENA'
        },
        BIDE: {
        ineCode: 'BIDE ',
        aeatCode: 'VI',
        description: 'BIDE'
        },
        BIDEB: {
        ineCode: 'BIDEB',
        aeatCode: 'GL',
        description: 'BIDEBIETA'
        },
        BJADA: {
        ineCode: 'BJADA',
        aeatCode: 'BJ',
        description: 'BAJADA'
        },
        BLOC: {
        ineCode: 'BLOC ',
        aeatCode: 'BL',
        description: 'BLOC'
        },
        BLQUE: {
        ineCode: 'BLQUE',
        aeatCode: 'BL',
        description: 'BLOQUE'
        },
        BQLLO: {
        ineCode: 'BQLLO',
        aeatCode: 'BQ',
        description: 'BARRANQUIL'
        },
        BRANC: {
        ineCode: 'BRANC',
        aeatCode: 'BR',
        description: 'BARRANCO'
        },
        BRDLA: {
        ineCode: 'BRDLA',
        aeatCode: 'BD',
        description: 'BARREDUELA'
        },
        BRZAL: {
        ineCode: 'BRZAL',
        aeatCode: 'BZ',
        description: 'BRAZAL'
        },
        BSRIA: {
        ineCode: 'BSRIA',
        aeatCode: 'BS',
        description: 'BASERRIA'
        },
        BULEV: {
        ineCode: 'BULEV',
        aeatCode: 'BV',
        description: 'BULEVAR'
        },
        BV: {
        ineCode: 'BV   ',
        aeatCode: 'BV',
        description: 'BULEVAR'
        },
        C_H: {
        ineCode: 'C.H. ',
        aeatCode: 'KH',
        description: 'CMNO HONDO'
        },
        C_N: {
        ineCode: 'C.N. ',
        aeatCode: 'KN',
        description: 'CMNO NUEVO'
        },
        C_V: {
        ineCode: 'C.V. ',
        aeatCode: 'KV',
        description: 'CMNO VIEJO'
        },
        C: {
        ineCode: 'C/   ',
        aeatCode: 'C/',
        description: 'C/'
        },
        CNADA: {
        ineCode: 'CÑADA',
        aeatCode: 'CÑ',
        description: 'CAÑADA'
        },
        CALLE: {
        ineCode: 'CALLE',
        aeatCode: 'CL',
        description: 'CALLE'
        },
        CAMI: {
        ineCode: 'CAMI ',
        aeatCode: 'CM',
        description: 'CAMI'
        },
        CAMIN: {
        ineCode: 'CAMIN',
        aeatCode: 'CM',
        description: 'CAMIN'
        },
        CAMPA: {
        ineCode: 'CAMPA',
        aeatCode: 'CP',
        description: 'CAMPA'
        },
        CAMPG: {
        ineCode: 'CAMPG',
        aeatCode: 'CG',
        description: 'CAMPING'
        },
        CAMPO: {
        ineCode: 'CAMPO',
        aeatCode: 'CP',
        description: 'CAMPO'
        },
        CANNO: {
        ineCode: 'CANÑO',
        aeatCode: 'KÑ',
        description: 'CANTIÑO'
        },
        CANAL: {
        ineCode: 'CANAL',
        aeatCode: 'CA',
        description: 'CANAL'
        },
        CANT: {
        ineCode: 'CANT ',
        aeatCode: 'CQ',
        description: 'CANTON'
        },
        CANTI: {
        ineCode: 'CANTI',
        aeatCode: 'QT',
        description: 'CANTINA'
        },
        CANTO: {
        ineCode: 'CANTO',
        aeatCode: 'CQ',
        description: 'CANTO'
        },
        CANTR: {
        ineCode: 'CANTR',
        aeatCode: 'QA',
        description: 'CANTERA'
        },
        CARRA: {
        ineCode: 'CARRA',
        aeatCode: 'QD',
        description: 'CARRERADA'
        },
        CARRE: {
        ineCode: 'CARRE',
        aeatCode: 'CL',
        description: 'CARRER'
        },
        CARRY: {
        ineCode: 'CARRY',
        aeatCode: 'VR',
        description: 'CARRERANY'
        },
        CASA: {
        ineCode: 'CASA ',
        aeatCode: 'CS',
        description: 'CASA'
        },
        CBTIZ: {
        ineCode: 'CBTIZ',
        aeatCode: 'CB',
        description: 'COBERTIZO'
        },
        CCVCN: {
        ineCode: 'CCVCN',
        aeatCode: 'CV',
        description: 'CIRCUNVALACION'
        },
        CELLA: {
        ineCode: 'CELLA',
        aeatCode: 'QN',
        description: 'CANELLA'
        },
        CERRO: {
        ineCode: 'CERRO',
        aeatCode: 'CE',
        description: 'CERRO'
        },
        CHLET: {
        ineCode: 'CHLET',
        aeatCode: 'CH',
        description: 'CHALET'
        },
        CINT: {
        ineCode: 'CINT ',
        aeatCode: 'CI',
        description: 'CINTURON'
        },
        CINY: {
        ineCode: 'CINY ',
        aeatCode: 'CI',
        description: 'CINYELL'
        },
        CIRCU: {
        ineCode: 'CIRCU',
        aeatCode: 'CV',
        description: 'CIRCUNVALACION'
        },
        CJLA: {
        ineCode: 'CJLA ',
        aeatCode: 'CU',
        description: 'CALLEJUELA'
        },
        CJTO: {
        ineCode: 'CJTO ',
        aeatCode: 'CN',
        description: 'CONJUNTO'
        },
        CLEYA: {
        ineCode: 'CLEYA',
        aeatCode: 'CY',
        description: 'CALEYA'
        },
        CLLJA: {
        ineCode: 'CLLJA',
        aeatCode: 'CJ',
        description: 'CALLEJA'
        },
        CLLON: {
        ineCode: 'CLLON',
        aeatCode: 'CK',
        description: 'CALLEJON'
        },
        CLLZO: {
        ineCode: 'CLLZO',
        aeatCode: 'KZ',
        description: 'CALLIZO'
        },
        CLYON: {
        ineCode: 'CLYON',
        aeatCode: 'KY',
        description: 'CALEYON'
        },
        CMNIO: {
        ineCode: 'CMÑO ',
        aeatCode: 'CM',
        description: 'CAMIÑO'
        },
        CMNET: {
        ineCode: 'CMNET',
        aeatCode: 'CM',
        description: 'CAMINET'
        },
        CMNO: {
        ineCode: 'CMNO ',
        aeatCode: 'CM',
        description: 'CAMINO'
        },
        CNLLA: {
        ineCode: 'CNLLA',
        aeatCode: 'QN',
        description: 'CANELLA'
        },
        CNVT: {
        ineCode: 'CNVT ',
        aeatCode: 'CW',
        description: 'CONVENT'
        },
        CNVTO: {
        ineCode: 'CNVTO',
        aeatCode: 'CW',
        description: 'CONVENTO'
        },
        COL: {
        ineCode: 'COL  ',
        aeatCode: 'CO',
        description: 'COLONIA'
        },
        COMPJ: {
        ineCode: 'COMPJ',
        aeatCode: 'QJ',
        description: 'COMPLEJO'
        },
        COOP: {
        ineCode: 'COOP ',
        aeatCode: 'KP',
        description: 'COOPERATIVA'
        },
        COSTA: {
        ineCode: 'COSTA',
        aeatCode: 'KO',
        description: 'COSTA'
        },
        COSTE: {
        ineCode: 'COSTE',
        aeatCode: 'KR',
        description: 'COSTERA'
        },
        CRA: {
        ineCode: 'CRA  ',
        aeatCode: 'KA',
        description: 'CARRERA'
        },
        CRCRO: {
        ineCode: 'CRCRO',
        aeatCode: 'CC',
        description: 'CRUCEIRO'
        },
        CRLLO: {
        ineCode: 'CRLLO',
        aeatCode: 'RL',
        description: 'CORRILLO'
        },
        CRO: {
        ineCode: 'CRO  ',
        aeatCode: 'QR',
        description: 'CARRERO'
        },
        CRRAL: {
        ineCode: 'CRRAL',
        aeatCode: 'QL',
        description: 'CORRAL'
        },
        CRRCI: {
        ineCode: 'CRRCI',
        aeatCode: 'KD',
        description: 'CORREDORCILLO'
        },
        CRRDA: {
        ineCode: 'CRRDA',
        aeatCode: 'KD',
        description: 'CORREDOIRA'
        },
        CRRDE: {
        ineCode: 'CRRDE',
        aeatCode: 'KD',
        description: 'CORREDERA'
        },
        CRRDO: {
        ineCode: 'CRRDO',
        aeatCode: 'KD',
        description: 'CORREDOR'
        },
        CRRIL: {
        ineCode: 'CRRIL',
        aeatCode: 'KL',
        description: 'CARRIL'
        },
        CRRLO: {
        ineCode: 'CRRLO',
        aeatCode: 'QO',
        description: 'CORRALILLO'
        },
        CRROL: {
        ineCode: 'CRROL',
        aeatCode: 'RR',
        description: 'CORRIOL'
        },
        CRTIL: {
        ineCode: 'CRTIL',
        aeatCode: 'QI',
        description: 'CARRETIL'
        },
        CRTJO: {
        ineCode: 'CRTJO',
        aeatCode: 'KT',
        description: 'CORTIJO'
        },
        CSRIO: {
        ineCode: 'CSRIO',
        aeatCode: 'CS',
        description: 'CASERIO'
        },
        CSTAN: {
        ineCode: 'CSTAN',
        aeatCode: 'KS',
        description: 'COSTANILLA'
        },
        CTRA: {
        ineCode: 'CTRA ',
        aeatCode: 'CR',
        description: 'CARRETERA'
        },
        CTRIN: {
        ineCode: 'CTRIN',
        aeatCode: 'QE',
        description: 'CARRETERIN'
        },
        CUADR: {
        ineCode: 'CUADR',
        aeatCode: 'CD',
        description: 'CUADRA'
        },
        CUEVA: {
        ineCode: 'CUEVA',
        aeatCode: 'QV',
        description: 'CUEVA/S'
        },
        CUSTA: {
        ineCode: 'CUSTA',
        aeatCode: 'CT',
        description: 'CUESTA'
        },
        CXON: {
        ineCode: 'CXON ',
        aeatCode: 'CX',
        description: 'CALEXON'
        },
        CZADA: {
        ineCode: 'CZADA',
        aeatCode: 'CZ',
        description: 'CALZADA'
        },
        CZADS: {
        ineCode: 'CZADS',
        aeatCode: 'CÇ',
        description: 'CALZADAS'
        },
        DEMAR: {
        ineCode: 'DEMAR',
        aeatCode: 'DM',
        description: 'DEMARCACION'
        },
        DHSA: {
        ineCode: 'DHSA ',
        aeatCode: 'DH',
        description: 'DEHESA'
        },
        DISEM: {
        ineCode: 'DISEM',
        aeatCode: 'DS',
        description: 'DISEMINADO'
        },
        DISSE: {
        ineCode: 'DISSE',
        aeatCode: 'DS',
        description: 'DISSEMINAT'
        },
        DRERA: {
        ineCode: 'DRERA',
        aeatCode: 'DR',
        description: 'DREÇERA'
        },
        EDIFC: {
        ineCode: 'EDIFC',
        aeatCode: 'ED',
        description: 'EDIFICIO/S'
        },
        EIRAD: {
        ineCode: 'EIRAD',
        aeatCode: 'EI',
        description: 'EIRADO'
        },
        EMPR: {
        ineCode: 'EMPR ',
        aeatCode: 'ER',
        description: 'EMPRESA'
        },
        ENTD: {
        ineCode: 'ENTD ',
        aeatCode: 'EP',
        description: 'ENTRADA'
        },
        EPTZA: {
        ineCode: 'EPTZA',
        aeatCode: 'PZ',
        description: 'ENPARANTZA'
        },
        ERREB: {
        ineCode: 'ERREB',
        aeatCode: 'AR',
        description: 'ERREBAL'
        },
        ERREP: {
        ineCode: 'ERREP',
        aeatCode: 'CR',
        description: 'ERREPIDE'
        },
        ERRIB: {
        ineCode: 'ERRIB',
        aeatCode: 'VG',
        description: 'ERRIBERA'
        },
        ESC: {
        ineCode: 'ESC  ',
        aeatCode: 'EC',
        description: 'ESCALA/S'
        },
        ESCA: {
        ineCode: 'ESCA ',
        aeatCode: 'EC',
        description: 'ESCALERA/S'
        },
        ESCAL: {
        ineCode: 'ESCAL',
        aeatCode: 'EW',
        description: 'ESCALINATA'
        },
        ESLDA: {
        ineCode: 'ESLDA',
        aeatCode: 'ES',
        description: 'ESPALDA'
        },
        ESPIG: {
        ineCode: 'ESPIG',
        aeatCode: 'EG',
        description: 'ESPIGO'
        },
        ESTAC: {
        ineCode: 'ESTAC',
        aeatCode: 'EN',
        description: 'ESTACIO'
        },
        ESTCN: {
        ineCode: 'ESTCN',
        aeatCode: 'EN',
        description: 'ESTACION'
        },
        ESTDA: {
        ineCode: 'ESTDA',
        aeatCode: 'EX',
        description: 'ESTRADA'
        },
        ETDEA: {
        ineCode: 'ETDEA',
        aeatCode: 'AV',
        description: 'ETORBIDEA'
        },
        ETXAD: {
        ineCode: 'ETXAD',
        aeatCode: 'GR',
        description: 'ETXADI'
        },
        ETXAR: {
        ineCode: 'ETXAR',
        aeatCode: 'CK',
        description: 'ETXARTE'
        },
        EXPLA: {
        ineCode: 'EXPLA',
        aeatCode: 'EZ',
        description: 'EXPLANADA'
        },
        EXTRM: {
        ineCode: 'EXTRM',
        aeatCode: 'EM',
        description: 'EXTRAMUROS'
        },
        EXTRR: {
        ineCode: 'EXTRR',
        aeatCode: 'ET',
        description: 'EXTRARRADIO'
        },
        FALDA: {
        ineCode: 'FALDA',
        aeatCode: 'FD',
        description: 'FALDA'
        },
        FBRCA: {
        ineCode: 'FBRCA',
        aeatCode: 'FC',
        description: 'FABRICA'
        },
        FINCA: {
        ineCode: 'FINCA',
        aeatCode: 'FN',
        description: 'FINCA'
        },
        G_V: {
        ineCode: 'G.V. ',
        aeatCode: 'GV',
        description: 'GRAN VIA'
        },
        GAIN: {
        ineCode: 'GAIN ',
        aeatCode: 'AT',
        description: 'GAIN'
        },
        GALE: {
        ineCode: 'GALE ',
        aeatCode: 'GA',
        description: 'GALERIA'
        },
        GLLZO: {
        ineCode: 'GLLZO',
        aeatCode: 'GZ',
        description: 'GALLIZO'
        },
        GORAB: {
        ineCode: 'GORAB',
        aeatCode: 'SB',
        description: 'GORABIDE'
        },
        GRANJ: {
        ineCode: 'GRANJ',
        aeatCode: 'GJ',
        description: 'GRANJA'
        },
        GRUP: {
        ineCode: 'GRUP ',
        aeatCode: 'GR',
        description: 'GRUP'
        },
        GRUPO: {
        ineCode: 'GRUPO',
        aeatCode: 'GR',
        description: 'GRUPO/S'
        },
        GTA: {
        ineCode: 'GTA  ',
        aeatCode: 'GL',
        description: 'GLORIETA'
        },
        HEGI: {
        ineCode: 'HEGI ',
        aeatCode: 'HG',
        description: 'HEGI'
        },
        HIPOD: {
        ineCode: 'HIPOD',
        aeatCode: 'HP',
        description: 'HIPODROMO'
        },
        HIRIB: {
        ineCode: 'HIRIB',
        aeatCode: 'AV',
        description: 'HIRIBIDEA'
        },
        HONDA: {
        ineCode: 'HONDA',
        aeatCode: 'PY',
        description: 'HONDARTZA'
        },
        HOYA: {
        ineCode: 'HOYA ',
        aeatCode: 'HY',
        description: 'HOYA'
        },
        ILLA: {
        ineCode: 'ILLA ',
        aeatCode: 'IL',
        description: 'ILLA'
        },
        INDA: {
        ineCode: 'INDA ',
        aeatCode: 'IN',
        description: 'INDA'
        },
        JARD: {
        ineCode: 'JARD ',
        aeatCode: 'JR',
        description: 'JARDI'
        },
        JDIN: {
        ineCode: 'JDIN ',
        aeatCode: 'JR',
        description: 'JARDIN'
        },
        JDINS: {
        ineCode: 'JDINS',
        aeatCode: 'JR',
        description: 'JARDINES'
        },
        KAI: {
        ineCode: 'KAI  ',
        aeatCode: 'ML',
        description: 'KAI'
        },
        KALE: {
        ineCode: 'KALE ',
        aeatCode: 'CL',
        description: 'KALEA'
        },
        KARIK: {
        ineCode: 'KARIK',
        aeatCode: 'CJ',
        description: 'KARRIK'
        },
        KARRE: {
        ineCode: 'KARRE',
        aeatCode: 'KA',
        description: 'KARRERA'
        },
        KARRI: {
        ineCode: 'KARRI',
        aeatCode: 'CL',
        description: 'KARRIKA'
        },
        KOSTA: {
        ineCode: 'KOSTA',
        aeatCode: 'KO',
        description: 'KOSTA'
        },
        KRRIL: {
        ineCode: 'KRRIL',
        aeatCode: 'KL',
        description: 'KARRIL'
        },
        LAGO: {
        ineCode: 'LAGO ',
        aeatCode: 'LA',
        description: 'LAGO'
        },
        LASTE: {
        ineCode: 'LASTE',
        aeatCode: 'AJ',
        description: 'LASTERBIDE'
        },
        LDERA: {
        ineCode: 'LDERA',
        aeatCode: 'LD',
        description: 'LADERA'
        },
        LEKU: {
        ineCode: 'LEKU ',
        aeatCode: 'LG',
        description: 'LEKU'
        },
        LLNRA: {
        ineCode: 'LLNRA',
        aeatCode: 'LL',
        description: 'LLANURA'
        },
        LLOC: {
        ineCode: 'LLOC ',
        aeatCode: 'LG',
        description: 'LLOC'
        },
        LOMA: {
        ineCode: 'LOMA ',
        aeatCode: 'LM',
        description: 'LOMA'
        },
        LOMO: {
        ineCode: 'LOMO ',
        aeatCode: 'LO',
        description: 'LOMO'
        },
        LORAK: {
        ineCode: 'LORAK',
        aeatCode: 'JR',
        description: 'LORATEGIAK'
        },
        LORAT: {
        ineCode: 'LORAT',
        aeatCode: 'JR',
        description: 'LORATEGI'
        },
        LUGAR: {
        ineCode: 'LUGAR',
        aeatCode: 'LG',
        description: 'LUGAR'
        },
        MALEC: {
        ineCode: 'MALEC',
        aeatCode: 'MA',
        description: 'MALECON'
        },
        MASIA: {
        ineCode: 'MASIA',
        aeatCode: 'MS',
        description: 'MASIA/S'
        },
        MAZO: {
        ineCode: 'MAZO ',
        aeatCode: 'MZ',
        description: 'MAZO'
        },
        MENDI: {
        ineCode: 'MENDI',
        aeatCode: 'MT',
        description: 'MENDI'
        },
        MERC: {
        ineCode: 'MERC ',
        aeatCode: 'MC',
        description: 'MERCADO'
        },
        MERCT: {
        ineCode: 'MERCT',
        aeatCode: 'MC',
        description: 'MERCAT'
        },
        MIRAD: {
        ineCode: 'MIRAD',
        aeatCode: 'MD',
        description: 'MIRADOR'
        },
        MOLL: {
        ineCode: 'MOLL ',
        aeatCode: 'ML',
        description: 'MOLL'
        },
        MONTE: {
        ineCode: 'MONTE',
        aeatCode: 'MT',
        description: 'MONTE'
        },
        MRDOR: {
        ineCode: 'MRDOR',
        aeatCode: 'MD',
        description: 'MIRADOR'
        },
        MTRIO: {
        ineCode: 'MTRIO',
        aeatCode: 'MO',
        description: 'MONASTERIO'
        },
        MUELL: {
        ineCode: 'MUELL',
        aeatCode: 'ML',
        description: 'MUELLE'
        },
        NAVE: {
        ineCode: 'NAVE ',
        aeatCode: 'NV',
        description: 'NAVE/S'
        },
        NCLEO: {
        ineCode: 'NCLEO',
        aeatCode: 'UN',
        description: 'NUCLEO'
        },
        NUDO: {
        ineCode: 'NUDO ',
        aeatCode: 'ND',
        description: 'NUDO'
        },
        ONDA: {
        ineCode: 'ONDA ',
        aeatCode: 'PY',
        description: 'ONDARTZA'
        },
        PAGO: {
        ineCode: 'PAGO ',
        aeatCode: 'PP',
        description: 'PAGO'
        },
        PALAC: {
        ineCode: 'PALAC',
        aeatCode: 'PC',
        description: 'PALACIO'
        },
        PANT: {
        ineCode: 'PANT ',
        aeatCode: 'P ',
        description: 'PANTANO'
        },
        PARC: {
        ineCode: 'PARC ',
        aeatCode: 'PQ',
        description: 'PARC'
        },
        PARKE: {
        ineCode: 'PARKE',
        aeatCode: 'PQ',
        description: 'PARKE'
        },
        PARTI: {
        ineCode: 'PARTI',
        aeatCode: 'PF',
        description: 'PARTICULAR'
        },
        PAS: {
        ineCode: 'PAS  ',
        aeatCode: 'PA',
        description: 'PAS'
        },
        PASAI: {
        ineCode: 'PASAI',
        aeatCode: 'PJ',
        description: 'PASAIA'
        },
        PASEA: {
        ineCode: 'PASEA',
        aeatCode: 'PS',
        description: 'PASEABIDE'
        },
        PASEO: {
        ineCode: 'PASEO',
        aeatCode: 'PS',
        description: 'PASEO'
        },
        PASSE: {
        ineCode: 'PASSE',
        aeatCode: 'PS',
        description: 'PASSEIG'
        },
        PATIO: {
        ineCode: 'PATIO',
        aeatCode: 'PK',
        description: 'PATIO'
        },
        PBDO: {
        ineCode: 'PBDO ',
        aeatCode: 'PB',
        description: 'POBLADO'
        },
        PBLO: {
        ineCode: 'PBLO ',
        aeatCode: 'PB',
        description: 'PUEBLO'
        },
        PDA: {
        ineCode: 'PDA  ',
        aeatCode: 'PV',
        description: 'PUJADA'
        },
        PDIS: {
        ineCode: 'PDIS ',
        aeatCode: 'PÇ',
        description: 'PASSADIS'
        },
        PG: {
        ineCode: 'PG   ',
        aeatCode: 'PG',
        description: 'PG'
        },
        PGIND: {
        ineCode: 'PGIND',
        aeatCode: 'PG',
        description: 'POLIGONO INDUST'
        },
        PINAR: {
        ineCode: 'PINAR',
        aeatCode: 'PN',
        description: 'PINAR'
        },
        PISTA: {
        ineCode: 'PISTA',
        aeatCode: 'PI',
        description: 'PISTA'
        },
        PJDA: {
        ineCode: 'PJDA ',
        aeatCode: 'SU',
        description: 'PUJADA, SUBIDA'
        },
        PL: {
        ineCode: 'PL   ',
        aeatCode: 'PL',
        description: 'PL'
        },
        PLA: {
        ineCode: 'PLA  ',
        aeatCode: 'PW',
        description: 'PLA'
        },
        PLACA: {
        ineCode: 'PLAÇA',
        aeatCode: 'PZ',
        description: 'PLAÇA'
        },
        PLAYA: {
        ineCode: 'PLAYA',
        aeatCode: 'PY',
        description: 'PLAYA'
        },
        PLAZA: {
        ineCode: 'PLAZA',
        aeatCode: 'PZ',
        description: 'PLAZA'
        },
        PLCET: {
        ineCode: 'PLCET',
        aeatCode: 'PL',
        description: 'PLACETA'
        },
        PLLOP: {
        ineCode: 'PLLOP',
        aeatCode: 'PX',
        description: 'PASILLO'
        },
        PLZLA: {
        ineCode: 'PLZLA',
        aeatCode: 'PL',
        description: 'PLAZUELA'
        },
        PNTE: {
        ineCode: 'PNTE ',
        aeatCode: 'PT',
        description: 'PUENTE'
        },
        POLIG: {
        ineCode: 'POLIG',
        aeatCode: 'PG',
        description: 'POLIGONO'
        },
        PONT: {
        ineCode: 'PONT ',
        aeatCode: 'PT',
        description: 'PONT'
        },
        PONTE: {
        ineCode: 'PONTE',
        aeatCode: 'PT',
        description: 'PONTE'
        },
        PORT: {
        ineCode: 'PORT ',
        aeatCode: 'PO',
        description: 'PORT'
        },
        PQUE: {
        ineCode: 'PQUE ',
        aeatCode: 'PQ',
        description: 'PARQUE'
        },
        PRANA: {
        ineCode: 'PRAÑA',
        aeatCode: 'PÑ',
        description: 'PRACIÑA'
        },
        PRAGE: {
        ineCode: 'PRAGE',
        aeatCode: 'PE',
        description: 'PARATGE'
        },
        PRAIA: {
        ineCode: 'PRAIA',
        aeatCode: 'PY',
        description: 'PRAIA'
        },
        PRAJE: {
        ineCode: 'PRAJE',
        aeatCode: 'PE',
        description: 'PARAJE'
        },
        PRAXE: {
        ineCode: 'PRAXE',
        aeatCode: 'PE',
        description: 'PARAXE'
        },
        PRAZA: {
        ineCode: 'PRAZA',
        aeatCode: 'PZ',
        description: 'PRAZA'
        },
        PROL: {
        ineCode: 'PROL ',
        aeatCode: 'PR',
        description: 'PROLONGACION'
        },
        PRTAL: {
        ineCode: 'PRTAL',
        aeatCode: 'PH',
        description: 'PORTAL'
        },
        PRTCO: {
        ineCode: 'PRTCO',
        aeatCode: 'PH',
        description: 'PORTICO'
        },
        PRZLA: {
        ineCode: 'PRZLA',
        aeatCode: 'PL',
        description: 'PRAZUELA'
        },
        PSAJE: {
        ineCode: 'PSAJE',
        aeatCode: 'PJ',
        description: 'PASAJE'
        },
        PSAXE: {
        ineCode: 'PSAXE',
        aeatCode: 'PJ',
        description: 'PASAXE'
        },
        PSLLO: {
        ineCode: 'PSLLO',
        aeatCode: 'PX',
        description: 'PASILLO'
        },
        PSMAR: {
        ineCode: 'PSMAR',
        aeatCode: 'PM',
        description: 'PASEO MARITIMO'
        },
        PTA: {
        ineCode: 'PTA  ',
        aeatCode: 'PU',
        description: 'PUERTA'
        },
        PTDA: {
        ineCode: 'PTDA ',
        aeatCode: 'PD',
        description: 'PARTIDA'
        },
        PTGE: {
        ineCode: 'PTGE ',
        aeatCode: 'PJ',
        description: 'PASSATGE'
        },
        PTILO: {
        ineCode: 'PTILO',
        aeatCode: 'PO',
        description: 'PORTILLO'
        },
        PTLLO: {
        ineCode: 'PTLLO',
        aeatCode: 'PO',
        description: 'PUERTILO'
        },
        PTO: {
        ineCode: 'PTO  ',
        aeatCode: 'PO',
        description: 'PUERTO'
        },
        PZO: {
        ineCode: 'PZO  ',
        aeatCode: 'PÇ',
        description: 'PASADIZO'
        },
        PZTA: {
        ineCode: 'PZTA ',
        aeatCode: 'PL',
        description: 'PLAZOLETA'
        },
        RABAL: {
        ineCode: 'RABAL',
        aeatCode: 'AR',
        description: 'RABAL'
        },
        RACDA: {
        ineCode: 'RACDA',
        aeatCode: 'RA',
        description: 'RACONADA'
        },
        RACO: {
        ineCode: 'RACO ',
        aeatCode: 'RC',
        description: 'RACO'
        },
        RAMAL: {
        ineCode: 'RAMAL',
        aeatCode: 'RM',
        description: 'RAMAL'
        },
        RAMPA: {
        ineCode: 'RAMPA',
        aeatCode: 'RP',
        description: 'RAMPA'
        },
        RAVAL: {
        ineCode: 'RAVAL',
        aeatCode: 'AR',
        description: 'RAVAL'
        },
        RBLA: {
        ineCode: 'RBLA ',
        aeatCode: 'RB',
        description: 'RAMBLA'
        },
        RBRA: {
        ineCode: 'RBRA ',
        aeatCode: 'RI',
        description: 'RIBERA'
        },
        RCDA: {
        ineCode: 'RCDA ',
        aeatCode: 'RN',
        description: 'RINCONADA'
        },
        RCON: {
        ineCode: 'RCON ',
        aeatCode: 'RC',
        description: 'RINCON'
        },
        RENTO: {
        ineCode: 'RENTO',
        aeatCode: 'RT',
        description: 'RENTO'
        },
        RESID: {
        ineCode: 'RESID',
        aeatCode: 'RS',
        description: 'RESIDENCIAL'
        },
        RIERA: {
        ineCode: 'RIERA',
        aeatCode: 'AY',
        description: 'RIERA'
        },
        RONDA: {
        ineCode: 'RONDA',
        aeatCode: 'RD',
        description: 'RONDA'
        },
        RTDA: {
        ineCode: 'RTDA ',
        aeatCode: 'RO',
        description: 'ROTONDA'
        },
        RUA: {
        ineCode: 'RUA  ',
        aeatCode: 'RU',
        description: 'RUA'
        },
        RUELA: {
        ineCode: 'RUELA',
        aeatCode: 'CU',
        description: 'RUELA'
        },
        RUERO: {
        ineCode: 'RUERO',
        aeatCode: 'RE',
        description: 'RUEIRO'
        },
        SANAT: {
        ineCode: 'SANAT',
        aeatCode: 'SA',
        description: 'SANATORIO'
        },
        SANTU: {
        ineCode: 'SANTU',
        aeatCode: 'ST',
        description: 'SANTUARIO'
        },
        SARBI: {
        ineCode: 'SARBI',
        aeatCode: 'AC',
        description: 'SARBIDE'
        },
        SBIDA: {
        ineCode: 'SBIDA',
        aeatCode: 'SB',
        description: 'SUBIDA'
        },
        SECT: {
        ineCode: 'SECT ',
        aeatCode: 'SC',
        description: 'SECTOR'
        },
        SEDER: {
        ineCode: 'SEDER',
        aeatCode: 'SD',
        description: 'SENDER'
        },
        SEDRA: {
        ineCode: 'SEDRA',
        aeatCode: 'SR',
        description: 'SENDERA'
        },
        SEKT: {
        ineCode: 'SEKT ',
        aeatCode: 'SC',
        description: 'SEKTORE'
        },
        SEND: {
        ineCode: 'SEND ',
        aeatCode: 'SN',
        description: 'SENDERO'
        },
        SENDA: {
        ineCode: 'SENDA',
        aeatCode: 'SD',
        description: 'SENDA'
        },
        SVTIA: {
        ineCode: 'SVTIA',
        aeatCode: 'CM',
        description: 'SERVENTIA'
        },
        TALDE: {
        ineCode: 'TALDE',
        aeatCode: 'GR',
        description: 'TALDE'
        },
        TOKI: {
        ineCode: 'TOKI ',
        aeatCode: 'PE',
        description: 'TOKI'
        },
        TRANS: {
        ineCode: 'TRANS',
        aeatCode: 'TS',
        description: 'TRANSITO'
        },
        TRAS: {
        ineCode: 'TRAS ',
        aeatCode: 'TA',
        description: 'TRASERA'
        },
        TRAV: {
        ineCode: 'TRAV ',
        aeatCode: 'TR',
        description: 'TRAVESSERA'
        },
        TRRNT: {
        ineCode: 'TRRNT',
        aeatCode: 'TO',
        description: 'TORRENTE'
        },
        TRSSI: {
        ineCode: 'TRSSI',
        aeatCode: 'TR',
        description: 'TRAVESSIA'
        },
        TRVA: {
        ineCode: 'TRVA ',
        aeatCode: 'TR',
        description: 'TRAVESIA'
        },
        TRVAL: {
        ineCode: 'TRVAL',
        aeatCode: 'TV',
        description: 'TRANSVERSAL'
        },
        URB: {
        ineCode: 'URB  ',
        aeatCode: 'UR',
        description: 'URBANIZACION'
        },
        URBAT: {
        ineCode: 'URBAT',
        aeatCode: 'UR',
        description: 'URBANITZACIO'
        },
        URBAZ: {
        ineCode: 'URBAZ',
        aeatCode: 'UR',
        description: 'URBANIZAZIO'
        },
        VALLE: {
        ineCode: 'VALLE',
        aeatCode: 'VA',
        description: 'VALLE'
        },
        VCTE: {
        ineCode: 'VCTE ',
        aeatCode: 'VD',
        description: 'VIADUCTE'
        },
        VCTO: {
        ineCode: 'VCTO ',
        aeatCode: 'VD',
        description: 'VIADUCTO'
        },
        VECIN: {
        ineCode: 'VECIN',
        aeatCode: 'VC',
        description: 'VECINDARIO'
        },
        VEGA: {
        ineCode: 'VEGA ',
        aeatCode: 'VG',
        description: 'VEGA'
        },
        VENAT: {
        ineCode: 'VENAT',
        aeatCode: 'VE',
        description: 'VEINAT'
        },
        VENLA: {
        ineCode: 'VENLA',
        aeatCode: 'VN',
        description: 'VENELA'
        },
        VIA: {
        ineCode: 'VIA  ',
        aeatCode: 'VI',
        description: 'VIA'
        },
        VIAL: {
        ineCode: 'VIAL ',
        aeatCode: 'VL',
        description: 'VIAL'
        },
        VIANY: {
        ineCode: 'VIANY',
        aeatCode: 'SN',
        description: 'VIARANY'
        },
        VILLA: {
        ineCode: 'VILLA',
        aeatCode: 'V ',
        description: 'VILLA'
        },
        VREDA: {
        ineCode: 'VREDA',
        aeatCode: 'VR',
        description: 'VEREDA'
        },
        VVDAS: {
        ineCode: 'VVDAS',
        aeatCode: 'VV',
        description: 'VIVIENDAS'
        },
        XDIN: {
        ineCode: 'XDIN ',
        aeatCode: 'JR',
        description: 'XARDIN'
        },
        ZEHAR: {
        ineCode: 'ZEHAR',
        aeatCode: 'ZE',
        description: 'ZEARKALETA'
        },
        ZONA: {
        ineCode: 'ZONA ',
        aeatCode: 'ZO',
        description: 'ZONA'
        },
        ZUBI: {
        ineCode: 'ZUBI ',
        aeatCode: 'PT',
        description: 'ZUBI'
        },
        ZUHAI: {
        ineCode: 'ZUHAI',
        aeatCode: 'AB',
        description: 'ZUHAIZTI'
        },
        ZUMAR: {
        ineCode: 'ZUMAR',
        aeatCode: 'AL',
        description: 'ZUMARDI'
        },
        XX: {
        ineCode: 'XX',
        aeatCode: 'XX',
        description: 'XX'
        },
        ZZ: {
        ineCode: 'ZZ',
        aeatCode: 'ZZ',
        description: 'ZZ'
        }
}