import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolGuard } from './guards/rol.guard';
import { SidenavLayoutComponent } from '../shared/layouts/sidenav-layout/sidenav-layout.component';
import { BasicLayoutComponent } from '../shared/layouts/basic-layout/basic-layout.component';
import { predefinedRoles } from 'src/app/app.globals';
import { PermissionGuard } from './guards/permission.guard';
import { AonPermission } from 'libraries/AonSDK/src/aon';

const routes: Routes = [
  {
  //
  // Login
  //
    path: '',
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/modules/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  {
  //
  // Admin and Enterprise
  //
    path: '',
    component: SidenavLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('src/app/modules/home/home.module').then((m) => m.HomeModule)
      },
      {
        path: 'inbox',
        loadChildren: () =>
          import('src/app/modules/inbox/inbox.module').then((m) => m.InboxModule),
          canActivate: [PermissionGuard],
          data: {
            requiredPermissions : [AonPermission.MESSENGER]
          }
      },
      {
        path: 'management',
        loadChildren: () =>
          import('src/app/modules/management/management.module').then((m) => m.ManagementModule),
          canActivate: [PermissionGuard],
          data: {
            requiredPermissions : [AonPermission.INVOICE_PORTAL, AonPermission.BANK]
          }
      },
      {
        path: 'tax-panel',
        loadChildren: () =>
          import('src/app/modules/tax-panel/tax-panel.module').then((m) => m.TaxPanelModule),
          canActivate: [PermissionGuard],
          data: {
            requiredPermissions : [AonPermission.FISCAL]
          }
      },
      {
        // Vista de los empleados, que ve la empresa
        path: 'employees-panel',
        loadChildren: () =>
          import('src/app/modules/employees-panel/employees-panel.module').then((m) => m.EmployeesPanelModule),
          canActivate: [PermissionGuard],
          data: {
            requiredPermissions : [AonPermission.PAYROLL_PORTAL]
          }
      },
      {
        // Vista del empleado
        path: 'employee-view',
        loadChildren: () =>
          import('src/app/modules/employee-view/employee-view.module').then((m) => m.EmployeeViewModule),
          canActivate: [PermissionGuard],
          data: {
            requiredPermissions : [AonPermission.PAYROLL_PORTAL]
          }
      },
      {
        path: 'documentation',
        loadChildren: () =>
          import('src/app/modules/documentation/documentation.module').then((m) => m.DocumentationModule),
          canActivate: [PermissionGuard],
          data: {
            requiredPermissions : [AonPermission.DOCUMENTAL]
          }
      },
      // {
      //   path: 'consulting',
      //   loadChildren: () =>
      //     import('src/app/modules/consulting/consulting.module').then((m) => m.ConsultingModule),
      // },
      {
        path: 'edit-profile',
        loadChildren: () =>
          import('src/app/modules/profile-company/profile-company.module').then((m) => m.ProfileCompanyModule),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      }
    ],
    canActivate: [AuthGuard, RolGuard], // TODO: Implementar RolGuard
    data: {
      requiredRoles: [
        predefinedRoles.ENTERPRISE,
        predefinedRoles.ADMIN
      ]
    }
  },
  {
  //
  // Employee
  //
    path: '',
    component: BasicLayoutComponent,
    children: [
      {
        // Vista del empleado
        path: 'main',
        loadChildren: () =>
          import('src/app/modules/employee-view/employee-view.module').then((m) => m.EmployeeViewModule),
      },
      {
        path: 'edit-profile-employee',
        loadChildren: () =>
          import('src/app/modules/profile-company/profile-company.module').then((m) => m.ProfileCompanyModule),
      },
      {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full',
      }
    ],
    canActivate: [AuthGuard, RolGuard], // TODO: Implementar RolGuard
    data: {
      requiredRoles: [
        predefinedRoles.EMPLOYEE
      ]
    }
  },
  { path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
