<div header>
  <app-icon 
    class="pointer" 
    shape="close" 
    (click)="this.destroy()"
  ></app-icon>
</div>
<div body>
  <app-icon
    [shape]="'check_circle'"
    class="green"
  ></app-icon> 
  <div 
    class="succes"
  >
    {{data.result}}
  </div>
</div>
<div progress *ngIf="data.duration">
  <mat-progress-bar 
    this.data.duration
    [mode]  = "'determinate'" 
    [value] = "progress"
  ></mat-progress-bar>
</div>