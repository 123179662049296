import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { ServiceFactory } from 'libraries/AonSDK/src/aon';

@Injectable({
  providedIn: 'root'
})
export class DomainService extends CommonService{

  repository = ServiceFactory.createSpecificMethods();

  constructor() {
    super();
  }

  getLogo(): string {
    return this.repository.getLogo().result;
  }

  getFavicon(): string {
    return this.repository.getFavicon().result;
  }

  getServiceDesk(): string {
    return this.repository.getServiceDesk().result;
  }

  getTitle(): string {
    return this.repository.getTitle().result;
  }

  getLoginMessage(): string {
    return this.repository.getLoginMessage().result;
  }
}
