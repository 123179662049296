<mat-sidenav-container id="menu-container" #content>
  <mat-sidenav *ngIf="!hide" #sidenav mode="side" disableClose="true" opened="true"
    id="menu-left"
    [ngClass]="opened ? {'menu-left-open' : true} : {'menu-left-open' : false}"
  >
    <div id="button-expande">
      <div class="button-expande-action" (click)="opened = !opened">
        <app-icon shape="keyboard_arrow_right" *ngIf="!opened"></app-icon>
        <app-icon shape="keyboard_arrow_left"  *ngIf="opened"></app-icon>
      </div>
    </div>
    <div>
      <div>
        <div 
          id="menu-left-title"
          [ngClass]="opened ? 'menu-open-text' : ''"
        >
          {{ 'MENU.MENU'| translate }}
        </div>
      </div>
      <div #iconHover appSidenavHover class="menu-left-option"
        [ngClass]="{
          'menu-select': item.selected,
          'your-other-classes': item.selected && selectedMenuItemClass === item.class
        }"
        *ngFor="let item of items;"
        (click)="deselectAll();select(item); navigate(item.routerlink)"
        routerLink={{item.routerlink}}
      >
        <app-icon class="{{ item.class }}" [shape]="item.shape" [matTooltip]="item.text" [matTooltipDisabled]="opened || this.items.length === 0"></app-icon>
        <span *ngIf="opened && this.items.length > 0" >
          {{item.text}}
        </span>
      </div>
    </div>

    <div *ngIf="!opened" class="menu-left-option-footer">
      <ng-container>
        <div
          [ngClass]="!subMenuOpened ? {'menu-left-option-footer-icon-reduced' : false} : {'menu-left-option-footer-icon-reduced' : true}"
          *ngFor="let shortcut of shortcuts;"
          routerLink={{shortcut.routerlink}}
          matTooltip="{{shortcut.toolTip}}"
          (click)="setOptions(shortcut)"
        >
          <app-icon class="griss" [shape]="shortcut.shape" *ngIf="subMenuOpened"></app-icon>
        </div>
        <div class="griss" (click)="subMenuOpened = !subMenuOpened"
          [ngClass]="!subMenuOpened ? {'menu-left-option-footer-icon-reduced-remove' : false} : {'menu-left-option-footer-icon-reduced-remove' : true}"
        >
          <app-icon *ngIf="!subMenuOpened" shape="add"></app-icon>
          <app-icon *ngIf="subMenuOpened"  shape="remove"></app-icon>
        </div>
      </ng-container>
    </div>
    <div *ngIf="opened" class="menu-left-option-footer-opend">
      <div class="menu-left-title-footer">
        {{'MENU.SHORTCUTS' | translate}}
      </div>
      <mat-grid-list cols="3" class="menu-left-option-footer-icon-expanded">
        <mat-grid-tile colspan="1" *ngFor="let shortcut of shortcuts;">
          <span
            class="griss"
            (click)="opened = !opened"
            routerLink={{shortcut.routerlink}}
            matTooltip="{{shortcut.toolTip}}"
            (click)="setOptions(shortcut)"
          >
            <app-icon [shape]="shortcut.shape"></app-icon>
          </span>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    id="body-container"
    [ngClass]="opened ? 'menu-open-content' : ''"
  >
    <div
      *ngIf="opened"
      id='menu-open'
      (click)="opened = !opened"
    ></div>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
