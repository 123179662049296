import { IMarkDetail, MarkStatus, ILocation } from "../interfaces/modelsInterfaces";
import { IFilter, IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";
import { Location } from "./Location";

export class MarkDetail implements IMarkDetail, IModel {
    private key: string;
    private apiObject: any;
    private date: Date;
    private status: MarkStatus;
    private location: ILocation;
    private idUser: string;

    public get IdUser(): string {
        return this.idUser;
    }

    public set IdUser(value: string) {
        this.idUser = value;
    }

    public getIdUser(): string {
        return this.idUser;
    }

    public setIdUser(value: string): IMarkDetail {
        this.idUser = value;
        return this;
    }

    public get Key(){
        return this.key;
    }

    public set Key(value: string){
        this.key = value;
    }

    public get ApiObject(){
        return this.apiObject;
    }

    public set ApiObject(value: any){
        this.apiObject = value;
    }

    public get Date(){
        return this.date;
    }

    public set Date(value: Date){
        this.date = value;
    }

    public get Status(){
        return this.status;
    }

    public set Status(value: MarkStatus){
        this.status = value;
    }

    public get Location(): ILocation{
        return this.location;
    }

    public set Location(value: ILocation){
        this.location = value;
    }

    constructor(lastDate?: Date, lastModification?: Date, status?: MarkStatus, location?: any) {
        this.date = lastDate || new Date(0);
        this.status = status || MarkStatus.ENTRADA;
        this.location = location || new Location();
        this.idUser = ''
        this.key = KeyGenerator.generate(15);
    }

    getDate(): Date {
        return this.date;
    }

    setDate(value: Date): IMarkDetail {
        this.date = value;
        return this;
    }

    getStatus(): MarkStatus {
        return this.status;
    }

    setStatus(value: MarkStatus): IMarkDetail {
        this.status = value;
        return this;
    }

    getLocation(): ILocation {
        return this.location;
    }

    setLocation(value: ILocation): IMarkDetail {
        this.location = value;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('lastDate', this.date);
        map.set('status', this.status);
        map.set('location', this.location);
        return map;
    }

    getSortableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('lastDate', this.date);
        map.set('status', this.status);
        map.set('location', this.location);
        return map;
    }

}

export class MarkDetailJSON {
    static parseDataToSend(data: any, currentMethod?: string | undefined, filter?: IFilter | undefined) {
        throw new Error("Method not implemented.");
    }
    
    static parseDataToReceive(data: any, currentMethod?: string | undefined, filter?: IFilter | undefined): MarkDetail {
        let markDetail = new MarkDetail();
        markDetail.ApiObject = data;
        markDetail.Key = data.id;
        markDetail.Date = data.date ? new Date(data.date) : new Date(0);
        let location: Location = new Location();
        location.Coordinates = data.coordinates ? data.coordinates.latitude + ',' + data.coordinates.longitude : '';
        location.Description = data.location ? data.location.name : '';
        location.Key = data.location ? data.location.id : '';
        markDetail.Location = location;
        // markDetail.Status = data.status;
        switch (data.status) {
            case 'in':
                markDetail.Status = MarkStatus.ENTRADA;
                break;
            case 'out':
                markDetail.Status = MarkStatus.SALIDA;
                break;
            case 'pause':
                markDetail.Status = MarkStatus.PAUSA;
                break;
            default:
                break;
        }
        markDetail.IdUser = data.task_holder.id
        return markDetail;
    }
}