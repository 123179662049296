import { IInvoice, IInvoiceSerie, IInvoiceCategory, IInvoiceTransactionType, IInvoiceActivity, ITax, IIRPF, IInvoiceExpirationLine, InvoiceType, InvoiceStatus, InvoiceClass, ContactType } from "../interfaces/modelsInterfaces";
import { ICollection, IModel } from "../interfaces/utilitiesInterfaces";
import { Collection } from "../utils/Collection";
import { KeyGenerator } from "../utils/KeyGenerator";
import { Contact } from "./Contact";
import { IRPF } from "./IRPF";
import { InvoiceActivityJSON, InvoiceActivity } from "./InvoiceActivity";
import { InvoiceCategoryJSON, InvoiceCategory } from "./InvoiceCategory";
import { InvoiceSerie } from "./InvoiceSerie";
import { Tax } from "./Tax";
import { InvoiceTransactionType } from "./InvoiceTransactionType";
import { ErrorResponse } from "../utils/Response";
import { Address, AddressJSON } from "./Address";
import { InvoiceExpirationLine } from "./InvoiceExpirationLine";
import { PaymentMethod } from "./PaymenMethod";
import { InvoiceLine } from "./InvoiceLine";
import { Product, ProductJSON } from "./Product";
import { ContactRepository } from "../repositorys/ContactRepository";

export class Invoice implements IInvoice, IModel {
    protected key: string;
    protected apiObject: any;
    protected serie: IInvoiceSerie;
    protected invoiceNumber: string;
    protected date: Date;
    protected totalAmount: number;
    protected contact: Contact;
    protected category: InvoiceCategory;
    protected lines: Collection<InvoiceLine>;
    protected transactionType: IInvoiceTransactionType;
    protected activity: InvoiceActivity;
    protected criCaja: boolean;
    protected re: boolean;
    protected regAgri: boolean;
    protected tax: ICollection<ITax>;
    protected irpf: ICollection<IIRPF>;
    protected invoiceExpirationLines: ICollection<IInvoiceExpirationLine>;
    protected type: InvoiceType;
    protected rectified: boolean;
    protected status: InvoiceStatus;
    protected isInbox: boolean;
    protected invoiceFilePath: string;
    protected invoiceClass: InvoiceClass;
    protected reference: string;
    protected withIRPF: boolean;
    protected isEmailSent: boolean;
    protected taxableBase: number;
    protected retentionPercentage: number;
    protected vatQuote: number;
    protected surchargeQuote: number;
    protected isSimplified: boolean;
    protected hasNote: boolean;
    protected remarks: string;

    constructor(serie?: IInvoiceSerie, invoiceNumber?: string, date?: Date, totalAmount?: number, contact?: Contact, category?: InvoiceCategory,
    lines?: Collection<InvoiceLine>, transactionType?: IInvoiceTransactionType, activity?: InvoiceActivity, criCaja?: boolean, re?: boolean,
    regAgri?: boolean, tax?: ICollection<ITax>, taxBase?: number, taxQuota?: number, irpf?: ICollection<IIRPF>, type?: InvoiceType,
    rectified?: boolean, status?: InvoiceStatus) {
        this.serie = serie || new InvoiceSerie();
        this.invoiceNumber = invoiceNumber || '';
        this.date = date || new Date();
        this.totalAmount = totalAmount || 0;
        this.contact = contact || new Contact();
        this.category = category || new InvoiceCategory();
        this.lines = lines || new Collection<InvoiceLine>();
        this.transactionType = transactionType || new InvoiceTransactionType();
        this.activity = activity || new InvoiceActivity();
        this.criCaja = criCaja || false;
        this.re = re || false;
        this.regAgri = regAgri || false;
        this.tax = tax || new Collection<Tax>();
        this.irpf = irpf || new Collection<IRPF>();
        this.invoiceExpirationLines = new Collection<IInvoiceExpirationLine>();
        this.key = KeyGenerator.generate(15);
        this.apiObject = {};
        this.type = type || InvoiceType.GASTO;
        this.rectified = rectified || false;
        this.status = status || InvoiceStatus.A_CONTABILIZAR;
        this.isInbox = false;
        this.invoiceFilePath = '';
        this.invoiceClass = InvoiceClass.PROFORMA;
        this.reference = '';
        this.withIRPF = false;
        this.isEmailSent = false;
        this.taxableBase = 0;
        this.retentionPercentage = 0;
        this.vatQuote = 0;
        this.surchargeQuote = 0;
        this.isSimplified = false;
        this.hasNote = false;
        this.remarks = '';
    }


    public get Remarks(): string {
        return this.remarks;
    }

    public set Remarks(value: string) { 
        this.remarks = value;
    }

    public getRemarks(): string {
        return this.remarks;
    }

    public setRemarks(value: string) { 
        this.remarks = value;
        return this;
    }

    public get HasNote(): boolean {
        return this.hasNote;
    }

    public set HasNote(value: boolean) { 
        this.hasNote = value;
    }

    public getHasNote(): boolean {
        return this.hasNote;
    }

    public setHasNote(value: boolean) { 
        this.hasNote = value;
        return this;
    }

    public get IsSimplified(): boolean{
        return this.isSimplified;
    }

    public set IsSimplified(value: boolean){
        this.isSimplified = value;
    }

    public getIsSimplified(): boolean{
        return this.isSimplified;
    }

    public setIsSimplified(value: boolean){
        this.isSimplified = value;
        return this;
    }

    public get TaxableBase(): number{
        return this.taxableBase;
    }

    public get RetentionPercentage(): number{
        return this.retentionPercentage;
    }

    public get VatQuote(): number{
        return this.vatQuote;
    }

    public get SurchargeQuote(): number{
        return this.surchargeQuote;
    }

    public set TaxableBase(value: number) {
        this.taxableBase = value;
    }
    
    public set RetentionPercentage(value: number) {
        this.retentionPercentage = value;
    }

    public set VatQuote(value: number) {
        this.vatQuote = value;
    }

    public set SurchargeQuote(value: number) {
        this.surchargeQuote = value;
    }

    public getTaxableBase(): number{
        return this.taxableBase;
    }
    
    public getRetentionPercentage(): number{
        return this.retentionPercentage;
    }

    public getVatQuote(): number{
        return this.vatQuote;
    }

    public setTaxableBase(value: number) {
        this.taxableBase = value;
        return this;
    }
    
    public setRetentionPercentage(value: number) {
        this.retentionPercentage = value;
        return this;
    }

    public setVatQuote(value: number) {
        this.vatQuote = value;
        return this;
    }

    public setSurchargeQuote(value: number) {
        this.surchargeQuote = value;
        return this;
    }

    public getSurchargeQuote(): number{
        return this.surchargeQuote;
    }

    public get IsEmailSent(): boolean {
        return this.isEmailSent;
    }

    public set IsEmailSent(value: boolean) {
        this.isEmailSent = value;
    }

    public getIsEmailSent(): boolean {
        return this.isEmailSent;
    }

    public setIsEmailSent(value: boolean): Invoice {
        this.isEmailSent = value;
        return this;
    }

    public get WithIRPF(): boolean {
        return this.withIRPF;
    }

    public set WithIRPF(value: boolean) {
        this.withIRPF = value;
    }

    getWithIRPF(): boolean {
        return this.withIRPF;
    }

    setWithIRPF(value: boolean): Invoice {
        this.withIRPF = value;
        return this;
    }

    getReference(): string {
        return this.reference;
    }

    setReference(value: string): Invoice {
        this.reference = value;
        return this;
    }

    public get Reference(): string {
        return this.reference;
    }

    public set Reference(value: string) {
        this.reference = value;
    }

    public get InvoiceClass(): InvoiceClass {
        return this.invoiceClass;
    }

    public set InvoiceClass(value: InvoiceClass) {
        this.invoiceClass = value;
    }

    public getInvoiceClass(): InvoiceClass {
        return this.invoiceClass;
    }

    public setInvoiceClass(value: InvoiceClass): IInvoice {
        this.invoiceClass = value;
        return this;
    }

    public get IsInbox(): boolean {
        return this.isInbox;
    }

    public set IsInbox(value: boolean) {
        this.isInbox = value;
    }

    public get InvoiceFilePath(): string {
        return this.invoiceFilePath;
    }

    public set InvoiceFilePath(value: string) {
        this.invoiceFilePath = value;
    }

    public getIsInbox(): boolean {
        return this.isInbox;
    }

    public setIsInbox(value: boolean): IInvoice {
        this.isInbox = value;
        return this;
    }

    public getInvoiceFilePath(): string {
        return this.invoiceFilePath;
    }

    public setInvoiceFilePath(value: string): IInvoice {
        this.invoiceFilePath = value;
        return this;
    }
    getSerie(): IInvoiceSerie {
        return this.serie
    }
    setSerie(value: IInvoiceSerie): IInvoice {
        this.serie = value;
        return this
    }
    getInvoiceNumber(): string {
        return this.invoiceNumber
    }
    setInvoiceNumber(value: string): IInvoice {
        this.invoiceNumber = value;
        return this
    }
    getInvoiceDate(): Date {
        return this.date
    }
    setInvoiceDate(value: Date): IInvoice {
        this.date = value;
        return this
    }
    getTotalAmount(): number {
        return this.totalAmount
    }
    setTotalAmount(value: number): IInvoice {
        this.totalAmount = value;
        return this
    }
    getContact(): Contact {
        return this.contact
    }
    setContact(value: Contact): IInvoice {
        this.contact = value;
        return this
    }
    getCategory(): IInvoiceCategory {
        return this.category
    }
    setCategory(value: InvoiceCategory): IInvoice {
        this.category = value;
        return this
    }
    getLines(): Collection<InvoiceLine> {
        return this.lines
    }
    setLines(value: Collection<InvoiceLine>): IInvoice {
        this.lines = value;
        return this
    }
    getTransactionType(): IInvoiceTransactionType {
        return this.transactionType
    }
    setTransactionType(value: IInvoiceTransactionType): IInvoice {
        this.transactionType = value;
        return this
    }
    getActivity(): IInvoiceActivity {
        return this.activity
    }
    setActivity(value: InvoiceActivity): IInvoice {
        this.activity = value;
        return this
    }
    getCriCaja(): boolean {
        return this.criCaja
    }
    setCriCaja(value: boolean): IInvoice {
        this.criCaja = value;
        return this
    }
    getRE(): boolean {
        return this.re
    }
    setRE(value: boolean): IInvoice {
        this.re = value;
        return this
    }
    getRegAgri(): boolean {
        return this.regAgri
    }
    setRegAgri(value: boolean): IInvoice {
        this.regAgri = value;
        return this
    }
    getTax(): ICollection<ITax> {
        return this.tax
    }
    setTax(value: ICollection<ITax>): IInvoice {
        this.tax = value;
        return this
    }
    getIRPF(): ICollection<IIRPF> {
        return this.irpf
    }
    setIRPF(value: ICollection<IIRPF>): IInvoice {
        this.irpf = value;
        return this
    }
    getInvoiceExpirationLines(): ICollection<IInvoiceExpirationLine> {
        return this.invoiceExpirationLines
    }
    setInvoiceExpirationLines(value: ICollection<IInvoiceExpirationLine>): IInvoice {
        this.invoiceExpirationLines = value;
        return this
    }
    getRectified(): boolean {
        return this.rectified
    }
    setRectified(value: boolean): IInvoice {
        this.rectified = value;
        return this
    }
    getType(): InvoiceType {
        return this.type
    }
    setType(value: InvoiceType): IInvoice {
        this.type = value;
        return this
    }
    getStatus(): InvoiceStatus {
        return this.status
    }
    setStatus(value: InvoiceStatus): IInvoice {
        this.status = value;
        return this
    }

    public get Status(): InvoiceStatus {
        return this.status;
    }

    public set Status(value: InvoiceStatus) {
        this.status = value;
    }

    public get Type(): InvoiceType {
        return this.type;
    }

    public set Type(value: InvoiceType) {
        this.type = value;
    }

    public get Rectified(): boolean {
        return this.rectified;
    }

    public set Rectified(value: boolean) {
        this.rectified = value;
    }

    public get Key(){
        return this.key;
    }

    public set Key(value: string){
        this.key = value;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Serie(): IInvoiceSerie {
        return this.serie;
    }

    public set Serie(value: IInvoiceSerie) {
        this.serie = value;
    }

    public get InvoiceNumber(): string {
        return this.invoiceNumber;
    }

    public set InvoiceNumber(value: string) {
        this.invoiceNumber = value;
    }

    public get Date(): Date {
        return this.date;
    }

    public set Date(value: Date) {
        this.date = value;
    }

    public get TotalAmount(): number {
        return this.totalAmount;
    }

    public set TotalAmount(value: number) {
        this.totalAmount = value;
    }

    public get Contact(): Contact {
        return this.contact;
    }

    public set Contact(value: Contact) {
        this.contact = value;
    }

    public get Category(): InvoiceCategory {
        return this.category;
    }

    public set Category(value: InvoiceCategory) {
        this.category = value;
    }

    public get Lines(): Collection<InvoiceLine> {
        return this.lines;
    }

    public set Lines(value: Collection<InvoiceLine>) {
        this.lines = value;
    }

    public get TransactionType(): IInvoiceTransactionType {
        return this.transactionType;
    }

    public set TransactionType(value: IInvoiceTransactionType) {
        this.transactionType = value;
    }

    public get Activity(): InvoiceActivity {
        return this.activity;
    }

    public set Activity(value: InvoiceActivity) {
        this.activity = value;
    }

    public get CriCaja(): boolean {
        return this.criCaja;
    }

    public set CriCaja(value: boolean) {
        this.criCaja = value;
    }

    public get RE(): boolean {
        return this.re;
    }

    public set RE(value: boolean) {
        this.re = value;
    }

    public get RegAgri(): boolean {
        return this.regAgri;
    }

    public set RegAgri(value: boolean) {
        this.regAgri = value;
    }

    public get Tax(): ICollection<ITax> {
        return this.tax;
    }

    public set Tax(value: ICollection<ITax>) {
        this.tax = value;
    }

    public get IRPF(): ICollection<IIRPF> {
        return this.irpf;
    }

    public set IRPF(value: ICollection<IIRPF>) {
        this.irpf = value;
    }

    public get InvoiceExpirationLines(): ICollection<IInvoiceExpirationLine> {
        return this.invoiceExpirationLines;
    }

    public set InvoiceExpirationLines(value: ICollection<IInvoiceExpirationLine>) {
        this.invoiceExpirationLines = value;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        let map = new Map<string,any>();
        map.set('serie', this.serie.Value);
        map.set('invoiceNumber', this.invoiceNumber);
        map.set('date', this.date);
        map.set('totalAmount', this.totalAmount);
        map.set('contact', this.contact.Name);
        map.set('category', this.category.Value);
        map.set('transactionType', this.transactionType.Value);
        map.set('activity', this.activity.Value);
        map.set('criCaja', this.criCaja);
        map.set('re', this.re);
        map.set('regAgri', this.regAgri);
        map.set('type', this.type);
        map.set('rectified', this.rectified);
        map.set('invoiceclass', this.invoiceClass);
        return map;
    }

    getSortableFields(): Map<string, any> {
        let map = new Map<string,any>();
        map.set('serie', this.serie.Value);
        map.set('invoiceNumber', this.invoiceNumber);
        map.set('date', this.date);
        map.set('totalAmount', this.totalAmount);
        map.set('contact', this.contact.Name);
        map.set('category', this.category.Value);
        map.set('transactionType', this.transactionType.Value);
        map.set('activity', this.activity.Value);
        map.set('criCaja', this.criCaja);
        map.set('re', this.re);
        map.set('regAgri', this.regAgri);
        map.set('type', this.type);
        map.set('rectified', this.rectified);
        map.set('invoiceclass', this.invoiceClass);
        return map;
    }
}

export class InvoiceJSON {
    static parseDataToSend(data: any) {
        throw new Error("Method not implemented.");
    }

    static parseDataToReceive(data: any, invoiceClass: InvoiceClass) {
        switch(invoiceClass) {
            case InvoiceClass.FACTURA: {
                let invoice = new Invoice();
                invoice.ApiObject = data;
                invoice.Key = data.id + ';' + invoiceClass;
                invoice.InvoiceClass = invoiceClass;
                invoice.Date = data.date ? new Date(data.date) : new Date(0)
                invoice.Serie = new InvoiceSerie(data.serie ? data.serie : '');
                invoice.Reference = data.reference ? data.reference : '';
                invoice.InvoiceNumber = data.number ? data.number : '';
                invoice.Type = data.type == 'expenses' || data.type == 'purchase' ? InvoiceType.GASTO : (data.type == 'ticket' ? InvoiceType.TICKET : InvoiceType.VENTA);
                invoice.TotalAmount = data.total ? data.total : 0;
                invoice.Status = data.status == 'pending' ? InvoiceStatus.A_CONTABILIZAR : InvoiceStatus.CONTABILIZADO;
                invoice.InvoiceFilePath = data.file ? data.file : '';
                invoice.IsInbox = data.isInbox ? data.isInbox : false;
                invoice.Contact = new Contact().setName(data.name);
                invoice.IsEmailSent = data.email ? data.email : false;
                invoice.VatQuote = data.quota ? data.quota : 0;
                invoice.SurchargeQuote = data.surcharge_quota ? data.surcharge_quota : 0;
                invoice.RetentionPercentage = data.retentionPercent ? data.retentionPercent : 0;
                invoice.TaxableBase = data.base ? data.base : 0;
                invoice.IsSimplified = data.document && data.document != '' ? false : true;
                return invoice;
            }
            case InvoiceClass.PROFORMA: {
                let invoice = new Invoice();
                invoice.ApiObject = data;
                invoice.Key = data.id + ';' + invoiceClass;
                invoice.InvoiceClass = invoiceClass;
                invoice.Date = data.date ? new Date(data.date) : new Date(0)
                invoice.Serie = new InvoiceSerie(data.serie ? data.serie : '');
                invoice.Reference = data.reference ? data.reference : '';
                invoice.InvoiceNumber = data.number ? data.number : '';
                invoice.Type = data.type == 'emitida' ? InvoiceType.VENTA : InvoiceType.GASTO;
                invoice.TotalAmount = data.total ? data.total : 0;
                invoice.Status = InvoiceStatus.A_CONTABILIZAR;
                invoice.InvoiceFilePath = data.file?.path ? "/" + data.file.path : '';
                invoice.IsEmailSent = data.email ? data.email : false;
                invoice.IsInbox = true;
                invoice.Contact = new Contact().setName(data.name ? data.name : '');
                invoice.VatQuote = data.quota ? data.quota : 0;
                invoice.SurchargeQuote = data.surcharge_quota ? data.surcharge_quota : 0;
                invoice.RetentionPercentage = data.retentionPercent ? data.retentionPercent : 0;
                invoice.TaxableBase = data.base ? data.base : 0;
                invoice.IsSimplified = data.document && data.document != '' ? false : true;
                return invoice;
            }
            default: {
                throw new ErrorResponse('0206', 'Invoice');
            }
        }
    }

    static async parseOneToReceive(data: any, invoiceClass: InvoiceClass) {
        switch(invoiceClass){
            case InvoiceClass.FACTURA: {
                let invoice = new Invoice();
                invoice.ApiObject = data;
                invoice.Key = data.id + ";" + invoiceClass;
                invoice.Activity = InvoiceActivityJSON.parseDataToReceive(data.activity);
                invoice.Category = InvoiceCategoryJSON.parseDataToReceive({code: data.category});
                let remarks = '';
                if(data.remarks && data.remarks.length > 0){
                    data.remarks.forEach((element:any) => {
                        remarks += element.reason ? element.reason + '\n' : '';
                    })
                }
                invoice.Remarks = remarks;
                let dataContact;
                let typeContact = '';
                    if(data.type == 'emitida'){
                        dataContact = data.receiver;
                        typeContact = ';' + ContactType.CLIENTE;
                    } else {
                        dataContact = data.sender;
                        let supplier;
                        let creditor;
                        try {
                            supplier = await new ContactRepository().get(dataContact.id + ';' + ContactType.PROVEEDOR);
                        } catch(error) {}
                        try {
                            creditor = await new ContactRepository().get(dataContact.id + ';' + ContactType.ACREEDOR);
                        } catch (error) {}
                        if(supplier) typeContact = ';' + ContactType.PROVEEDOR;
                        if(creditor) typeContact = ';' + ContactType.ACREEDOR;
                    }
                    let contact = new Contact();
                    contact.Key = dataContact.id ? dataContact.id + typeContact : KeyGenerator.generate(15);
                    contact.Address = dataContact.address ? AddressJSON.parseDataToReceive(dataContact.address) : new Address();
                    contact.Document = dataContact.document ? dataContact.document : '';
                    contact.Name = dataContact.name ? dataContact.name : '';
                    contact.ApiObject = dataContact;
                    contact.Country = dataContact.nationality ? dataContact.nationality : '';
                invoice.Contact = contact;
                invoice.CriCaja = data.vatAccrualPayment == 'true' || data.vatAccrualPayment == true ? true : false;
                invoice.Date = new Date(data.date);
                let irpfs = new Collection<IRPF>();
                let taxes = new Collection<Tax>();
                data.taxes?.forEach((element: any) => {
                    if(element.tax == "IRPF"){
                        let irpf = new IRPF();
                        irpf.Key = element.withholding_type + element.percentage;
                        irpf.Value = element.withholding_type;
                        irpf.Percentage = element.percentage;
                        irpf.Base = element.base;
                        irpf.Quota = element.quota;
                        irpfs.add(irpf);
                    }
                    if(element.tax == "IVA"){
                        let tax = new Tax();
                        tax.Key = element.percentage;
                        tax.Percentage = element.percentage;
                        tax.Base = element.base;
                        tax.Quota = element.quota;
                        tax.Surcharge = element.surcharge;
                        tax.SurchargeQuota = element.surcharge_quota;
                        taxes.add(tax);
                    }
                });
                invoice.Tax = taxes;
                invoice.IRPF = irpfs;
                invoice.InvoiceClass = InvoiceClass.FACTURA;
                let collectionFinances = new Collection<InvoiceExpirationLine>();
                data.finances?.forEach((element: any) => {
                    let finance = new InvoiceExpirationLine();
                    finance.ApiObject = element;
                    finance.Key = KeyGenerator.generate(15);
                    finance.BankAccount = element.bank_account;
                    finance.Date = new Date(element.due_date);
                    finance.Amount = element.amount;
                    let paymethod = new PaymentMethod();
                    paymethod.Key = element.paymethod;
                    finance.PaymentMethod = paymethod;
                    collectionFinances.add(finance);
                });
                invoice.InvoiceExpirationLines = collectionFinances;
                invoice.InvoiceFilePath = generateURL(data.id, "invoice");
                invoice.Reference = data.reference;
                invoice.InvoiceNumber = data.number;
                if(data.type == 'recibida')
                    invoice.InvoiceNumber = data.reference && data.reference.split('/').lenght > 1 ? data.reference.split('/')[1] : '';
                invoice.IsInbox = false;
                let collectionLines = new Collection<InvoiceLine>();
                data.details?.forEach((element: any) => {
                    let line = new InvoiceLine();
                    line.ApiObject = element;
                    line.Key = KeyGenerator.generate(15);
                    line.Discount = +element.discount;
                    line.Price = +element.price;
                    line.Quantity = element.quantity;
                    line.TotalPrice = element.amount;
                    line.description = element.description;
                    line.IrpfKey = element.withholding_type + element.withholding_percentage;
                    let product = new Product();
                    if(element.item){
                        product.ApiObject = element.item;
                        product = ProductJSON.parseDataToReceive(element.item, element.item.product);
                        product.Key = element.item.product.id;
                        product.Price = element.item.price;
                        product.IRPF = element.item.product.retention;
                        product.Tax = element.item.product.vat;
                    }
                    line.Product = product;
                    line.IRPF = element.withholding_percentage;
                    line.Tax = element.percentage;
                    collectionLines.add(line);
                });
                invoice.Lines = collectionLines;
                invoice.RE = data.surcharge == 'false' || data.surcharge == false ? false : true;
                invoice.Rectified = data.rectified;
                invoice.RegAgri = data.withholdingFarmer == 'true' || data.withholdingFarmer == true ? true : false;
                invoice.Serie = new InvoiceSerie().setValue(data.serie);
                invoice.TotalAmount = data.total;
                let transactionType = new InvoiceTransactionType();
                transactionType.Key = data.transaction;
                invoice.TransactionType = transactionType;
                invoice.Type = data.type == 'expenses' || data.type == 'purchase' ? InvoiceType.GASTO : (data.type == 'ticket' ? InvoiceType.TICKET : InvoiceType.VENTA);
                invoice.HasNote = data.comments && data.comments != '' ? data.comments : ''
                return invoice;
            }
            case InvoiceClass.PROFORMA:{
                let invoice = new Invoice();
                invoice.ApiObject = data;
                invoice.Key = data.id + ";" + invoiceClass;
                invoice.Activity = data.activity ? InvoiceActivityJSON.parseDataToReceive(data.activity) : new InvoiceActivity();
                invoice.Category = InvoiceCategoryJSON.parseDataToReceive({code: data.category});
                let remarks = '';
                if(data.remarks && data.remarks.lenght > 0){
                    data.remarks.forEach((element:any) => {
                        remarks += element.reason ? element.reason + '\n' : '';
                    })
                }
                invoice.Remarks = remarks;
                let dataContact;
                let typeContact = '';
                    if(data.type == 'emitida'){
                        dataContact = data.receiver;
                        typeContact = ';' + ContactType.CLIENTE;
                    } else {
                        dataContact = data.sender ? data.sender : {};
                        let supplier;
                        let creditor;
                        try {
                            supplier = await new ContactRepository().get(dataContact.id + ';' + ContactType.PROVEEDOR);
                        } catch(error) {}
                        try {
                            creditor = await new ContactRepository().get(dataContact.id + ';' + ContactType.ACREEDOR);
                        } catch (error) {}
                        if(supplier) typeContact = ';' + ContactType.PROVEEDOR;
                        if(creditor) typeContact = ';' + ContactType.ACREEDOR;
                    }
                    let contact = new Contact();
                    contact.Key = dataContact.id ? dataContact.id + typeContact : KeyGenerator.generate(15);
                    if(dataContact.Address)
                        contact.Address = AddressJSON.parseDataToReceive(dataContact.Address);
                    else if (dataContact.address)
                        contact.Address = AddressJSON.parseDataToReceive(dataContact.address);
                    contact.Document = dataContact.document ? dataContact.document : '';
                    contact.Name = dataContact.name ? dataContact.name : '';
                    contact.ApiObject = dataContact;
                    contact.Country = dataContact.nationality ? dataContact.nationality : '';
                invoice.Contact = contact;
                invoice.CriCaja = data.vatAccrualPayment == 'true' || data.vatAccrualPayment == true ? true : false;
                invoice.Date = new Date(data.date);
                let irpfs = new Collection<IRPF>();
                let taxes = new Collection<Tax>();
                data.taxes?.forEach((element: any) => {
                    if(element.tax == "IRPF"){
                        let irpf = new IRPF();
                        irpf.Key = element.withholding_type + element.percentage;
                        irpf.Value = element.withholding_type;
                        irpf.Percentage = element.percentage;
                        irpf.Base = element.base;
                        irpf.Quota = element.quota;
                        irpfs.add(irpf);
                    }
                    if(element.tax == "IVA"){
                        let tax = new Tax();
                        tax.Key = element.percentage;
                        tax.Percentage = element.percentage;
                        tax.Base = element.base;
                        tax.Quota = element.quota;
                        tax.Surcharge = element.surcharge;
                        tax.SurchargeQuota = element.surcharge_quota;
                        taxes.add(tax);
                    }
                });
                invoice.Tax = taxes;
                invoice.IRPF = irpfs;
                invoice.InvoiceClass = InvoiceClass.PROFORMA;
                let collectionFinances = new Collection<InvoiceExpirationLine>();
                data.finances?.forEach((element: any) => {
                    let finance = new InvoiceExpirationLine();
                    finance.ApiObject = element;
                    finance.Key = KeyGenerator.generate(15);
                    finance.BankAccount = element.bank_account;
                    finance.Date = new Date(element.due_date);
                    finance.Amount = element.amount;
                    let paymethod = new PaymentMethod();
                    paymethod.Key = element.paymethod;
                    finance.PaymentMethod = paymethod;
                    collectionFinances.add(finance);
                });
                invoice.InvoiceExpirationLines = collectionFinances;
                invoice.InvoiceFilePath = generateURL(data.id, "rawdoc");
                invoice.InvoiceNumber = data.number;
                invoice.Reference = data.reference;
                if(data.type == 'recibida')
                    invoice.InvoiceNumber = data.reference && data.reference.split('/').length > 0 ? data.reference.split('/')[1] : '';
                invoice.IsInbox = false;
                let collectionLines = new Collection<InvoiceLine>();
                data.details?.forEach((element: any) => {
                    let line = new InvoiceLine();
                    line.ApiObject = element;
                    line.Key = KeyGenerator.generate(15);
                    line.Discount = +element.discount;
                    line.Price = +element.price;
                    line.Quantity = element.quantity;
                    line.TotalPrice = element.amount;
                    line.description = element.description;
                    line.IrpfKey = element.withholding_type + element.withholding_percentage;
                    let product = new Product();
                    if(element.item){
                        product.ApiObject = element.item;
                        product = ProductJSON.parseDataToReceive(element.item, element.item.product);
                        product.Key = element.item.product.id;
                        product.Price = element.item.price;
                        product.IRPF = element.item.product.retention;
                        product.Tax = element.item.product.vat;
                    }
                    line.Surcharge = element.surcharge;
                    line.Product = product;
                    line.IRPF = element.withholding_percentage;
                    line.Tax = element.percentage;
                    collectionLines.add(line);
                });
                invoice.Lines = collectionLines;
                invoice.RE = data.surcharge == 'false' || data.surcharge == false ? false : true;
                invoice.Rectified = data.rectified;
                invoice.RegAgri = data.withholdingFarmer == 'true' || data.withholdingFarmer == true ? true : false;
                invoice.Serie = new InvoiceSerie().setValue(data.serie);
                invoice.TotalAmount = data.total;
                let transactionType = new InvoiceTransactionType();
                transactionType.Key = data.transaction;
                invoice.TransactionType = transactionType;
                invoice.Type = data.type == 'emitida' ? InvoiceType.VENTA : InvoiceType.GASTO;
                invoice.HasNote = data.comments && data.comments != '' ? data.comments : ''
                return invoice;
            }
            default:{
                throw new ErrorResponse('0206', 'Invoice');
            }
        }
    }
}

let generateURL = (id: string, value: String) => {
    return'/ms/api/download_invoice_pdf?json=' + btoa(
        JSON.stringify({
            "id": id,
            "source": value,
            "domain_id": localStorage.getItem("domainId"),
            "domain_name": localStorage.getItem("domainName"),
            "login": localStorage.getItem("login")
        })
    );
}