import { IFilter, ICollection, ICertificate } from "../aon";
import { CertificateSharedTypeList, CertificateSharedTypeListToApi, CertificateTypeList, CertificateTypeListToApi, ICertificateSharedType, ICertificateType } from "../interfaces/modelsInterfaces";
import { ICertificateSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { CertificateJSON, Certificate } from "../models/Certificate";
import { CertificateSharedType } from "../models/CertificateSharedType";
import { CertificateType } from "../models/CertificateType";
import { Collection } from "../utils/Collection";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { GenericRepository } from "./GenericRepository";
import { ErrorResponse } from "../utils/Response";
import { FileToBase64 } from "../utils/FileHelper";
import { CERTIFICATE_URL } from "../utils/ApiUrls";
import { SecondaryUserJSON } from "../models/SecondaryUser";

export class CertificateRepository extends GenericRepository<Certificate> implements ICertificateSpecificMethodsRepository, IMultipleObjectCrudRepository<Certificate>, ISingleObjectCrudRepository<Certificate> {
    async getCollection(filter?: IFilter | undefined): Promise<ICollection<Certificate>> {
        let response = await ApiHttpRequest.get(BASE_URL + CERTIFICATE_URL.CERTIFICATE, {}, filter);
        let collection = new Collection<Certificate>();
        if(response)
            response.forEach((element: any) => {
                collection.add(CertificateJSON.parseDataToReceive(element));
            });
        return collection;
    }

    async get(key: string): Promise<Certificate> {
        let json = {
            id: +key
        }
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + CERTIFICATE_URL.CERTIFICATE_ONE, json), {}, {});
        if(!(response?.type! == 'error')){
            let certficate = CertificateJSON.parseDataToReceive(response);
            try{
                let secondaryUsers = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + CERTIFICATE_URL.SECONDARY_USERS, json), {}, {});
                if(!(secondaryUsers?.type! == 'error')){
                    secondaryUsers.array.forEach((element: any) => {
                        certficate.SecondaryUsers.add(SecondaryUserJSON.parseToReceive(element));
                    });
                }
            } catch(e) {}
            return certficate;
        }else{
            throw new ErrorResponse('1601');
        }
    }

    async delete(key: string): Promise<void> {
        let json = {
            id: key
        }
        let response = await ApiHttpRequest.delete(BASE_URL + CERTIFICATE_URL.CERTIFICATE, {}, json);
        if(!(response?.type! == 'error')){
            return;
        }else{
            throw new ErrorResponse('1603');
        }
    }

    async update(element: Certificate): Promise<Certificate> {
        let json = {
            id: element.Key,
            aeat: element.Aeat,
            tgss: element.Tgss,
            sepe: element.Sepe,
            alias: element.Alias,
            owner: CertificateSharedTypeListToApi[element.SharedType.Value as keyof typeof CertificateSharedTypeListToApi]
        }
        let response = await ApiHttpRequest.put(BASE_URL + CERTIFICATE_URL.CERTIFICATE, {}, json);
        if(response != null){
            return new Certificate();    
        }else{
            throw new ErrorResponse('1605');
        }
        
    }

    async uploadCertificate(file: File, cert: Certificate): Promise<ICertificate> {
        let json = {
            fileData: await FileToBase64(file),
            password: cert.Password,
            // De momento vamos a dejar todos los certificados que se suban a través de la api como privados ya que 
            // el hecho de que sea publico solo tendría sentido en la suite, 
			// dentro de un portal ninguna empresa querría tener un certificado público que fuera visible desde otra
            confidential: CertificateTypeListToApi.privado,
            owner: CertificateSharedTypeListToApi[cert.SharedType.Value as keyof typeof CertificateSharedTypeListToApi],
            aeat: cert.Aeat,
            tgss: cert.Tgss,
            sepe: cert.Sepe,
            alias: cert.Alias,
        }
        let response = await ApiHttpRequest.post(BASE_URL + CERTIFICATE_URL.CERTIFICATE, {}, json);
        if(!(response?.type! == 'error')){
            return CertificateJSON.parseDataToReceive(response);
        } else {
            throw new ErrorResponse('1604');    
        }
    }

    async getCertificateTypeList(): Promise<ICollection<ICertificateType>> {
        let collection = new Collection<ICertificateType>();
        Object.values(CertificateTypeList).forEach(element => {
            collection.add(new CertificateType(element));
        })
        return collection;
    }

    async getCertificateSharedTypeList(): Promise<ICollection<ICertificateSharedType>> {
        let collection = new Collection<ICertificateSharedType>();
        Object.values(CertificateSharedTypeList).forEach(element => {
            collection.add(new CertificateSharedType(element));
        })
        return collection;
    }

    async validateCertificate(key: string): Promise<boolean> {
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + CERTIFICATE_URL.VERIFY, {id: +key}), {}, {});
        if(!(response?.type! == 'error')){
            return true;
        } else {
            throw new ErrorResponse('1602');
        }
    }   

    async checkPassword(file: File, password: String): Promise<boolean> {
        let json = {
            fileData: await FileToBase64(file),
            password: password,
        }
        let response = await ApiHttpRequest.post(BASE_URL + CERTIFICATE_URL.CHECK_PASSWORD, {}, json);
        return response;
    }
}