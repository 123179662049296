<app-pagination
  *ngIf                = "pagination"
  [pagination]         = "pagination"
  [paginationDate]     = "paginationDate"
  (paginationDateEmit) = "tableHandlePageEvent($event)"
></app-pagination>
<table
  mat-table
  mat-flat-table
  [dataSource]="data"
  class="component-table"
>
  <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
    <th mat-header-cell
      *matHeaderCellDef
      [ngClass]="head[column]?.class ? head[column].class : head[column]"
    >
      <app-input
        *ngIf="column === 'checkbox'"
        [type]="'checkbox'"
        [color]="headCheckboxColor"
        (inputValue)="toggleSelectAll($event)"
        [value]="headCheckboxValue"
      ></app-input>
      {{ head[column]?.text ? head[column].text : head[column] }}
    </th>
    <td mat-cell
      *matCellDef="let element; let col = index"
      (click)="onRowClick(element.key, element.type, $event)"
      [ngClass]="[
        data[col]?.class || '', 
        (!isNotArrayOrObject(element[column]) && column === 'actions') ? 'right' : ''
      ]"
    >
      <!--Add text only-->
      <span *ngIf="isNotArrayOrObject( element[column] )" [innerHTML]="element[column]"></span>
      <span *ngIf="!isNotArrayOrObject( element[column] ) && element[column]?.icon">
        <span *ngFor="let button of element[column].icon">
          <app-icon
            id='btn-models'
            shape="{{button.shape}}"
            class='{{button.class ? button.class : "griss" }}'
            [matTooltip]="button.tooltip || ''"
          ></app-icon>
        </span>
        <span *ngIf="element[column].text" [innerHTML]="element[column].text"></span>
      </span>
      <!--Add buttons acctions-->
      <span *ngIf="!isNotArrayOrObject( element[column] ) && column === 'actions'">
        <span *ngFor="let button of element[column]">
          <app-icon
            id='btn-models'
            shape="{{button.shape}}"
            class='{{button.class ? button.class : "griss click" }}'
            [matTooltip]="button.tooltip || ''"
            (click)="tableClick($event, element['key'], button.shape)"
          ></app-icon>
        </span>
      </span>
      <!-- Add button checkbox -->
      <app-input *ngIf="!isNotArrayOrObject( element[column] ) && element[column]?.checkbox "
        [type]="'checkbox'"
        [color]="element[column].checkbox.color"
        (inputValue)="toggleRowSelection($event, element, column)"
        [value]="element[column].checkbox.value"
      ></app-input>
      <!-- Add input -->
      <app-input *ngIf="!isNotArrayOrObject( element[column] ) && element[column]?.input"
        [type]="'text'"
        [value]="element[column]?.input.value"
        (inputValue)="inputEdit($event, element, column)"
      ></app-input>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row
    *matRowDef="let row; columns: columnsToDisplay;"
  ></tr>
</table>
