import { DatePipe } from '@angular/common';

export const DateFormat = (dateFormat: any, format: string) => {
  // El idioma que tenemos seleccionado
  const language = localStorage.getItem('selectedLanguage');
  // Formateamos el dato como string
  dateFormat = dateFormat.toString().replace(/CET/gi, '');
  // Miramos si el date no es null
  if(dateIsNull(new Date(dateFormat)) || dateFormat === 'Invalid Date'){
    return '';
  }
  // Comprovamos el formato a devolver
    // Datos necesarios para comprobar
      const chars: {[index: string]: string} = {
        'dd'  : 'yyyy',
        'yyyy': 'dd'
      };
      const formatNotAllowed = [
        'yyyy/MM/dd',
        'yyyy/MM/dd, HH:mm',
        'yyyy/MM/dd HH:mm',
        'yyyy-MM-dd, HH:m m',
        'yyyy-MM-dd HH:mm'
      ];
    // Modificamos el valor, por el formato correcto si es necesario
      if(formatNotAllowed.includes(format)){
        format = format.replace(/yyyy|dd/gi, m => chars[m]);
      }
  // Retornamos el valor formateado
  return new DatePipe(language || 'es').transform(dateFormat, format);
}

// Fecha actual
export const getDate = (): string => {
  const startDate = new Date();
  return DateFormat(startDate, 'yyyy-MM-dd')|| '';
};

// Anio actual
export const getYear = (): string => {
  const startDate = new Date();
  return DateFormat(startDate, 'yyyy')|| '';
};

// Filtros fechas principio semana
export const getStartDateOfWeek = (): string => {
  const startDate = new Date();
  const currentDay = startDate.getDay();
  const startDay = currentDay === 0 ? 6 : currentDay - 1;
  startDate.setDate(startDate.getDate() - startDay);
  return DateFormat(startDate, 'yyyy-MM-dd')|| '';
};

// Filtros fechas final semana
export const getEndDateOfWeek = (): string => {
  const endDate = new Date();
  const currentDay = endDate.getDay();
  const remainingDays = 7 - currentDay - 1;
  endDate.setDate(endDate.getDate() + remainingDays);
  return DateFormat(endDate, 'yyyy-MM-dd')|| '';
};

// Filtros fechas principio mes
export const getStartDateOfMonth = (): string => {
  const startDate = new Date();
  startDate.setDate(1);
  return DateFormat(startDate, 'yyyy-MM-dd')|| '';
};

// Filtros fechas final mes
export const getEndDateOfMonth = (): string => {
  const endDate = new Date();
  endDate.setMonth(endDate.getMonth() + 1);
  endDate.setDate(0);
  return DateFormat(endDate, 'yyyy-MM-dd')|| '';
};

//Devuelve si una fecha es null
export const dateIsNull = (date: Date): boolean => {
  return date.getTime() == new Date(0).getTime();
}

// Función para formatear la hora
export const formatTime = (date: Date): string => {
  // Si no tenemos hora devolvemos --:--:--
  const defaultDate = new Date(0);
  if (date.getTime() === defaultDate.getTime()) {
    return '--:--:--';
  }
  // Si tenemos hora la devolvemos
  return DateFormat(date, 'HH:mm:ss')|| '';
};

export const convertMillisecondsToTimeString = (ms: number): string => {
  let seconds = Math.floor((ms / 1000) % 60);
  let minutes = Math.floor((ms / (1000 * 60)) % 60);
  let hours   = Math.floor( ms / (1000 * 60 * 60));

  // Convertir los números a cadenas y asegurarse de que los minutos y segundos tengan dos dígitos
  const hoursStr    = hours.toString();
  const minutesStr  = minutes.toString().padStart(2, '0');
  const secondsStr  = seconds.toString().padStart(2, '0');

  return `${hoursStr}h ${minutesStr}m ${secondsStr}s`;
}

// Devuelve los 5 días anteriores
export const lastFiveDays = () => {
  const currentDate = new Date();
  const endDateFilter = DateFormat(currentDate, 'yyyy-MM-dd');
  const startDateFilter = new Date(currentDate);
  startDateFilter.setDate(startDateFilter.getDate() - 4);
  return {
    startDateFilter: DateFormat(startDateFilter, 'yyyy-MM-dd'), endDateFilter
  };
}

// Devuelve los 5 meses anteriores
export const lastFiveMonths = () => {
  const currentDate     = new Date();
  const startDateFilter = new Date(currentDate);
  const endDateFilter   = getEndDateOfMonth();
  startDateFilter.setMonth(startDateFilter.getMonth() - 5);
  startDateFilter.setDate(1); // Inicio del mes
  // Ajusta si el día del mes inicial es mayor que el día del mes final
  if (startDateFilter.getDate() > currentDate.getDate()) {
    startDateFilter.setMonth(startDateFilter.getMonth() - 1);
    startDateFilter.setDate(0); // Último día del mes anterior
  }
  return {
    startDateFilter: DateFormat(startDateFilter, 'yyyy-MM-dd'), endDateFilter
  };
}
