<mat-paginator
  *ngIf="pagination && paginationDate.pageTotal > paginationDate.pageSize"
  (page)="handlePageEvent($event)"
  [showFirstLastButtons]="showFirstLastButtons"
  [length]="paginationDate.pageTotal"
  [pageSize]="paginationDate.pageSize"
  [hidePageSize]="hidePageSize"
  [pageIndex]="paginationDate.pageIndex"
  [pageSizeOptions]="pageSizeOptions"
></mat-paginator>
