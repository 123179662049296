import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RolGuard  {

  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Permisos necesarios para esa vista
    const requiredRoles = route.data.requiredRoles;

    return new Promise(resolve => {
      this.authService.getUserRol().then((userRol: string[]) => {
        // Verifica si el usuario tiene los permisos para acceder a la ruta
        const hasRoles = userRol.some(role => requiredRoles.includes(role));
        // No tiene permisos
        if(!hasRoles){
          this.router.navigate(['']);
        }
        // Devolvemos TRUE si tenemos los permisos correspondientes
        resolve(hasRoles ? true : false);
      });
    });
  }

}
