import { IPaymentMethod } from "../interfaces/modelsInterfaces";
import { IFilter, IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class PaymentMethod extends SimpleValue implements IPaymentMethod, IModel {}

export class PaymentMethodJSON {
    static parseDataToSend(data: any, currentMethod?: string | undefined, filter?: IFilter | undefined) {
        throw new Error("Method not implemented.");
    }

    static parseDataToReceive(data: any, currentMethod?: string | undefined, filter?: IFilter | undefined) {
        let paymethod = new PaymentMethod();
        paymethod.ApiObject = data;
        paymethod.Key = data.id;
        paymethod.Value = data.name;
        return paymethod;
    }
}