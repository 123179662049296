import { ICollection, IFilter } from "../interfaces/utilitiesInterfaces";
import { ErrorResponse } from "../utils/Response";
import { Bank, BankJSON } from "../models/Bank";
import { Collection } from "../utils/Collection";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { GenericRepository } from "./GenericRepository";
import { NORDIGEN_BANK_URL, BANK_URL } from "../utils/ApiUrls";
import { IBankNordigenSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IBank, IBankBalances, IBankMovements } from "../aon";
import { BankFilter, BankStatementsFilter } from "../utils/ModelsFilters";
import { AonFilter } from "../utils/AonFilter";
import { BankFields, BankStatementsFields } from "../utils/ModelsFields";
import { BankMovementsJSON } from "../models/BankMovements";
import { BankBalancesJSON } from "../models/BankBalances";

export class BankRepository extends GenericRepository<Bank> implements IBankNordigenSpecificMethodsRepository, IMultipleObjectCrudRepository<Bank>, ISingleObjectCrudRepository<Bank> {

    //Devuelve todos los bancos
    async getCollection(filter?: BankFilter): Promise<ICollection<Bank>> {
        let response = await ApiHttpRequest.get(BASE_URL + BANK_URL.GET_BANK_LIST, {}, {});
        let collection: ICollection<Bank> = new Collection<Bank>();
        if(response.length == 0){
            return collection;
        }else{
        response.forEach((element: any) => {
            collection.add(BankJSON.parseDataToReceive(element))
        })
        let active = new AonFilter<BankFields, never, never>();
        active.addField(BankFields.ACTIVE, true);
        return collection.filter(active.getFilter());
        }
    }
    //Devuelve un banco por su identificador
    async getOneBank(key ?: string): Promise<IBank> {
        let response = await ApiHttpRequest.get(BASE_URL + BANK_URL.GET_BANK + '?id=' + key, {}, {});
        return BankJSON.parseDataToReceive(response);
    }
    //Crea un nuevo banco
    async create(element: Bank): Promise<Bank> {
        let response = await ApiHttpRequest.post(BASE_URL + BANK_URL.CREATE, {}, BankJSON.parseDataToSend(element));
        if(!(response?.type! == 'error'))
            return BankJSON.parseDataToReceive(response);
                else
            throw new ErrorResponse('0701');
    }
    //Actualiza un banco
    async update(element: Bank): Promise<Bank> {
       let response = await ApiHttpRequest.post(BASE_URL + BANK_URL.UPDATE, {}, BankJSON.parseDataToSend(element));
        if(!(response?.type! == 'error'))
            return response;
        else
            throw new ErrorResponse('0702');
    }
    //Elimina un banco
    async delete(key: string): Promise<void> {
        let json = {
            id : key
        }
        let response = await ApiHttpRequest.post(BASE_URL + BANK_URL.DELETE , {}, json);
        return response
    }
    //Obtiene la lista de balances de los bancos vinculados a Nordigen
     async getBalances(): Promise<IBankBalances> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.NORDIGEN_BANK_BALANCES, {}, {});
        return BankBalancesJSON.parseDataToReceive(response) ;
    }
    //Obtiene la lista de movimientos de los bancos vinculados a Nordigen
     async getMovements(key ?: number): Promise<IBankMovements> {
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.NORDIGEN_BANK_MOVEMENTS + '?id=' + key, {}, {});

        return BankMovementsJSON.parseDataToReceive(response);
    }
    //Obtiene la lista de movimientos almacenados en base de datos , admite un filtro de busqueda
    async getMovementsDB(filter ?: BankStatementsFilter): Promise<IBankMovements> {
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + NORDIGEN_BANK_URL.NORDIGEN_BANK_MOVEMENTS_DB, generateFilter(filter)),{} ,{});
        return BankMovementsJSON.parseDataToReceive(response) ;
    }

    async getMovementsCount(filter ?: BankStatementsFilter): Promise<number> {
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + NORDIGEN_BANK_URL.NORDIGEN_BANK_MOVEMENTS_COUNT, generateFilter(filter)),{}, {});
        return response;
    }

    //Obtiene el link para vincular el banco con Nordigen
     async getNordigenLink(key ?: string): Promise<void> {
     let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.GET_NORDIGEN_BANK_LINK + '?id=' + key, {}, {});
     const link = response?.link;
     if(link !== 'Already linked'){
        window.open(link, '_blank');
        return response;
     }else{
        throw new ErrorResponse('0708');
     }
    }
    //Desvincula el banco de Nordigen
    async unlinkBank(key ?: string): Promise<boolean> {
        return await ApiHttpRequest.post(BASE_URL + NORDIGEN_BANK_URL.UNLINK_BANK + '?id=' + key, {}, {});
    }
    //Guarda los movimientos en la base de datos
    async movementsToDB(filter? : BankStatementsFilter): Promise<boolean> {
        let response = await ApiHttpRequest.post(ApiHttpRequest.makeURL( BASE_URL + NORDIGEN_BANK_URL.NORDIGEN_BANK_MOVEMENTS , generateFilter(filter)) , {}, {});
        return response;
    }

    //Guarda los balances en la base de datos
    async balancesToDB(key: string): Promise<boolean> {
        let response = await ApiHttpRequest.post(BASE_URL + NORDIGEN_BANK_URL.NORDIGEN_BANK_BALANCES +'?id='+key , {}, {});
        return response;
    }
    //Compara la fecha actual con la del ultimo balance guardado en la base de datos, devuelve true si la diferencia es mayor a 24 horas indicando que habria que actualizar el registro
    async compareBalanceDate(): Promise<boolean>{
        let response = await ApiHttpRequest.get(BASE_URL + NORDIGEN_BANK_URL.COMPARE_BALANCE_DATE, {}, {});
        return response
    }
}

let generateFilter = (filter?: BankStatementsFilter) => {
    let page = filter?.pageNum ? filter?.pageNum : 1;
    let perPage = filter?.pageItems ? filter?.pageItems : 20;
    let id = filter?.fields?.get(BankStatementsFields.ID_BANK) ? filter?.fields?.get(BankStatementsFields.ID_BANK) : '';
    let status = filter?.fields?.get(BankStatementsFields.STATUS) ? filter?.fields?.get(BankStatementsFields.STATUS) : undefined;
    let from = filter?.fields?.get(BankStatementsFields.DATE_FROM) ? filter?.fields?.get(BankStatementsFields.DATE_FROM) : '';
    let to = filter?.fields?.get(BankStatementsFields.DATE_TO) ? filter?.fields?.get(BankStatementsFields.DATE_TO) : '';
    let description = filter?.fields?.get(BankStatementsFields.DESCRIPTION) ? filter?.fields?.get(BankStatementsFields.DESCRIPTION) : '';
    let amount = filter?.fields?.get(BankStatementsFields.AMOUNT) ? filter?.fields?.get(BankStatementsFields.AMOUNT) : '';
    let global = filter?.fields?.get(BankStatementsFields.GLOBAL) ? filter?.fields?.get(BankStatementsFields.GLOBAL) : '';
    let json = {
        page: page,
        perPage: perPage,
    };

    if(id != ''){
        Object.defineProperty(json, 'id', {
            value: id,
            enumerable: true
        });
    }
    if(status != undefined){
        Object.defineProperty(json, 'status', {
            value: status,
            enumerable: true
        });
    }
    if(from != '')
        Object.defineProperty(json, 'from', {
            value: new Date(from).toISOString(),
            enumerable: true
        });
    if(to != '')
        Object.defineProperty(json, 'to', {
            value: new Date(to).toISOString(),
            enumerable: true
        });
    if(description != '')
        Object.defineProperty(json, 'description', {
            value: description,
            enumerable: true
        });
    if(amount != '')
        Object.defineProperty(json, 'amount', {
            value: amount,
            enumerable: true
        });
    if(global != '')
        Object.defineProperty(json, 'global', {
            value: global,
            enumerable: true
        });
    return json;
}
