import { IMedia, MediaType } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";

export class Media implements IMedia, IModel {
    private type: MediaType;
    private value: string;
    private apiObject: any;
    private key: string;

    public get Key(): string {
        return this.key;
    }

    public set Key(key: string) {
        this.key = key;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public getKey(){
        return this.Key;
    }

    public set Type(value: MediaType) {
        this.type = value;
    }

    public get Type(): MediaType {
        return this.type;
    }

    public getType(){
        return this.Type;
    }

    public setType(value: MediaType): IMedia{
        this.type = value;
        return this;
    }

    public get Value(): string {
        return this.value;
    }

    public set Value(value: string) {
        this.value = value;
    }

    public setValue(value: string): IMedia{
        this.value = value;
        return this;
    }

    public getValue(){
        return this.Value;
    }

    constructor(type?: MediaType, value?: string) {
        this.type = type || MediaType.PHONE;
        this.value = value || '';
        this.key = '';
        this.apiObject = '';
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class MediaJSON {
    static parseDataToReceive(data: any){
        let media = new Media();
        switch(data.media){
            case 'cellular':
                media.Type = MediaType.PHONE;
                break;
            case 'fixed_phone':
                media.Type = MediaType.PHONE;
                break;
            case 'web':
                media.Type = MediaType.WEB;
                break;
            case 'email':
                media.Type = MediaType.EMAIL;
                break;
        }
        media.ApiObject = data;
        media.Key = data.id;
        media.Value = data.value;
        return media;
    }
}
