import { Component, OnInit, OnDestroy } from '@angular/core';
import { DateFormat, formatTime } from 'src/app/core/utilities/time';

@Component({
  selector    : 'app-clock',
  templateUrl : './clock.component.html',
  styleUrls   : ['./clock.component.scss']
})
export class ClockComponent implements OnInit, OnDestroy {
  currentTime     : string = '--:--:--';
  private interval: any;
  // Obtener el nombre del día actual
  today      : string | null = DateFormat(new Date(), 'EEEE');
  // Formatear la fecha actual
  todayDate  : string | null = DateFormat(new Date(), 'dd/MM/yyyy');

  constructor() { }

  ngOnInit() {
    this.updateTime();
    this.interval = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  updateTime() {
    this.currentTime = formatTime(new Date());
  }

}
