import { IBank } from "../interfaces/modelsInterfaces";
import { IBankNordigenSpecificMethods } from "../interfaces/serviceInterfaces";
import { IBankNordigenSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { ErrorResponse } from "../utils/Response";
import { Response } from "../utils/Response";
import { IResponse } from "../interfaces/utilitiesInterfaces";
import { GenericService } from "./GenericCrudService";
import { Bank } from "../models/Bank";
import { BankStatementsFilter } from "../utils/ModelsFilters";


export class BankService extends GenericService<Bank, never> implements IBankNordigenSpecificMethods {
    protected SpecificMethodsRepository: IBankNordigenSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<Bank> & ISingleObjectCrudRepository<Bank>, type: { new (): Bank }, SpecificMethodsRepository: IBankNordigenSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

   async getOneBank(key: string): Promise<IResponse<IBank>> {
        try {
            return new Response<IBank>(this.SpecificMethodsRepository.getOneBank(key));
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0703');
        }
    }

     async getBalances(): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getBalances());
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0704');
        }
    }

    async getMovements(key ?: number): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getMovements(key));
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0705');
        }
    }

    async getMovementsDB(filter?: BankStatementsFilter): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getMovementsDB(filter));
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0705');
        }
    }

    async getNordigenLink(key : string): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getNordigenLink(key));
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0123', 'Bank');
        }
    }

    async movementsToDB(filter?: BankStatementsFilter): Promise<IResponse<boolean>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.movementsToDB(filter));
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0123', 'Bank');
        }
    }

    async balancesToDB(key: string): Promise<IResponse<boolean>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.balancesToDB(key));
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0123', 'Bank');
        }
    }

    async compareBalanceDate(): Promise<IResponse<boolean>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.compareBalanceDate());
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0123', 'Bank');
        }
    }

    async unlinkBank(key: string): Promise<boolean> {
        try {
            return this.SpecificMethodsRepository.unlinkBank(key);
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0123', 'Bank');
        }
    }

    async getMovementsCount(filter ?: BankStatementsFilter): Promise<number> {
        try {
            return this.SpecificMethodsRepository.getMovementsCount(filter);
        } catch (error) {
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0123', 'Bank');
        }
    }
}
