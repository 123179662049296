import { ErrorResponse, ICollection, ISalary } from "../aon";
import { IMultipleObjectCrudRepository, ISalarySpecificMethodsRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { Salary, SalaryJSON } from "../models/Salary";
import { DOCUMENT_URL } from "../utils/ApiUrls";
import { Collection } from "../utils/Collection";
import { formatDate } from "../utils/Date";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { SalaryFields } from "../utils/ModelsFields";
import { SalaryFilter } from "../utils/ModelsFilters";
import { GenericRepository } from "./GenericRepository";

export class SalaryRepository extends GenericRepository<Salary> implements ISalarySpecificMethodsRepository, IMultipleObjectCrudRepository<Salary>, ISingleObjectCrudRepository<Salary> {

    async get(key: string): Promise<Salary> {
        let params = { id: key };
        let url = ApiHttpRequest.makeURL(DOCUMENT_URL.SALARY_ID, params);
        let response = await ApiHttpRequest.get(BASE_URL + url, {}, {});
        if(response){
            return SalaryJSON.parseDataToReceice(response);
        } else {
            throw new Error('2201')
        }
    }

    async getCollection(filter?: SalaryFilter): Promise<ICollection<Salary>> {
        let collection = new Collection<Salary>();
        let url = ApiHttpRequest.makeURL(DOCUMENT_URL.SALARY, generateSalaryFilter(filter));
        let response = await ApiHttpRequest.get(BASE_URL + url, {}, {});
        if(response && response.length > 0)
            response.forEach((element: any) => {
                collection.add(SalaryJSON.parseDataToReceice(element));
            })
        return collection;
    }

    async countSalary(filter?: SalaryFilter): Promise<number> {
        let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(DOCUMENT_URL.SALARY_COUNT, generateSalaryFilter(filter)),{}, {});
        return response;
    }

    async getSalaryFile(salary: ISalary): Promise<Blob> {
        return await ApiHttpRequest.httpRequestFile(BASE_URL + salary.File, {}, {});
    }

}

let generateSalaryFilter = (filter?: SalaryFilter) => {
    let params = {}
    if(filter?.fields?.get(SalaryFields.GLOBAL))
        Object.defineProperty(params, 'global', {
            value: filter?.fields?.get(SalaryFields.GLOBAL),
            enumerable : true,
        })
    if(filter?.pageNum){
        Object.defineProperty(params, 'page', {
            value: filter?.pageNum,
            enumerable : true,
        })
    }
    if(filter?.pageItems){
        Object.defineProperty(params, 'per_page', {
            value: filter?.pageItems,
            enumerable : true,
        })
    }
    if(filter?.fields?.has(SalaryFields.DOCUMENT)){
        Object.defineProperty(params, "document", {
            value: filter?.fields?.get(SalaryFields.DOCUMENT),
            enumerable: true
        });
    }
    if(filter?.fields?.has(SalaryFields.DATE_FROM)){
        Object.defineProperty(params, "from", {
            value: formatDate(filter?.fields?.get(SalaryFields.DATE_FROM)),
            enumerable: true
        });
    }
    if(filter?.fields?.has(SalaryFields.DATE_TO)){
        Object.defineProperty(params, "to", {
            value: formatDate(filter?.fields?.get(SalaryFields.DATE_TO)),
            enumerable: true
        });
    }
    return params;
}