<div header>
    Error
    <app-icon
      class="pointer"
      shape="close"
      (click)="this.destroy()"
    ></app-icon>
</div>
<div body>
  <app-icon
    [shape]="' error'"
    class="red"
  ></app-icon>
  <div
    class="error"
    *ngFor="let item of data.errorList"
  >
    {{item}}
  </div>
  <div
    class="error"
    *ngIf="data.error && !data.errorList"
  >
    {{data.error}}
  </div>
</div>
