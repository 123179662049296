import { IDocumentTag } from "../interfaces/modelsInterfaces";
import { IFilter, IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";
import { CREATE_SINGLE, UPDATE_SINGLE } from "../utils/Environment";

export class DocumentTag implements IDocumentTag, IModel {
    private key: string;
    private name: string;
    private apiObject: any;

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Key() {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public get Name(): string {
        return this.name;
    }

    public set Name(value: string) {
        this.name = value;
    }

    constructor(name?: string) {
        this.name = name || '';
        this.key = KeyGenerator.generate(15);
        this.apiObject = {};
    }
    getName(): string {
        return this.name;
    }
    setName(value: string): IDocumentTag {
        this.name = value;
        return this
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('name', this.name);
        return map;
    }

    getSortableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('name', this.name);
        return map;
    }
}

export class DocumentTagJSON {
    static parseDataToSend(data: any, currentMethod: string, filter?: IFilter | undefined) {
        if(currentMethod == CREATE_SINGLE)
            return {'name': data.Name};
        if(currentMethod == UPDATE_SINGLE){
            data.ApiObject.name = data.name;
            return data.ApiObject;
        }
        throw new Error("Method not implemented.");
    }

    static parseDataToReceive(data: any, currentMethod: string, filter?: IFilter | undefined) {
        let tag = new DocumentTag();
        tag.Name = data.name;
        tag.ApiObject = data;
        tag.Key = data.id;
        return tag;
    }
}