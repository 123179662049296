import { IInvoiceActivity, IInvoiceCategory } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class InvoiceCategory extends SimpleValue implements IInvoiceCategory, IModel {
    private principal: boolean;

    constructor(value?: any){
        super(value);
        this.principal = false;
    }

    public get Principal(): boolean {
        return this.principal;
    }

    public set Principal(value: boolean) {
        this.principal = value;
    }

    public getPrincipal(): boolean {
        return this.principal;
    }

    public setPrincipal(value: boolean): IInvoiceCategory {
        this.principal = value;
        return this;
    }

    public getApiObject(): any {
        return this.ApiObject;
    }
}

export class InvoiceCategoryJSON {
    static parseDataToReceive(data: any): InvoiceCategory {
        let category = new InvoiceCategory(data.code + ' - ' + data.name);
        category.ApiObject = data;
        category.Key = data.code;
        category.Value = data.code && data.name ? data.code + ' - ' + data.name : '';
        category.Principal = false;
        return category;
    }
}