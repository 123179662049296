<div #tag class="tag"
    [ngStyle]="{
        'border': border,
        'borderRadius': borderRadius,
        'backgroundColor': backgroundColor,
        'color': color,
        'padding': padding,
        'width': '100%',
        'height': '100%',
        'fontSize': fontSize
    }"
>
    <ng-content></ng-content>
</div>
