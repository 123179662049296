import { IContractType } from "../aon";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class ContractType extends SimpleValue implements IContractType, IModel {
    private shortValue: string;

    constructor(){
        super();
        this.shortValue = ""
    }

    public get ShortValue(): string {
        return this.shortValue;
    }

    public set ShortValue(value: string) {
        this.shortValue = value;
    }

    public getShortValue(): string {
        return this.shortValue;
    }

    public setShortValue(value: string): IContractType {
        this.shortValue = value;
        return this;
    }
}

export class ContractTypeJSON {
    static parseDataToReceive(data: any){
        let contractType = new ContractType();
        contractType.ApiObject = data;
        contractType.Key = data.value ? data.value : "";
        contractType.Value = data.name ? data.name : "";
        contractType.ShortValue = data.shortValue ? data.shortValue : "";
        return contractType;
    }
}