import { Injectable } from '@angular/core';
import { ServiceFactory, ICollection, IDocument, IFilter, MainFolders } from 'libraries/AonSDK/src/aon';
import { CommonService } from './common.service';
import { DocumentFilter } from 'libraries/AonSDK/src/utils/ModelsFilters';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends CommonService {

  private service = ServiceFactory.createDocumentService();

  constructor() {
    super();
  }

  async getDocumentList(filter?: DocumentFilter): Promise<ICollection<IDocument>> {
    return (await this.service.getCollection(filter)).result;
  }

  async getDocument(pkey: any): Promise<IDocument> {
    return (await this.service.getElement(pkey)).result;
  }

  async uploadDocument(document: IDocument, file: File): Promise<boolean> {
    return (await this.service.uploadDocument(document, file)).result;
  }

  async updateDocument(documents: IDocument | ICollection<IDocument>): Promise<IDocument | ICollection<IDocument>> {
    return (await this.service.updateElement(documents as IDocument)).result;
  }

  async deleteDocument(pkey: any): Promise<boolean> {
    return (await this.service.deleteElement(pkey)).result;
  }

  async getDocumentFile(document: IDocument): Promise<Blob> {
    return (await this.service.getRawFile(document)).result
  }

  async downloadDocument(documentData: IDocument): Promise<void> {
    let response: Blob = (await this.getDocumentFile(documentData))
    if(response.type == ''){
      response = new Blob([response], {type: 'application/pdf'});
    }
    const blobUrl = URL.createObjectURL(response);
    const a = document.createElement('a')
    a.href = blobUrl
    a.download = documentData.FileName;
    a.click();
    URL.revokeObjectURL(blobUrl);
  }

  async downloadCompressedDocumentList(document: IDocument[]): Promise<any> {
    return (await this.service.getCompressedFileList(document)).result;
  }

  async getDocumentalCount(filter: DocumentFilter): Promise<number> {    
    return (await this.service.getDocumentalCount(filter));
  }

  async getSalaryByDocument(filter: DocumentFilter): Promise<ICollection<IDocument>> {
    return (await this.service.getSalaryByDocument(filter)).result;
  }

  async countSalaryByDocument(filter: DocumentFilter): Promise<number> {
    return (await this.service.countSalaryByDocument(filter)).result;
  }

}
