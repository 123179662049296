/*
  validateFormatNumber --> Función para validar el formato utilizado en los inputs de type number.
    .................
  traductionNumber     --> Función para traducir el número dependiendo del idioma seleccionado y el número de decimales que necesitamos.
    ..................
  formatNumber         --> Función que usa la función traductionNumber y es usada para el formateo de importes de banco y en el caso de no tener importe añadirle un string ('N/D').
    ..................
  roundNumber          --> Función para el redondeo de decimales, por el método común de redondeo.
*/

// Validar que en los input de type = number, tenemos un formato valido
export function validateFormatNumber(event: any): string | boolean {
  let key;
  if (event.type === 'paste') {
    key = event.clipboardData.getData('text/plain');
  } else {
    key = event.keyCode;
    key = String.fromCharCode(key);
  }

  // 1 - Permitir del 0 al 9
  // 2 - Permitir .
  // 3 - Permitir ,
  // 4 - Permitir -
  const regex = /[0-9]|\.|\,|\-/;

  if (!regex.test(key)) {
    if (event.returnValue) event.returnValue = false;
    if (event.preventDefault) {
      event.preventDefault();
    }
    return false;
  }
  return key;
}

// Traducir el número dependiendo del idioma
export function traductionNumber(number: number, decimales: number = 2) {
  // El idioma que tenemos seleccionado
  const language = localStorage.getItem('selectedLanguage') || 'es';
  // Formatear el número dependiendo del idioma
  const numberFormatter = new Intl.NumberFormat(language, {
    minimumFractionDigits: decimales,
    maximumFractionDigits: decimales,
    useGrouping: true,
    // Esto asegura que se utilicen las configuraciones adecuadas para el idioma seleccionado
    localeMatcher: 'best fit'
  });
  return numberFormatter.format(number);
}

// Agregamos decimales al numero pasado, si no se le pasan decimales, usaremos 2 decimales
// Se devuelve el valor como string
export function formatNumber(number: number, decimales: number = 2): string {
  if (typeof number === 'number') {
    // Retornamos el valor formateado
    return traductionNumber(number, decimales);
  } else {
    return 'N/D';
  }
}
// Agregamos decimales al numero pasado, si no se le pasan decimales, usaremos 2 decimales
// Se devuelve el valor como number
export function roundNumber(number: number, decimales: number = 2): number {
  if (typeof number === 'number') {
    const factor  = Math.pow(10, decimales);
    const rounded = Math.round(number * factor);

    return (Math.floor(rounded / factor) + rounded % factor / factor);
  } else {
    return 0;
  }
}
