import { ICno } from "../aon";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class Cno extends SimpleValue implements ICno, IModel {}

export class CnoJSON {
    static parseDataToReceive(data: any){
        let cno = new Cno();
        cno.ApiObject = data;
        cno.Key = data.cnoCode ? data.cnoCode : "";
        cno.Value = data.cno ? data.cno : "";
        return cno;
    }
}