import { IInvoiceTransactionType } from "../interfaces/modelsInterfaces";
import { IFilter, IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class InvoiceTransactionType extends SimpleValue implements IInvoiceTransactionType, IModel {}

export class InvoiceTransactionTypeJSON {
    static parseDataToSend(data: any, currentMethod?: string | undefined, filter?: IFilter | undefined) {
        throw new Error("Method not implemented.");
    }
    static parseDataToReceive(data: any, currentMethod?: string | undefined, filter?: IFilter | undefined) {
        let transactionType = new InvoiceTransactionType();
        transactionType.ApiObject = data;
        transactionType.Key = data.value;
        transactionType.Value = data.name;
        return transactionType;
    }
}
export const Transactions = [
    {value: 'NAC', name: 'Nacional'},
    {value: 'INTR', name: 'Intracomunitaria'},
    {value: 'EXTR', name: 'Extracomunitaria'},
    {value: 'CCM', name: 'Canarias, Ceuta y Melilla'},
    {value: 'ISP', name: 'I.S.P.'},
  ];