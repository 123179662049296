import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav-layout',
  templateUrl: './sidenav-layout.component.html',
  styleUrls: ['./sidenav-layout.component.scss']
})
export class SidenavLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
