import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent, MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { PaginationDate } from 'src/app/core/models/interface/paginationDate';

@Component({
  selector    : 'app-pagination',
  templateUrl : './pagination.component.html',
  styleUrls   : ['./pagination.component.scss']
})

export class PaginationComponent implements OnInit {
  showFirstLastButtons  = true;   // Mostrar boton ir primera pagina y ultima
  hidePageSize          = true;  // Mostrar selector de elementos por pagina
  pageSizeOptions       = [10, 15, 20, 25];
  pageSizeMin           = 10; // Elementos minimos para mostrar las paginas
  @Input() pagination     : boolean = false;
  @Input() paginationDate : PaginationDate = {
    'pageTotal' : 500,
    'pageSize'  : this.pageSizeMin,
    'pageIndex' : 0
  };
  @Output() paginationDateEmit : EventEmitter<any> = new EventEmitter();

  constructor(
    private paginator       : MatPaginatorIntl,
    private translateService: TranslateService,
  ) {
    this.translateService.get([
      'COMMON.PAGE.PAGE',
      'COMMON.PAGE.ITEMS_PER_PAGE',
      'COMMON.PAGE.FIRST_PAGE',
      'COMMON.PAGE.PREVIUOS_PAGE',
      'COMMON.PAGE.NEXT_PAGE',
      'COMMON.PAGE.LAST_PAGE',
      'COMMON.PAGE.GET_RANGE_PAGE'
    ]).subscribe((result) => {
      paginator.firstPageLabel    = result['COMMON.PAGE.FIRST_PAGE'];
      paginator.lastPageLabel     = result['COMMON.PAGE.LAST_PAGE'];
      paginator.itemsPerPageLabel = result['COMMON.PAGE.ITEMS_PER_PAGE'];
      paginator.nextPageLabel     = result['COMMON.PAGE.NEXT_PAGE'];
      paginator.previousPageLabel = result['COMMON.PAGE.PREVIUOS_PAGE'];
      paginator.getRangeLabel     = (page: number, pageSize: number, length: number) => {
//        if (length === 0) {
//          return `Page 1 ${result['COMMON.PAGE.GET_RANGE_PAGE']} 1`;
//        }
//        const amountPages = Math.ceil(length / pageSize);
//        return `${result['COMMON.PAGE.PAGE']}  ${page + 1} ${result['COMMON.PAGE.GET_RANGE_PAGE']} ${amountPages}`;
//        
        if (length == 0 || pageSize == 0) {
          return `0 `+ result['COMMON.PAGE.GET_RANGE_PAGE'] +` ${length}`;
        }

        length = Math.max(length, 0);
        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex =
         startIndex < length
           ? Math.min(startIndex + pageSize, length)
           : startIndex + pageSize;

        return `${startIndex + 1} - ${endIndex} `+ result['COMMON.PAGE.GET_RANGE_PAGE'] +` ${length}`;
        
      };
    })
  }

  ngOnInit(): void {
  }

  handlePageEvent(event: PageEvent) {
    this.paginationDate.pageSize  = event.pageSize;
    this.paginationDate.pageIndex = event.pageIndex;
    // Emitimos la pagina en la que estamos y los elementos a mostrar
    this.paginationDateEmit.emit(this.paginationDate);
  }

}
