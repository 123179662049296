import { ILocation } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class Location implements ILocation, IModel {
    key: string;
    apiObject: any;
    latitude: number;
    longitude:number;
    coordinates: string;
    description: string;
    radio: number;

    constructor(coordinates?: string, description?: string, radio?: number) {
        this.key = KeyGenerator.generate(15);
        this.apiObject = {};
        this.coordinates = coordinates || '';
        this.description = description || '';
        this.radio = radio || 0;
        this.latitude = 0;
        this.longitude = 0;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Coordinates(): string {
        return this.coordinates;
    }

    public set Coordinates(value: string) {
        this.coordinates = value;
    }

    public get Description(): string {
        return this.description;
    }

    public set Description(value: string) {
        this.description = value;
    }

    public get Radio(): number {
        return this.radio;
    }

    public set Radio(value: number) {
        this.radio = value;
    }

    getCoordinates(): string {
        return this.coordinates;
    }

    setCoordinates(value: string): ILocation {
        this.coordinates = value;
        return this;
    }

    getDescription(): string {
        return this.description;
    }

    setDescription(value: string): ILocation {
        this.description = value;
        return this;
    }

    getRadio(): number {
        return this.radio;
    }

    setRadio(value: number): ILocation {
        this.radio = value;
        return this;
    }

    getLatitude(): number {
        return this.latitude;
    }

    setLatitude(value: number): ILocation {
        this.latitude = value;
        return this;
    }

    public get Latitude(): number {
        return this.latitude;
    }

    public set Latitude(value: number) {
        this.latitude = value;
    }

    getLongitude(): number {
        return this.longitude;
    }

    setLongitude(value: number): ILocation {
        this.longitude = value;
        return this;
    }

    public get Longitude(): number {
        return this.longitude;
    }

    public set Longitude(value: number) {
        this.longitude = value;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class LocationJSON {
    static parseDataToReceive(data: any) {
        let location: Location = new Location();
        location.apiObject = data;
        location.Coordinates = data.coordinates.latitude + ',' + data.coordinates.longitude;
        location.longitude = data.coordinates.longitude;
        location.latitude = data.coordinates.latitude;
        location.Description = data.description;
        location.Key = data.id;
        location.Radio = data.radio;
        return location;
    }

    static parseDataToSend(location: ILocation, create: boolean) {
        let json = {
            latitude: location.Latitude,
            longitude: location.Longitude,
            radio: location.Radio,
            coordinates: location.Latitude + "," + location.Longitude,
            description: location.Description,
            direction: ""
        }
        if(!create)
            Object.defineProperty(json, "id", {
                value: location.getKey(),
                enumerable: true
            })
        return json;
    }
}
