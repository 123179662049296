import { IWorkplace } from "../aon";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class Workplace implements IWorkplace, IModel  {
    private key: string;
    private apiObject: any;
    private description: string;

    constructor(){
        this.key = KeyGenerator.generate(15);
        this.apiObject = {};
        this.description = '';
    }

    public get Description(): string {
        return this.description;
    }

    public set Description(value: string) {
        this.description = value;
    }
    
    public getDescription(): string {
        return this.description;
    }

    public setDescription(value: string): IWorkplace {
        this.description = value;
        return this;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    getKey(): string {
        return this.Key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
    
    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class WorkplaceJSON {
    static parseDataToReceive(element: any): IWorkplace {
        let workplace = new Workplace();
        workplace.ApiObject = element;
        workplace.Key = element.id ? element.id : '';
        workplace.Description = element.description ? element.description : '';
        return workplace;
    }
}