<app-spinner *ngIf="spinnerLoadFile"></app-spinner>
<ng2-pdfjs-viewer
  *ngIf   = "this.fileBytes && pdfSrc.FileType.split('/')[1] === 'pdf'"
  [zoom]  = "zoom"
  [locale]= "locale"
  [pdfSrc]= "fileBytes"
  [downloadFileName]= "fileName"
></ng2-pdfjs-viewer>
<div id="image-viewer" *ngIf="this.fileBytes && pdfSrc.FileType.split('/')[1] !== 'pdf'">
  <img [src]="safeImageUrl" alt="Image">
</div>
<div *ngIf="showErrorMessage && !fileBytes" class="padding">{{ "DOCUMENTATION.DOCUMENT_CANNOT_BE_VIEWED" | translate }}</div>
