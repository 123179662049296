import { IDayType } from "../interfaces/modelsInterfaces";
import { ICollection, IModel } from "../interfaces/utilitiesInterfaces";
import { Collection } from "../utils/Collection";
import { KeyGenerator } from "../utils/KeyGenerator";

export class DayType implements IDayType, IModel {
    private name: string;
    private key: string;
    private apiObject: any;

    constructor(){
        this.name = ''
        this.key = KeyGenerator.generate(15)
        this.apiObject = {}
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    public get Name(): string {
        return this.name;
    }

    public set Name(value: string) {
        this.name = value;
    }

    public get Key(): string {
        return this.key;
    }

    public get ApiObject() : any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string): DayType {
        this.name = value;
        return this;
    }
}

export let collectionDayType: ICollection<IDayType> = new Collection<DayType>();
let dayType: DayType = new DayType().setName('semanal');
dayType.Key = 'semanal';
let dayType2: DayType = new DayType().setName('diario');
dayType2.Key = 'diaria';
collectionDayType.add(dayType);
collectionDayType.add(dayType2);