import { ITax, IIRPF, IInvoiceCategory, IInvoiceSerie, IInvoiceTransactionType, IInvoiceActivity, IInvoice, IInvoiceConfiguration } from "../interfaces/modelsInterfaces";
import { IInvoiceSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IInvoiceSpecificMethods } from "../interfaces/serviceInterfaces";
import { IFilter, IResponse, ICollection } from "../interfaces/utilitiesInterfaces";
import { Invoice } from "../models/Invoice";
import { InvoiceConfiguration } from "../models/InvoiceConfiguration";
import { TaxType } from "../models/Tax";
import { InvoiceFilter } from "../utils/ModelsFilters";
import { ErrorResponse } from "../utils/Response";
import { Response } from "../utils/Response";
import { GenericService } from "./GenericCrudService";

export class InvoiceService extends GenericService<Invoice, InvoiceFilter> implements IInvoiceSpecificMethods {
    protected SpecificMethodsRepository: IInvoiceSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<Invoice> & ISingleObjectCrudRepository<Invoice>, type: { new (): Invoice }, SpecificMethodsRepository: IInvoiceSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    /**
     * Obtiene una lista de impuestos.
     *
     * @param {IFilter} filter - El filtro a aplicar a la lista de impuestos. Opcional.
     * @return {Promise<IResponse<ICollection<ITax>>>} Una promesa que se resuelve con la respuesta que contiene la lista de impuestos.
     */
    async getTaxList(type?: TaxType): Promise<IResponse<ICollection<ITax>>> {
        try {
            return new Response<ICollection<ITax>>(this.SpecificMethodsRepository.getTaxList(type));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1720');
        }
    }

    /**
     * Obtiene una lista de elementos de IRPF.
     *
     * @param {IFilter} filter - Filtro opcional para aplicar a la lista
     * @return {Promise<IResponse<ICollection<IIRPF>>>} Una promesa que se resuelve en la respuesta que contiene la lista de elementos de IRPF
     */
    async getIRPFList(filter?: IFilter | undefined): Promise<IResponse<ICollection<IIRPF>>> {
        try {
            return new Response<ICollection<IIRPF>>(this.SpecificMethodsRepository.getIRPFList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1721');
        }
    }

    /**
     * Obtiene una lista de elementos de IRPF para los productos.
     *
     * @param {IFilter} filter - Filtro opcional para aplicar a la lista
     * @return {Promise<IResponse<ICollection<IIRPF>>>} Una promesa que se resuelve en la respuesta que contiene la lista de elementos de IRPF
     */
    async getProductIRPFList(filter?: IFilter | undefined): Promise<IResponse<ICollection<IIRPF>>> {
        try {
            return new Response<ICollection<IIRPF>>(this.SpecificMethodsRepository.getProductIRPFList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1207');
        }
    }

    /**
     * Obtiene la lista de categorías de facturas de ventas.
     *
     * @param {IFilter} filter - Un filtro opcional para aplicar a la lista.
     * @returns {Promise<IResponse<ICollection<IInvoiceCategory>>>} Una promesa que se resuelve con la respuesta que contiene la lista de categorías de facturas.
     */
    async getInvoiceSalesCategoryList(filter?: IFilter | undefined): Promise<IResponse<ICollection<IInvoiceCategory>>> {
        try {
            return new Response<ICollection<IInvoiceCategory>>(this.SpecificMethodsRepository.getInvoiceSalesCategoryList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1723');
        }
    }

    /**
     * Obtiene la lista de categorías de facturas de gastos.
     *
     * @param {IFilter} filter - Un filtro opcional para aplicar a la lista.
     * @returns {Promise<IResponse<ICollection<IInvoiceCategory>>>} Una promesa que se resuelve con la respuesta que contiene la lista de categorías de facturas.
     */
    async getInvoicePurchaseCategoryList(filter?: IFilter | undefined): Promise<IResponse<ICollection<IInvoiceCategory>>> {
        try {
            return new Response<ICollection<IInvoiceCategory>>(this.SpecificMethodsRepository.getInvoicePurchaseCategoryList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1723');
        }
    }

    /**
     * Obtiene la lista de categorías de facturas de tickets.
     *
     * @param {IFilter} filter - Un filtro opcional para aplicar a la lista.
     * @returns {Promise<IResponse<ICollection<IInvoiceCategory>>>} Una promesa que se resuelve con la respuesta que contiene la lista de categorías de facturas.
     */
    async getInvoiceTicketCategoryList(filter?: IFilter | undefined): Promise<IResponse<ICollection<IInvoiceCategory>>> {
        try {
            return new Response<ICollection<IInvoiceCategory>>(this.SpecificMethodsRepository.getInvoiceTicketCategoryList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1723');
        }
    }

    /**
     * Recupera una lista de series de facturas.
     *
     * @param {IFilter} filter - Filtro opcional para aplicar a la lista
     * @returns {Promise<IResponse<ICollection<IInvoiceSerie>>>} Una promesa que se resuelve con una respuesta que contiene la lista de series de facturas
     */
    async getInvoiceSerieList(filter?: IFilter | undefined): Promise<IResponse<ICollection<IInvoiceSerie>>> {
        try {
            return new Response<ICollection<IInvoiceSerie>>(this.SpecificMethodsRepository.getInvoiceSerieList(filter));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1728');
        }
    }

    /**
     * Recupera la lista de tipos de transacción.
     *
     * @param {IFilter} [filter] - Filtro opcional para aplicar a los tipos de transacción.
     * @returns {Promise<IResponse<ICollection<IInvoiceTransactionType>>>} Una promesa que se resuelve en la respuesta que contiene la colección de tipos de transacción.
     */
    async getTransactionTypeList(filter?: IFilter | undefined): Promise<IResponse<ICollection<IInvoiceTransactionType>>> {
        try {
            return new Response<ICollection<IInvoiceTransactionType>>(this.SpecificMethodsRepository.getTransactionTypeList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1724');
        }
    }

    /**
     * Recupera una lista de métodos de pago.
     *
     * @param {IFilter} filter - (opcional) Un objeto que especifica los criterios de filtro.
     * @returns {Promise<IResponse<ICollection<ITax>>>} Una promesa que se resuelve con una respuesta que contiene una colección de objetos de impuestos.
     */
    async getPaymentMethodList(filter?: IFilter | undefined): Promise<IResponse<ICollection<ITax>>> {
        try {
            return new Response<ICollection<ITax>>(this.SpecificMethodsRepository.getPaymentMethodList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1725');
        }
    }

    /**
     * Obtiene la lista de actividades de facturación.
     *
     * @param {IFilter | undefined} filter - Un filtro opcional para aplicar a la consulta.
     * @return {Promise<IResponse<ICollection<IInvoiceActivity>>>} Una Promesa que se resuelve en la respuesta que contiene la colección de actividades de facturación.
     */
    async getInvoiceActivityList(filter?: IFilter | undefined): Promise<IResponse<ICollection<IInvoiceActivity>>> {
        try {
            return new Response<ICollection<IInvoiceActivity>>(await this.SpecificMethodsRepository.getInvoiceActivityList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1726');
        }
    }

    /**
     * Convierte una proforma en factura
     * @param key clave primaria del objecto invoice
     * @returns true si se ha convertido false en caso de haber algun fallo
     */
    async acceptInvoice(key: string): Promise<IResponse<IInvoice>> {
        try {
            return new Response<IInvoice>(await this.SpecificMethodsRepository.acceptInvoice(key));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1711');
        }
    }

    /**
     * Descarga una factura
     * @param key clave primaria de la factura
     */
    async downloadInvoice(key: string): Promise<IResponse<Blob>> {
        try {
            return new Response<Blob>(await this.SpecificMethodsRepository.downloadInvoice(key));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1707');
        }
    }

    /**
     * Obtiene la nota de una factura
     * @param key clave primaria de la factura
     */
    async getInvoiceNote(key: string): Promise<IResponse<string>> {
        try {
            return new Response<string>(await this.SpecificMethodsRepository.getInvoiceNote(key));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1729');
        }
    }

    /**
     * Cambia la nota de una factura
     * @param key clave primaria de la factura
     * @param note nueva nota
     */
    async createInvoiceNote(key:string, note: string): Promise<IResponse<boolean>> {
        try {
            return new Response<boolean>(await this.SpecificMethodsRepository.createInvoiceNote(key, note));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1712');
        }
    }

    /**
     * Elimina la nota de una factura
     * @param key clave primaria de la factura
     * @param note nueva nota
     */
    async deleteInvoiceNote(key:string): Promise<IResponse<boolean>> {
        try {
            return new Response<boolean>(await this.SpecificMethodsRepository.deleteInvoiceNote(key));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1714');
        }
    }

    /**
     * Edita la nota de una factura
     * @param key clave primaria de la factura
     * @param note nueva nota
     */
    async updateInvoiceNote(key:string, note: string): Promise<IResponse<boolean>> {
        try {
            return new Response<boolean>(await this.SpecificMethodsRepository.updateInvoiceNote(key, note));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1713');
        }
    }

    /**
     * Duplica una factura
     * @param key clave primaria de la factura
     */
    async duplicateInvoice(key: string): Promise<IResponse<IInvoice>> {
        try {
            return new Response<IInvoice>(await this.SpecificMethodsRepository.duplicateInvoice(key));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0201', 'Invoice');
        }
    }

    /**
     * Crea una factura rectificativa
     * @param key clave primaria de la factura
     */
    async createCorrectiveInvoice(key: string): Promise<IResponse<IInvoice>> {
        try {
            return new Response<IInvoice>(this.SpecificMethodsRepository.createCorrectiveInvoice(key));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1716');
        }
    }

    /**
     * Obtiene la configuracion de factura existente
     */
    async getInvoicePrintConfiguration(): Promise<IResponse<IInvoiceConfiguration>> {
        try {
            return new Response<IInvoiceConfiguration>(await this.SpecificMethodsRepository.getInvoicePrintConfiguration());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1705');
        }
    }

    /*
    * Actualiza la configuracion de la factura
    * @param configuration
    */
    async updateInvoiceConfiguration(configuration: InvoiceConfiguration): Promise<IResponse<IInvoiceConfiguration>> {
        try {
            return new Response<IInvoiceConfiguration>(this.SpecificMethodsRepository.updateInvoiceConfiguration(configuration));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1706');
        }

}
    /*
     * Crea una factura rectificativa
     * @param key clave primaria de la factura
     */
    async sendInvoiceEmail(key: string, email: string): Promise<IResponse<void>> {
        try {
            return new Response<void>(this.SpecificMethodsRepository.sendInvoiceEmail(key, email));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1710');
        }
    }

    /**
     * Obtiene la previsualizacion del PDF en Base64 basada en la configuracion actual de la factura
     * @returns true
     */
    async visualizeInvoicePrintPdf(): Promise <any>{
        try {
            return new Response<any>(await this.SpecificMethodsRepository.visualizeInvoicePrintPdf());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1707');
        }
    }

    /*
     * Returns the invoice sales statistics
     */
    async getInvoiceStatSales(from: Date): Promise<IResponse<any>>{
        try {
            return new Response<void>(this.SpecificMethodsRepository.getInvoiceStatSales(from));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1717');
        }
    }
    /**
     * Returns the invoice expenses statistics
     */
    async getInvoiceStatExpenses(from: Date): Promise<IResponse<any>>{
        try {
            return new Response<void>(this.SpecificMethodsRepository.getInvoiceStatExpenses(from));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1718');
        }
    }
    /**
     * Returns the invoice report statistics
     */
    async getInvoiceStatReport(from: Date): Promise<IResponse<any>>{
        try {
            return new Response<void>(this.SpecificMethodsRepository.getInvoiceStatReport(from));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1719');
        }
    }

     async multipleDownloadInvoicePDF(keys: string[]): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.multipleDownloadInvoicePDF(keys));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1709');
        }
    }

     async multipleDownloadInvoiceExcel(keys: string[]): Promise<IResponse<any>> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.multipleDownloadInvoiceExcel(keys));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1709');
        }
    }

    async getInvoiceCount(filter : IFilter): Promise<any> {
        try {
            return new Response<any>(this.SpecificMethodsRepository.getInvoiceCount(filter));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1729');
        }
    }

}
