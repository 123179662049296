import { IDomainSpecificMethodsRepository } from "../interfaces/repositoryInterfaces";
import { DomainObject, DOMAINS } from "../utils/Environment";

export class ApiDomainSpecificMethodsRepository implements IDomainSpecificMethodsRepository {
    domain: DomainObject | undefined = this.getDomainObject();    

    getDomainObject(): DomainObject | undefined {
        return DOMAINS[window.location.host.split('.')[0] as keyof typeof DOMAINS];
    }

    getLogo(): string {
        return this.domain == undefined 
            ? './assets/images/logo/logo-aon.png'
            :  this.domain.logo;
    }

    getFavicon(): string {
        return this.domain == undefined 
            ? 'favicon.ico'
            :  this.domain.favicon;
    }

    getServiceDesk(): string {
        return this.domain == undefined 
            ? ''
            :  this.domain.servicedesk;
    }

    getTitle(): string {
        return this.domain == undefined 
            ? 'Aon Solutions'
            :  this.domain.title;
    }

    getLoginMessage(): string {
        return this.domain == undefined 
            ? 'LOGIN.ACCESS_YOUR_AON_ACCOUNT'
            :  this.domain.loginMessage;
    }
}