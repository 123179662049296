<div class="canvas-chart">
  <canvas
    baseChart
    [datasets]="chartData"
    [labels]="chartLabels"
    [legend]="legend"
    [type]="chartType"
    [options]="chartOptions"
  >
  </canvas>
</div>
