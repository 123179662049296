<button
  [type]="type"
  class="appButton"
  appGlobalHover
  [class]="class"
  [backgroundColor]="hoverBackgroundColor"
  [borderColor]="hoverBorderColor"
  [oldBackgroundColor]="backgroundColor"
  [oldBorderColor]="(border.split(' ')[2] ? border.split(' ')[2] : '')"
  [color]="hoverColor"
  [oldColor]="color"
  matTooltip= "{{ toolTip }}"
  [disabled]="disabled"
  [ngStyle]="{
    'width': width,
    'height': height,
    'backgroundColor': backgroundColor,
    'border': border,
    'color': color,
    'fontSize': fontSize,
    'padding': padding,
    'borderRadius': borderRadius,
    'justifyContent': align
  }"
>
  <ng-content></ng-content>
  <div *ngIf="checkData" class="check-data">
    <div class="check-data-circle">
    </div>
  </div>
</button>
