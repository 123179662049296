import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomainService } from 'src/app/core/services/domain.service';
import { UserService } from 'src/app/core/services/user.service';

interface IUser {
  Name: string;
  Email: string;
  Lastname: string;
}

@Component({
  selector: 'app-redmine',
  templateUrl: './redmine.component.html',
  styleUrls: ['./redmine.component.scss'],
})
export class RedmineComponent implements OnInit {
  user: IUser | null = null;
  htmlScript: boolean = false;

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private renderer: Renderer2,
    private el: ElementRef,
    public domainService: DomainService
  ) {
    if(this.domainService.getServiceDesk() != ''){
      this.userService.getCurrentUserData().then((data) => {
        this.user = data;
        this.translateService
        .get([
          'PROFILE.NAME',
          'COMMON.REDMINE.HELP_YOU',
          'INBOX.SUBJECT',
          'COMMON.REDMINE.DETAILED_DESCRIPTION',
          'COMMON.REDMINE.TICKET_CREATED',
          'COMMON.REDMINE.TICKET_CREATED_ERROR',
          'COMMON.REDMINE.TICKET_CREATE',
          'COMMON.REDMINE.OBLIGATORY_EMAIL',
          'COMMON.REDMINE.TICKET_REGISTERED',
        ])
        .subscribe((translations) => {
          this.scriptOptions(translations);
          this.htmlScript = true;
        });
      });
    }
  }

  ngOnInit(): void {
    if(this.domainService.getServiceDesk() != ''){
      this.loadScript();
    }
  }

  // Función para configurar el widget de redmine
  scriptOptions(translations: any) {
    let css = `
      #widget_form {
        .btn{
          color: #292a31;
          border: solid 0.05rem #d2d2d6;
          border-radius: 0.35rem;
        }
      }
   `;
    css = css.replace(/\s+/g, "").trim();
   
    const scriptOptions = this.renderer.createElement('script');
    scriptOptions.type = 'text/javascript';
    scriptOptions.text = `
      RedmineHelpdeskWidget.config({
        color: '#f0f0f1',
        styles: '${css}',
        translation: {
          nameLabel: '${translations['PROFILE.NAME']}',
          emailLabel: '${translations['COMMON.REDMINE.OBLIGATORY_EMAIL']}',
          descriptionLabel: '${translations['COMMON.REDMINE.DETAILED_DESCRIPTION']}',
          createButtonLabel: '${translations['COMMON.REDMINE.TICKET_CREATE']}',
          createSuccessDescription: '${translations['COMMON.REDMINE.TICKET_REGISTERED']}',
          createErrorLabel: '${translations['COMMON.REDMINE.TICKET_CREATED_ERROR']}',
          subjectLabel: '${translations['INBOX.SUBJECT']}',
          createSuccessLabel: '${translations['COMMON.REDMINE.TICKET_CREATED']}'
        },
        identify: {
          trackerValue: 'Support',
          projectValue: '${this.domainService.getServiceDesk()}',
          nameValue: '${this.user?.Name} ${this.user?.Lastname}',
          emailValue: '${this.user?.Email}',
          customFieldValues: {}
        },
        attachment: true,
        title: '${translations['COMMON.REDMINE.HELP_YOU']}'
      });
    `;
    this.renderer.appendChild(document.body, scriptOptions);
  }

  callScriptOptions() {
    if (!this.htmlScript && this.user !== null) {
      this.scriptOptions({});
    }
  }

  async loadScript() {
    const script  = this.renderer.createElement('script');
    script.type   = 'text/javascript';
    script.src    = 'https://redmine.ayudat.es/helpdesk_widget/widget.js';

    this.renderer.appendChild(document.body, script);
  }
}
