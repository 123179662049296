import { IInvoiceActivity } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class InvoiceActivity implements IInvoiceActivity, IModel {
    key: string;
    apiObject: any;
    value: string;
    description: string;
    principal: boolean;

    constructor(value?: string, description?: string){
        this.key = KeyGenerator.generate(15);
        this.apiObject = {};
        this.value = '';
        this.description = '';
        this.principal = false;
    }

    public getValue(): string {
        return this.Value;
    }

    public setValue(value: string): InvoiceActivity {
        this.Value = value;
        return this;
    }

    public getDescription(): string {
        return this.Description;
    }

    public setDescription(value: string): InvoiceActivity {
        this.Description = value;
        return this;
    }

    public getPrincipal(): boolean {
        return this.principal;
    }

    public setPrincipal(value: boolean): InvoiceActivity {
        this.principal = value;
        return this;
    }

    public get Principal(): boolean {
        return this.principal;
    }

    public set Principal(value: boolean) {
        this.principal = value;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public get Value(): string {
        return this.value;
    }

    public set Value(value: string) {
        this.value = value;
    }

    public get Description(): string {
        return this.description;
    }

    public set Description(value: string) {
        this.description = value;
    }

    public getKey(): string {
        return this.Key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class InvoiceActivityJSON {
    static parseDataToReceive(data: any): InvoiceActivity {
        if(data.id){
            let activity = new InvoiceActivity();
            activity.ApiObject = data;
            activity.Key = data.id;
            activity.Description = data.cnaeDescription ? data.cnaeDescription : '';
            activity.Value = data.vatRegime ? data.vatRegime : '';
            activity.Principal = data.principal ? data.principal : false;
            return activity;
        }else{
            return new InvoiceActivity();
        }
    }
}