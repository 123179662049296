import { BorderType, BorderTypeToJson, IInvoiceConfiguration, LanguageList, LanguageToJson, ThemeList, ThemeToJson} from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class InvoiceConfiguration implements IInvoiceConfiguration , IModel {
    private active : boolean;
    private logo : boolean;
    private enterpriseData: boolean;
    private registryData: boolean;
    private contactData: boolean;
    private details: boolean;
    private border: BorderTypeToJson;
    private color: string;
    private legalWarning: string;
    private backGround: boolean;
    private language: LanguageToJson; 
    private adjust : boolean;
    private header : number;
    private footer : number;
    private backgroundImage: File = new File([],'');
    private theme: ThemeToJson;
    private key: string;
    private apiObject: any;

    constructor(active?: boolean, logo?: boolean, enterpriseData?: boolean, registryData?: boolean, contactData?: boolean, details?: boolean, border?: BorderTypeToJson, color?: string, legalWarning?: string , key?: string, backGround?: boolean, language?: LanguageToJson, adjust?: boolean, header?: number, footer?: number , backgroundImage= File, theme?: ThemeToJson) {
        this.active = active || true;
        this.logo = logo || false;
        this.enterpriseData = enterpriseData || false;
        this.registryData = registryData || false;
        this.contactData = contactData || false;
        this.details = details || false;
        this.border = border || BorderTypeToJson["Sin borde"];
        this.color = color || '';
        this.legalWarning = legalWarning || '';
        this.language = language || LanguageToJson["es"];
        this.key = KeyGenerator.generate(15);
        this.backGround = backGround || false;
        this.adjust = adjust || false;
        this.header = header || 0;
        this.footer = footer || 0;
        this.backgroundImage = new backgroundImage([],'');
        this.theme = theme || ThemeToJson["BLACK_AND_WHITE"];
        this.key = key || '';
        
    }

    public getLogo() : boolean{
        return this.logo;
    }

    public getEnterpriseData(): boolean{
        return this.enterpriseData
    }

    public getRegistryData(): boolean{
        return this.registryData
    }

    public getContactData(): boolean{
        return this.contactData
    }

    public getDetails(): boolean{
        return this.details
    }

    public getBorder(): BorderTypeToJson{
        return this.border
    }

    public getColor(): string{
        return this.color
    }

    public getLegalWarning(): string{
        return this.legalWarning
    }

    public getBackGround(): boolean {
        return this.backGround
    }

    public getLanguage(): LanguageToJson {
        return this.language    
    }

    public getAdjust(): boolean {
        return this.adjust
    }

    public getHeader(): number {
        return this.header
    }

    public getFooter(): number {
        return this.footer
    }

    public getBackgroundImage(): File {
        return this.backgroundImage
    }

    public getTheme(): ThemeToJson {
        return this.theme
    }

     public getActive(): boolean {
        return this.active   
    }

    public get Logo(): boolean{
        return this.logo
    }

    public get EnterpriseData(): boolean{
        return this.enterpriseData
    }

    public get RegistryData(): boolean{
        return this.registryData
    }

    public get ContactData(): boolean{
        return this.contactData
    }

    public get Details(): boolean{
        return this.details
    }

    public get Border(): BorderTypeToJson{
        return this.border
    }

    public get Color(): string{
        return this.color
    }

    public get LegalWarning(): string{
        return this.legalWarning
    }

    public get Key(): string {
        return this.key
    }

    public get ApiObject(): any {
        return this.apiObject
    }

    public get BackGround(): boolean {
        return this.backGround
    }

    public get Language(): LanguageToJson {
        return this.language
    }

    public get Adjust(): boolean {
        return this.adjust
    }

    public get Header(): number {
        return this.header
    }

    public get Footer(): number {
        return this.footer
    }

    public get BackgroundImage(): File {
        return this.backgroundImage
    }

    public get Theme(): ThemeToJson {
        return this.theme
    }

    public get Active(): boolean {
        return this.active
    }

    public set Logo(value: boolean){
        this.logo = value
    }

    public set EnterpriseData(value:boolean) {
        this.enterpriseData = value
    }

    public set RegistryData(value:boolean) {
        this.registryData = value
    }

    public set ContactData(value:boolean) {
        this.contactData = value
    }

    public set Details(value:boolean) {
        this.details = value
    }

    public set Border(value:BorderTypeToJson) {
        this.border = value
    }

    public set Color(value:string) {
        this.color = value
    }

    public set LegalWarning(value:string) {
        this.legalWarning = value
    }

    public set Key(value:string)  {
        this.key = value
    }

    public set ApiObject(value:any)  {
        this.apiObject = value
    }

    public set BackGround(value:boolean) {
        this.backGround = value
    }

    public set Language(value:LanguageToJson) {
        this.language = value
    }

    public set Adjust(value:boolean) {
        this.adjust = value
    }

    public set Header(value: number) {
        this.header = value;
    }

    public set Footer(value: number) {
        this.footer = value;
    }

    public set BackgroundImage(value: File) {
        this.backgroundImage = value;
    }

    public set Theme(value: ThemeToJson) {
        this.theme = value;
    }

    public set Active(value: boolean) {
        this.active = value
    }


    public setLogo(value:boolean): any {
        this.logo = value;
    }

    public setEnterpriseData(value:boolean): any {
        this.enterpriseData = value;
    }

    public setRegistryData(value:boolean): any {
        this.registryData = value;
    }

    public setContactData(value:boolean): any {
        this.contactData = value;
    }

    public setDetails(value:boolean): any {
        this.details = value;
    }

    public setBorder(value:BorderTypeToJson): any {
        this.border = value;
    }

    public setColor(value:string) : any{
        this.color = value;
    }

    public setLegalWarning(value:string) : any{
        this.legalWarning = value;
    }

    public setBackGround(value: boolean): any {
        this.backGround = value;
    }

    public setLanguage(value: LanguageToJson): any {
        this.language = value;
    }

    public setAdjust(value: boolean): any {
        this.adjust = value;
    }

    public setHeader(value: number): any {
        this.header = value;
    }

    public setFooter(value: number): any {
        this.footer = value;
    }
    
    public setBackgroundImage(value: File): any {
        this.backgroundImage = value;
    }

    public setTheme(value: ThemeToJson): any {
        this.theme = value;
    }

    public setActive(value: boolean): IInvoiceConfiguration {
        this.active = value
        return this
    }

    getKey(): string {
        return this.Key;
    }

    setKey(value: string): IInvoiceConfiguration {
        this.Key = value
        return this
    }

    getFilterableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('color', this.Color);
        return map;
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class InvoiceConfigurationJSON {
    static parseDataToReceive(data: any){
        let configuration = new InvoiceConfiguration();
        const borderKey = data.border as keyof typeof BorderTypeToJson;
        const languageKey = data.language as keyof typeof LanguageToJson;
        const themeKey = data.theme.theme as keyof typeof ThemeToJson;
        configuration.ApiObject = data.apiObject || {};
        configuration.Key = data.key || '';
        configuration.Logo = data.logo || false;
        configuration.EnterpriseData = data.enterpriseData  || false;
        configuration.RegistryData = data.registryData || false;
        configuration.ContactData = data.contactData || false;
        configuration.Details = data.details || false;
        const borderValue = BorderTypeToJson[borderKey];
        if (borderValue !== undefined) {
            configuration.Border = borderValue;
        } else {
            throw new Error('El valor de borde recibido no es válido');
        }
        const languageValue = LanguageToJson[languageKey];
        if (languageValue !== undefined) {
            configuration.Language = languageValue;
        } else {
            throw new Error('El valor de idioma recibido no es válido');
        }
        const themeValue = ThemeToJson[themeKey];
        if (themeValue !== undefined) {
            configuration.Theme = themeValue;
        } else {
            throw new Error('El valor de tema recibido no es válido');
        }
        configuration.Color = data.color || '';
        configuration.LegalWarning = data.legalWarning || '';
        configuration.BackGround = data.backGround || false;
        configuration.Adjust = data.adjust || false;
        configuration.Header = data.header || 0;
        configuration.Footer = data.footer || 0;
        configuration.BackgroundImage = data.backgroundImage || '';
        return configuration;
    }

    static getBorderValue(borderType: BorderType): number {
        return BorderTypeToJson[borderType];
    }

    static getLanguageValue(language: LanguageList): any {
        return LanguageToJson[language];
    }

    static getThemeValue(theme: ThemeList) : any {
        return ThemeToJson[theme];
    }

    static invoiceConfigurationToJson(element: IInvoiceConfiguration){
        let json ={
            "active" : element.Active,
            "adjust": element.Adjust,
            "backGround": element.BackGround,
            "backgroundImage": element.BackgroundImage,
            "border": element.Border,
            "color": element.Color,
            "contactData": element.ContactData,
            "details": element.Details,
            "enterpriseData": element.EnterpriseData,
            "footer": element.Footer,
            "header": element.Header,
            "key": element.Key,
            "language": element.Language,
            "legalWarning": element.LegalWarning,
            "logo": element.Logo,
            "registryData": element.RegistryData,
            "theme": element.Theme,
        }
        return json;
    }
}

export class PersonalizedTheme {

    private boxBodyBackGroundColor: string;
    private boxBorderColor: string;
    private boxTitleBackGround: string;
    private boxTitleTextColor: string;
    private customerBackGroundColor: string;
    private textColor: string;
    private theme: string;
    private titleTextColor: string;


    constructor(boxBodyBackGroundColor?: string , boxBorderColor?: string, boxTitleBackGround?: string, boxTitleTextColor?: string, customerBackGroundColor?: string, textColor?: string, theme?: string, titleTextColor?: string){ {
        this.boxBodyBackGroundColor = boxBodyBackGroundColor || '';
        this.boxBorderColor = boxBorderColor || '';
        this.boxTitleBackGround = boxTitleBackGround || '';
        this.boxTitleTextColor = boxTitleTextColor || '';
        this.customerBackGroundColor = customerBackGroundColor || '';
        this.textColor = textColor || '';
        this.theme = theme || '';
        this.titleTextColor = titleTextColor || '';
        }
    }

    getBoxBodyBackGroundColor(): string {
        return this.boxBodyBackGroundColor;
    }
    setBoxBodyBackGroundColor(value: string): PersonalizedTheme {
        this.boxBodyBackGroundColor = value;
        return this;
    }

    getBoxBorderColor(): string {
        return this.boxBorderColor;
    }
    setBoxBorderColor(value: string): PersonalizedTheme {
        this.boxBorderColor = value;
        return this;
    }

    getBoxTitleBackGround(): string {
        return this.boxTitleBackGround;
    }
    setBoxTitleBackGround(value: string): PersonalizedTheme {
        this.boxTitleBackGround = value;
        return this;
    }

    getBoxTitleTextColor(): string {
        return this.boxTitleTextColor;
    }
    setBoxTitleTextColor(value: string): PersonalizedTheme {
        this.boxTitleTextColor = value;
        return this;
    }

    getCustomerBackGroundColor(): string {
        return this.customerBackGroundColor;
    }
    setCustomerBackGroundColor(value: string): PersonalizedTheme {
        this.customerBackGroundColor = value;
        return this;
    }

    getTextColor(): string {
        return this.textColor;
    }
    setTextColor(value: string): PersonalizedTheme {
        this.textColor = value;
        return this;
    }

    getTheme(): string {
        return this.theme;
    }
    setTheme(value: string): PersonalizedTheme {
        this.theme = value;
        return this;
    }

    getTitleTextColor(): string {
        return this.titleTextColor;
    }
    setTitleTextColor(value: string): PersonalizedTheme {
        this.titleTextColor = value;
        return this;
    }

    
    setPersonalizedTheme(jsonTheme?: any){
        //TODO ver como se van a traer el color
    //     jsonTheme.tittleTextColor = document.getElementById('tittleTextColorPicker') || '';
    //     jsonTheme.boxBorderColor = document.getElementById('boxBorderColorPicker') || '';
    //     jsonTheme.boxTitleBackgroundColor = document.getElementById('boxTitleBackgroundColorPicker') || '';
    //     jsonTheme.boxTitleTextColor = document.getElementById('boxTitleTextColorPicker') || '';
    //     jsonTheme.boxBodyBackgroundColor = document.getElementById('boxBodyBackgroundColorPicker') || '';
    //     jsonTheme.textColor = document.getElementById('textColorPicker') || '';
    //     jsonTheme.customerBackgroundColor = document.getElementById('customerBackgroundColorPicker') || '';
        jsonTheme.theme = 'PERSONALIZED'
        jsonTheme.tittleTextColor = '#cfc097'
        jsonTheme.boxBorderColor = '#69c9c8'
        jsonTheme.boxTitleBackgroundColor = '#a06dbd'
        jsonTheme.boxTitleTextColor =  '#d94180'
        jsonTheme.boxBodyBackgroundColor = '#7955d4'
        jsonTheme.textColor = '#a80f0f'
        jsonTheme.customerBackgroundColor = '#d18889'
    return jsonTheme;

    }
}