import { ApiHttpRequest } from "../utils/Http";
import { ALLOWED_ROLES, BASE_URL, GET_METHOD } from "../utils/Environment";
import { COMPANY , LOGIN_URL, MAGIC_LINK_URL, USER_URL} from "../utils/ApiUrls";
import { ErrorResponse } from "../utils/Response";
import { IAuthenticationRepository } from "../interfaces/repositoryInterfaces";
import { Permission, PermissionJSON } from "../models/Permission";
import { AonPermission } from "../aon";

export class APIAuthenticationRepository implements IAuthenticationRepository {
  constructor() {
  }

  async login(email: string, password: string): Promise<void> {
    let data = {
      username: email,
      password: password,
      isTokenExpirable: true
    }
  
    let url = BASE_URL + LOGIN_URL.LOGIN;
    let customHeaders = {};
    let result = await ApiHttpRequest.post(url,customHeaders,data);

    if(result.type == "error")
      throw new ErrorResponse('0101');
    else {
      localStorage.setItem('token', result.session_id);
    }
  }

  async logout(): Promise<void> {
    if(localStorage.getItem('token')) {
      localStorage.removeItem('token');
      localStorage.removeItem('enterprise');
      localStorage.removeItem('domainId');
      localStorage.removeItem('domainName');
      localStorage.removeItem('idEnterprise');
      localStorage.removeItem('login');
      localStorage.removeItem('registry');
    } else 
      throw new ErrorResponse('0111');
  }

  async tokenLogin(token: string): Promise<void> {
      localStorage.setItem('token', token);
  }

  async magicLink(email: string): Promise<void>{
    let json = {
      email: email,
      url  : window.location.origin
    }
    await ApiHttpRequest.post(BASE_URL + MAGIC_LINK_URL.GET_MAGIC_LINK , {}, json);
  }

  // async test(){
  //   this.magicLink('tocir30584@laymro.com');
  // }

  /**
   * Verifica el permiso para el usuario en el dominio.
   *
   * @param {string} domainName - El nombre del dominio para verificar el permiso.
   * @return {Promise<void>} - Una promesa que se resuelve si se permite el permiso, o se rechaza con un código de error.
   */
  async checkPermission(domainName: string): Promise<boolean> {
    let customHeaders = {
      session_id  : localStorage.getItem('token'),
      domain_name : domainName,
      domain_login: ''
    };

    try {
      const response = await ApiHttpRequest.post(BASE_URL + COMPANY.APP_ROLES, customHeaders, {});

      // Verificación correcta. Si uno de los roles es permitido, devuelve true.
      const isRoleAllowed = response.domainUserRoles.some((role: string) => ALLOWED_ROLES.includes(role));

      // if (!domainName && !isRoleAllowed) {
      //   await this.logout();  // Se debe cerrar sesión antes de tirar error
      //   throw new ErrorResponse('0102');
      // }
      return isRoleAllowed;
    } catch (error) {
      throw error instanceof ErrorResponse ? error : new ErrorResponse('0102');
    }
  }

  /**
   * Devuelve los roles del usuario
   *
   */
  async permissionsRolUser(): Promise<string []> {
    try {
      let id = localStorage.getItem('user')
      const result = await ApiHttpRequest.get(BASE_URL + COMPANY.ROLES+ id , {}, {});
      return result;
    } catch (error) {
      throw error instanceof ErrorResponse ? error : new ErrorResponse('0102');
    }
  }

  async userInfo(): Promise<void> {
      let userInfo = await ApiHttpRequest.httpRequest(BASE_URL + LOGIN_URL.USER_INFO, GET_METHOD, {}, {})
      if(userInfo.type == "error") throw new ErrorResponse('0101');
      localStorage.setItem('login', userInfo.login);
      localStorage.setItem('user', userInfo.id);
  }

  async setRegistry(): Promise<void> {
      let user = await ApiHttpRequest.httpRequest(BASE_URL + LOGIN_URL.TASKHOLDER_USER, GET_METHOD, {}, {})
      if(user.length > 0)
        localStorage.setItem('registry', user[0].id);
  }
  
  async getAllPermissions(): Promise<Permission> {
    let id = localStorage.getItem('user')
    const result = await ApiHttpRequest.get(BASE_URL + COMPANY.ROLES+ id , {}, {});
    return PermissionJSON.parseDataToReceive(result);
  }

  async checkOnePermission(permission: AonPermission): Promise<boolean> {
    return (await ApiHttpRequest.post(BASE_URL + USER_URL.CHECK_PERMISSION, {}, {"roles": permission})).result;
  }

  async getUserPermissionsAndRoles(userId: string): Promise<Permission> {
    const result = await ApiHttpRequest.get(BASE_URL + COMPANY.ROLES + userId , {}, {});
    return PermissionJSON.parseDataToReceive(result);
  }

}