import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { AonPermission, ErrorResponse } from 'libraries/AonSDK/src/aon';
import { SpinnerService } from '../services/spinner.service';

@Injectable({
  providedIn: 'root'
})

export class PermissionGuard  {

  constructor(
    private authService   : AuthService,
    private router        : Router,
    private spinnerService: SpinnerService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // Muestra el spinner mientras se procesa la autorización
    this.spinnerService.show();
    const requiredPermissions = route.data.requiredPermissions as [AonPermission];
    // No hacer una llamada por cada permiso
    try {
      let hasPermission = await this.authService.checkMultiplePermissions(requiredPermissions);
      // Oculta el spinner
      this.spinnerService.hide();

      if(!hasPermission){
        this.router.navigate(['']).then(() => {
          throw new ErrorResponse('0102');
        });
      }

      return hasPermission;
    } catch (error) {
      // Oculta el spinner
      this.spinnerService.hide();
      // si da algun tipo de fallo
      this.authService.logout();
      this.router.navigate(['']);
      throw error instanceof ErrorResponse ?  error : new ErrorResponse('0111');
    }
  }

}
