interface ErrorObject {
  description: string;
  result: string;
}
/**
 *  Configuration parameters
 */
// true para activar que los datos lleguen desde la api, false para usar datos ficticion locales
export let APIEnvironment  = true;
// true activa unos tests simples para ver que los métodos funcionan correctamente, false para desactivarlos
export let test: boolean   = false;
// Idioma de carga para SDK
export let LANGUAGE: string = getBrowserLanguage();
export const selectedLanguage: string = 'selectedLanguage';

/**
 *
 * CONSTS
 *
 */
  // URL for test environment
  export let BASE_URL = APIEnvironment ? 'https://aonsolutions.org' : 'http://localhost:8080';
  declare var require: any;
  if(APIEnvironment){
    try {
      BASE_URL = require(`../../../../src/environments/environment`).environment.urlApiAon;
    } catch (error) {
//      console.error(error);
    }
  }

export const GET_SINGLE = 'singleObjectGet'
export const CREATE_SINGLE = 'singleObjectCreate';
export const UPDATE_SINGLE = 'singleObjectUpdate';
export const DELETE_SINGLE = 'singleObjectDelete';
export const GET_MULTIPLE = 'multipleObjectGet';
export const CREATE_MULTIPLE = 'multipleObjectCreate';
export const UPDATE_MULTIPLE = 'multipleObjectUpdate';
export const DELETE_MULTIPLE = 'multipleObjectDelete';

export const GET_METHOD = 'GET';
export const POST_METHOD = 'POST';
export const PUT_METHOD = 'PUT';
export const DELETE_METHOD = 'DELETE';

// Roles permitidos
// ADMIN y ENTERPRISE se trataran igual, como si se fuera empresa
export const ALLOWED_ROLES = ['ADMIN', 'ENTERPRISE', 'EMPLOYEE'];

/**
 *
 * ERROR DATA
 *
 */
export const ERRORS: { [key: string]: ErrorObject } = {
  /* 00XX */
  '0000': {description:'OK_OPERATION_SUCCEED', result:''},
  /* 01XX */
  '0101': {description:'ERROR_LOGIN_INVALID_CREDENTIALS', result:'ERRORS.SESSION.INVALID_CREDENTIALS'},
  '0102': {description:'ERROR_LOGIN_INVALID_CREDENTIALS', result:'ERRORS.SESSION.NO_PERMISSIONS'},
  '0111': {description:'SESSION_EXPIRED', result:'ERRORS.SESSION.NO_EXISTS'},
  '0112': {description:'ERROR_SESSION', result:'ERRORS.SESSION.NO_ENTERPRISE'},
  '0113': {description:'ERROR_SESSION', result:'ERRORS.SESSION.SELECT_ENTERPRISE'},
  '0114': {description:'ERROR_SESSION', result:'ERRORS.SESSION.SET_TOKEN'},
  '0123': {description:'ERROR_MODEL', result:'ERRORS.MODEL.ACCESS'},
  '0124': {description:'ERROR_DENIED', result:'ERRORS.PERMISSION.DENIED'},
  '0125': {description:'ERROR_SESSION_EXPIRED', result:'ERRORS.SESSION.EXPIRED'},
  '0199': {description:'ERROR_NOT_IMPLEMENTED', result:'ERRORS.NOT_IMPLEMENTED.FRIEND'},
  /* 02XX */
  '0201': {description:'ERROR_MODEL_CREATE', result:'ERRORS.MODEL.CREATE'},
  '0202': {description:'ERROR_MODEL_UPDATE', result:'ERRORS.MODEL.EDIT'},
  '0203': {description:'ERROR_MODEL_DELETE', result:'ERRORS.MODEL.DELETE'},
  '0204': {description:'ERROR_MODEL_FILTER', result:'ERRORS.MODEL.FILTER'},
  '0205': {description:'ERROR_MODEL_ELEMENT', result:'ERRORS.MODEL.EMPTY'},
  '0206': {description:'ERROR_MODEL_ELEMENT', result:'ERRORS.MODEL.GET_ELEMENT'},
  /* 03XX  DOCUMENT ERRORS */
  '0301': {description:'ERROR_DOCUMENT_UPLOAD', result:'ERRORS.DOCUMENT.UPLOAD'},
  '0302': {description:'ERROR_DOCUMENT_UPLOAD', result:'ERRORS.DOCUMENT.ROUTE_NAME'},
  '0303': {description:'ERROR_DOCUMENT_UPLOAD', result:'ERRORS.DOCUMENT.EXISTS'},
  '0304': {description:'ERROR_DOCUMENT_RAW', result:'ERRORS.DOCUMENT.GET_RAW'},
  '0305': {description:'ERROR_DOCUMENT_URL', result:'ERRORS.DOCUMENT.URL'},
  /* 04XX  MESSAGE ERRORS */
  '0401': {description:'ERROR_MESSAGE_ARCHIVE', result:'ERRORS.MESSAGE.ARCHIVE'},
  '0402': {description:'ERROR_MESSAGE_REOPEN', result:'ERRORS.MESSAGE.REOPEN'},
  '0403': {description:'ERROR_MESSAGE_COUNT', result:'ERRORS.MESSAGE.COUNT'},
  '0404': {description:'ERROR_MESSAGE_READ', result:'ERRORS.MESSAGE.SET_READ'},
  /* 05XX  REPORT ERRORS */
  '0501': {description:'ERROR_REPORT_PAYMENT', result:'ERRORS.INVOICE.PAYMENT.GET'},
  '0502': {description:'ERROR_REPORT_SALES', result:'ERRORS.INVOICE.SALES.GET'},
  /* 06XX  TAX ERRORS */
  '0601': {description:'ERROR_TAX_MODEL_PAYMENT', result:'ERRORS.TAX.PAYMENT.NRC'},
  '0602': {description:'ERROR_TAX_MODEL_PAYMENT', result:'ERRORS.TAX.PAYMENT.BANK_ACCOUNT'},
  '0603': {description:'ERROR_TAX_MODEL_REJECT', result:'ERRORS.TAX.PAYMENT.REJECT'},
  '0604': {description:'ERROR_TAX_MODEL_PDF_DOWNLOAD', result:'ERRORS.TAX.DOWNLOAD.PDF'},
  '0605': {description:'ERROR_TAX_MODEL_EXCEL_DOWNLOAD', result:'ERRORS.TAX.DOWNLOAD.EXCEL'},
  '0606': {description:'ERROR_TAX_MODEL_PAYMENT', result:'ERRORS.TAX.PAYMENT.YEAR'},
  '0607': {description:'ERROR_TAX_MODEL_PAYMENT', result:'ERRORS.TAX.PAYMENT.DATE'},
  '0608': {description:'ERROR_TAX_MODEL_PAYMENT', result:'ERRORS.TAX.PAYMENT.BANK_ACCOUNT_OUT'},
  /* 07XX BANK ERRORS */
  '0701':{description:'ERROR_BANK_CREATE', result:'ERRORS.BANK.CREATE'},
  '0702':{description:'ERROR_BANK_UPDATE', result:'ERRORS.BANK.EDIT'},
  '0703':{description:'ERROR_BANK_GET', result:'ERRORS.BANK.GET'},
  '0704':{description:'ERROR_BANK_BALANCES', result:'ERRORS.BANK.BALANCES'},
  '0705':{description:'ERROR_BANK_MOVEMENTS', result:'ERRORS.BANK.MOVEMENTS'},
  '0706':{description:'ERROR_BANK_LINKED', result:'ERRORS.BANK.LINKED'},
  '0707':{description:'ERROR_BANK_UNLINKED', result:'ERRORS.BANK.UNLINKED'},
  '0708':{description:'ERROR_BANK_ALREADY_LINKED', result:'ERRORS.BANK.ALREADY_LINKED'},
  /* 08XX ENTERPRISE ERRORS */
  '0801':{description: 'ERROR_ENTERPRISE_AVATAR', result :'ERRORS.ENTERPRISE.AVATAR'},
  '0802':{description: 'ERROR_ENTERPRISE_CREATE', result :'ERRORS.ENTERPRISE.CREATE'},
  '0803':{description: 'ERROR_ENTERPRISE_UPDATE', result :'ERRORS.ENTERPRISE.EDIT'},
  /*09XX ACCOUTING ERRORS */
  '0901':{description: 'ERROR_ACCOUNTING_PERIODS', result:'ERRORS.ACCOUNTING.PERIODS'},
  '0902':{description: 'ERROR_ACCOUNTING_PYG', result: 'ERRORS.ACCOUNTING.PYG'},
  /*10XX CONTACT ERRORS */
  '1001':{description: 'ERROR_CONTACT_TYPE', result:'ERRORS.CONTACT.TYPE'},
  '1002':{description:'ERROR_CONTACT_ELEMENT', result:'ERRORS.CONTACT.ELEMENT'},
  '1003':{description: 'ERROR_CONTACT_ADDRESS', result:'ERRORS.CONTACT.ADDRESS'},
  '1004':{description: 'ERROR_CONTACT_CREATE', result:'ERRORS.CONTACT.CREATE'},
  '1005':{description:'ERROR_CONTACT_UPDATE', result:'ERRORS.CONTACT.EDIT'},
  '1006':{description:'ERROR_CONTACT_COUNT', result:'ERRORS.CONTACT.COUNT'},
  /*11XX CONTRACT ERRORS*/
  '1101':{description: 'ERROR_CONTRACT_ELEMENT' , result:'ERRORS.CONTRACT.GET'},
  '1102':{description: 'ERROR_CONTRACT_CREATE', result:'ERRORS.CONTRACT.CREATE'},
  '1103':{description:'ERROR_CONTRACT_QUOTE_GROUP', result: 'ERRORS.CONTRACT.GUOTE_GROUP'},
  '1104':{description:'ERROR_CONTRACT_TASK_HOLDER', result:'ERRORS.CONTRACT.TASK_HOLDER'},
  '1105':{description:'ERROR_CONTRACT_SALARY_TYPE' , result:'ERRORS.CONTRACT.SALARY_TYPE'},
  /*12XX  PRODUCT ERRORS*/
  '1201':{description: 'ERROR_PRODUCT_ELEMENT', result: 'ERRORS.PRODUCT.GET'},
  '1202':{description:'ERROR_PRODUCT_DUPLICATE_ID', result:'ERRORS.PRODUCT.DUPLICATE'},
  '1203':{description:'ERROR_PRODUCT_TYPE', result:'ERRORS.PRODUCT.TYPE'},
  '1204':{description:'ERROR_PRODUCT_CLASS', result:'ERRORS.PRODUCT.CLASS'},
  '1205':{description:'ERROR_PRODUCT_IVA', result:'ERRORS.PRODUCT.IVA'},
  '1206':{description:'ERROR_PRODUCT_UPDATE', result:'ERRORS.PRODUCT.EDIT'},
  '1207':{description:'ERROR_PRODUCT_IRPF', result:'ERRORS.PRODUCT.IRPF'},
  '1208':{description:'ERROR_PRODUCT_STATUS', result :'ERRORS.PRODUCT.STATUS'},
  '1209':{description:'ERROR_PRODUCT_CATEGORIES' , result:'ERRORS.PRODUCT.CATEGORIES'},
  '1210':{description:'ERROR_PRODUCT_COUNT', result:'ERRORS.PRODUCT.COUNT'},
  '1211':{description:'ERROR_PRODUCT_CREATE', result:'ERRORS.PRODUCT.CREATE'},
  /*13XX TASK HOLDER ERRORS*/
  '1301':{description: 'ERROR_TASK_HOLDER_ELEMENT', result: 'ERRORS.TASK_HOLDER.GET'},
  /*14XX USER ERRORS */
  '1401':{description:'ERROR_USER_GET,', result:'ERRORS.USER.GET'},
  '1402':{description:'ERROR_USER_UPDATE,', result:'ERRORS.USER.EDIT'},
  '1403':{description:'ERROR_USER_DOCUMENT', result:'ERRORS.USER.DOCUMENT'},
  '1404':{description:'ERROR_USER_PWD', result:'ERRORS.USER.PWD'},
  /*15XX MARK DETAIL ERRORS */
  '1501':{description:'ERROR_MARK_DETAIL_LOCATION', result:'ERRORS.MARK_DETAIL.LOCATION'},
  '1502':{description:'ERROR_MARK_DETAIL_LIST', result:'ERRORS.MARK_DETAIL.LIST'},
  '1503':{description:'ERROR_MARK_DETAIL_HISTORY', result:'ERRORS.MARK_DETAIL.HISTORY'},
  '1504':{description:'ERROR_MARK_DETAIL_ENTRY', result:'ERRORS.MARK_DETAIL.ENTRY'},
  '1505':{description:'ERROR_MARK_DETAIL_PAUSE', result:'ERRORS.MARK_DETAIL.PAUSE'},
  '1506':{description:'ERROR_MARK_DETAIL_EXIT', result:'ERRORS.MARK_DETAIL.EXIT'},
  '1507':{description:'ERROR_MARK_DETAIL_DELETE', result:'ERRORS.MARK_DETAIL.DELETE'},
  '1508':{description:'ERROR_MARK_DETAIL_CREATE', result:'ERRORS.MARK_DETAIL.CREATE'},
  '1509':{description:'ERROR_MARK_DETAIL_UPDATE', result:'ERRORS.MARK_DETAIL.EDIT'},
  /*16XX CERTIFICATE ERRORS*/
  '1601':{description:'ERROR_CERTIFICATE_GET', result:'ERRORS.CERTIFICATE.GET'},
  '1602':{description:'ERROR_CERTIFICATE_VALIDATE', result:'ERRORS.CERTIFICATE.VALIDATE'},
  '1603':{description:'ERROR_CERTIFICATE_DELETE', result:'ERRORS.CERTIFICATE.DELETE'},
  '1604':{description:'ERROR_CERTIFICATE_UPLOAD', result:'ERRORS.CERTIFICATE.UPLOAD'},
  '1605':{description:'ERROR_CERTIFICATE_UPDATE', result:'ERRORS.CERTIFICATE.EDIT'},
  /*17XX INVOICE ERRORS*/
  '1701':{description:'ERROR_INVOICE_GET',result:'ERRORS.INVOICE.GET'},
  '1702':{description:'ERROR_INVOICE_CREATE' , result:'ERRORS.INVOICE.CREATE'},
  '1703':{description:'ERROR_INVOICE_UPDATE', result:'ERRORS.INVOICE.EDIT'},
  '1704':{description:'ERROR_INVOICE_DELETE',  result:'ERRORS.INVOICE.DELETE'},
  '1705':{description:'ERROR_INVOICE_CONFIGURATION', result:'ERRORS.INVOICE.CONFIGURATION'},
  '1706':{description:'ERROR_INVOICE_UPDATE_CONFIGURATION', result:'ERRORS.INVOICE.UPDATE_CONFIGURATION'},
  '1707':{description:'ERROR_INVOICE_PDF', result:'ERRORS.INVOICE.PDF'},
  '1708':{description:'ERROR_INVOICE_MULTIPLE_PDF' , result:'ERRORS.INVOICE.MULTIPLE_PDF'},
  '1709':{description:'ERROR_INVOICE_MULTIPLE_EXCEL' , result:'ERRORS.INVOICE.MULTIPLE_EXCEL'},
  '1710':{description:'ERROR_INVOICE_EMAIL', result:'ERRORS.INVOICE.EMAIL'},
  '1711':{description:'ERROR_INVOICE_ACCEPT', result:'ERRORS.INVOICE.ACCEPT'},
  '1712':{description:'ERROR_INVOICE_CREATE_NOTE', result:'ERRORS.INVOICE.CREATE_NOTE'},
  '1713':{description:'ERROR_INVOICE_UPDATE_NOTE', result:'ERRORS.INVOICE.UPDATE_NOTE'},
  '1714':{description:'ERROR_INVOICE_DELETE_NOTE', result:'ERRORS.INVOICE.DELETE_NOTE'},
  '1715':{description:'ERROR_INVOICE_DUPLICATE', result:'ERRORS.INVOICE.DUPLICATE'},
  '1716':{description:'ERROR_INVOICE_CORRECTIVE', result:'ERRORS.INVOICE.CORRECTIVE'},
  '1717':{description:'ERROR_INVOICE_STAT_SALE' , result:'ERRORS.INVOICE.STAT_SALE'},
  '1718':{description:'ERROR_INVOICE_STAT_EXPENSES' , result:'ERRORS.INVOICE.STAT_EXPENSE'},
  '1719':{description:'ERROR_INVOICE_STAT_REPORT' , result:'ERRORS.INVOICE.STAT_REPORT'},
  '1720':{description:'ERROR_INVOICE_TAX_LIST', result:'ERRORS.INVOICE.TAX_LIST'},
  '1721':{description:'ERROR_INVOICE_IRPF_LIST', result:'ERRORS.INVOICE.IRPF_LIST'},
  '1722':{description:'ERROR_INVOICE_SALES_CATEGORY', result:'ERRORS.INVOICE.SALES_CATEGORY'},
  '1723':{description:'ERROR_INVOICE_PURCHASES_CATEGORY', result:'ERRORS.INVOICE.PURCHASES_CATEGORY'},
  '1724':{description:'ERROR_INVOICE_TRANSACTION_TYPE', result:'ERRORS.INVOICE.TRANSACTION_TYPE'},
  '1725':{description:'ERROR_INVOICE_PAYMENT_METHOD' , result:'ERRORS.INVOICE.PAYMENT_METHOD'},
  '1726':{description:'ERROR_INVOICE_ACTIVITY', result:'ERRORS.INVOICE.ACTIVITY'},
  '1727':{description:'ERROR_INVOICE_COUNT', result:'ERRORS.INVOICE.COUNT'},
  '1728':{description:'ERROR_INVOICE_SERIE', result:'ERRORS.INVOICE.SERIE'},
  '1729':{description:'ERROR_INVOICE_NOTE', result:'ERRORS.INVOICE.NOTE'},
  '1730':{description:'ERROR_INVOICE_TAX', result:'ERRORS.INVOICE.TAX'},
  '1731':{description:'ERROR_INVOICE_IRPF', result:'ERRORS.INVOICE.IRPF'},
  '1732':{description:'ERROR_INVOICE_DISCOUNT', result:'ERRORS.INVOICE.DISCOUNT'},
  '1733':{description:'ERROR_INVOICE_SURCHARGE', result:'ERRORS.INVOICE.SURCHARGE'},
  '1734':{description:'ERROR_INVOICE_CRICAJA', result:'ERRORS.INVOICE.CRICAJA'},
  '1735':{description:'ERROR_INVOICE_REGAGRI', result:'ERRORS.INVOICE.REGAGRI'},
  '1736':{description:'ERROR_INVOICE_FINANCEDATE', result:'ERRORS.INVOICE.FINANCEDATE'},
  '1737':{description:'ERROR_INVOICE_DOWNLOADLIST', result:'ERRORS.INVOICE.DOWNLOADLIST'},
  /*18XX ADDRESS ERRORS */
  '1801':{description:'ERROR_ADDRESS_COUNTRY', result:'ERRORS.ADDRESS.COUNTRY'},
  '1802':{description:'ERROR_ADDRESS_STREET_TYPE', result:'ERRORS.ADDRESS.STREET_TYPE'},
  /*19XX COLLECTION ERRORS*/
  '1901':{description:'ERROR_COLLECTION_GET', result:'ERRORS.COLLECTION.GET' },
  '1902':{description:'ERROR_COLLECTION_CREATE', result:'ERRORS.COLLECTION.CREATE' },
  '1903':{description:'ERROR_COLLECTION_UPDATE', result:'ERRORS.COLLECTION.EDIT' },
  '1904':{description:'ERROR_COLLECTION_DELETE', result:'ERRORS.COLLECTION.DELETE' },
  /*20XX DOCUMENT TAG ERRORS*/
  '2001':{description:'ERROR_DOCUMENTTAG_GET', result:'ERRORS.DOCUMENTTAG.GET' },
  '2002':{description:'ERROR_DOCUMENTTAG_CREATE', result:'ERRORS.DOCUMENTTAG.CREATE' },
  '2003':{description:'ERROR_DOCUMENTTAG_UPDATE', result:'ERRORS.DOCUMENTTAG.EDIT' },
  '2004':{description:'ERROR_DOCUMENTTAG_DELETE', result:'ERRORS.DOCUMENTTAG.DELETE' },
  /*21XX MARK ERRORS*/
  '2101':{description:'ERROR_MARK_GET', result:'ERRORS.MARK.GET' },
  /*22XX SALARY ERRORS*/
  '2201':{description:'ERROR_SALARY_GET', result:'ERRORS.SALARY.GET'},
  /*23XX COMUNICA ERRORS*/
  '2301':{description:'ERROR_COMUNICA_SEND_CONTRACT' , result:'ERRORS.COMUNICA.SEND_CONTRACT'},
  '2302':{description:'ERROR_COMUNICA_CCC' , result:'ERRORS.COMUNICA.CCC'},
  '2303':{description:'ERROR_COMUNICA_DATE' , result:'ERRORS.COMUNICA.DATE'},
  '2304':{description:'ERROR_COMUNICA_NSS' , result:'ERRORS.COMUNICA.NSS'},
  '2305':{description:'ERROR_COMUNICA_DOCUMENT' , result:'ERRORS.COMUNICA.DOCUMENT'},
  '2306':{description:'ERROR_COMUNICA_TYPE_CONTRACT' , result:'ERRORS.COMUNICA.TYPE_CONTRACT'},
  '2307':{description:'ERROR_COMUNICA_QUOTE_GROUP' , result:'ERRORS.COMUNICA.QUOTE_GROUP'}
}

export const CLASS_NAMES: { [key: string]: ErrorObject } = {
  'INVOICE': { description: 'CLASS_NAME_INVOICE', result: 'CLASSNAMES.INVOICE' },
  'PRODUCT': { description: 'CLASS_NAME_PRODUCT', result: 'CLASSNAMES.PRODUCT' },
  'CONTACT': { description: 'CLASS_NAME_CONTACT', result: 'CLASSNAMES.CONTACT' },
  'BANK': { description: 'CLASS_NAME_BANK', result: 'CLASSNAMES.BANK' },
  'TAXMODEL': { description: 'CLASS_NAME_TAXMODEL', result: 'CLASSNAMES.TAXMODEL' },
  'MESSAGE': { description: 'CLASS_NAME_MESSAGE', result: 'CLASSNAMES.MESSAGE' },
  'FOLDER': { description: 'CLASS_NAME_FOLDER', result: 'CLASSNAMES.FOLDER' },
  'DOCUMENT': { description: 'CLASS_NAME_DOCUMENT', result: 'CLASSNAMES.DOCUMENT' },
  'DOCUMENTTAG': { description: 'CLASS_NAME_DOCUMENTTAG', result: 'CLASSNAMES.DOCUMENTTAG' },
  'DOMAIN': { description: 'CLASS_NAME_DOMAIN', result: 'CLASSNAMES.DOMAIN' },
  'ADDRESS': { description: 'CLASS_NAME_ADDRESS', result: 'CLASSNAMES.ADDRESS' },
  'CERTIFICATE': { description: 'CLASS_NAME_CERTIFICATE', result: 'CLASSNAMES.CERTIFICATE' },
  'CONTRACT': { description: 'CLASS_NAME_CONTRACT', result: 'CLASSNAMES.CONTRACT' },
  'ENTERPRISE': { description: 'CLASS_NAME_ENTERPRISE', result: 'CLASSNAMES.ENTERPRISE' },
  'MARK': { description: 'CLASS_NAME_MARK', result: 'CLASSNAMES.MARK' },
  'MARKDETAIL': { description: 'CLASS_NAME_MARKDETAIL', result: 'CLASSNAMES.MARKDETAIL' },
  'MESSAGECHAT': { description: 'CLASS_NAME_MESSAGECHAT', result: 'CLASSNAMES.MESSAGECHAT' },
  'REPORTING': { description: 'CLASS_NAME_REPORTING', result: 'CLASSNAMES.REPORTING' },
  'SALARY': { description: 'CLASS_NAME_SALARY', result: 'CLASSNAMES.SALARY' },
  'TASKHOLDER': { description: 'CLASS_NAME_TASKHOLDER', result: 'CLASSNAMES.TASKHOLDER' },
  'USER': { description: 'CLASS_NAME_USER', result: 'CLASSNAMES.USER' },
};
/**
 *
 * TRADUCTIONS ERRORS
 *
 */
  // Select language in local
  if(localStorage.getItem(selectedLanguage) === null){
    // Lenguaje predefinido en el estorage
    localStorage.setItem(selectedLanguage, LANGUAGE);
  } else {
    LANGUAGE = localStorage.getItem(selectedLanguage) as string;
  }

  // import language
  let translationData: any;
  import(`../translations/${LANGUAGE}.json`).then(json => {
    // Exist import language
    loadTranslation(json);
    classNameTranslation(json);
  }).catch((err) => {
    // No language, we select Spanish
    loadTranslation(import ('../translations/es.json'));
    classNameTranslation(import ('../translations/es.json'));
  });

  // Load language
  async function loadTranslation(importLanguage: object) {
    translationData = await importLanguage;

    for (const key in ERRORS) {
      let auxKey = ERRORS[key].result.split('.');
      let translateMessage = translationData;
      for (const word of auxKey) {
        translateMessage = translateMessage[word];
        ERRORS[key].result = translateMessage;
      }
    }
  }

  async function classNameTranslation(importLanguage: Promise<any>) {
    translationData = await importLanguage;
    for (const key in CLASS_NAMES) {
      let auxKey = CLASS_NAMES[key].result.split('.');
      let translateMessage = translationData;
      for (const word of auxKey) {
        if (translateMessage[word] !== undefined) {
          translateMessage = translateMessage[word];
          CLASS_NAMES[key].result = translateMessage
        } 
      }
    }
  }

  /**
   * 
   * DOMAIN CONFIGURATION
   * 
   */
  export interface DomainObject {
    id: string,
    name: string,
    logo: string,
    favicon: string,
    title: string,
    loginMessage: string,
    servicedesk: string
  }

  export const DOMAINS: { [key: string]: DomainObject } = {
    pre: {
      id          : '36396',
      name        : 'nuevo-cadiz.aonsolutions.org',
      logo        : './assets/images/logo/logo.png',
      favicon     : './assets/images/favicon/plataforma_favicon.ico',
      title       : 'Plataforma',
      loginMessage: 'LOGIN.ACCESS_YOUR_PLATAFORMA_ACCOUNT',
      servicedesk : ''
    },
    infoautonomos: {
      id          : '36076',
      name        : 'infoautonomos.aonsolutions.net',
      logo        : './assets/images/logo/INFOAUTONOMOS_LOGO.png',
      favicon     : './assets/images/favicon/infoautonomos_favicon.ico',
      title       : 'Infoautónomos',
      loginMessage: 'LOGIN.ACCESS_YOUR_INFOAUTONOMOS_ACCOUNT',
      servicedesk : ''
    }
  }

  // Coger el idioma del navegador
  function getBrowserLanguage(): string {
  /*
    Referencia de Idiomas
      Inglés (English)
        Código de país: en-US
        Código de idioma: en
      Español (Spanish)
        Código de país: es-ES
        Código de idioma: es
      Catalán (Català)
        Código de país: ca-ES
        Código de idioma: ca
      Euskera (Euskara)
        Código de país: eu-ES
        Código de idioma: eu
      Gallego (Galego)
        Código de país: gl-ES
        Código de idioma: gl
      Francés (Français)
        Código de país: fr-FR
        Código de idioma: fr
      Chino (中文)
        Código de país: zh-CN
        Código de idioma: zh
      Alemán (Deutsch)
        Código de país: de-DE
        Código de idioma: de
  */
    // Mapeo de idiomas del navegador a códigos de idioma de la aplicación
    const languageList: { [key: string]: string } =  { 
      'en-US': 'en',
      'es-ES': 'es',
      'ca-ES': 'ca',
      'eu-ES': 'eu',
      'gl-ES': 'gl',
      'fr-FR': 'fr',
      'zh-CN': 'zh',
      'de-DE': 'de'
    }
    const language = window.navigator.language || (window.navigator as any).userLanguage;
    
    // Si no tenemos cogemos como predefinido 'es'
    return languageList[language] ? languageList[language] : 'es';
  }
