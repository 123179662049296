<mat-icon
  *ngIf='!matIcon'
  class="material-symbols-outlined"
  matTooltip= "{{ toolTip }}"
  fontIcon="{{ shape }}"
  [ngStyle]="{
    'width': size,
    'height': size,
    'color': color,
    'padding': padding,
    'backgroundColor': backgroundColor,
    'border': border,
    'borderRadius': borderRadius
  }"
>
</mat-icon>

<mat-icon
  *ngIf='matIcon'
  svgIcon="{{ shape }}"
  [ngStyle]="{
    'width': size,
    'height': size,
    'color': color,
    'padding': padding,
    'backgroundColor': backgroundColor,
    'border': border,
    'borderRadius': borderRadius
  }"
></mat-icon>
