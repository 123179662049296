import { IProduct, IProductCategory, IProductClass, IProductType, IProductStatus, ITax, IIRPF, ProductStatusApiReceive, ProductClassApiReceive, ProductTypeApiReceive } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";
import { IRPF } from "./IRPF";
import { ProductCategory, ProductCategoryJSON } from "./ProductCategory";
import { ProductClass } from "./ProductClass";
import { ProductStatus } from "./ProductStatus";
import { ProductType } from "./ProductType";
import { Tax } from "./Tax";

export class Product implements IProduct, IModel {
    private key: string;
    private apiObject: any;
    private code: string;
    private name: string;
    private category: IProductCategory;
    private class: IProductClass;
    private type: IProductType;
    private status: IProductStatus;
    private tax: ITax;
    private irpf: IIRPF;
    private barCode: string;
    private description: string;
    private costPrice: number;
    private benefit: number;
    private price: number;
    private pvp: number;

    constructor(code?: string, name?: string, category?: IProductCategory, productClass?: IProductClass, type?: IProductType, status?: IProductStatus,
        tax?: ITax, irpf?: IIRPF, barCode?: string, description?: string, costPrice?: number, benefit?: number, price?: number, pvp?: number) {
        this.apiObject = {};
        this.key = KeyGenerator.generate(15);
        this.code = code || '';
        this.name = name || '';
        this.category = category || new ProductCategory();
        this.class = productClass || new ProductClass();
        this.type = type || new ProductType();
        this.status = status || new ProductStatus();
        this.tax = tax || new Tax();
        this.irpf = irpf || new IRPF();
        this.barCode = barCode || '';
        this.description = description || '';
        this.costPrice = costPrice || 0;
        this.benefit = benefit || 0;
        this.price = price || 0;
        this.pvp = pvp || 0;
    }
    getCode(): string {
        return this.code
    }
    setCode(value: string): IProduct {
        this.code = value;
        return this
    }
    getName(): string {
        return this.name
    }
    setName(value: string): IProduct {
        this.name = value;
        return this
    }
    getCategory(): IProductCategory {
        return this.category
    }
    setCategory(value: IProductCategory): IProduct {
        this.category = value;
        return this
    }
    getClass(): IProductClass {
        return this.class
    }
    setClass(value: IProductClass): IProduct {
        this.class = value;
        return this
    }
    getType(): IProductType {
        return this.type
    }
    setType(value: IProductType): IProduct {
        this.type = value;
        return this
    }
    getStatus(): IProductStatus {
        return this.status
    }
    setStatus(value: IProductStatus): IProduct {
        this.status = value;
        return this
    }
    getTax(): ITax {
        return this.tax
    }
    setTax(value: ITax): IProduct {
        this.tax = value;
        return this
    }
    getIRPF(): IIRPF {
        return this.irpf
    }
    setIRPF(value: IIRPF): IProduct {
        this.irpf = value;
        return this
    }
    getBarCode(): string {
        return this.barCode
    }
    setBarCode(value: string): IProduct {
        this.barCode = value;
        return this
    }
    getDescription(): string {
        return this.description
    }
    setDescription(value: string): IProduct {
        this.description = value;
        return this
    }
    getCostPrice(): number {
        return this.costPrice
    }
    setCostPrice(value: number): IProduct {
        this.costPrice = value;
        return this
    }
    getBenefit(): number {
        return this.benefit
    }
    setBenefit(value: number): IProduct {
        this.benefit = value;
        return this
    }
    getPrice(): number {
        return this.price
    }
    setPrice(value: number): IProduct {
        this.price = value;
        return this
    }
    getPvp(): number {
        return this.pvp
    }
    setPvp(value: number): IProduct {
        this.pvp = value;
        return this
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Key() {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public get Code() {
        return this.code;
    }

    public set Code(value: string) {
        this.code = value;
    }

    public get Name() {
        return this.name;
    }

    public set Name(value: string) {
        this.name = value;
    }

    public get Category() {
        return this.category;
    }

    public set Category(value: IProductCategory) {
        this.category = value;
    }

    public get Class() {
        return this.class;
    }

    public set Class(value: IProductClass) {
        this.class = value;
    }

    public get Type() {
        return this.type;
    }

    public set Type(value: IProductType) {
        this.type = value;
    }

    public get Status() {
        return this.status;
    }

    public set Status(value: IProductStatus) {
        this.status = value;
    }

    public get Tax() {
        return this.tax;
    }

    public set Tax(value: ITax) {
        this.tax = value;
    }

    public get IRPF() {
        return this.irpf;
    }

    public set IRPF(value: IIRPF) {
        this.irpf = value;
    }

    public get BarCode() {
        return this.barCode;
    }

    public set BarCode(value: string) {
        this.barCode = value;
    }

    public get Description() {
        return this.description;
    }

    public set Description(value: string) {
        this.description = value;
    }

    public get CostPrice() {
        return this.costPrice;
    }

    public set CostPrice(value: number) {
        this.costPrice = value;
    }

    public get Benefit() {
        return this.benefit;
    }

    public set Benefit(value: number) {
        this.benefit = value;
    }

    public get Price() {
        return this.price;
    }

    public set Price(value: number) {
        this.price = value;
    }

    public get Pvp() {
        return this.pvp;
    }

    public set Pvp(value: number) {
        this.pvp = value;
    }

    getKey(): string {
        return this.key;
    }
    getFilterableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('code', this.code);
        map.set('name', this.name);
        map.set('category', this.category);
        map.set('class', this.class);
        map.set('type', this.type);
        map.set('status', this.status);
        map.set('tax', this.tax);
        map.set('irpf', this.irpf);
        map.set('barCode', this.barCode);
        map.set('description', this.description);
        map.set('costPrice', this.costPrice);
        map.set('benefit', this.benefit);
        map.set('price', this.price);
        map.set('pvp', this.pvp);
        return map;
    }
    getSortableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('code', this.code);
        map.set('name', this.name);
        map.set('category', this.category);
        map.set('class', this.class);
        map.set('type', this.type);
        map.set('status', this.status);
        map.set('tax', this.tax);
        map.set('irpf', this.irpf);
        map.set('barCode', this.barCode);
        map.set('description', this.description);
        map.set('costPrice', this.costPrice);
        map.set('benefit', this.benefit);
        map.set('price', this.price);
        map.set('pvp', this.pvp);
        return map;
    }
}

export class ProductJSON {
    static parseDataToReceive(data: any, dataProduct?: any){
        if(data.product){
            let product = new Product();
            Object.defineProperty(product.ApiObject, 'item',{
                value: data,
                enumerable: true
            });
            Object.defineProperty(product.ApiObject, 'product',{
                value: dataProduct,
                enumerable: true
            });
            product.Key = data.id;
            product.BarCode = data.barcode;
            data.product.category ? product.Category = ProductCategoryJSON.parseDataToReceive(data.product.category) : product.Category = new ProductCategory();
            product.Status = new ProductStatus(ProductStatusApiReceive[data.status as keyof typeof ProductStatusApiReceive]);
            product.Class = new ProductClass(ProductClassApiReceive[data.product.kind as keyof typeof ProductClassApiReceive]);
            product.Type = new ProductType(ProductTypeApiReceive[data.product.type as keyof typeof ProductTypeApiReceive]);
            product.Code = data.code;
            product.Description = data.description
            product.Name = data.name
            product.CostPrice = data.purchasePrice
            product.Price = data.price
            product.Benefit = data.profitPercent
            product.Pvp = +((1+((dataProduct.vat ? dataProduct.vat : 0) - (dataProduct.retention ? dataProduct.retention : 0))/100) * data.price).toFixed(2);
            let irpf = new IRPF(dataProduct.retention ? dataProduct.retention : 0);
            dataProduct.retention == 0 ? irpf.Key = '-' : irpf.Key = dataProduct.retention.toString();
            product.IRPF = irpf;
            let tax = new Tax(dataProduct.vat.toString() ? dataProduct.vat.toString() : '0');
            tax.Key = dataProduct.vat.toString() ? dataProduct.vat.toString() : '0';
            product.Tax = tax;
            return product;
        }
        else{
            let product = new Product();
            product.ApiObject = data;
            product.Key = data.id;
            product.Code = data.code;
            product.Name = data.name;
            data.category ? product.Category = ProductCategoryJSON.parseDataToReceive(data.category) : product.Category = new ProductCategory();
            product.Status = new ProductStatus(ProductStatusApiReceive[data.status as keyof typeof ProductStatusApiReceive]);
            return product;
        }
    }

    static parseDataToSend(data: any) {
        throw new Error("Method not implemented.");
    }
}