import { ErrorResponse, IResponse, ISalary, Response } from "../aon";
import { IMultipleObjectCrudRepository, ISalarySpecificMethodsRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { ISalarySpecificMethods } from "../interfaces/serviceInterfaces";
import { Salary } from "../models/Salary";
import { SalaryFilter } from "../utils/ModelsFilters";
import { GenericService } from "./GenericCrudService";

export class SalaryService extends GenericService<Salary, SalaryFilter> implements ISalarySpecificMethods {
    protected SpecificMethodsRepository: ISalarySpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<Salary> & ISingleObjectCrudRepository<Salary>, type: { new (): Salary }, SpecificMethodsRepository: ISalarySpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    async countSalary(filter?: SalaryFilter): Promise<IResponse<number>> {
        try{
            return new Response<number>(await this.SpecificMethodsRepository.countSalary(filter));
        }catch(error){
            throw error instanceof ErrorResponse ? error : new ErrorResponse('1003');
        }
    }

    async getSalaryFile(salary: ISalary): Promise<IResponse<Blob>> {
        try{
            return new Response<Blob>(await this.SpecificMethodsRepository.getSalaryFile(salary));
        }catch(error){
            throw error instanceof ErrorResponse ? error : new ErrorResponse('1003');
        }
    }

}