import { Component } from '@angular/core';
import { formatNumber } from 'src/app/core/utilities/number';
import { IBank, ICollection } from 'libraries/AonSDK/src/aon';
import { BankService } from 'src/app//core/services/bank.service';
import { DateFormat } from 'src/app/core/utilities/time';
import { globalRouterlinkSections } from 'src/app/app.globals';
import { Router } from '@angular/router';

@Component({
  selector    : 'app-card-bank',
  templateUrl : './card-bank.component.html',
  styleUrls   : ['./card-bank.component.scss']
})

export class CardBankComponent {
  spinner     : boolean = true;
  totalAmount : number  = 0;
  banks      !: ICollection<IBank>;
  // Ruta
  globalRouterlinkSections = globalRouterlinkSections;

  constructor(
    private bankService: BankService,
    private router     : Router
  ) {
    this.bankService.getBankList().then((response) => {
      this.banks = response;
      this.banks.forEach((bank) => {
        if(typeof bank.Total == 'number')
          this.totalAmount += bank.Total;
      });
      // Quitamos el spinner despues de cargar los bancos
      this.spinner = false;
    });
  }

  hasBanks(): boolean {
    // Si no tenemos bancos y si tenemos que sea mayor de cero
    return !this.banks ? false : this.banks && this.banks.size() > 0;
  }

  formatTotal(value: number): string {
    return formatNumber(value);
  }

  currentDate(bank: IBank): string {
    return DateFormat(bank.LastUpdate, 'dd/MM/yyyy HH:mm:ss') || '';
  }

  rowClick(event: any) {
    // si no es un icono o un input me hace el navigate(puedo hacer click en la card)
    if ( event.target.tagName !== 'MAT-ICON') {
      this.router.navigate([globalRouterlinkSections['banks']]);
    }
  }
}
