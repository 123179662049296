<ng-container>
  <div
    id="breadcrumb"
    *ngIf="this.menuItem.length > 0"
  >
    <div mat-button id="enterprise-selector" (click)="this.openSelectEnterprise();">
      {{selectedEnterprise}}
      <app-icon shape="arrow_drop_down"></app-icon>
      <app-spinner
        *ngIf         = "spinnerBreadcrumb"
        [diameter]    = "20"
        [strokeWidth] = "3"
      ></app-spinner>
      <app-dropdown-menu
        #enterpriseSelector
        [menuItem]="this.menuItem"
      ></app-dropdown-menu>
    </div>
    <div id="crumb">
      <div class="current-route" *ngFor="let crumb of breadcrumb">
        {{ crumb }}
        <app-icon shape="arrow_right"></app-icon>
      </div>
    </div>
  </div>
</ng-container>
