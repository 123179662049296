import { ICollection, IResponse, ITaskHolder } from "../aon";
import { IMultipleObjectCrudRepository, ISingleObjectCrudRepository, ITaskHolderSpecificMethodsRepository } from "../interfaces/repositoryInterfaces";
import { ITaskHolderSpecificMethods } from "../interfaces/serviceInterfaces";
import { TaskHolder } from "../models/TaskHolder";
import { TaskHolderFilter } from "../utils/ModelsFilters";
import { ErrorResponse } from "../utils/Response";
import { Response } from "../utils/Response";
import { GenericService } from "./GenericCrudService";

export class TaskHolderService extends GenericService<TaskHolder, never> implements ITaskHolderSpecificMethods {

    protected SpecificMethodsRepository: ITaskHolderSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<TaskHolder> & ISingleObjectCrudRepository<TaskHolder>, type: { new (): TaskHolder }, SpecificMethodsRepository: ITaskHolderSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    async getTaskHolderByDocument(document: string): Promise<IResponse<ITaskHolder>> {
        try {
            return new Response<ITaskHolder>(await this.SpecificMethodsRepository.getTaskHolderByDocument(document));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1301');
        }
    }

    async getTaskHolderByUserId(userId: string): Promise<IResponse<ITaskHolder>> {
        try {
            return new Response<ITaskHolder>(await this.SpecificMethodsRepository.getTaskHolderByUserId(userId));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1301');
        }
    }

    async getTaskHolderListEmployee(filter?: TaskHolderFilter): Promise<IResponse<ICollection<ITaskHolder>>> {
        try {
            return new Response<ICollection<ITaskHolder>>(await this.SpecificMethodsRepository.getTaskHolderListEmployee(filter));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1301');
        }
    }

    async getTaskHolderEmployeeCount(filter?: TaskHolderFilter): Promise<IResponse<number>> {
        try {
            return new Response<number>(await this.SpecificMethodsRepository.getTaskHolderEmployeeCount(filter));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1301');
        }
    }
}