<mat-toolbar id="tool-bar">
  <div id="tool-bar-logo">
    <img src="{{this.domainService.getLogo()}}" routerLink="home"/>
  </div>
  <div *ngIf="usserLoggged && enterpriseSelected" id="tool-bar-routes">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <div *ngIf="usserLoggged && enterpriseSelected" id="tool-bar-icons">
    <app-button *ngIf="displayHomeIcon" class="icon-only" routerLink="home">
      <app-icon [shape]="'home'"></app-icon>
    </app-button>
    <app-button
      class="icon-only"
      (click)="this.dropdownMenuComponent.openDropdownMenu()"
    >
      <app-icon [shape]="'person'"></app-icon>
      <app-dropdown-menu
        *ngIf="this.menuItem.length > 0"
        #first
        [menuItem]="this.menuItem"
      ></app-dropdown-menu>
    </app-button>
  </div>
  <div *ngIf="!usserLoggged" id="tool-bar-icons">
    <app-button class="icon-only" (click)="this.dropdownMenuComponent.openDropdownMenu()" [toolTip]="'HEADER.LANGUAGE' | translate">
      <app-icon [shape]="'language'"></app-icon>
      <app-dropdown-menu
        *ngIf="this.menuItem.length > 0"
        #first
        [menuItem]="this.menuItem"
      ></app-dropdown-menu>
    </app-button>
  </div>
</mat-toolbar>
<app-redmine></app-redmine>
