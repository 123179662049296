import { IMark } from "../interfaces/modelsInterfaces";
import { IMarkSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IFilter, ICollection } from "../interfaces/utilitiesInterfaces";
import { MarkJSON, Mark } from "../models/Mark";
import { Collection } from "../utils/Collection";
import { ApiHttpRequest } from "../utils/Http";
import { BASE_URL } from "../utils/Environment";
import { GenericRepository } from "./GenericRepository";
import { formatDate } from "../utils/Date";
import { MARK_URL } from "../utils/ApiUrls";
import { MarkFields } from "../utils/ModelsFields";
import { MarkFilter } from "../utils/ModelsFilters";

export class MarkRepository extends GenericRepository<Mark> implements IMarkSpecificMethodsRepository, IMultipleObjectCrudRepository<Mark>, ISingleObjectCrudRepository<Mark> {

    async getCollection(filter: IFilter): Promise<ICollection<Mark>> {
        let url = MARK_URL.LIST;
        let collection = new Collection<Mark>();
        let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(url, generateFilters(filter)), {}, {})
        response.forEach((element: any) => {
            collection.add(MarkJSON.parseDataToReceive(element, 'user', filter))
        })
        return collection;
    }

    async getMarksOfOneUser(userId: string, filter?: IFilter): Promise<ICollection<IMark>> {
        let url = MARK_URL.LIST_HOLDER;
        let collection: ICollection<IMark> = new Collection<IMark>();
        let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(url, generateFilters(filter, userId)), {}, {})
        response.forEach((element: any) => {
            collection.add(MarkJSON.parseDataToReceive(element, "mark", filter))
        })
        return collection;
    }

}

let generateFilters = (filter?: MarkFilter, userId?: string) => {
    let from = filter?.fields?.get(MarkFields.START_DATE) ? filter.fields?.get(MarkFields.START_DATE) : '';
    let to = filter?.fields?.get(MarkFields.END_DATE) ? filter.fields?.get(MarkFields.END_DATE) : '';
    let page = filter?.pageNum ? filter.pageNum : undefined;
    let perPage = filter?.pageItems ? filter.pageItems : undefined;
    let params = {
        group: 'DAY',
        active: true,
        value: to == '' || from == '' ? 'today' : 'personalized'
    }
    if(userId)
        Object.defineProperty(params, 'taskHolderId', {
            value: userId,
            enumerable: true
        })
    if(from != '')
        Object.defineProperty(params, 'startDate', {
            value: formatDate(from),
            enumerable: true
        })
    if(to != '')
        Object.defineProperty(params, 'endDate', {
            value: formatDate(to),
            enumerable: true
        })
    if(page != undefined && perPage != undefined){
        Object.defineProperty(params, "page", {
            value: page,
            enumerable: true
        });
        Object.defineProperty(params, "perPage", {
            value: perPage,
            enumerable: true
        });
    }
    if(filter?.fields?.has(MarkFields.GLOBAL))
        Object.defineProperty(params, "search", {
            value: filter?.fields?.get(MarkFields.GLOBAL),
            enumerable: true
        });
    return params;
}