import { ICountryAon, IStreetType } from "../interfaces/modelsInterfaces";
import { IApiAddressSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IAddressSpecificMethods } from "../interfaces/serviceInterfaces";
import { ICollection, IResponse } from "../interfaces/utilitiesInterfaces";
import { Address } from "../models/Address";
import { ErrorResponse, Response } from "../utils/Response";
import { GenericService } from "./GenericCrudService";


export class AddressService extends GenericService<Address, never> implements IAddressSpecificMethods {
    protected SpecificMethodsRepository: IApiAddressSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<Address> & ISingleObjectCrudRepository<Address>, type: { new (): Address }, SpecificMethodsRepository: IApiAddressSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    async getCountryList(): Promise<IResponse<ICollection<ICountryAon>>> {
        try {
            return new Response<ICollection<ICountryAon>>(await this.SpecificMethodsRepository.getCountryList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1801');
        }
    }

    async getStreetTypeList(): Promise<IResponse<ICollection<IStreetType>>> {
        try {
            return new Response<ICollection<IStreetType>>(await this.SpecificMethodsRepository.getStreetTypeList());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1802');
        }
    }
}