import { Component, Input, OnInit } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() color      : string  = 'primary';
  @Input() diameter   : number  = 70;
  @Input() strokeWidth: number  = 5;
  @Input() scroll     : boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.validateColor();
    if(this.scroll){
      // Scroll para que sea visible el spinner
      const scrollBody = document.querySelectorAll('#layaut-componet-body');
      scrollBody.forEach((div: Element) => {
        (div as HTMLElement).scrollTo(0, 0);
      });
      const scrollContent = document.querySelectorAll('#layaut-componet-content');
      scrollContent.forEach((div: Element) => {
        (div as HTMLElement).scrollTo(0, 0);
      });
    }
  }

  /**
  * Valida el color y establece un valor predeterminado si se proporciona un color no válido.
  *
  * @param {} - Sin parámetros.
  * @return {} - Sin valor de retorno.
  */
  private validateColor(): void {
    const allowedColors = ['black', 'primary', 'positive', 'attention', 'warning', 'alt-primary', 'alt-positive', 'alt-attention', 'alt-warning'];

    if (!allowedColors.includes(this.color)) {
      this.color = 'primary';
    }
  }

}
