import { IInvoice, IProduct, IContact, IDocument, IFolder, ICertificate, IEnterprise, IRegistryEnterprise, IDocumentNote, IBank, ITaxModel, IMessage, IMessageChat, IEmployee, IMark, IUser, IContract, IInvoiceLine, IInvoiceExpirationLine, ITax, IIRPF, ISalary } from "../interfaces/modelsInterfaces";
import { ICollection } from "../interfaces/utilitiesInterfaces";
import { Bank } from "../models/Bank";
import { Certificate } from "../models/Certificate";
import { Contact } from "../models/Contact";
import { Contract } from "../models/Contract";
import { DocumentNote } from "../models/DocumentNote";
import { Employee } from "../models/Employee";
import { Enterprise } from "../models/Enterprise";
import { Folder } from "../models/Folder";
import { Invoice } from "../models/Invoice";
import { Mark } from "../models/Mark";
import { Message } from "../models/Message";
import { MessageChat } from "../models/MessageChat";
import { Product } from "../models/Product";
import { RegistryEnterprise } from "../models/RegistryEnterprise";
import { TaxModel } from "../models/TaxModel";
import { User } from "../models/User";
import { Collection } from "../utils/Collection";
import { Document } from "../models/Document";
import { InvoiceLine } from "../models/InvoiceLine";
import { InvoiceExpirationLine } from "../models/InvoiceExpirationLine";
import { Tax } from "../models/Tax";
import { IRPF } from "../models/IRPF";
import { Salary } from "../models/Salary";

export class CollectionFactory {

    static createSalaryCollection(): ICollection<ISalary> {
        return new Collection<Salary>;
    }

    static createIRPFCollection(): ICollection<IIRPF> {
        return new Collection<IRPF>();
    }

    static createTaxCollection(): ICollection<ITax> {
        return new Collection<Tax>();
    }

    static createInvoiceExpirationLineCollection(): ICollection<IInvoiceExpirationLine> {
        return new Collection<InvoiceExpirationLine>();
    }

    static createInvoiceLineCollection(): ICollection<IInvoiceLine> {
        return new Collection<InvoiceLine>();
    }

    static createInvoiceCollection(): ICollection<IInvoice> {
        return new Collection<Invoice>();
    }

    static createProductCollection(): ICollection<IProduct> {
        return new Collection<Product>();
    }

    static createContactCollection(): ICollection<IContact> {
        return new Collection<Contact>();
    }

    static createDocumentCollection(): ICollection<IDocument> {
        return new Collection<Document>();
    }

    static createFolderCollection(): ICollection<IFolder> {
        return new Collection<Folder>();
    }

    static createCertificateCollection(): ICollection<ICertificate> {
        return new Collection<Certificate>();
    }

    static createEnterpriseCollection(): ICollection<IEnterprise> {
        return new Collection<Enterprise>();
    }

    static createRegistryEnterpriseCollection(): ICollection<IRegistryEnterprise> {
        return new Collection<RegistryEnterprise>();
    }

    static createDocumentNoteCollection(): ICollection<IDocumentNote> {
        return new Collection<DocumentNote>();
    }

    static createBankCollection(): ICollection<IBank> {
        return new Collection<Bank>();
    }

    static createTaxModelCollection(): ICollection<ITaxModel> {
        return new Collection<TaxModel>();
    }

    static createMessageCollection(): ICollection<IMessage> {
        return new Collection<Message>();
    }

    static createMessageChatCollection(): ICollection<IMessageChat> {
        return new Collection<MessageChat>();
    }

    static createEmployeeCollection(): ICollection<IEmployee> {
        return new Collection<Employee>();
    }

    static createMarkCollection(): ICollection<IMark> {
        return new Collection<Mark>();
    }

    static createUserCollection(): ICollection<IUser> {
        return new Collection<User>();
    }

    static createContractCollection(): ICollection<IContract> {
        return new Collection<Contract>();
    }
}