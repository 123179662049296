import { IAddress } from "../interfaces/modelsInterfaces";

export let isBlankAddress = (value: IAddress): boolean => {
    return value.City == '' 
    && value.RestOfDirection == '' 
    && value.Direction == '' 
    && value.Number == '' 
    && value.PostalCode == '' 
    && value.Province == '';
}

export let isSameObjectAndAddress = (value1: any, value2: IAddress): boolean => {
    return value1.address == value2.Direction
    && value1.address2 == value2.RestOfDirection
    && value1.city == value2.City
    && value1.number == value2.Number
    && value1.province == value2.Province
    && value1.zip == value2.PostalCode
    && value1.streetType == value2.Type.Value;
}