<label
  *ngIf="uploadPermissions"
  id="file-upload"
  [ngClass]="{'drag-over': isDropOver}"
  appDragAndDrop
  (click)="modalUploadDocument($event)"
  (drop)="onDrop($event)"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
>
  <ng-content></ng-content>
</label>

<!-- Modal -->
<app-modal-controller
  #modalUploadFile
></app-modal-controller>
<!-- Fin Modal -->

<div 
  *ngIf="filesAndFolderUploaded.length > 0 && filesAndFolderUploadedView"
  id="uploading-files"
  [class.end-two-mensagge] = "
    filesAndFolderUploaded.length - (uploadedError + uploadedCorrect) == 0 
    && uploadedCorrect > 0
    && uploadedError > 0
  "
>
  <div head>
    <div id="head-text">
      <span id="spinner-total" *ngIf = "filesAndFolderUploaded.length - (uploadedError + uploadedCorrect) > 0">
        <mat-spinner
          [color]       = "'alt-warning'" 
          [diameter]    = "'26'" 
          [strokeWidth] = "'4'"
        ></mat-spinner>
        {{ "COMMON.UPLOAD.UPLOADING" | translate }}&nbsp;<b>{{ filesAndFolderUploaded.length - (uploadedError + uploadedCorrect) }}</b>&nbsp;
        <span *ngIf = "filesAndFolderUploaded.length - (uploadedError + uploadedCorrect) > 1">
          {{ "COMMON.UPLOAD.FILES" | translate }}
        </span>
        <span *ngIf = "filesAndFolderUploaded.length - (uploadedError + uploadedCorrect) < 2">
          {{ "COMMON.UPLOAD.FILE" | translate }}
        </span>
      </span>
      <span *ngIf = "filesAndFolderUploaded.length - (uploadedError + uploadedCorrect) == 0">
        <p *ngIf = "uploadedCorrect > 0">
          <app-icon 
            class   = "pink"
            [shape]= "'check_circle'"
          ></app-icon>
          <span *ngIf = "uploadedCorrect > 1">
            {{ "COMMON.UPLOAD.UPLOADEDS" | translate }}
          </span>
          <span *ngIf = "uploadedCorrect < 2">
            {{ "COMMON.UPLOAD.UPLOADED" | translate }}
          </span>
          &nbsp;<b>{{ uploadedCorrect }}</b>&nbsp;
          <span *ngIf = "uploadedCorrect > 1">
            {{ "COMMON.UPLOAD.FILES" | translate }}
          </span>
          <span *ngIf = "uploadedCorrect < 2">
            {{ "COMMON.UPLOAD.FILE" | translate }}
          </span>
        </p>
        <p *ngIf = "uploadedError > 0">
          <app-icon 
            class   = "pink"
            [shape] = "'cancel'"
          ></app-icon>
          <span *ngIf = "uploadedError > 1">
            {{ "COMMON.UPLOAD.NOT_UPLOADEDS" | translate }}
          </span>
          <span *ngIf = "uploadedError < 2">
            {{ "COMMON.UPLOAD.NOT_UPLOADED" | translate }}
          </span>
          &nbsp;<b>{{ uploadedError }}</b>&nbsp;
          <span *ngIf = "uploadedError > 1">
            {{ "COMMON.UPLOAD.FILES" | translate }}
          </span>
          <span *ngIf = "uploadedError < 2">
            {{ "COMMON.UPLOAD.FILE" | translate }}
          </span>
        </p>
      </span>
    </div>
    <div id="uploading-files-close">
      <app-icon 
        *ngIf  = "filesAndFolderUploaded.length - (uploadedError + uploadedCorrect) == 0"
        [shape]= "'clear'"
        (click)= "closeUploadingFiles()"
      ></app-icon>
    </div>
  </div>
  <div body>
    <div
      class = "uploading"
      *ngFor= "let file of filesAndFolderUploaded; let i = index"
    > 
      <div
        class="uploaded"
        [matTooltip]="file.document.name"
      > 
        <app-icon class="pink" [shape]="'picture_as_pdf'"></app-icon>
        <span class="name">
          {{ file.document.name }}
        </span>
        <span class="subido">
          <mat-spinner
            *ngIf         = "file.uploaded === 'uploaded'"
            [color]       = "'alt-warning'" 
            [diameter]    = "'26'" 
            [strokeWidth] = "'4'"
          ></mat-spinner>
          <app-icon 
            *ngIf   = "file.uploaded === 'up'"
            class   = "pink"
            [shape] = "'check_circle'"
          ></app-icon>
          <app-icon 
            *ngIf   = "file.uploaded === 'error'"
            class   = "pink"
            [shape] = "'cancel'"
          ></app-icon>
        </span>
      </div>
      <div class="upload-error" *ngIf="file.error !== ''">
        {{ file.error }}
      </div>
    </div>
  </div>
</div>
