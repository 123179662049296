import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';
import { EnterpriseService } from 'src/app/core/services/enterprise.service';
import { IEnterprise } from 'libraries/AonSDK/src/aon';
import { MenuItem } from 'src/app/core/models/interface/menu-item';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector    : 'app-breadcrumb',
  templateUrl : './breadcrumb.component.html',
  styleUrls   : ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit {
  @ViewChild('enterpriseSelector') dropdownMenuComponent: DropdownMenuComponent = new DropdownMenuComponent;
  currentRoute        : string      = this.router.url.replace('/','');
  breadcrumb          : string[]    = this.currentRoute.split('/');
  selectedEnterprise  : string      = "";
  menuItem            : MenuItem [] = [];
  spinnerBreadcrumb   : boolean     = false;

  constructor(
    private router            : Router,
    public  enterpriseService : EnterpriseService,
    public  authService       : AuthService,
    private translate         : TranslateService
  ) {
    // Coger la ruta
    this.router.events.subscribe((event) => {
      event instanceof NavigationEnd ? this.checkCurrentRoute() : null
    })

    // Mostramos solo la empresa en la que estasmos a la hora de cargar
    this.enterpriseService.getCurrentEntepriseData().then((response) => {
      this.selectedEnterprise   = response.SocialReason;
      this.menuItem[0] = {
        root  : true,
        text  : response.SocialReason,
        click : () => this.selectEnterprise(response)
      }
    })
  }

  ngOnInit(): void {
    // Coger la ruta si recarga la pagina
    this.checkCurrentRoute();
  }

  // Cargar traducciones según el idioma seleccionado
  loadTranslations() {
    this.translate.get('BREADCRUMB').subscribe((translations: any) => {
      this.breadcrumb = this.breadcrumb.map((crumb: string) => translations[crumb.toUpperCase()] || crumb);
    });
  }

  // Abir el selector de empresas
  openSelectEnterprise(){
    // Spiner cargando
    this.spinnerBreadcrumb = true;
    // Listado de empresas a mostrar
    this.enterpriseService.getEnterpriseList().then((enterprises) => {
      // Vaciamos los valores
      this.menuItem = [];
      // Cargamos los valores
      enterprises.forEach((element) => {
        this.menuItem.push({
          root  : true,
          text  : element.Name,
          click : () => this.selectEnterprise(element)
        });
      });
      // Quitamos spiner
      this.spinnerBreadcrumb = false;
      // Abrir el listado de empresas
      this.dropdownMenuComponent.openDropdownMenu();
    })
  }

  // Seleccionar una empresa
  async selectEnterprise(enterprise: IEnterprise) {
    await this.authService.setEnterprise(enterprise);
    window.location.assign('/');
  }

  // Mostrar ruta
  checkCurrentRoute() {
    this.currentRoute = this.router.url.replace('/','');
    const routeArray  = this.currentRoute.split('/');
    // Rutas que con ID
    const router      = {
      'inbox'   : true, 'edit' : true,
      'create'  : true, 'sales': true,
      'contact' : true, 'bills': true,
      'mark'    : true, 'bank': true
    };
    // Quitamos el ID para que no salga en el Breadcumb
    if(router[routeArray[routeArray.length - 2] as keyof typeof router]){
      routeArray.pop();
    }
    // Quitamos Rutas del HOME para que no salga en el Breadcumb
    const routerNoView = {
      'home': true, 'main': true,
    };
    if(routerNoView[routeArray[0] as keyof typeof routerNoView]){
      routeArray.pop();
    }
    // Agregamos los valores
    this.breadcrumb = routeArray;
    // Cargamos las traducciones
    this.loadTranslations();
  }

}
