import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ServiceFactory, IEnterprise, AonPermission } from 'libraries/AonSDK/src/aon';
import { CommonService } from './common.service';
import { Permission } from 'libraries/AonSDK/src/models/Permission';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends CommonService {

  private authManager = ServiceFactory.createAuthenticationManager()

  constructor(private router: Router) {
    super();
  }

  async login(email: string, password: string): Promise<boolean> {
    let login = (await this.authManager.login(email, password)).result;

    if(login) {
      this.router.navigate(['selectEnterprise']);
    }
    return login;
  }

  async logout(): Promise<void> {
    await this.authManager.logout();
    this.router.navigate(['/'])
  }

  isLoggedIn(): boolean {
    return this.authManager.isAuthenticated().result;
  }

  async magicLogin(token: string): Promise<boolean> {
    (await this.authManager.tokenLogin(token)).result;
    this.router.navigate(['/selectEnterprise']);
    return true;
  }

  async magicLink(email: string): Promise<boolean> {
    return (await this.authManager.magicLink(email)).result;
  }

  async setEnterprise(enterprise: IEnterprise): Promise<boolean> {
    return (await this.authManager.setEnterprise(enterprise)).result;
  }

  isEnterpriseSelected(): boolean {
    return this.authManager.isEnterpriseSelected().result;
  }

  getEnterpriseSelected(): string {
    return this.authManager.getEnterpriseSelected().result;
  }

  async getUserPermissions(userId: string): Promise<Permission> {
    return (await this.authManager.getUserPermissionsAndRoles(userId)).result;
  }
  
  async getUserRol(): Promise<string[]> {
    return (await this.authManager.permissionsRolUser()).result;
  }

  async getAllPermissions(): Promise<Permission> {
    return (await this.authManager.getAllPermissions()).result;
  }

  async checkMultiplePermissions(permissionArray: [AonPermission]): Promise<boolean> {
    // Todo los permisos que tenemos
    const permissions = (await this.getAllPermissions()).Permissions;
    // Miramos si tenemos alguno de los permisos
    for await (let permission of permissionArray) {
      if(permissions.includes(permission)){
        return true;
      }
    }

    return false;
  }

  async checkOnePermission(permission: AonPermission): Promise<boolean> {
    return (await this.authManager.checkOnePermission(permission)).result;
  }

}
