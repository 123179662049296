import { IInvoiceLine } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";
import { Product } from "./Product";

export class InvoiceLine implements IInvoiceLine, IModel {
    key: string;
    apiObject: any;
    product: Product;
    description: string;
    quantity: number;
    price: number;
    discount: number;
    totalPrice: number;
    tax: number;
    surcharge: number;
    irpf: number;
    irpfKey: string;

    constructor(product?: Product,quantity?: number,price?: number,discount?: number,totalPrice?: number,tax?: number,irpf?: number){
        this.apiObject = {};
        this.key = KeyGenerator.generate(15);
        this.product = product || new Product();
        this.quantity = quantity || 0;
        this.price = price || 0;
        this.discount = discount || 0;
        this.totalPrice = totalPrice || 0;
        this.tax = tax || 0;
        this.irpf = irpf || 0;
        this.description = '';
        this.surcharge = 0;
        this.irpfKey = '';
    }

    public get IrpfKey(): string {
        return this.irpfKey;
    }

    public set IrpfKey(value: string) {
        this.irpfKey = value;
    }

    public getIrpfKey(): string {
        return this.irpfKey;
    }

    public setIrpfKey(value: string): InvoiceLine {
        this.irpfKey = value
        return this
    }

    public get Surcharge(): number {
        return this.surcharge;
    }

    public set Surcharge(value: number) {
        this.surcharge = value;
    }

    public getSurcharge(): number {
        return this.surcharge;
    }

    public setSurcharge(value: number): InvoiceLine {
        this.surcharge = value
        return this;
    }

    getDescription(): string {
        return this.description
    }

    setDescription(value: string): InvoiceLine {
        this.description = value;
        return this;
    }

    getProduct(): Product {
        return this.product
    }
    setProduct(value: Product): InvoiceLine {
        this.product = value;
        return this
    }
    getQuantity(): number {
        return this.quantity
    }
    setQuantity(value: number): InvoiceLine {
        this.quantity = value;
        return this
    }
    getPrice(): number {
        return this.price
    }
    setPrice(value: number): InvoiceLine {
        this.price = value;
        return this
    }
    getDiscount(): number {
        return this.discount
    }
    setDiscount(value: number): InvoiceLine {
        this.discount = value;
        return this
    }
    getTotalPrice(): number {
        return this.totalPrice
    }
    setTotalPrice(value: number): InvoiceLine {
        this.totalPrice = value;
        return this
    }
    getTax(): number {
        return this.tax
    }
    setTax(value: number): InvoiceLine {
        this.tax = value;
        return this
    }
    getIRPF(): number {
        return this.irpf
    }
    setIRPF(value: number): InvoiceLine {
        this.irpf = value;
        return this
    }

    public get Description(): string {
        return this.description;
    }

    public set Description(value: string) {
        this.description = value;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Key() {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public get Product(): Product {
        return this.product;
    }

    public set Product(value: Product) {
        this.product = value;
    }

    public get Quantity(): number {
        return this.quantity;
    }

    public set Quantity(value: number) {
        this.quantity = value;
    }

    public get Price(): number {
        return this.price;
    }

    public set Price(value: number) {
        this.price = value;
    }

    public get Discount(): number {
        return this.discount;
    }

    public set Discount(value: number) {
        this.discount = value;
    }

    public get TotalPrice(): number {
        return this.totalPrice;
    }

    public set TotalPrice(value: number) {
        this.totalPrice = value;
    }

    public get Tax(): number {
        return this.tax;
    }

    public set Tax(value: number) {
        this.tax = value;
    }

    public get IRPF(): number {
        return this.irpf;
    }

    public set IRPF(value: number) {
        this.irpf = value;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        return new Map<string,any>();
    }

    getSortableFields(): Map<string, any> {
        return new Map<string,any>();
    }
}