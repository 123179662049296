import { TaskHolder, TaskHolderJSON } from "../models/TaskHolder";
import { MESSAGE_URL } from "../utils/ApiUrls";
import { Collection } from "../utils/Collection";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { GenericRepository } from "./GenericRepository";
import { ICollection } from "../interfaces/utilitiesInterfaces";
import { ErrorResponse } from "../utils/Response";
import { ITaskHolderSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { ITaskHolder } from "../aon";
import { TaskHolderFilter } from "../utils/ModelsFilters";
import { TaskHolderFields } from "../utils/ModelsFields";

export class TaskHolderRepository extends GenericRepository<TaskHolder> implements ITaskHolderSpecificMethodsRepository, IMultipleObjectCrudRepository<TaskHolder>, ISingleObjectCrudRepository<TaskHolder> {

    async getCollection(filter?: TaskHolderFilter): Promise<ICollection<TaskHolder>>{
        let collection = new Collection<TaskHolder>();
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_LIST, generateFilters(filter)), {}, {})
        if(!(response?.type! == 'error'))
            response.forEach((element: any) => {
                collection.add(TaskHolderJSON.parseDataToReceive(element));
            });
        else 
            throw new ErrorResponse('1301');
        return collection;
    }

    async get(key: string): Promise<TaskHolder>{
        let response = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_ONE + '?id=' + key, {}, {})
        if(!(response?.type! == 'error'))
            return TaskHolderJSON.parseDataToReceive(response);
        else
            throw new ErrorResponse('1301');
    }

    async getTaskHolderByDocument(document: string): Promise<ITaskHolder> {
        let response = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_LIST + '?search=' + document, {}, {})
        if(!(response?.type! == 'error'))
            return TaskHolderJSON.parseDataToReceive(response[0]);
        else
            throw new ErrorResponse('1301');
    }

    async getTaskHolderByUserId(userId: string): Promise<ITaskHolder> {
        let response = await ApiHttpRequest.get(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_LIST + '?user=' + userId, {}, {})
        if(!(response?.type! == 'error'))
            return TaskHolderJSON.parseDataToReceive(response[0]);
        else
            throw new ErrorResponse('1301');
    }

    async getTaskHolderListEmployee(filter?: TaskHolderFilter): Promise<ICollection<ITaskHolder>> {
        let collection = new Collection<TaskHolder>();
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + MESSAGE_URL.GET_TASK_HOLDER_LIST_EMPLOYEE, generateFilters(filter)), {}, {})
        if(!(response?.type! == 'error'))
            response.forEach((element: any) => {
                collection.add(TaskHolderJSON.parseDataToReceive(element));
            });
        else 
            throw new ErrorResponse('1301');
        return collection;
    }

    async getTaskHolderEmployeeCount(filter?: TaskHolderFilter): Promise<number> {
        let response = await ApiHttpRequest.get(ApiHttpRequest.makeURL(BASE_URL + MESSAGE_URL.TASK_HOLDER_EMPLOYEE_COUNT, generateFilters(filter)), {}, {})
        if(!(response?.type! == 'error'))
            return response;
        else 
            throw new ErrorResponse('1301');
    }
}

let generateFilters = (filter?: TaskHolderFilter) => {
    let page = filter?.pageNum ? filter.pageNum : undefined;
    let perPage = filter?.pageItems ? filter.pageItems : undefined;
    let params = {}
    if(page != undefined && perPage != undefined){
        Object.defineProperty(params, "page", {
            value: page,
            enumerable: true
        });
        Object.defineProperty(params, "perPage", {
            value: perPage,
            enumerable: true
        });
    }
    if(filter?.fields?.has(TaskHolderFields.GLOBAL))
        Object.defineProperty(params, "search", {
            value: filter?.fields?.get(TaskHolderFields.GLOBAL),
            enumerable: true
        });
    return params;
}