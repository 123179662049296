import { ErrorResponse } from "../utils/Response";
import { IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { ICollection, IFilter } from "../interfaces/utilitiesInterfaces";
import { DocumentTag, DocumentTagJSON } from "../models/DocumentTag";
import { Collection } from "../utils/Collection";
import { BASE_URL, GET_MULTIPLE } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { GenericRepository } from "./GenericRepository";

export class DocumentTagRepository extends GenericRepository<DocumentTag> implements IMultipleObjectCrudRepository<DocumentTag>, ISingleObjectCrudRepository<DocumentTag> {

    async create(element: DocumentTag): Promise<DocumentTag> {
        // not workin
        // let data = DocumentTagJSON.parseDataToSend(element, CREATE_SINGLE);
        // let response = await ApiHttpRequest.httpRequest(BASE_URL + this.apiModel.getUrl(CREATE_SINGLE), this.apiModel.getMethod(CREATE_SINGLE), {}, data);
        // return DocumentTagJSON.parseDataToReceive(response, GET_SINGLE);
        throw new ErrorResponse('0199')
    }

    async update(element: DocumentTag): Promise<DocumentTag> {
        // not working
        // let filter = new FilterBuilder();
        // filter.addField('id', element.Key);
        // let data = DocumentTagJSON.parseDataToSend(element, UPDATE_SINGLE);
        // let url = DocumentTagJSON.getUrl(UPDATE_SINGLE, filter.getFilter());
        // let method = this.apiModel.getMethod(UPDATE_SINGLE);
        // let response = await ApiHttpRequest.httpRequest(BASE_URL + url, method, element, data)
        // if(response) return element;
        throw new ErrorResponse('0199')
    }

    async getCollection(filter?: IFilter): Promise<ICollection<DocumentTag>> {
        let response = await ApiHttpRequest.get(BASE_URL + '/ms/api/company/banks', {}, {});
        let collection: ICollection<DocumentTag> = new Collection<DocumentTag>();
        response.forEach((element: any) => {
            collection.add(DocumentTagJSON.parseDataToReceive(element, GET_MULTIPLE, filter));
        })
        return collection;
    }
    
}