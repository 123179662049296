import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as L from 'leaflet';
import 'leaflet-control-geocoder';
import { GeolocationService } from 'src/app/core/services/geolocation.service';
import { Subscription } from 'rxjs';

// geocoder.d.ts
declare module 'leaflet' {
  namespace Control {
    function Geocoder(options?: any): any; // Declaración del geocodificador
  }
}

interface Addrees {
  name: string;
  coordinates: L.LatLngExpression;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  map!: L.Map;
  spinner: boolean = true;
  // traducciones
  traductions: any;
  @Output() coordinatesChanged: EventEmitter<{ name: string; coordinates: L.LatLngExpression }> = new EventEmitter();
  // coordenadas iniciales en caso de no coger en la que estás
  coordinates: L.LatLngExpression = [40.416775, -3.703790];
  @Input() initialCoordinates: L.LatLngExpression = this.coordinates;
  // ubicación actual
  @Input() useCurrentLocation: boolean = false;
  @Input() radio: number = 15;
  // Para almacenar el marcador actual
  private currentMarker: L.Marker | null = null;
  // Referencia al círculo
  private circle: L.Circle | null = null;
  // para almacenar la ubicacion de las imagenes
  private myIcon = L.icon({
    iconUrl         : 'assets/images/leaflet/marker-icon.png',
    iconRetinaUrl   : 'assets/images/leaflet/marker-icon-2x.png',
    shadowUrl       : 'assets/images/leaflet/marker-shadow.png',
    iconSize        : [25, 41], // Tamaño del icono
    iconAnchor      : [12, 41], // Punto de anclaje del icono
    popupAnchor     : [1, -34]  // Punto de anclaje del popup
  });
  private attribution: string = `
    &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors,
    <a href="https://carto.com/attributions">CartoDB</a>, and geocoding by
    <a href="https://nominatim.openstreetmap.org/">Nominatim</a>.
  `;
  // localizacion del navegador al iniciar un nuevo marcador
  createLocationName: string = '';
  createCoordinates : L.LatLngExpression = [0, 0];

  constructor(
    private http              : HttpClient,
    private translateService  : TranslateService,
    private geolocationService: GeolocationService
  ){
    this.translateService.get([
      'COMMON.MAP.SEARCH',
      'COMMON.MAP.NO_LOCATION_FOUND',
      'COMMON.MAP.UNKNOWN_LOCATION',
    ]).subscribe((result) => {
      this.traductions = result;
    });
  }

  ngOnInit() {
    // Suscribirse al observable para obtener el valor de name y coordinates
    this.subscription = this.geolocationService.isCreateGeolocation.subscribe(data => {
      this.createLocationName = data.name;        // Aquí accedes a name
      this.createCoordinates  = data.coordinates; // Aquí accedes a coordinates
    });
    if (this.useCurrentLocation) {
      this.getCurrentLocation();
    } else {
      this.initMap();
      this.map.invalidateSize();
    }
  }

  ngOnDestroy() {
    // Desuscribirse al observable para evitar fugas de memoria
    this.subscription.unsubscribe();
  }

  private initMap(): void {
    // Inicializamos el mapa
    this.map = L.map('map').setView(this.initialCoordinates, 21);

    // Definir las capas de CartoDB
    const cartoDBPositron = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
      attribution: this.attribution
    });

    const cartoDBDark = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
      attribution: this.attribution
    });

    // Agregar la capa de CartoDB Positron al mapa por defecto
    cartoDBPositron.addTo(this.map);
    // Crear un objeto para las capas base
    const baseMaps = {
      "CartoDB Positron": cartoDBPositron,
      "CartoDB Dark"    : cartoDBDark
    };

    // Agregar el círculo alrededor del marcador (radio)
    this.circle = L.circle(this.initialCoordinates, {
      color       : '#33a9a9',     // Color del borde
      fillColor   : '#33a9a9', // Color de relleno
      fillOpacity : 0.2,     // Opacidad del relleno
      radius      : this.radio    // Radio en metros
    }).addTo(this.map);

    // Añadimos un marcador de ejemplo
    this.addMarker(this.initialCoordinates, this.myIcon);

    // Llamar a getLocationName para obtener la dirección desde las coordenadas iniciales
    const latlng = L.latLng(this.initialCoordinates);
    this.getLocationName(latlng.lat, latlng.lng);

    // Manejar el evento de búsqueda
    const searchControl = new (L.Control.Geocoder as any)({
      defaultMarkGeocode: false,
      placeholder       : this.traductions['COMMON.MAP.SEARCH'],
      errorMessage      : this.traductions['COMMON.MAP.NO_LOCATION_FOUND'],
      collapsed         : false
    }).addTo(this.map);

    searchControl.on('markgeocode', (e: any) => {
      const coordinates = e.geocode.center;
      this.map.setView([coordinates.lat, coordinates.lng], 13);

      // Eliminar el marcador anterior, si existe
      this.removeMarker();

      // Crear el marcador con el nombre del lugar
      this.addMarker([coordinates.lat, coordinates.lng], this.myIcon);
      // Actualizar marcador y círculo
      this.updateMarkerAndCircle([coordinates.lat, coordinates.lng]);

      // Emitir las coordenadas como un arreglo
      const newCoordinates: Addrees = {
        'name'       : e.geocode.name,
        'coordinates': [coordinates.lat, coordinates.lng]
      };
      // Actualizar la geolocalización en el servicio
      this.geolocationService.updateGeolocation(newCoordinates);
      this.coordinatesChanged.emit(newCoordinates);
    });

    // Agregar el control de capas
    L.control.layers(baseMaps).addTo(this.map);
    // Quitar el spinner
    this.spinner = false;
  }

  // Método para agregar un marcador
  private addMarker(coordinates: L.LatLngExpression, icon: L.Icon): void {
    // Crear el marcador arrastrable
    this.currentMarker = L.marker(coordinates, {
      icon      : icon,
      draggable : true  // Hacer que el marcador sea arrastrable
    }).addTo(this.map);

    // Manejar el evento de movimiento del marcador
    this.currentMarker.on('moveend', (event: any) => {
      const newCoordinates = event.target.getLatLng();              // Obtener nuevas coordenadas
      // Actualizar posición del círculo
      this.updateMarkerAndCircle(newCoordinates);
      this.getLocationName(newCoordinates.lat, newCoordinates.lng); // Actualizar las coordenadas
    });
  }

  // Mover el circulo del radio
  private updateMarkerAndCircle(newCoordinates: L.LatLngExpression): void {
    // Actualizar la posición del marcador
    if (this.currentMarker) {
      this.currentMarker.setLatLng(newCoordinates);
    }

    // Actualizar la posición del círculo
    if (this.circle) {
      this.circle.setLatLng(newCoordinates);
      this.circle.setRadius(this.radio);
    }
  }

  // Método para actualizar el radio del círculo
  setRadio(newRadius: number): void {
    this.radio = newRadius;
    if (this.circle) {
      this.circle.setRadius(this.radio);
    }
  }

  // Método para obtener el nombre de la ubicación a partir de las coordenadas
  public async getLocationName(lat: number, lon: number): Promise<void> {
    if(this.createLocationName === '' || (Array.isArray(this.createCoordinates) && (this.createCoordinates[0] !== lat || this.createCoordinates[1] !== lon))) {
      // Construir la URL para la API de Nominatim
      const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&zoom=16&addressdetails=1&format=json`;
      this.http.get(url).subscribe((response: any) => {
        const address       = response.address;
        const locationName  = [
          address.road          || '',
          address.neighbourhood || '',
          address.borough       || '',
          address.city          || '',
          address.town          || '',
          address.province      || '',
          address.state         || '',
          address.postcode      || '',
          address.country       || ''
        ].filter(part => part).join(', '); // Solo agrega las partes que existan

        // Emitir la dirección y las coordenadas
        const newCoordinates: Addrees = {
          name        : locationName || this.traductions['COMMON.MAP.UNKNOWN_LOCATION'],
          coordinates : [lat, lon]
        };
        // Actualizar la geolocalización en el servicio
        this.geolocationService.updateGeolocation(newCoordinates);
        this.coordinatesChanged.emit(newCoordinates);
      });
    } else {
      // Emitir la dirección y las coordenadas, ya alamacenadas
      const newCoordinates: Addrees = {
        name        : this.createLocationName,
        coordinates : this.createCoordinates
      };
      this.coordinatesChanged.emit(newCoordinates);
    }
  }

  // Método para obtener la ubicación actual
  getCurrentLocation() {
    // si no esta en el observable es que no tiene geolocalización de crear
    // vamos que no ha entrado a crear localizacion
    if(this.createLocationName === ''){
      // Obtener la ubicación actual del navegador
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude  = position.coords.latitude;
            const longitude = position.coords.longitude;
            this.initialCoordinates = [latitude, longitude];
            // Cargamos el mapa
            this.initMap();
          },(error) => {
            // No tiene geolocalización activada
            // Cargamos el mapa
            this.initialCoordinates = this.coordinates;
            this.initMap();
          }
        );
      }
    } else {
      // Ya tiene coordenadas, no miramos la del navegador
      this.initialCoordinates = this.createCoordinates;
      // Cargamos el mapa
      this.initMap();
    }
  }

  // Método para eliminar el marcador
  private removeMarker(): void {
    if (this.currentMarker) {
      this.map.removeLayer(this.currentMarker);
      this.currentMarker = null;
    }
  }

  // Método para establecer coordenadas manualmente
  setCoordinates(latitude: number, longitude: number) {
    const coordinates: L.LatLngExpression = [latitude, longitude];
    this.map.setView(coordinates, 13);
    this.removeMarker();
    this.addMarker(coordinates, this.myIcon);
  }
}
