import { IRegistryEnterprise } from "../interfaces/modelsInterfaces";
import { IFilter, IModel } from "../interfaces/utilitiesInterfaces";
import { formatDate } from "../utils/Date";
import { KeyGenerator } from "../utils/KeyGenerator";
import { ErrorResponse } from '../utils/Response';

export class RegistryEnterprise implements IRegistryEnterprise, IModel {
  private id: string;
  private idEnterprise: string;
  private description: string;
  private dateCreation: Date;
  private dateRegistration: Date;
  private notary: string;
  private protocol: string;
  private inscription: string;
  private key: string;
  protected apiObject: any;
  private country : string;

  constructor(idEnterprise?: string, description?: string, dateCreation?: Date, dateRegistration?: Date, notary?: string, protocol?: string, inscription?: string, key?: string, country?: string) {
    this.id = KeyGenerator.generate(15);
    this.idEnterprise = idEnterprise || '';
    this.description = description || '';
    this.dateCreation = dateCreation || new Date();
    this.dateRegistration = dateRegistration || new Date();
    this.notary = notary || '';
    this.protocol = protocol || '';
    this.inscription = inscription || '';
    this.key = this.idEnterprise;
    this.country = country || '';
  }

  public get ApiObject(): any {
      return this.apiObject;
  }

  public set ApiObject(value: any) {
      this.apiObject = value;
  }

  public get Id(): string {
    return this.id;
  }

  public set Id(value: string) {
    this.id = value;
  }

  public get IdEnterprise(): string {
    return this.idEnterprise;
  }

  public set IdEnterprise(value: string) {
    this.idEnterprise = value;
  }

  public get Description(): string {
    return this.description;
  }

  public set Description(value: string) {
    this.description = value;
  }

  public set Country(value: string) {
    this.country = value;
  }

  public get Country(): string {
    return this.country;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(value: string): RegistryEnterprise {
    this.description = value;
    return this;
  }

  public get DateCreation(): Date {
    return this.dateCreation;
  }

  public set DateCreation(value: Date) {
    this.dateCreation = value;
  }

  public getDateCreation(): Date {
    return this.dateCreation;
  }

  public setDateCreation(value: Date): RegistryEnterprise {
    this.dateCreation = value;
    return this;
  }

  public get DateRegistration(): Date {
    return this.dateRegistration;
  }

  public set DateRegistration(value: Date) {
    this.dateRegistration = value;
  }

  public getDateRegistration(): Date {
    return this.dateRegistration;
  }

  public setDateRegistration(value: Date): RegistryEnterprise {
    this.dateRegistration = value;
    return this;
  }

  public get Notary(): string {
    return this.notary;
  }

  public set Notary(value: string) {
    this.notary = value;
  }

  public getNotary(): string {
    return this.notary;
  }

  public setNotary(value: string): RegistryEnterprise {
    this.notary = value;
    return this;
  }

  public get Protocol(): string {
    return this.protocol;
  }

  public set Protocol(value: string) {
    this.protocol = value;
  }

  public getProtocol(): string {
    return this.protocol;
  }

  public setProtocol(value: string): RegistryEnterprise {
    this.protocol = value;
    return this;
  }

  public get Inscription(): string {
    return this.inscription;
  }

  public set Inscription(value: string) {
    this.inscription = value;
  }

  public getInscription(): string {
    return this.inscription;
  }

  public setInscription(value: string): RegistryEnterprise {
    this.inscription = value;
    return this;
  }

  public get Key(): string {
    return this.key;
  }

  public set Key(value: string) {
    this.key = value;
  }

  getKey(): string {
    return this.idEnterprise;
  }

  getFilterableFields(): Map<string, any> {
    let map = new Map<string, any>();
    map.set('id', this.Id);
    map.set('idEnterprise', this.IdEnterprise);
    map.set('description', this.Description);
    map.set('dateCreation', this.DateCreation);
    map.set('dateRegistration', this.DateRegistration);
    map.set('notary', this.Notary);
    map.set('protocol', this.Protocol);
    map.set('inscription', this.Inscription);
    return map;
  }

  getSortableFields(): Map<string, any> {
    let map = new Map<string, any>();
    map.set('id', this.Id);
    map.set('idEnterprise', this.IdEnterprise);
    map.set('description', this.Description);
    map.set('dateCreation', this.DateCreation);
    map.set('dateRegistration', this.DateRegistration);
    map.set('notary', this.Notary);
    map.set('protocol', this.Protocol);
    map.set('inscription', this.Inscription);
    return map;
  }

}

export class RegistryEnterpriseJSON {
  static parseDataToSend(registryEnterprise: RegistryEnterprise): Promise<any> {
    const json = registryEnterprise.ApiObject;

    Object.defineProperties(json, {
      'domain': registryEnterprise.ApiObject.domain,
    });

    json.record_data = {
      'id': registryEnterprise.Key,
      'domain': registryEnterprise.ApiObject.domain.id,
      'creation_date': formatDate(registryEnterprise.DateCreation),
      'description': registryEnterprise.Description,
      'notary': registryEnterprise.Notary,
      'number': registryEnterprise.Protocol,
      'recordDate': formatDate(registryEnterprise.DateRegistration),
      'registration': registryEnterprise.Inscription
    };

    return json;
  }

  static parseDataToReceive(data: any, currentMethod?: string | undefined, filter?: IFilter | undefined): RegistryEnterprise {
    try {
      let registryEnterprise = new RegistryEnterprise();
      registryEnterprise.ApiObject = data;
      registryEnterprise.DateCreation = (data.record_data.creation_date) ? data.record_data.creation_date : null;
      registryEnterprise.DateRegistration = (data.record_data.recordDate) ? data.record_data.recordDate : null;
      registryEnterprise.Description = (data.record_data.description) ? data.record_data.description : null;
      registryEnterprise.Id = (data.record_data.registry) ? data.record_data.registry : null;
      registryEnterprise.IdEnterprise = (data.id) ? data.id : null;
      registryEnterprise.Inscription = (data.record_data.registration) ? data.record_data.registration : null;
      registryEnterprise.Key = (data.record_data.id) ? data.record_data.id : null;
      registryEnterprise.Notary = (data.record_data.notary) ? data.record_data.notary : null;
      registryEnterprise.Protocol = (data.record_data.number) ? data.record_data.number : null;

      if (data.addresses) delete data.addresses;
      if (data.banks) delete data.banks;
      if (data.media) delete data.media;
      if (data.paymethod) delete data.paymethod;

      return registryEnterprise;
    }
    catch(error) {
      // TODO: Implementar mensaje de error específico para parseo
      throw error instanceof ErrorResponse ?  error : new ErrorResponse('0199');
    }
  }
}