import { ISecondaryUser } from "../aon";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class SecondaryUser implements ISecondaryUser, IModel {
    private name: string;
    private naf: string;
    private status: string;
    private statusDate: Date;
    private key: string;
    private apiObject: any;

    constructor() {
        this.name = '';
        this.naf = '';
        this.status = '';
        this.statusDate = new Date(0);
        this.key = KeyGenerator.generate(15);
        this.apiObject = {};
    }

    public get Name(): string {
        return this.name;
    }

    public get Naf(): string {
        return this.naf;
    }

    public get Status(): string {
        return this.status;
    }

    public get StatusDate(): Date {
        return this.statusDate;
    }

    public get Key(): string {
        return this.key;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public getName(): string {
        return this.name;
    }
    
    public getNaf(): string {
        return this.naf;
    }

    public getStatus(): string {
        return this.status;
    }

    public getStatusDate(): Date {
        return this.statusDate;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public set Name(value: string) {
        this.name = value;
    }
    
    public set Naf(value: string) {
        this.naf = value;
    }

    public set Status(value: string) {
        this.status = value;
    }

    public set StatusDate(value: Date) {
        this.statusDate = value;
    }

    public setName(value: string): ISecondaryUser {
        this.name = value;
        return this;
    }
    
    public setNaf(value: string): ISecondaryUser {
        this.naf = value;
        return this;
    }

    public setStatus(value: string): ISecondaryUser {
        this.status = value;
        return this;
    }

    public setStatusDate(value: Date): ISecondaryUser {
        this.statusDate = value;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        let map: Map<string,any> = new Map();
        return map;
    }

    getSortableFields(): Map<string, any> {
        let map: Map<string,any> = new Map();
        return map;
    }

}

export class SecondaryUserJSON {
    public static parseToReceive(data: any): SecondaryUser {
        let su = new SecondaryUser();
        su.ApiObject = data;
        su.Key = data.number ? data.number : '';
        su.Naf = data.number ? data.number : '';
        su.Name = data.name ? data.name : '';
        su.Status = data.status ? data.status : '';
        su.StatusDate = data.date ? new Date(data.date) : new Date(0);
        return su;
    }
}