import { IEnterprise, IAddress, IMedia, MediaType } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { GET_MULTIPLE, GET_SINGLE } from "../utils/Environment";
import { KeyGenerator } from "../utils/KeyGenerator";
import { ErrorResponse } from "../utils/Response";
import { Address, AddressJSON } from "./Address";
import { MediaJSON, Media } from "./Media";

export class Enterprise implements IEnterprise, IModel {
    private id: string;
    private domainName: string;
    private domainId: string;
    private name: string;
    private profilePhoto: string;
    private address: IAddress;
    private country: string;
    private province: string;
    private socialReason: string;
    private email: Media[];
    private phone: Media[];
    private website: Media[];
    private document: string;
    private registry: string;
    private key: string;
    protected apiObject: any;

    public get Id(): string {
        return this.id;
    }

    public set Id(value: string) {
        this.id = value;
    }

    public getId(): string {
        return this.id;
    }

    public setId(value: string): IEnterprise {
        this.id = value;
        return this;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Registry(): string {
        return this.registry;
    }

    public set Registry(value: string) {
        this.registry = value;
    }

    public get DomainName(): string{
        return this.domainName;
    }

    public set DomainName(value: string){
        this.domainName = value;
    }

    public get DomainId(): string {
        return this.domainId;
    }

    public set DomainId(value: string) {
        this.domainId = value;
    }

    public get Name(): string {
      return this.name;
    }

    public set Name(value: string) {
      this.name = value;
    }

    public get Address(): IAddress {
      return this.address;
    }

    public set Address(value: IAddress) {
      this.address = value;
    }

    public get Country(): string {
      return this.country;
    }

    public set Country(value: string) {
      this.country = value;
    }

    public get Province(): string {
      return this.province;
    }

    public set Province(value: string) {
      this.province = value;
    }

    public get SocialReason(): string {
      return this.socialReason;
    }

    public set SocialReason(value: string) {
      this.socialReason = value;
    }

    public get Email(): Media [] {
      return this.email;
    }

    public set Email(value: Media []) {
      this.email = value;
    }

    public get Phone(): Media[] {
      return this.phone;
    }

    public set Phone(value: Media[]) {
      this.phone = value;
    }

    public get Website(): Media[] {
      return this.website;
    }

    public set Website(value: Media[]) {
      this.website = value;
    }

    public get ProfilePhoto(): string {
        return this.profilePhoto;
    }

    public set ProfilePhoto(value: string) {
        this.profilePhoto = value;
    }

    public get Document(): string {
        return this.document;
    }

    public set Document(value: string) {
        this.document = value;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(key: string) {
        this.key = key;
    }

    constructor(name?: string, document?: string, profilePhoto?: string, address?: IAddress, country?: string, province?: string, socialReason?: string, email?: Media[], phone?: Media[], website?: Media[], registry?: string, key?: string, id?:string) {
        this.id = '';
        this.name = name || '';
        this.profilePhoto = profilePhoto || '';
        this.address = address || new Address();
        this.country = country || '';
        this.province = province || '';
        this.socialReason = socialReason || '';
        this.email = email || [];
        this.phone = phone || [];
        this.website = website || [];
        this.document = document || document || '';
        this.key = document || '';
        this.domainId = '';
        this.domainName = '';
        this.registry = '';
    }

    getName(): string {
        return this.name;
    }

    setName(value: string): IEnterprise {
        this.name = value;
        return this
    }

    getProfilePhoto(): string {
        return this.profilePhoto;
    }

    setProfilePhoto(value: string): IEnterprise {
        this.profilePhoto = value;
        return this
    }

    getAdress(): IAddress {
        return this.address;
    }

    setAdress(value: IAddress): IEnterprise {
        this.address = value;
        return this
    }

    getCountry(): string {
        return this.country;
    }

    setCountry(value: string): IEnterprise {
        this.country = value;
        return this
    }

    getProvince(): string {
        return this.province;
    }

    setProvince(value: string): IEnterprise {
        this.province = value;
        return this
    }

    getSocialReason(): string {
        return this.socialReason;
    }

    setSocialReason(value: string): IEnterprise {
        this.socialReason = value;
        return this
    }

    getEmail(): IMedia[] {
        return this.email;
    }

    setEmail(value: Media[]): IEnterprise {
        this.email = value;
        return this
    }

    getPhone(): IMedia[] {
        return this.phone;
    }

    setPhone(value: Media[]): IEnterprise {
        this.phone = value;
        return this
    }

    getWebsite(): IMedia[] {
        return this.website;
    }

    setWebsite(value: Media[]): IEnterprise {
        this.website = value;
        return this
    }

    getDocument(): string {
        return this.document;
    }

    setDocument(value: string): IEnterprise {
        this.document = value;
        return this
    }

    getDomainName(): string {
        return this.domainName;
    }

    setDomainName(value: string): IEnterprise {
        this.domainName = value;
        return this
    }

    getDomainId(): string {
        return this.domainId;
    }

    setDomainId(value: string): IEnterprise {
        this.domainId = value;
        return this
    }

    getRegistry(): string {
        return this.registry;
    }

    setRegistry(value: string): IEnterprise {
        this.registry = value;
        return this
    }

    getKey(): string {
        return this.document;
    }

    getFilterableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('name', this.Name);
        map.set('document', this.Document);
        return map;
    }

    getSortableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('name', this.Name);
        map.set('document', this.Document);
        return map;
    }

}

export class EnterpriseJSON {
    static parseDataToSend(enterprise: Enterprise): Promise<any> {
        let json = enterprise.ApiObject;
        Object.defineProperties(json, {
            'dirty': {
                value: true,
                enumerable : true,
            },
        })
        delete json.banks;
        delete json.paymethod;
        delete json.record_data;
        json.alias = enterprise.Name;
        
        if(json.addresses)
            json.addresses.forEach((element: any) => {
                if(element.main == true){
                    element.city = enterprise.Address.City;
                    element.address = enterprise.Address.Direction;
                    element.address2 = enterprise.Address.RestOfDirection;
                    element.dirty = true;
                    element.number = enterprise.Address.Number;
                    element.province = enterprise.Address.Province;
                    element.streetType = enterprise.Address.Type.Value;
                    element.zip = enterprise.Address.PostalCode;
                    element.country = enterprise.Address.Country.Value;
                }
            })
        let array = enterprise.Phone.concat(enterprise.Email.concat(enterprise.Website));
        if(json.media)
            json.media.forEach((element: any) => {
                array.forEach((media: Media) => {
                    if(media.getKey() == element.id){
                        element.value = media.Value;
                        element.dirty = true;
                    }
                })
            })
        array.forEach((element: Media) => {
            if(!element.ApiObject.id)
                json.media.push({
                    administrative: true,
                    commercial: true,
                    dirty: true,
                    media: element.Type,
                    registry: localStorage.getItem('idEnterprise'),
                    technical: true,    
                    value: element.Value,
                    removed: false,
                })
        })
        json.media.forEach((element: any) => {
            if(element.dirty == false && element.media != 'fax')
                element.removed = true
        })
        return json;
    }

    static parseDataToReceive(data: any, currentMethod: string): any {
        if(currentMethod == GET_MULTIPLE){
            if(data.type == 'CONSULTANCY') return new Enterprise();
            let enterprise = new Enterprise();
            enterprise.ApiObject = data;
            enterprise.Document = data.document ? data.document : KeyGenerator.generate(9);
            enterprise.Name = data.name ? data.name : ''
            enterprise.Key = data.domain.id ? data.domain.id : data.id;
            enterprise.DomainName = data.domain.name ? data.domain.name : data.domain;
            enterprise.DomainId = data.domain.id ? data.domain.id : data.id;
            enterprise.Registry = data.registry ? data.registry : '';
            enterprise.Id = data.registry ? data.registry : data.id;
            return enterprise;
        }else if (currentMethod == GET_SINGLE){
            let enterprise = new Enterprise();
            enterprise.ApiObject = data;
            enterprise.Id = data.id;
            enterprise.Address = data.address.address
            enterprise.Country = data.address.country
            enterprise.Document = data.document
            enterprise.DomainId = data.domain.id
            enterprise.DomainName = data.domain.name
            enterprise.Email = [] 
            enterprise.Key = data.id
            enterprise.Name = data.name
            enterprise.Phone = [];
            enterprise.Province = data.address.province
            enterprise.Registry = data.registry
            enterprise.SocialReason = '' 
            enterprise.Website = [];
            return enterprise;
        }else if (currentMethod == 'data'){
            let enterprise = new Enterprise();
            enterprise.ApiObject = data;
            enterprise.Id = data.id;
            enterprise.Name = data.alias;
            enterprise.SocialReason = data.name;
            enterprise.Country = data.nationality;
            enterprise.Document = data.document;
            if(data.addresses)
                data.addresses.forEach((element:any) => {
                    if(element.main == true)
                        enterprise.Address = AddressJSON.parseDataToReceive(element);
                })
            if(data.media)
                data.media.forEach( (element:any) => {
                    if(element.media != 'fax'){
                        let media: Media = MediaJSON.parseDataToReceive(element);
                        switch(media.Type){
                            case MediaType.EMAIL:
                                enterprise.Email.push(media);
                                break;
                            case MediaType.PHONE:
                                enterprise.Phone.push(media);
                                break;
                            case MediaType.WEB:
                                enterprise.Website.push(media);
                                break;
                        }
                    }
                });
            return enterprise;
        }
        throw new ErrorResponse('0199')
    }
}