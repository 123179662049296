import { Component, Inject, Output, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateFormat } from 'src/app/core/utilities/time';
import { FolderService } from 'src/app/core/services/folder.service';
import { TranslateService } from '@ngx-translate/core';

interface Folder {
  value : string;
  text  : string;
}

@Component({
  selector    : 'app-upload-modal',
  templateUrl : './upload-modal.component.html',
  styleUrls   : ['./upload-modal.component.scss'],
})

export class UploadModalComponent implements OnInit {
  isDropOver      : boolean   = false;
  folderList      : Folder[]  = [];
  folderSelected  : string    = '/proforma';
  folderNoSelected: boolean   = false;
  filesAndFolder  : any[]     = [];
  currentDate =
    DateFormat(new Date().toISOString()
    , 'dd/MM/yyyy'
  );
  currentDateHour =
    DateFormat(new Date().toISOString()
    , 'HH:mm'
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadModalComponent>,
    private translateService: TranslateService,
    private folderService: FolderService,
  ) {
    // Guardamos las carpetas en un array para mostrarlas en el select
    this.folderService.getFolderList().then((listFolders) => {
      listFolders.forEach((folder) => {
        this.folderList.push({
          value : folder.getKey(),
          text  : folder.Name,
        });
      });
    });
  }

  ngOnInit(): void {
    // Si arrastramos files en el componente padre
    if(typeof this.data === 'object'){
      this.previewDocumentsUpload(this.data);
    }
  }

/*
  Evento cogiendo documentos
*/
  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      this.previewDocumentsUpload(target.files);
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files) {
      this.previewDocumentsUpload(event.dataTransfer.files);
    }
    this.isDropOver = false;
  }

  onDragOver(event: any) {
    event.preventDefault();
    this.isDropOver = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    this.isDropOver = false;
  }
/*
  FIN - Evento cogiendo documentos
*/

  // Comprobamos si se ha seleccionado una carpeta antes de subir el documento
  checkFolder(event: Event) {
    if (this.folderSelected === '') {
      event.preventDefault();
      this.folderNoSelected = true;
    } else {
      this.folderNoSelected = false;
    }
  }

  // Guardamos el documento y la carpeta seleccionada
  async previewDocumentsUpload(event: any) {
    // Mirar si tenemos carpeta marcada
    if(!this.folderNoSelected){
      const files = event;
      Array.from(files).forEach((file) => {
        // Montamos los datos a enviar, cuando se cierre el modal
        this.filesAndFolder.push({
          document: file,
          path    : this.folderSelected,
          uploaded: 'uploaded',
          error   : ''
        });
      });
    } else {
      this.translateService.get(['COMMON.UPLOAD.FOLDER']).subscribe((result) => {
        throw result['COMMON.UPLOAD.FOLDER'];
      });
    }
  }

  // Limpiamos el documento subido
  clearDocument(index: number) {
    // Quitamos el documento escogido
    this.filesAndFolder.splice(index, 1);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
