import { FilterBuilder } from "./FilterBuilder";
import { FilterOrder } from "./ModelsFields";

export class AonFilter<K extends string, T extends string = never, H extends string = never> extends FilterBuilder<K, T, H> {

    constructor(){
        super();
    }

    addField(field: K, value: any): void {
        super.addField(field, value);
    }

    addInterval(field: T, startValue: any, endValue: any): void {
        super.addInterval(field, startValue, endValue);
    }

    deleteField(field: K): void {
        super.deleteField(field);
    }

    deleteInterval(field: T): void {
        super.deleteInterval(field);
    }

    addOrder(field: H, order: FilterOrder): void {
        super.addOrder(field, order);
    }
    
}