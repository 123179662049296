import { ITax } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class Tax extends SimpleValue implements ITax, IModel {
    protected surcharge: number;
    protected surchargeQuota: number;
    protected percentage: number;
    protected quota: number;
    protected base: number;

    public get Surcharge(): number {
        return this.surcharge;
    }

    public get SurchargeQuota(): number {
        return this.surchargeQuota;
    }

    public get Percentage(): number {
        return this.percentage;
    }

    public get Quota(): number {
        return this.quota;
    }

    public get Base(): number {
        return this.base;
    }

    public set Surcharge (value: number) {
        this.surcharge = value;
    }

    public set SurchargeQuota (value: number) {
        this.surchargeQuota = value;
    }

    public set Percentage (value: number) {
        this.key = value.toString();
        this.percentage = value;
    }

    public set Quota (value: number) {
        this.quota = value;
    }

    public set Base (value: number) {
        this.base = value;
    }
    
    constructor(value?: any) {
        super(value);
        this.surcharge = 0;
        this.surchargeQuota = 0;
        this.percentage = 0;
        this.quota = 0;
        this.base = 0;
    }
    
}

export class TaxJSON {
    static parseDataToReceive(data: any, taxType: TaxType): Tax {
        let tax = new Tax();
        tax.ApiObject = data;
        tax.Key = data.percentage;
        tax.Value = taxType == TaxType.IVA ? data.percentage + '%' : data.percentage + '% + ' + data.surcharge + '%';
        tax.Surcharge = data.surcharge;
        tax.Percentage = data.percentage;
        return tax;
    }
}

export enum TaxType {
    IVA = 'IVA',
    SURCHARGE_IVA = 'IVA_RE'
}

export const TaxIVAPercentage = [
    {value:21.0, name:'21%', surcharge: 0},
    {value:10.0, name:'10%', surcharge: 0},
    {value:5.0, name:'5%', surcharge: 0},
    {value:4.0, name:'4%', surcharge: 0},
    {value:0.0, name:'0%', surcharge: 0}
];

export const TaxVatREPercentage = [
    {value:21.0, name:'21%+5,2%', surcharge: 5.2},
    {value:10.0, name:'10%+1,4%', surcharge: 1.4},
    {value:5.0, name:'5%+0,5%', surcharge: 0.5},
    {value:4.0, name:'4%+0,5%', surcharge: 0.5},
    {value:0.0, name:'0%', surcharge: 0}
];