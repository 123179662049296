import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector    : 'app-result-snack-bar',
  templateUrl : './result-snack-bar.component.html',
  styleUrls   : ['./result-snack-bar.component.scss']
})
export class ResultSnackBarComponent implements OnInit {
  progress: number = 0;
  interval: any;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public _snackbar: MatSnackBarRef<ResultSnackBarComponent>
  ) {

  }

  ngOnInit(): void {
    // Se le ha marcado una duracion
    if(this.data.duration)
      this.progressBar();
  }

  progressBar(){
    var timeTotal: number = this.data.duration;
    var timeCount: number = timeTotal;

    this.interval = setInterval(() => {
      if(timeCount == 0){
        // Cerramos el aviso
        this.destroy();
        clearInterval(this.interval);
      } else {
        // Quitamos decimales si no falla
          // Barra subiendo porgreso
//          this.progress = Math.round(100 -((timeCount  * 100) / timeTotal));
          // Barra disminuyendo progreso
          this.progress = Math.round(((timeCount  * 100) / timeTotal));
        timeCount=timeCount-1;
      }
    }, 1);
  }

  destroy() {
    clearInterval(this.interval);
    this.data.preClose();
  }

}
