import { ILocation, IMark, IMarkDetail, IMarkHistory } from "../interfaces/modelsInterfaces";
import { IMarkDetailSpecificMethods } from "../interfaces/serviceInterfaces";
import { ICollection } from "../interfaces/utilitiesInterfaces";
import { IResponse } from "../interfaces/utilitiesInterfaces";
import { Response, ErrorResponse } from "../utils/Response";
import { GenericService } from "./GenericCrudService";
import { MarkDetail } from "../models/MarkDetail";
import { IMarkDetailSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";


export class MarkDetailService extends GenericService<MarkDetail, never> implements IMarkDetailSpecificMethods {
    protected SpecificMethodsRepository: IMarkDetailSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<MarkDetail> & ISingleObjectCrudRepository<MarkDetail>, type: { new (): MarkDetail }, SpecificMethodsRepository: IMarkDetailSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    async createLocation(location: ILocation): Promise<IResponse<ILocation>> {
        try{
            return new Response<ILocation>(await this.SpecificMethodsRepository.createLocation(location));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1501');
        }
    }

    async updateLocation(location: ILocation): Promise<IResponse<ILocation>> {
        try{
            return new Response<ILocation>(await this.SpecificMethodsRepository.updateLocation(location));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1501');
        }
    }

    async deleteLocation(key: string): Promise<IResponse<boolean>> {
        try{
            return new Response<boolean>(await this.SpecificMethodsRepository.deleteLocation(key));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1501');
        }
    }

    async getLocation(key: string): Promise<IResponse<ILocation>> {
        try{
            return new Response<ILocation>(await this.SpecificMethodsRepository.getLocation(key));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1501');
        }
    }


    async getLocationList(): Promise<IResponse<ICollection<ILocation>>> {
        try{
            return new Response<ICollection<ILocation>>(await this.SpecificMethodsRepository.getLocationList());
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1501');
        }
    }

    async getMarkDetailList(mark: IMark): Promise<IResponse<ICollection<IMarkDetail>>> {
        try{
            return new Response<ICollection<IMarkDetail>>(await this.SpecificMethodsRepository.getMarkDetailList(mark));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1502');
        }
    }

    async getMarkDetailHistory(key: String): Promise<IResponse<ICollection<IMarkHistory>>> {
        try{
            return new Response<ICollection<IMarkHistory>>(await this.SpecificMethodsRepository.getMarkDetailHistory(key));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1503');
        }
    }

    async markEntry(): Promise<IResponse<boolean>> {
        try{
            return new Response<boolean>(await this.SpecificMethodsRepository.markEntry());
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1504');
        }
    }

    async markPause(): Promise<IResponse<boolean>> {
        try{
            return new Response<boolean>(await this.SpecificMethodsRepository.markPause());
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1505');
        }
    }

    async markExit(): Promise<IResponse<boolean>> {
        try{
            return new Response<boolean>(await this.SpecificMethodsRepository.markExit());
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1506');
        }
    }

    async deleteMarkDetail(element: IMarkDetail): Promise<IResponse<boolean>> {
        try{
            return new Response<boolean>(await this.SpecificMethodsRepository.deleteMarkDetail(element));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1507');
        }
    }

}
