import { IDocument, IDocumentTag, MainFolders } from "../interfaces/modelsInterfaces";
import { ICollection, IFilter, IModel } from "../interfaces/utilitiesInterfaces";
import { Collection } from "../utils/Collection";
import { KeyGenerator } from "../utils/KeyGenerator";
import { DocumentFields } from "../utils/ModelsFields";
import { DocumentFilter } from "../utils/ModelsFilters";
import { ErrorResponse } from "../utils/Response";
import { DocumentTag } from "./DocumentTag";

export class Document implements IDocument, IModel {
    private file: string;
    private fileName: string;
    private fileSize: number;
    private fileType: string;
    private path: string;
    private date: Date;
    private key: string;
    private id: string;
    private tag: ICollection<IDocumentTag>;
    protected apiObject: any;

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    constructor(file?: string, fileName?: string, fileSize?: number, fileType?: string, date?: Date, path?: string, tag?: Collection<DocumentTag>, id?: string) {
        this.file = file || '';
        this.fileName = fileName || '';
        this.fileSize = fileSize || 0;
        this.fileType = fileType || '';
        this.path =  path || '';
        this.date = date || new Date();
        this.key = path && fileName ? path + '/' + fileName : '';
        this.id = KeyGenerator.generate(15);
        this.apiObject = '';
        this.tag = tag || new Collection<IDocumentTag>();
    }
    getFile(): string {
        return this.file
    }
    setFile(value: string): IDocument {
        this.file = value;
        return this
    }
    getFileName(): string {
        return this.fileName
    }
    setFileName(value: string): IDocument {
        this.fileName = value;
        return this
    }
    getFileSize(): number {
        return this.fileSize
    }
    setFileSize(value: number): IDocument {
        this.fileSize = value;
        return this
    }
    getFileType(): string {
        return this.fileType
    }
    setFileType(value: string): IDocument {
        this.fileType = value;
        return this
    }
    getPath(): string {
        return this.path
    }
    setPath(value: string): IDocument {
        this.path = value;
        return this
    }
    getDate(): Date {
        return this.date
    }
    setDate(value: Date): IDocument {
        this.date = value;
        return this
    }
    getTag(): ICollection<IDocumentTag> {
        return this.tag
    }
    setTag(value: ICollection<IDocumentTag>): IDocument {
        this.tag = value;
        return this
    }

    public get Tag(): ICollection<IDocumentTag> {
        return this.tag;
    }

    public set Tag(value: ICollection<IDocumentTag>) {
        this.tag = value;
    }

    public get FileName(): string {
        return this.fileName;
    }

    public get FileSize(): number {
        return this.fileSize;
    }

    public get FileType(): string {
        return this.fileType;
    }

    public get Date(): Date {
        return this.date;
    }

    public get File(): string {
        return this.file;
    }

    public get Path(): string {
        return this.path;
    }

    public set File(file: string) {
        this.file = file;
    }

    public set FileName(fileName: string) {
        this.fileName = fileName;
    }

    public set FileSize(fileSize: number) {
        this.fileSize = fileSize;
    }

    public set FileType(fileType: string) {
        this.fileType = fileType;
    }

    public set Date(date: Date) {
        this.date = date;
    }

    public set Path(path: string) {
        this.path = path;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(key: string) {
        this.key = key;
    }

    public get Id(): string {
        return this.id;
    }

    public set Id(id: string) {
        this.id = id;
    }

    getKey(): string {
        return this.path + '/' + this.id;
    }

    getFilterableFields(): Map<string,any> {
        let map = new Map<string, any>();
        map.set('filename', this.FileName);
        map.set('filesize', this.FileSize);
        map.set('filetype', this.FileType);
        map.set('path', this.Path);
        // if(this.tag.size() > 0)
        //     this.tag.forEach(tag => {
        //         map.set('tag', tag.Name);
        //     })
        return map;
    }

    getSortableFields(): Map<string,any> {
        let map = new Map<string, any>();
        map.set('filename', this.FileName);
        map.set('filesize', this.FileSize);
        map.set('filetype', this.FileType);
        map.set('path', this.Path);
        return map;
    }
}

export class DocumentJSON {

    static parseDataToSend(data: any) {
        throw new ErrorResponse('0199')
    }

    static parseSalaryToReceive(data: any){
        let document = new Document()
        document.ApiObject = data;
        document.File = '/ms/api/contract/salary/pdf?salaryId='+data.id+'&enterpriseId='+localStorage.getItem('enterpriseId')+'&type=SALARY'
        document.FileName = data.employeeName;
        document.FileSize = 0
        document.FileType = 'application/pdf'
        document.Date = data.issue_date
        document.Path = ""
        document.Key = data.id ? data.id : ''
        document.Id = data.id ? data.id : ''
        return document;
    }

    static parseDataToReceive(data: any, currentMethod:string, filter: DocumentFilter) {
        let document = new Document()
        let path = filter.fields?.get(DocumentFields.PATH)[0];
        if(path.indexOf(MainFolders.LABORAL) !== -1){
            document.ApiObject = data;
            document.File = '/ms/api/contract/salary/pdf?salaryId='+data.id+'&enterpriseId='+localStorage.getItem('enterpriseId')+'&type=SALARY'
            document.FileName = data.employeeName;
            document.FileSize = 0
            document.FileType = 'application/pdf'
            document.Date = data.issue_date
            document.Path = filter.fields?.get(DocumentFields.PATH)[0]
            document.Key = data.id ? data.id : ''
            document.Id = data.id ? data.id : ''
        }else if(path == MainFolders.PROFORMA || path == MainFolders.PAPELERA){
            document.ApiObject = data;
            document.File = data.file && data.file.path ? '/' + data.file.path : '/ms/api/download_invoice_pdf?json=' + btoa(
                JSON.stringify({
                    "id":data.id,
                    "source":"rawdoc",
                    "domain_id":localStorage.getItem("domainId"),
                    "domain_name":localStorage.getItem("domainName"),
                    "login":localStorage.getItem("login")
                })
            );
            const milliseconds = new Date(data.date).getTime();
            if(data.fileName!= null && data.fileName.includes('.')){
                data.fileName = data.fileName.replace(/\.[^.]*$/, '');
            }
            document.FileName = data.fileName || milliseconds;
            document.FileSize = 0;
            document.FileType = data.file && data.file.content_type ? data.file.content_type : 'application/pdf';
            document.Date = data.date ? data.date : new Date();
            document.Path = filter.fields?.get(DocumentFields.PATH)[0]
            document.Key = data.id ? data.id : '';
            document.Id = data.id ? data.id : '';
        }else if(path == MainFolders.CONTABILIZADO || path == MainFolders.ACONTABILIZAR){
            document.ApiObject = data;
            document.FileType = data.contentType ? data.contentType : 'application/pdf'
            document.File = data.id;
            document.Date = data.date;
            document.Path = filter.fields?.get(DocumentFields.PATH)[0];
            document.FileName = data.reference ? data.reference : '';
        }else if(path == MainFolders.FISCAL){
            document.ApiObject = data;
            // TO DO => que es source? de donde lo obtiene?
            document.File = '/ms/api/attach?attachType=data&file=true&source_id='+data.id+'&source=9'
            document.FileName = data.model + '-' + data.year + '-' + data.period
            document.FileSize = 0
            document.FileType = 'application/pdf'
            document.Date = new Date(data.year + "-" + data.period.replace('T','')*3 + "-1");
            document.Path = filter.fields?.get(DocumentFields.PATH)[0]
            document.Key = data.id ? data.id : '';
            document.Id = data.id ? data.id : '';
        }else if(path == MainFolders.DOCUMENTOS){
            document.ApiObject = data;
            document.FileType = data.file.type ? data.file.type : '';
            document.Path = filter.fields?.get(DocumentFields.PATH)[0]
            document.File = data.file.url ? data.file.url : '';
            document.Key = data.id ? data.id : KeyGenerator.generate(15);
            document.FileName = data.title ? data.title : '';
            document.Id = data.id ? data.id : KeyGenerator.generate(15);
            if(data.date){
                let newDate = data.date.split('/');
                document.Date = new Date(newDate[2]+'-'+newDate[1]+'-'+newDate[0]);
            }
        }
        return document
  }
}