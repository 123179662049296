import { ICollection, IFilter } from "../aon";
import { ICountryAon, IStreetType } from "../interfaces/modelsInterfaces";
import { IApiAddressSpecificMethodsRepository } from "../interfaces/repositoryInterfaces";
import { Address } from "../models/Address";
import { Countries, CountryAon } from "../models/CountryAon";
import { StreetType, streetTypeConst } from "../models/StreetType";
import { Collection } from "../utils/Collection";
import { GenericRepository } from "./GenericRepository";
export class AddressRepository extends GenericRepository<Address> implements IApiAddressSpecificMethodsRepository {

    async getCountryList(): Promise<ICollection<ICountryAon>> {
        let collection = new Collection<ICountryAon>();
        Countries.forEach(element => {
            collection.add(new CountryAon(element.iso2, element.nombre));
        });
        return collection;
    }

    async getStreetTypeList(): Promise<ICollection<IStreetType>> {
        let collection = new Collection<ICountryAon>();
        Object.values(streetTypeConst).forEach(element => {
            collection.add(new StreetType(element.ineCode, element.description));
        })
        return collection;
    }

}