import { ISalaryType } from "../interfaces/modelsInterfaces";
import { Collection } from "../utils/Collection";
import { KeyGenerator } from "../utils/KeyGenerator";
import { ICollection, IModel } from "../interfaces/utilitiesInterfaces"

export class SalaryType implements ISalaryType, IModel {
    private name: string;
    private key: string;
    private apiObject: any;

    constructor(){
        this.name = ''
        this.key = KeyGenerator.generate(15)
        this.apiObject = {}
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    public get Name(): string {
        return this.name;
    }

    public set Name(value: string) {
        this.name = value;
    }

    public get Key(): string {
        return this.key;
    }

    public get ApiObject() : any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public getName(): string {
        return this.name;
    }

    public setName(value: string): SalaryType {
        this.name = value;
        return this;
    }

}

export let collectionSalaryType: ICollection<ISalaryType> = new Collection<SalaryType>();
let salaryType: SalaryType = new SalaryType().setName('Bruto');
salaryType.Key = '1';
let salaryType2: SalaryType = new SalaryType().setName('Neto');
salaryType2.Key = '2';
collectionSalaryType.add(salaryType);
collectionSalaryType.add(salaryType2);