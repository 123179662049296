import { IDocument, MainFolders } from "../interfaces/modelsInterfaces";
import { IDocumentSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { ApiHttpRequest } from "../utils/Http";
import { BASE_URL } from "../utils/Environment";
import { DocumentJSON, Document } from "../models/Document";
import { ICollection } from "../interfaces/utilitiesInterfaces";
import { Collection } from "../utils/Collection";
import { DOCUMENT_URL, INVOICE_URL, SCOPE_URL, TAXMODEL_URL } from "../utils/ApiUrls";
import { ErrorResponse } from "../utils/Response";
import { Base64toBlob, FileToBase64 } from "../utils/FileHelper";
import { GenericRepository } from "./GenericRepository";
import { DocumentFields } from "../utils/ModelsFields";
import { DocumentFilter } from "../utils/ModelsFilters";

export class DocumentRepository extends GenericRepository<Document> implements IDocumentSpecificMethodsRepository, IMultipleObjectCrudRepository<Document>, ISingleObjectCrudRepository<Document> {

    async getCollection(filter: DocumentFilter): Promise<ICollection<Document>> {
        let path: string = filter?.fields?.get(DocumentFields.PATH)[0] || '';
        let collection: ICollection<Document> = new Collection<Document>();
        let url;
        let page = filter?.pageNum || 1;
        let perPage = filter?.pageItems || 200;
        if(path == MainFolders.PROFORMA){
            url = INVOICE_URL.GET_INVOICE_LIST + '?status=inbox&page=' + page + '&per_page=' + perPage
        }else if(path == MainFolders.ACONTABILIZAR){
            url = INVOICE_URL.GET_INVOICE_NEW_PORTAL + '?recorded=pending&page=' + page + '&per_page=' + perPage
        }else if(path == MainFolders.CONTABILIZADO){
            url = INVOICE_URL.GET_INVOICE_NEW_PORTAL + '?recorded=scored&page=' + page + '&per_page=' + perPage
        }else if(path == MainFolders.FISCAL){
            url = TAXMODEL_URL.GET_TAXMODEL_LIST
        }else if(path == MainFolders.PAPELERA){
            url = INVOICE_URL.GET_INVOICE_LIST + '?status=draft&page=' + page + '&per_page=' + perPage
        }else if(path.includes(MainFolders.LABORAL)){
            url = ApiHttpRequest.makeURL(DOCUMENT_URL.SALARY, generateDocumentalFilters(filter)) + "&contract_ids=" + path.split('/')[2];
        }else if(path == MainFolders.DOCUMENTOS){
            url = ApiHttpRequest.makeURL(DOCUMENT_URL.GET_ALL_DOCUMENT, generateDocumentalFilters(filter)); 
        }else{
            throw new ErrorResponse('0305')
        }
        let response = await ApiHttpRequest.get(BASE_URL + url, {}, {});
        response.forEach((element: any) => {
            collection.add(DocumentJSON.parseDataToReceive(element, '', filter));
        })
        let collectionFiltered : ICollection<Document> = new Collection<Document>();
        if(path == MainFolders.FISCAL){
            if(filter)
                collection = collection.filter(filter);
            collection.forEach((element: any) => {
                if(element.apiObject.status == 'SENT' || element.apiObject.status == 'BATCHED'){
                    collectionFiltered.add(element);
               }
            })      
            collection = collectionFiltered.paginate(page, perPage);
        }
        return collection;
    }

    async uploadDocument(document: IDocument, file: File): Promise<boolean> {
        // old upload to rawdoc, proforma, etc
        // let json = {
        //     file: {
        //         content: await FileToBase64(file),
        //         contentEncoding: "base64",
        //         contentName: document.FileName,
        //         contentSize: file.size,
        //         contentType: file.type,
        //         date: new Date(),
        //         domain: {id: localStorage.getItem("domainId"), name: localStorage.getItem("domainName")},
        //         name: document.FileName,
        //         size: file.size,
        //     },
        //     invoice: {
        //         category: "",
        //         comments: "",
        //         creation_user: localStorage.getItem("login"),
        //         date: new Date(),
        //         details: [],
        //         domain: localStorage.getItem("domainId"),
        //         finances: [],
        //         number: "",
        //         fileName: document.FileName,
        //         receiver: {document: "", name: "", address: {country: "", address: "", zip: "", city: "", province: ""}},
        //         address: {country: "", address: "", zip: "", city: "", province: ""},
        //         reference: "",
        //         remarks: [],
        //         selfconta: false,
        //         sender: {document: "", name: "", address: {country: "", address: "", zip: "", city: "", province: ""}},
        //         serie: new Date().getFullYear(),
        //         series: new Date().getFullYear(),
        //         status: "inbox",
        //         suplidos: {active: false, description: "", total: 0},
        //         taxes: [],
        //         tbai: false,
        //         tbaiUrl: "",
        //         total: 0,
        //         transaction: "NAC",
        //         type: "recibida",
        //         withholding: false,
        //     }
        // }
        // let response = await ApiHttpRequest.post(BASE_URL + INVOICE_URL.GET_INVOICE__ONE, {}, json)
        // if(response?.type == 'error') return false; 
        // else return true;
        let scopesResp = await ApiHttpRequest.get(BASE_URL + SCOPE_URL.GET_SCOPE_LIST, {}, {});
        let scope = null;
        if(scopesResp && scopesResp.length && scopesResp.length > 0){
            scopesResp.forEach((element: any) => {
                if(element.name && typeof element.name == 'string' && element.name.toUpperCase() == 'GENERAL' 
                || element.description && typeof element.description == 'string' && element.description.toUpperCase() == 'GENERAL'){
                    scope = element.id ? element.id : null;
                }
            });
        }
        let base64 = await FileToBase64(file)
        let json = {
            "domain":
                {
                    "id": localStorage.getItem("domainId"),
                    "name": localStorage.getItem("domainName"),
                },
            "name": document.FileName,
            "size": file.size,
            "scope": scope,
            "type": "enterprise", // TO DO enterprise de momento pero en un futuro cuando pueda subir un empleado seran tipo empleado
            "date": new Date().toISOString(),
            "content": base64,
            "contentType": file.type,
            "contentName": document.FileName,
            "contentEncoding": "base64",
            "contentSize": file.size,
            "category": null,
            "tag": null
        }
        let options = new Object();
        Object.defineProperty(options,'method',{value: "POST"});
        Object.defineProperty(options,'body',{value: JSON.stringify(json)});
        let response = await fetch(BASE_URL + DOCUMENT_URL.UPLOAD_DOCUMENT + '/' + 
            localStorage.getItem('domainName') + '/' + localStorage.getItem('login'), options);
        let result = await response.json();
        if((result?.type! == 'error')){
            throw new ErrorResponse('0301',  result.json().error ? result.error : 'Error');
        }else{
            return true;
        }
    }

    async getRawFile(document: IDocument): Promise<any> {
        if(document.File != ''){
            let url = document.File
            if(typeof document.File == 'number' || document.Path == MainFolders.ACONTABILIZAR || document.Path == MainFolders.CONTABILIZADO){
                let response = await ApiHttpRequest.get(BASE_URL + INVOICE_URL.GET_INVOICE__ONE + '?id=' + document.File, {}, {});
                url = response?.file?.path ? response.file.path : '';
            }if(document.Path == MainFolders.FISCAL){
                let response = await ApiHttpRequest.get(BASE_URL + DOCUMENT_URL.ATTACH + '?attachType=data&file=true&source_id=' + document.getKey().split('/')[2], {}, {});
                if(response.content){
                    return Base64toBlob(response.content, document.FileType)
                }
            }
            if(document.Path == MainFolders.DOCUMENTOS){
                let response = await fetch(BASE_URL + '/' + url);
                return response.blob();
            }
            return await ApiHttpRequest.httpRequestFile(BASE_URL + url, {}, {});
        }else{
            throw new ErrorResponse('0304')
        }
    }

    async getCompressedFileList(documents: Document[]): Promise<any> {
        if(documents.length > 500){
            throw new ErrorResponse('1737')
        }
        let keys: string[] = [];
        let notDocumentKeys: IDocument[] = [];
        documents.forEach(element => {
        if (!element.getKey().includes(MainFolders.DOCUMENTOS))
            notDocumentKeys.push(element);
        else 
            keys.push(element.getKey().split('/')[2]);
        });
        if(keys.length > 0){
            const jsonData = {
                "domainId": localStorage.getItem("domainId"),
                "domainName": localStorage.getItem("domainName"),
                "domainLogin": localStorage.getItem("login"),
                "ids": keys,
            }
            const base64Json = btoa(JSON.stringify(jsonData));
            const a = document.createElement('a')
            a.href = BASE_URL + DOCUMENT_URL.MULTIPLE_DOWNLOAD + '/document?json='+base64Json
            a.download ='a';
            a.click();
            URL.revokeObjectURL(a.href);
            return true;
        } else if (notDocumentKeys.length > 0){
            for(const element of notDocumentKeys){
                let response = await this.getRawFile(element);
                const blobUrl = URL.createObjectURL(response);
                const a = document.createElement('a')
                a.href = blobUrl
                a.download = element.FileName;
                a.click();
                URL.revokeObjectURL(blobUrl);
            }
        }
    }

    async getDocumentalCount(filter : DocumentFilter): Promise<number> {
        let url;
        let path: string = filter?.fields?.get(DocumentFields.PATH)[0] || '';
        
        if(path == MainFolders.FISCAL){
            return await this.getFiscalModelsCount(filter);
        }else if(path.includes(MainFolders.LABORAL)){
            return await this.getEmployeeSalaryCount(filter);
        }else{
            url = ApiHttpRequest.makeURL(DOCUMENT_URL.DOCUMENT_COUNT, generateDocumentalFilters(filter)); 
            return await ApiHttpRequest.get(BASE_URL + url, {}, {});
        }
    }

    /**
    * Recupera el total de modelos de la empresa
    *
    * @param {ContractFilter} filter - El filtro de búsqueda
    * @return {Promise<number>} Una promesa que se resuelve con el conteo de modelos
    */
    async getFiscalModelsCount(filter: DocumentFilter): Promise<number> {
        let collection: ICollection<Document> = new Collection<Document>();
        let response = await ApiHttpRequest.get(BASE_URL + TAXMODEL_URL.GET_TAXMODEL_LIST,{}, {});
        response.forEach((element: any) => {
            collection.add(DocumentJSON.parseDataToReceive(element, '', filter));
        })
        let path: string = filter?.fields?.get(DocumentFields.PATH)[0] || '';
        let collectionFiltered : ICollection<Document> = new Collection<Document>();
        if(path == MainFolders.FISCAL){
            if(filter){
                collection = collection.filter(filter);
            }
            collection.forEach((element: any) => {
                if(element.apiObject.status == 'SENT' || element.apiObject.status == 'BATCHED'){
                    collectionFiltered.add(element);
                }
            })      
        }
        return collectionFiltered.size();
    }

    /**
    * Recupera el total de nominas de un trabajador
    *
    * @param {ContractFilter} filter - El filtro de búsqueda
    * @return {Promise<number>} Una promesa que se resuelve con el conteo de nominas
    */
    async getEmployeeSalaryCount(filter: DocumentFilter): Promise<number> {
        let params = generateCountFilter(filter);
        let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(DOCUMENT_URL.SALARY_COUNT, params),{}, {});
        return response;
    }

    async getSalaryByDocument(filter: DocumentFilter): Promise<ICollection<IDocument>> {
        let collection = new Collection<IDocument>();
        let url = ApiHttpRequest.makeURL(DOCUMENT_URL.SALARY, generateDocumentalFilters(filter));
        let response = await ApiHttpRequest.get(BASE_URL + url, {}, {});
        if(response && response.length > 0)
            response.forEach((element: any) => {
                collection.add(DocumentJSON.parseSalaryToReceive(element));
            })
        return collection;
    }

    async countSalaryByDocument(filter: DocumentFilter): Promise<number> {
        let response = await ApiHttpRequest.get(BASE_URL + ApiHttpRequest.makeURL(DOCUMENT_URL.SALARY_COUNT, generateDocumentalFilters(filter)),{}, {});
        return response;
    }
    
}

let generateDocumentalFilters = (filter?: DocumentFilter) => {
    let page = filter?.pageNum || 1;
    let perPage = filter?.pageItems || 200;
    let global = filter?.fields?.get(DocumentFields.GLOBAL) || '';
    let params = {
        type: 'all',
        page: page,
        per_page: perPage,
        domain: localStorage.getItem("domainId")
    }
    if(global){
        Object.defineProperty(params, "global", {
            value: global,
            enumerable: true
        });
    }
    if(filter?.fields?.has(DocumentFields.EMPLOYEE_DOCUMENT)){
        Object.defineProperty(params, "document", {
            value: filter?.fields?.get(DocumentFields.EMPLOYEE_DOCUMENT),
            enumerable: true
        });
    }
    return params;
}

let generateCountFilter = (filter?: DocumentFilter) => {
    let path: string = filter?.fields?.get(DocumentFields.PATH)[0] || '';
    let contractId =  path.split('/')[2];
    let global = filter?.fields?.get(DocumentFields.GLOBAL) || '';
    let json = {}
    if(contractId != '')
        Object.defineProperty(json, 'contract_ids', {
            value: contractId,
            enumerable: true
        });
    if(global)
        Object.defineProperty(json, "global", {
            value: global,
            enumerable: true
        });
    if(document)
        Object.defineProperty(json, "document", {
            value: document,
            enumerable: true
        });
    return json;
}