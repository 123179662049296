import { IIRPF } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class IRPF extends SimpleValue implements IIRPF, IModel {
    private type: string;
    private percentage: number;
    private quota: number;
    private base: number;

    public get Percentage(): number {
      return this.percentage;
    }

    public get Quota(): number {
      return this.quota;
    }

    public get Base(): number {
      return this.base;
    }

    public set Percentage(value: number) {
      this.percentage = value;
    }
    
    public set Quota(value: number) {
      this.quota = value;
    }

    public set Base(value: number) {
      this.base = value;
    }

    constructor(value?: string, type?: string) {
        super(value);
        this.type = type || '';
        this.percentage = 0;
        this.quota = 0;
        this.base = 0;
    }
  
    getType(): string {
        return this.type
    }
    setType(value: string): IIRPF {
        this.type = value;
        return this
    }

    public get Type() {
        return this.type;
    }

    public set Type(value: string) {
        this.type = value;
    }

    getFilterableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('value', this.value);
        map.set('type', this.type);
        return map;
    }

    getSortableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('value', this.value);
        map.set('type', this.type);
        return map;
    }
}

export class IRPFJSON {
    static parseDataToReceive(data: any, isFullKey: boolean): IRPF {
        if(isFullKey){
            let irpf = new IRPF();
            irpf.ApiObject = data;
            irpf.Value = data.withholding_type;
            irpf.Type = data.name + " - " + data.description;
            irpf.Key = data.withholding_type + data.percentage;
            irpf.Percentage = data.percentage;
            return irpf;
        }else {
            let irpf = new IRPF();
            if(data.percentage){
                irpf.ApiObject = data;
                irpf.Value = data.withholding_type;
                irpf.Type = data.percentage + '%';
                irpf.Key = data.percentage.toString();
                irpf.Percentage = data.percentage;
                return irpf;
            }else {
                irpf.Key = '-';
                return irpf;
            }
        }
    }
}

export const IRPFType = [
    {
      id: '',
      name: "0%",
      description: "0%",
      percentage: 0.0
    },
    {
      id: 'professional',
      name: "Profesional (G/01) - 15%",
      description: "Actividades profesionales con carácter general.",
      percentage: 15.0
    },
    {
      id: 'renting',
      name: "Arrendamiento - 19%",
      description: "Arrendamiento o subarrendamiento de bienes inmuebles urbanos.",
      percentage: 19.0
    },
     {
      id: 'movableCapital',
      name: "Cap. Mobiliario (A) - 19%",
      description: "Derivados de la participación en fondos propios de entidades.",
      percentage: 19.0
    },
     {
      id: 'farmer',
      name: "Agrícola (H/01) - 2%",
      description: "Actividades agrícolas y ganaderas en general.",
      percentage: 2.0
    },
     {
      id: 'transportOperator',
      name: "Est. Obj. (H/04) - 1%",
      description: "Determinadas actividades empresariales en Estimación Objetiva.",
      percentage: 1.0
    },
    {
      id: 'M190_G_02',
      name: "Profesional (G/02) - 7%",
      description: "Determinadas actividades profesionales (recaudadores municipales, mediadores de seguros ...)",
      percentage: 7.0
    },
    {
      id: 'M190_G_03',
      name: "Profesional (G/03) - 7%",
      description: "Profesionales de nuevo inicio.",
      percentage: 7.0
    },
    {
      id: 'M190_H_02',
      name: "Agrícola (H/02) - 1%",
      description: "Actividades de engorde de porcino y avicultura.",
      percentage: 1.0
    },
    {
      id: 'M190_H_03',
      name: "Forestales (H/03) - 2%",
      description: "Actividades forestales.",
      percentage: 2.0
    },
    {
      id: 'M190_I_01',
      name: "Der.Imagen (I/01) - 24%",
      description: "Rendimientos del art. 75.2.b): cesión derecho de imagen",
      percentage: 24.0
    },
     {
      id: 'M190_I_02',
      name: "Otras (I/02) - 19%",
      description: "Rendimientos del art. 75.2.b): resto de conceptos",
      percentage: 19.0
    },
     {
      id: 'M190_J',
      name: "Cesión Der. Imagen(J) - 19%",
      description: "Imputación rentas por cesión derechos imagen",
      percentage: 19.0
    },
     {
      id: 'M190_K_01',
      name: "Premios (K/01) - 19%",
      description: "Premios de juegos, concursos, rifas... sujetos a retención, (K - 01)",
      percentage: 19.0
    },
     {
      id: 'M190_K_03',
      name: "Premios (K/03) - 19%",
      description: "Premios de juegos, concursos, rifas... sujetos a retención, (K - 03)",
      percentage: 19.0
    },
     {
      id: 'M190_K_02',
      name: "Gan. forestal (K/02) - 19%",
      description: "Aprovechamientos forestales en montes públicos.",
      percentage: 19.0
    },
     {
      id: 'M193_C1',
      name: "Cap. Mobiliario (C/1) - 19%",
      description: "Propiedad intelectual, industrial, prestación de asistencia técnica.",
      percentage: 19.0
    },
     {
      id: 'M193_C2',
      name: "Cap. Mobiliario (C/2) - 15%",
      description: "Propiedad intelectual cuando el contribuyente perceptor no sea el autor.",
      percentage: 15.0
    },
     {
      id: 'M193_C3',
      name: "Cap. Mobiliario (C/3) - 24%",
      description: "Rendimientos derivados de la cesión del derecho de explotación de derechos de imagen siempre que no sean en el desarrollo de una actividad económica",
      percentage: 24.0
    },
     {
      id: 'M190_F_01',
      name: "Trabajo (F/01) - 15%",
      description: "Premios literarios, artísticos o científicos no exentos de IRPF, cuando tengan la consideración de rendimientos del trabajo.",
      percentage: 15.0
    },
    {
      id: 'M190_F_02_1',
      name: "Cursos (F/02) - 15%",
      description: "Cursos, conferencias, seminarios.",
      percentage: 15.0
    },
    {
      id: 'M190_F_02_2',
      name: "Elab. obras (F/02) - 15%",
      description: "Elaboración de obras literarias, artísticas o científicas.",
      percentage: 15.0
    }
  ];