import { Component, OnInit } from '@angular/core';
import * as myGlobals from './app.globals';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { DomainService } from './core/services/domain.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
  selector    : 'app-root',
  templateUrl : './app.component.html',
  styleUrls   : ['./app.component.scss']
})

export class AppComponent implements OnInit {
  favIcon  : HTMLLinkElement  | null = document.querySelector('#favIcon');
  aonTitle : HTMLTitleElement | null = document.querySelector('#aonTitle');
  isLoading: boolean = false;

  constructor(
    private translateService: TranslateService,
    private matIconRegistry : MatIconRegistry,
    private domSanitizer    : DomSanitizer,
    public domainService    : DomainService,
    private spinnerService  : SpinnerService
  ) {
    if(this.favIcon)
      this.favIcon.href = domainService.getFavicon();
    if(this.aonTitle)
      this.aonTitle.innerText = domainService.getTitle();
    /*
      Idioma
    */
      if(localStorage.getItem('selectedLanguage') === null){
        // Lenguaje predefinido en el estorage
        localStorage.setItem('selectedLanguage', myGlobals.predefinedLanguage);
      }
      // Cargamos el almacenado en storage
      this.translateService.setDefaultLang(localStorage.getItem('selectedLanguage') as string);
      this.translateService.use(localStorage.getItem('selectedLanguage') as string);
    /*
      FIN idioma
    */

    /*
      Add icons
    */
      Object.entries(myGlobals.aon_add_icon).forEach(([key, value]) => {
        matIconRegistry.addSvgIconLiteral(
          key, domSanitizer.bypassSecurityTrustHtml( value )
        );
      });
    /*
      FIN add icons
    */
  }
  
  ngOnInit(): void {
    // Para usar de manera global
    this.spinnerService.isLoading.subscribe(loading => this.isLoading = loading);
  }

  // Modificamos el lenguaje y recargamos
  selectLanguage(language: string) {
    localStorage.setItem('selectedLanguage', language);
    location.reload();
  }

}
