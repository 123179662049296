import { Injectable } from '@angular/core';
import { CollectionFactory, Factory, FilterFactory } from 'libraries/AonSDK/src/aon';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public objectFactory = Factory;
  public collectionFactory = CollectionFactory;
  public filterFactory = FilterFactory;

  constructor() { }
}
