<div
  class="contentHeaderContainer"
  [ngStyle]="{'height': 'calc(' + heightHeader + ')'}"
>
  <div
    class="contentHeaderContainerMain"
    [ngStyle]="{'padding': padding }"
  >
    <ng-content></ng-content>
  </div>
  <mat-divider></mat-divider>
</div>