import { IWorkerCollective } from "../aon";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class WorkerCollective extends SimpleValue implements IWorkerCollective, IModel {}

export class WorkerCollectiveJSON {
    static parseDataToReceive(data: any){
        let workerCollective = new WorkerCollective();
        workerCollective.ApiObject = data;
        workerCollective.Key = data.value ? data.value : "";
        workerCollective.Value = data.name ? data.name : "";
        return workerCollective;
    }
}

export const getWorkersCollective = [
    { name: "Producción", value: "967"},
    { name: "Previsibles", value: "968"}
];