import { IQuoteGroup } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class QuoteGroup implements IQuoteGroup, IModel {
    private key: string;
    private apiObject: any;
    private name: string;
    private quoteMonth: boolean;

    constructor(){
        this.key = KeyGenerator.generate(15);
        this.apiObject = {};
        this.name = '';
        this.quoteMonth = false;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    public get Name(): string {
        return this.name;
    }

    public set Name(value: string) {
        this.name = value;
    }

    getName(): string {
        return this.name;
    }

    setName(value: string): IQuoteGroup {
        this.name = value
        return this
    }

    public get QuoteMonth(): boolean {
        return this.quoteMonth;
    }

    public set QuoteMonth(value: boolean) {
        this.quoteMonth = value;
    }
    
    getQuoteMonth(): boolean {
        return this.quoteMonth
    }

    setQuoteMonth(value: boolean): IQuoteGroup {
        this.quoteMonth = value
        return this
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class QuoteGroupJSON {
    static parseDataToReceive(data: any): QuoteGroup{
        let quoteGroup = new QuoteGroup();
        quoteGroup.Key = data.value
        quoteGroup.Name = data.name
        quoteGroup.ApiObject = data
        return quoteGroup
    }
}