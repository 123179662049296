import { ICollection, IFilter } from "../interfaces/utilitiesInterfaces";
import { ITaxModel, IBank, ITaxModelDetail } from "../interfaces/modelsInterfaces";
import { IMultipleObjectCrudRepository, ISingleObjectCrudRepository, ITaxModelSpecificMethodsRepository } from "../interfaces/repositoryInterfaces";
import { TaxModel, TaxModelJSON } from "../models/TaxModel";
import { DOCUMENT_URL, TAXMODEL_URL } from "../utils/ApiUrls";
import { Collection } from "../utils/Collection";
import { BASE_URL } from "../utils/Environment";
import { ApiHttpRequest } from "../utils/Http";
import { Base64toBlob } from "../utils/FileHelper";
import { GenericRepository } from "./GenericRepository";
import { ErrorResponse } from "../utils/Response";
import { TaxModelDetailJSON } from "../models/TaxModelDetail";

export class TaxModelRepository extends GenericRepository<TaxModel> implements ITaxModelSpecificMethodsRepository, IMultipleObjectCrudRepository<TaxModel>, ISingleObjectCrudRepository<TaxModel> {
    async get(key: string): Promise<TaxModel> {
        let id = key.split(';')[0];
        let type = key.split(';')[1];
        let response = await ApiHttpRequest.get(BASE_URL + TAXMODEL_URL.GET_TAXMODEL + '?id=' + id + '&type=' + type, {}, {});
        return TaxModelJSON.parseDataToReceive(response);
    }
    
    async getTaxModelDetails(key: string): Promise<ICollection<ITaxModelDetail>>{
        let id = key.split(';')[0];
        let type = key.split(';')[1];
        let collection = new Collection<ITaxModelDetail>();
        let response = await ApiHttpRequest.get(BASE_URL + TAXMODEL_URL.GET_TAXMODEL_DETAIL + '?id=' + id + '&type=' + type, {}, {});
        response.forEach((element: any) => {
            collection.add(TaxModelDetailJSON.parseDataToReceive(element));
        })
        return collection;
    }

    async getCollection(filter?: IFilter): Promise<ICollection<TaxModel>> {
        let response = await ApiHttpRequest.get(BASE_URL + TAXMODEL_URL.GET_TAXMODEL_LIST, {}, {});
        let collection: ICollection<TaxModel> = new Collection<TaxModel>();
        response.forEach((element: any) => {
            collection.add(TaxModelJSON.parseDataToReceive(element))
        })
        if(collection.size() > 0){
            if(filter?.intervalFields || filter?.fields) collection = collection.filter(filter);
            if(filter?.orderBy) collection.sort(filter);
            if(filter?.pageItems && filter.pageNum) collection = collection.paginate(filter.pageNum,filter.pageItems);
        }
        return collection;
    }

    async rejectModel(model : TaxModel, reason: String): Promise<boolean> {
        let json = model.ApiObject;
        Object.defineProperty(json, 'reasonReject', {
            value: reason,
            enumerable: true,
            writable: false
        })
        let response = await ApiHttpRequest.post(BASE_URL + TAXMODEL_URL.PAY_TAXMODEL, {}, json);
        if(response) return true;
        else throw new ErrorResponse('0603')
    }
    async payTaxModelWithNRC(model: TaxModel, nrc: string): Promise<boolean> {
        let json = model.ApiObject;
        Object.defineProperty(json, 'nrc', {
            value: nrc,
            enumerable: true,
            writable: false
        })
            let response = await ApiHttpRequest.post(BASE_URL + TAXMODEL_URL.PAY_TAXMODEL, {}, json);
            if(response) return true;        
            else throw new ErrorResponse('0602')       
    }        

    async payTaxModelWithBank(model: TaxModel, bank: IBank): Promise<boolean> {
        let json = model.ApiObject;
        Object.defineProperty(json, 'iban', {
            value: bank.Iban,
            enumerable: true,
            writable: false
        })
        if(await this.dateControlTaxModel(model)){
          let response = await ApiHttpRequest.post(BASE_URL + TAXMODEL_URL.PAY_TAXMODEL, {}, json);
          if(response)
            return true;
          else 
            throw new ErrorResponse('0602')
        } else {
          throw new ErrorResponse('0608')
        }
    }
    
    async downloadPDFTaxModel(model: ITaxModel): Promise<Blob> {
        let response = await ApiHttpRequest.get(BASE_URL + DOCUMENT_URL.ATTACH + '?attachType=data&file=true&source_id=' + model.getKey().split(';')[0], {}, {});
        if(response.content){
            return Base64toBlob(response.content, 'application/pdf');
        }else {
            throw new ErrorResponse('0604')
        }
    }

    async downloadExcelTaxModel(model: ITaxModel): Promise<Blob> {
        let params = {
            'id': model.getKey().split(';')[0],
            'type': model.Name,
            'url': BASE_URL
        }
        let response: Blob = await ApiHttpRequest.httpRequestFile(ApiHttpRequest.makeURL(BASE_URL + TAXMODEL_URL.EXCEL, params), {}, {});
        if(response){
            return response;
        }else{
            throw new ErrorResponse('0605')
        }
    }
    //Esta función realiza la verificación de fechas
    async dateControlTaxModel(model: TaxModel): Promise<boolean> {
        enum QUARTERS {
            FIRST_QUARTER   = 1,
            SECOND_QUARTER  = 2,
            THIRD_QUARTER   = 3,
            FOURTH_QUARTER  = 4
        };

        if(model && model.Year && model.Trimester){
            let genericDates: { [trimestre: number]: { date: string } } = {
                [QUARTERS.FIRST_QUARTER] : { date: model.Year+'-04-16T13:00:00' },
                [QUARTERS.SECOND_QUARTER]: { date: model.Year+'-07-17T13:00:00' },
                [QUARTERS.THIRD_QUARTER] : { date: model.Year+'-10-16T13:00:00' },
                [QUARTERS.FOURTH_QUARTER]: { date: (model.Year + 1)+'-01-24T13:00:00' } 
            };

            let specificDates: { [year: number]: { [trimestre: number]: {date: string} } } = {
                2024: {
                    [QUARTERS.FIRST_QUARTER] : { date: '2024-04-16T13:00:00' },
                    [QUARTERS.SECOND_QUARTER]: { date: '2024-07-17T13:00:00' },
                    [QUARTERS.THIRD_QUARTER] : { date: '2024-10-16T13:00:00' },
                    [QUARTERS.FOURTH_QUARTER]: { date: '2025-01-25T13:00:00' } 
                } 
            };

            let year            = model.Year;
            let trimester       = model.Trimester;
            let actualDate      = new Date();
            let dateExpiration  = specificDates[year] ? new Date(specificDates[year][trimester].date) :  new Date(genericDates[trimester].date);

            // Si la fecha actual es menor a la fecha de expiración
            if (actualDate.getTime() < dateExpiration.getTime()){
                return true;
            }
        }
        return false;
    }
}