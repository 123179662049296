import { ICollection, IFilter, IResponse } from "../interfaces/utilitiesInterfaces";
import { IContactSpecificMethods } from "../interfaces/serviceInterfaces";
import { ErrorResponse, Response } from "../utils/Response";
import { IAddress } from "../interfaces/modelsInterfaces";
import { IContactSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { Contact } from "../models/Contact";
import { GenericService } from "./GenericCrudService";
import { ContactFilter } from "../utils/ModelsFilters";

export class ContactService extends GenericService<Contact, ContactFilter> implements IContactSpecificMethods {
    protected SpecificMethodsRepository: IContactSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<Contact> & ISingleObjectCrudRepository<Contact>, type: { new (): Contact }, SpecificMethodsRepository: IContactSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    async getContactFullAddress(key: string): Promise<IResponse<ICollection<IAddress>>> {
        try{
            return new Response<ICollection<IAddress>>(await this.SpecificMethodsRepository.getContactFullAddress(key));
        }catch(error){
            throw error instanceof ErrorResponse ? error : new ErrorResponse('1003');
        }
    }

    async countContacts(filter?: IFilter): Promise<IResponse<number>>{
        try{
            return new Response<number>(await this.SpecificMethodsRepository.countContacts(filter));
        }catch(error){
            throw error instanceof ErrorResponse ? error : new ErrorResponse('0123', 'Contact');
        }
    }

}
