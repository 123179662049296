import { IReportingRepository } from "../interfaces/repositoryInterfaces";
import { ApiHttpRequest } from "../utils/Http";
import { BASE_URL } from "../utils/Environment";
import { ACCOUNTING_URL, REPORTING_URL } from "../utils/ApiUrls";

export class ApiReportingRepository implements IReportingRepository {

    private httpRequest = new ApiHttpRequest();

    async cobrosPagos(from: Date, to: Date): Promise<any> {
        let json = await ApiHttpRequest.get(BASE_URL + REPORTING_URL.FINANCE + '?from='+from.toISOString()+'&to='+to.toISOString(), {}, {})
        /** PARSE JSON TO CHARTS.JS LIBRARY => MAYBE THIS WILL BE DO IT IN THE FUTURE IN ANGULAR PROJECT SERVICE */
        let keys = json.data;
        let datasets: any[] = [], ventas: any[] = [], gastos: any[] = [], fechas: any[] = [];
        for(let i = 0; i < keys.length; i++){
            fechas.push(keys[i].date)
            ventas.push(keys[i].pago ? keys[i].pago : 0)
            gastos.push(keys[i].cobro ? keys[i].cobro : 0)
        }
        datasets.push({data:gastos, label:'COLLECTIONS'})
        datasets.push({data:ventas, label:'PAYMENTS'})
        return { datasets: datasets, label: fechas };
    }

    async ventasGastos(from: Date): Promise<any> {
        let to: Date = new Date();
        let json = await ApiHttpRequest.get(BASE_URL + REPORTING_URL.INVOICE + '?from='+from.toISOString() + '&to='+to.toISOString(), {}, {})
        /** PARSE JSON TO CHARTS.JS LIBRARY => MAYBE THIS WILL BE DO IT IN THE FUTURE IN ANGULAR PROJECT SERVICE */
        let keys = json.data;
        let datasets: any[] = [], ventas: any[] = [], gastos: any[] = [], fechas: any[] = [];
        for(let i = 0; i < keys.length; i++){
            fechas.push(keys[i].date)
            ventas.push(keys[i].Ventas ? keys[i].Ventas : 0)
            gastos.push(
                (keys[i].Gastos ? keys[i].Gastos : 0)
                +
                (keys[i].Compras ? keys[i].Compras : 0)
                +
                (keys[i]["Gt.NO Ded"] ? keys[i]["Gt.NO Ded"] : 0)
            )
        }
        datasets.push({data:ventas, label:'SALES'})
        datasets.push({data:gastos, label:'BILLS'})
        return { datasets: datasets, label: fechas };
    }
}



export class AccountingRepository {

    async getPeriods(): Promise<any> {
        const jsonData = {
            "domain": localStorage.getItem("domainId"),
            "domainName": localStorage.getItem("domainName"),
            "user": '',
            "level": 5,
            "byMonth": true
        }
        let json = await ApiHttpRequest.post(BASE_URL + ACCOUNTING_URL.GET_PERIODS, {}, jsonData)
        return json
    }

    async getPyg(periodFilter?: string , exerciseFilter?: string): Promise<any> {
        const jsonPeriod = await this.getPeriods();
        interface Statement {
            credit: any;
            debit: any;
            month: any;
            account?: {
                code: string;
                id: string;
                type: string;
            };
        } 

        if(periodFilter == null || exerciseFilter == null) {
            periodFilter = 'Yearly';
            exerciseFilter = jsonPeriod[0].name;        
        }
        
        jsonPeriod.forEach((element: any) => {
            if(element.name == exerciseFilter) localStorage.setItem("period", element.id);
        })
   
        const jsonData = {
            "domainId": localStorage.getItem("domainId"),
            "domainName": localStorage.getItem("domainName"),
            "login": localStorage.getItem("login"),
            "level": 5,
            "byMonth": true,
            "period": localStorage.getItem("period"),
            "fromDate": localStorage.getItem("fromDate"),
            "toDate": localStorage.getItem("toDate"),
        }
        let json = await ApiHttpRequest.post(BASE_URL + ACCOUNTING_URL.GET_PYG, {}, jsonData)

        let statements = json.intervals.map((obj: any) => obj.statements);
           
        if (periodFilter == 'Yearly') {
        //Ingresos
        const filteredStatementsIncome : Statement[] =[];
        statements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && statement.account.code.substring(0, 1) == "7") {
                filteredStatementsIncome.push(statement);
                }
            });
        });
        let totalIncome= filteredStatementsIncome.length != 0 ? filteredStatementsIncome.map((a) => a.credit - a.debit).reduce((a, b) => a + b) : 0;
        totalIncome = (Math.round(totalIncome * 100) / 100)/2;

        //Compras
        const filteredStatementsPurchases : Statement[] = [];
        statements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 60 && Number.parseInt(statement.account.code.substring(0, 2)) <= 61 ) {
                    filteredStatementsPurchases.push(statement);
                }
            });
        });
        let totalPurchase = filteredStatementsPurchases.length != 0 ? filteredStatementsPurchases.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        totalPurchase = (Math.round(totalPurchase * 100) / 100)/2;
                
        //Gastos
        const filteredStatementsOutGoings : Statement [] = [];
            statements.forEach((statementGroup: Statement[]) => {
                statementGroup.forEach((statement: Statement) => {
                    if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 62 && Number.parseInt(statement.account.code.substring(0, 2)) <= 67 ) {
                        filteredStatementsOutGoings.push(statement)
                    }
                });
            });
        let totalOutGoings = filteredStatementsOutGoings.length != 0 ? filteredStatementsOutGoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b): 0;
        totalOutGoings = (Math.round(totalOutGoings * 100) / 100)/2;

        //Rdo bruto
        let raw = totalIncome - totalPurchase - totalOutGoings;
        raw = Math.round(raw * 100) / 100

        //Amortizaciones 
        const filteredStatementsAmortizations : Statement [] = [];
            statements.forEach((statementGroup: Statement[]) => {
                statementGroup.forEach((statement: Statement) => {
                    if (statement.account && (statement.account.code.substring(0, 2)) == "68" ) {
                        filteredStatementsAmortizations.push(statement)
                    }
                });
            });
        let totalAmortization = filteredStatementsAmortizations.length != 0 ? filteredStatementsAmortizations.map((a) => a.debit - a.credit).reduce((a, b) => a + b): 0;
        totalAmortization = (Math.round(totalAmortization * 100) / 100)/2;

        //Otros gastos
        const filteredStatementsOtherOutgoings : Statement [] = [];
            statements.forEach((statementGroup: Statement[]) => {
             statementGroup.forEach((statement: Statement) => {
                if (statement.account && (statement.account.code.substring(0, 2)) == "69" ) {
                    filteredStatementsOtherOutgoings.push(statement)
                }
            });
        });
        let totalOtherOutGoings = filteredStatementsOtherOutgoings.length != 0 ? filteredStatementsOtherOutgoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b): 0;
        totalOtherOutGoings = (Math.round(totalOtherOutGoings * 100) / 100)/2;

        //Liquido
        let liquid = raw - totalOtherOutGoings - totalAmortization;
        liquid = (Math.round(liquid * 100) / 100)/2;

        let datasets: any[] = [], ingresos: any[] = [], compras:any[] = [], gastos: any[] = [],otrosGastos :any[] = [], rdoBruto: any[] = [], amortizaciones: any[] = [], rdoNeto: any[] = [];

        ingresos.push(totalIncome);
        compras.push(totalPurchase);
        gastos.push(totalOutGoings);
        otrosGastos.push(totalOtherOutGoings);
        rdoBruto.push(raw);
        amortizaciones.push(totalAmortization);
        rdoNeto.push(liquid);

        datasets.push({data:ingresos, label:'INGRESOS'})
        datasets.push({data:compras, label:'COMPRAS'})
        datasets.push({data:gastos, label:'GASTOS'})
        datasets.push({data:otrosGastos, label:'OTROS GASTOS'})
        datasets.push({data:rdoBruto, label:'RDO BRUTO'})
        datasets.push({data:amortizaciones, label:'AMORTIZACIONES'})
        datasets.push({data:rdoNeto, label:'RDO NETO'})

        return datasets;

        }else if(periodFilter == 'Quarterly') {
        
        const firstQuarter : Statement[] = [];
        const secondQuarter : Statement[] = [];
        const thirthQuarter : Statement[] = [];
        const fourthQuarter : Statement[] = [];

        let firstTrStatements = json.intervals.filter((obj: any) => obj.interval.name === '01/2023' || obj.interval.name === '02/2023' || obj.interval.name === '03/2023');
        firstTrStatements = firstTrStatements.map((obj: any) => obj.statements);

        let secondTrStatements = json.intervals.filter((obj: any) => obj.interval.name === '04/2023' || obj.interval.name === '05/2023' || obj.interval.name === '06/2023');
        secondTrStatements = secondTrStatements.map((obj: any) => obj.statements);

        let thirdTrStatements = json.intervals.filter((obj: any) => obj.interval.name === '07/2023' || obj.interval.name === '08/2023' || obj.interval.name === '09/2023');
        thirdTrStatements = thirdTrStatements.map((obj: any) => obj.statements);

        let fourthTrStatements = json.intervals.filter((obj: any) => obj.interval.name === '10/2023' || obj.interval.name === '11/2023' || obj.interval.name === '12/2023');
        fourthTrStatements = fourthTrStatements.map((obj: any) => obj.statements);

        firstTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && statement.account.code.substring(0, 1) == "7") {
                    firstQuarter.push(statement);
                }
            });
        })

        secondTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && statement.account.code.substring(0, 1) == "7") {
                    secondQuarter.push(statement);
                }
            });
        })

        thirdTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && statement.account.code.substring(0, 1) == "7") {
                    thirthQuarter.push(statement);
                }
            });
        })

        fourthTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && statement.account.code.substring(0, 1) == "7") {
                    fourthQuarter.push(statement);
                }
            });
        })
        //Ingresos
        let incomeFirstQuarter = firstQuarter.length != 0 ? firstQuarter.map((a) => a.credit - a.debit).reduce((a, b) => a + b) : 0;
        incomeFirstQuarter = (Math.round(incomeFirstQuarter * 100) / 100);

        let incomeSecondQuarter = secondQuarter.length != 0 ? secondQuarter.map((a) => a.credit - a.debit).reduce((a, b) => a + b) : 0;
        incomeSecondQuarter = (Math.round(incomeSecondQuarter * 100) / 100);

        let incomeThirdQuarter = thirthQuarter.length != 0 ? thirthQuarter.map((a) => a.credit - a.debit).reduce((a, b) => a + b) : 0;
        incomeThirdQuarter = (Math.round(incomeThirdQuarter * 100) / 100);

        let incomeFourthQuarter = fourthQuarter.length != 0 ? fourthQuarter.map((a) => a.credit - a.debit).reduce((a, b) => a + b) : 0;
        incomeFourthQuarter = (Math.round(incomeFourthQuarter * 100) / 100);

        //Primer trimestre
        //Compras
        const firstQuarterPurchases : Statement[] = [];
        firstTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 60 && Number.parseInt(statement.account.code.substring(0, 2)) <= 61 ) {
                    firstQuarterPurchases.push(statement);
                }
            });
        })
        let purchaseFirstQuarter = firstQuarterPurchases.length != 0 ? firstQuarterPurchases.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        purchaseFirstQuarter = (Math.round(purchaseFirstQuarter * 100) / 100);

        //Gastos
        const firstQuarterOutgoings : Statement[] = [];
        firstTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 62 && Number.parseInt(statement.account.code.substring(0, 2)) <= 67 ) {
                    firstQuarterOutgoings.push(statement);
                }
            });
        })
        let outgoingsFirstQuarter = firstQuarterOutgoings.length != 0 ? firstQuarterOutgoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        outgoingsFirstQuarter = (Math.round(outgoingsFirstQuarter * 100) / 100);
        
        //Otros Gastos
        const firstQuarterOtherOutgoings : Statement[] = [];

        firstTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && (statement.account.code.substring(0, 2)) == "69" ) {
                    firstQuarterOtherOutgoings.push(statement);
                }
            });
        })
        let otherOutgoingsFirstQuarter = firstQuarterOtherOutgoings.length != 0 ? firstQuarterOtherOutgoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        otherOutgoingsFirstQuarter = (Math.round(otherOutgoingsFirstQuarter * 100) / 100);

        //Amortizaciones
        const firstQuarterAmortizations : Statement[] = [];

        firstTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && (statement.account.code.substring(0, 2)) == "68" ) {
                    firstQuarterAmortizations.push(statement);
                }
            });
        })
        let amortizationsFirstQuarter = firstQuarterAmortizations.length != 0 ? firstQuarterAmortizations.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        amortizationsFirstQuarter = (Math.round(amortizationsFirstQuarter * 100) / 100);

        let totalOutgoingsFirstQuarter = Math.round((purchaseFirstQuarter + outgoingsFirstQuarter + otherOutgoingsFirstQuarter + amortizationsFirstQuarter) * 100) / 100;

        let resultFirstQuarter = Math.round((incomeFirstQuarter - totalOutgoingsFirstQuarter) * 100) / 100;


        //2do trimestre

        //Compras
        const secondQaurterPurchases : Statement[] = [];
        secondTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 60 && Number.parseInt(statement.account.code.substring(0, 2)) <= 61 ) {
                    secondQaurterPurchases.push(statement);
                }
            });
        })
        let purchaseSecondQuarter = secondQaurterPurchases.length != 0 ? secondQaurterPurchases.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        purchaseSecondQuarter = (Math.round(purchaseSecondQuarter * 100) / 100);

        //Gastos
        const secondQuarterOutgoings : Statement[] = [];
        secondTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 62 && Number.parseInt(statement.account.code.substring(0, 2)) <= 67 ) {
                    secondQuarterOutgoings.push(statement);
                }
            });
        })
        let outgoingsSecondQuarter = secondQuarterOutgoings.length != 0 ? secondQuarterOutgoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        outgoingsSecondQuarter = (Math.round(outgoingsSecondQuarter * 100) / 100);

        //Otros Gastos 
        const secondQuarterOtherOutgoings : Statement[] = [];
        secondTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && (statement.account.code.substring(0, 2)) == "69" ) {
                    secondQuarterOtherOutgoings.push(statement);
                }
            });
        })
        let otherOutgoingsSecondQuarter = secondQuarterOtherOutgoings.length != 0 ? secondQuarterOtherOutgoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        otherOutgoingsSecondQuarter = (Math.round(otherOutgoingsSecondQuarter * 100) / 100);

        //Amortizaciones
        const secondQauerterAmortizations : Statement[] = [];

        secondTrStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && (statement.account.code.substring(0, 2)) == "68" ) {
                    secondQauerterAmortizations.push(statement);
                }
            });
        })
        let amortizationsSecondQauerter = secondQauerterAmortizations.length != 0 ? secondQauerterAmortizations.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        amortizationsSecondQauerter = (Math.round(amortizationsSecondQauerter * 100) / 100);

        let totalOutgoingsSecondQuarter = Math.round((purchaseSecondQuarter + outgoingsSecondQuarter + otherOutgoingsSecondQuarter + amortizationsSecondQauerter) * 100) / 100;
        let resultSecondQuarter = Math.round((incomeSecondQuarter - totalOutgoingsSecondQuarter) * 100) / 100;

        //3er Trimestre
        //Compras
        const thirdQuarterPurchases : Statement[] = [];
        thirdTrStatements.forEach((statementGroup: Statement[]) => {
             statementGroup.forEach((statement: Statement) => {
                 if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 60 && Number.parseInt(statement.account.code.substring(0, 2)) <= 61 ) {
                    thirdQuarterPurchases.push(statement);
                 }
             });
         })
         let purchaseThirdQuarter = thirdQuarterPurchases.length != 0 ? thirdQuarterPurchases.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
         purchaseThirdQuarter = (Math.round(purchaseThirdQuarter * 100) / 100);
 
         //Gastos
         const thirdQuarterOutgoings : Statement[] = [];
         thirdTrStatements.forEach((statementGroup: Statement[]) => {
             statementGroup.forEach((statement: Statement) => {
                 if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 62 && Number.parseInt(statement.account.code.substring(0, 2)) <= 67 ) {
                    thirdQuarterOutgoings.push(statement);
                 }
             });
         })
         let outgoingsThirdQuarter = thirdQuarterOutgoings.length != 0 ? thirdQuarterOutgoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
         outgoingsThirdQuarter = (Math.round(outgoingsThirdQuarter * 100) / 100);
 
         //Otros Gastos
         const thirdQuarterOtherOutgoings : Statement[] = [];
         thirdTrStatements.forEach((statementGroup: Statement[]) => {
             statementGroup.forEach((statement: Statement) => {
                 if (statement.account && (statement.account.code.substring(0, 2)) == "69" ) {
                    thirdQuarterOtherOutgoings.push(statement);
                 }
             });
         })
         let otherOutgoingsThirdQuarter = thirdQuarterOtherOutgoings.length != 0 ? thirdQuarterOtherOutgoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
         otherOutgoingsThirdQuarter = (Math.round(otherOutgoingsThirdQuarter * 100) / 100);
 
         //Amortizaciones
         const thirdQuarterAmortizations : Statement[] = [];
         thirdTrStatements.forEach((statementGroup: Statement[]) => {
             statementGroup.forEach((statement: Statement) => {
                 if (statement.account && (statement.account.code.substring(0, 2)) == "68" ) {
                    thirdQuarterAmortizations.push(statement);
                 }
             });
         })
         let amortizationsThirdQuarter = thirdQuarterAmortizations.length != 0 ? thirdQuarterAmortizations.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
         amortizationsThirdQuarter = (Math.round(amortizationsThirdQuarter * 100) / 100);
 
         let totalOutgoingsThirdQuarter = Math.round((purchaseThirdQuarter + outgoingsThirdQuarter + otherOutgoingsThirdQuarter + amortizationsThirdQuarter) * 100) / 100;
         let resultThirdQuarter = Math.round((incomeThirdQuarter - totalOutgoingsThirdQuarter) * 100) / 100;

        //4to Trimestre
        //Compras
         const fourthQuarterPurchases : Statement[] = [];
         fourthTrStatements.forEach((statementGroup: Statement[]) => {
             statementGroup.forEach((statement: Statement) => {
                 if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 60 && Number.parseInt(statement.account.code.substring(0, 2)) <= 61 ) {
                    fourthQuarterPurchases.push(statement);
                 }
             });
         })
         let purchaseFourthQuarter = fourthQuarterPurchases.length != 0 ? fourthQuarterPurchases.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
         purchaseFourthQuarter = (Math.round(purchaseFourthQuarter * 100) / 100); 
 
         //Gastos
         const fourthQuarterOutgoings : Statement[] = [];
         fourthTrStatements.forEach((statementGroup: Statement[]) => {
             statementGroup.forEach((statement: Statement) => {
                 if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 62 && Number.parseInt(statement.account.code.substring(0, 2)) <= 67 ) {
                    fourthQuarterOutgoings.push(statement);
                 }
             });
         })
         let outgoingsFourthQuarter = fourthQuarterOutgoings.length != 0 ? fourthQuarterOutgoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
         outgoingsFourthQuarter = (Math.round(outgoingsFourthQuarter * 100) / 100);
 
         //Otros Gastos
         const fourthQuarterOtherOutgoings : Statement[] = [];
         fourthTrStatements.forEach((statementGroup: Statement[]) => {
             statementGroup.forEach((statement: Statement) => {
                 if (statement.account && (statement.account.code.substring(0, 2)) == "69" ) {
                    fourthQuarterOtherOutgoings.push(statement);
                 }
             });
         })
         let otherOutgoingsFourthQuarter = fourthQuarterOtherOutgoings.length != 0 ? fourthQuarterOtherOutgoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
         otherOutgoingsFourthQuarter = (Math.round(otherOutgoingsFourthQuarter * 100) / 100);
 
         //Amortizaciones
         const fourthQuarterAmortizations : Statement[] = [];
         fourthTrStatements.forEach((statementGroup: Statement[]) => {
             statementGroup.forEach((statement: Statement) => {
                 if (statement.account && (statement.account.code.substring(0, 2)) == "68" ) {
                    fourthQuarterAmortizations.push(statement);
                 }
             });
         })
         let amortizationsFourthQuarter = fourthQuarterAmortizations.length != 0 ? fourthQuarterAmortizations.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
         amortizationsFourthQuarter = (Math.round(amortizationsFourthQuarter * 100) / 100);
 
         let totalOutgoingsFourthQuarter = Math.round((purchaseFourthQuarter + outgoingsFourthQuarter + otherOutgoingsFourthQuarter + amortizationsFourthQuarter) * 100) / 100;
         let resultFourthQuarter = Math.round((incomeFourthQuarter - totalOutgoingsFourthQuarter) * 100) / 100;

         let datasets: any[] = [], ingresos: any[] = [], gastos: any[] = [], total: any[] = [];

         ingresos.push(incomeFirstQuarter);
         ingresos.push(incomeSecondQuarter);
         ingresos.push(incomeThirdQuarter);
         ingresos.push(incomeFourthQuarter);

         gastos.push(totalOutgoingsFirstQuarter);
         gastos.push(totalOutgoingsSecondQuarter);
         gastos.push(totalOutgoingsThirdQuarter);
         gastos.push(totalOutgoingsFourthQuarter);
         
       
         total.push(resultFirstQuarter);
         total.push(resultSecondQuarter);
         total.push(resultThirdQuarter);
         total.push(resultFourthQuarter);
 
         datasets.push({data:ingresos, label:'INGRESOS'})
         datasets.push({data:gastos, label:'GASTOS'})
         datasets.push({data:total, label:'RESULTADO'})

         return datasets;
         
        }else if(periodFilter == 'Monthly') {

        let datasets: any[] = [], ingresos: any[] = [], gastos: any[] = [], total: any[] = [];
        // const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        for(let i = 1; i < json.intervals.length; i++) {
            
        let monthStatements = json.intervals.filter((obj: any) => obj.interval.name === '0'+i+'/2023');
        monthStatements = monthStatements.map((obj: any) => obj.statements);
        //Ingresos
        const monthIncome : Statement[] = [];
        monthStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account  && statement.account.code.substring(0, 1) == "7" ) {
                    monthIncome.push(statement);
                 }
            });
        })
        let incomeMonth = monthIncome.length != 0 ? monthIncome.map((a) => a.credit - a.debit).reduce((a, b) => a + b) : 0;
        incomeMonth = (Math.round(incomeMonth * 100) / 100);
        //Gastos
        const monthOutgoings : Statement[] = [];
        monthStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 62 && Number.parseInt(statement.account.code.substring(0, 2)) <= 67 ) {
                    monthOutgoings.push(statement);
                 }
            });
        })
        let outgoingsMonth = monthOutgoings.length != 0 ? monthOutgoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        outgoingsMonth = (Math.round(outgoingsMonth * 100) / 100);
        //Otros Gastos
        const monthOtherOutgoings : Statement[] = [];
        monthStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && (statement.account.code.substring(0, 2)) == "69" ) {
                    monthOtherOutgoings.push(statement);
                 }
            });
        })
        let otherOutgoingsMonth = monthOtherOutgoings.length != 0 ? monthOtherOutgoings.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        otherOutgoingsMonth = (Math.round(otherOutgoingsMonth * 100) / 100);
        //Compras
        const monthPurchase : Statement[] = [];
        monthStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && Number.parseInt(statement.account.code.substring(0, 2)) >= 60 && Number.parseInt(statement.account.code.substring(0, 2)) <= 61 ) {
                    monthPurchase.push(statement);
                 }
            });
        })
        let purchaseMonth = monthPurchase.length != 0 ? monthPurchase.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        purchaseMonth = (Math.round(purchaseMonth * 100) / 100);
        //Amortizaciones
        const monthAmortizations : Statement[] = [];
        monthStatements.forEach((statementGroup: Statement[]) => {
            statementGroup.forEach((statement: Statement) => {
                if (statement.account && statement.account.code.substring(0, 2) == "68" ) {
                    monthAmortizations.push(statement);
                 }
            });
        })
        let amortizationsMonth = monthAmortizations.length != 0 ? monthAmortizations.map((a) => a.debit - a.credit).reduce((a, b) => a + b) : 0;
        amortizationsMonth = (Math.round(amortizationsMonth * 100) / 100);

        let totalOutgoingMonthly = Math.round(( outgoingsMonth + otherOutgoingsMonth + purchaseMonth + amortizationsMonth) * 100) / 100;

        let resultMonthly = Math.round((incomeMonth - totalOutgoingMonthly) * 100) / 100;
         
        ingresos.push(incomeMonth);
        gastos.push(totalOutgoingMonthly);
        total.push(resultMonthly);
        }
            datasets.push({data:ingresos, label:'Ingresos'})
            datasets.push({data:gastos, label:'Gastos'})
            datasets.push({data:total, label:'Resultado'})
            return datasets;
        }

        }   
    }