import { IGeozone } from "../aon";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class Geozone implements IGeozone, IModel {
    value: string;
    key: string;
    apiObject: any;

    constructor(){
        this.key = KeyGenerator.generate(15);
        this.value = '';
        this.apiObject = {};
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: string) {
        this.apiObject = value;
    }

    public get Value(): string {
        return this.value;
    }

    public set Value(value: string) {
        this.value = value;
    }

    public getValue(): string {
        return this.value;
    }

    public setValue(value: string): IGeozone {
        this.value = value;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        let map = new Map<string, any>();
        return map;
    }

    getSortableFields(): Map<string, any> {
        let map = new Map<string, any>();
        return map;
    }

}