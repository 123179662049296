import { IDocumentNote } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { ErrorResponse } from "../utils/Response";

export class DocumentNote implements IDocumentNote, IModel {
    private text: string;
    private path: string;
    private key: string;
    protected apiObject: any;

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    constructor(text?: string, path?: string) {
        this.text = text || '';
        this.path = path || '';
        this.key = path || '';
    }
    getText(): string {
        return this.text
    }
    setText(value: string): IDocumentNote {
        this.text = value;
        return this
    }
    getPath(): string {
        return this.path
    }
    setPath(value: string): IDocumentNote {
        this.path = value;
        return this
    }

    public get Text(): string {
        return this.text;
    }

    public set Text(value: string) {
        this.text = value;
    }

    public get Path(): string {
        return this.path;
    }

    public set Path(value: string) {
        this.path = value;
    }

    public get Key(): string {
        return this.key;
    }

    public set Key(value: string) {
        this.key = value;
    }

    getKey(): string {
        return this.path;
    }

    getFilterableFields(): Map<string,any> {
        let map = new Map<string, any>();
        map.set('text', this.Text);
        return map;
    }

    getSortableFields(): Map<string,any> {
        let map = new Map<string, any>();
        map.set('text', this.Text);
        return map;
    }
}

export class DocumentNoteJSON {
    static parseDataToSend(data: any) {
        throw new ErrorResponse('0199')
    }
    static parseDataToReceive(data: any) {
        throw new ErrorResponse('0199')
    }
}