import { Injectable } from '@angular/core';
import { ServiceFactory, IBank, ICollection } from 'libraries/AonSDK/src/aon';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';
import { BankStatementsFilter } from 'libraries/AonSDK/src/utils/ModelsFilters';

@Injectable({
  providedIn: 'root',
})
export class BankService extends CommonService{

  private bankService = ServiceFactory.createBankService();

  constructor(private http: HttpClient) {
    super();
    this.test();
  }

  async test() {
  }

  async getBank(key : string): Promise<IBank> {
    return (await this.bankService.getOneBank(key)).result;
  }

  async getBankList(filter?: never): Promise<ICollection<IBank>> {
    return (await this.bankService.getCollection(filter)).result;
  }

  async getBalances(){
    return (await this.bankService.getBalances()).result;
  }

  // trae los movimientos de base de datos
  async getMovements(key ?: number){
    return (await this.bankService.getMovements(key)).result;
  }

  async getMovementsBD(filter?: BankStatementsFilter){
    return (await this.bankService.getMovementsDB(filter)).result;
  }

  async getNordigenLink(key : string){
    return (await this.bankService.getNordigenLink(key)).result;
  }

  async movementsToDB(filter?: BankStatementsFilter){
    return (await this.bankService.movementsToDB(filter)).result;
  }

  async balancesToDB(key: string){
    return (await this.bankService.balancesToDB(key)).result;
  }

  async createBank(bank: IBank): Promise<IBank> {
    return (await this.bankService.createElement(bank)).result;
  }

  async updateBank(bank: IBank): Promise<IBank> {
    return (await this.bankService.updateElement(bank)).result;
  }

  async deleteBank(pkey: any): Promise<boolean> {
    return (await this.bankService.deleteElement(pkey)).result;
  }

  async compareBalanceDate(): Promise<boolean> {
    return (await this.bankService.compareBalanceDate()).result;
  }

  async unlinkBank(key: string): Promise<boolean> {
    return (await this.bankService.unlinkBank(key));
  }

  async getMovementscount(filter ?: BankStatementsFilter): Promise<number> {
    return (await this.bankService.getMovementsCount(filter));
  }
}
