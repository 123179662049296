<div>
  <div id="tabs-header" class='{{tabColor}}'>
    <div id="tabs-tool">
      <ng-content select="[tool]"></ng-content>
    </div>
    <mat-tab-group
      (selectedTabChange) = "tabChanged($event)"
      [selectedIndex]     = 'tabIndex'
      mat-stretch-tabs    = "false"
      disableRipple
    >
      <mat-tab  *ngFor="let tab of tabs; index as i">
        <ng-template mat-tab-label>
          <span class="tab-name" title="{{ tab.name }}">
            <app-icon *ngIf="tab.icon != undefined" [shape]="tab.icon || ''" [color]="tab.color || ''" [size]="'1.2rem'"></app-icon>
            {{ tab.name }}
          </span>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div id="tabs-content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
