import { IMarkHistory, MarkStatus } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { Location } from "./Location";

export class MarkHistory implements IMarkHistory, IModel {
    private user: String;
    private lastModification: Date;
    private lastRegistry: Date;
    private location: any;
    private status: MarkStatus;
    private apiObject: any;
    private key: any;

    constructor(user?: String, lastModification?: Date, lastRegistry?: Date, location?: any, status?: MarkStatus) {
        this.user = user || '';
        this.lastModification = lastModification || new Date(0);
        this.lastRegistry = lastRegistry || new Date(0);
        this.location = location || {};
        this.status = status || MarkStatus.ENTRADA;
    }

    public get Key(): any {
        return this.key;
    }

    public set Key(value: any) {
        this.key = value;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }
    
    public get User(): String {
        return this.user;
    }
    
    public set User(value: String) {
        this.user = value;
    }
    
    public get LastModification(): Date {
        return this.lastModification;
    }
    
    public set LastModification(value: Date) {
        this.lastModification = value;
    }
    
    public get LastRegistry(): Date {
        return this.lastRegistry;
    }
    
    public set LastRegistry(value: Date) {
        this.lastRegistry = value;
    }

    public get Location(): any {
        return this.location;
    }

    public set Location(value: any) {
        this.location = value;
    }
    
    public get Status(): MarkStatus {
        return this.status;
    }

    public set Status(value: MarkStatus) {
        this.status = value;
    }
    
    getUser(): String {
        return this.user;
    }

    setUser(value: String): IMarkHistory {
        this.user = value;
        return this;
    }

    getLastModification(): Date {
        return this.lastModification;
    }

    setLastModification(value: Date): IMarkHistory {
        this.lastModification = value;
        return this;
    }

    getLastRegistry(): Date {
        return this.lastRegistry;
    }

    setLastRegistry(value: Date): IMarkHistory {
        this.lastRegistry = value;
        return this;
    }

    getLocation() {
        return this.location;
    }

    setLocation(value: any): IMarkHistory {
        this.location = value;
        return this;
    }

    getStatus(): MarkStatus {
        return this.status;
    }

    setStatus(value: MarkStatus): IMarkHistory {
        this.status = value;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class MarkHistoryJSON {
    static parseDataToReceive(data: any): IMarkHistory {
        let mark = new MarkHistory();
        mark.ApiObject = data;
        mark.Key = data.id;
        mark.LastModification = data.creation_date;
        mark.LastRegistry = data.date;
        let location: Location = new Location();
        location.Coordinates = data.coordinates ? data.coordinates.latitude + ',' + data.coordinates.longitude : '';
        location.Description = data.location && data.location.id ? data.location.name : '';
        location.Key = data.location && data.location.id? data.location.id : '';
        mark.Location = location;
        switch(data.status){
            case 'in':
                mark.Status = MarkStatus.ENTRADA;
                break;
            case 'out':
                mark.Status = MarkStatus.SALIDA;
                break;
            case 'pause':
                mark.Status = MarkStatus.PAUSA;
                break;
            default:
                mark.Status = MarkStatus.ENTRADA;
                break;
        }
        mark.User = data.user_name;
        return mark;
    }
}