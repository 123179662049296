import { IInvoiceSerie } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class InvoiceSerie implements IInvoiceSerie, IModel {
    private value: string;
    private count: number;
    private key: string;
    private apiObject: any;

    constructor(value?: string, count?: number) {
        this.value = value || '';
        this.count = count || 0;
        this.key = KeyGenerator.generate(15);
        this.apiObject = {}
    }

    public get Value(): string {
        return this.value
    }

    public get Count(): number {
        return this.count
    }

    public get Key(): string {
        return this.key
    }

    public get ApiObject(): any {
        return this.apiObject
    }

    public set Value(value: string) {
        this.value = value;
    }
    
    public set Count(value: number) {
        this.count = value;
    }
    
    public set Key(value: string) {
        this.key = value;
    }
    
    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    getCount(): number {
        return this.count;
    }

    setCount(value: number): IInvoiceSerie {
        this.count = value;
        return this;
    }

    getValue(): string {
        return this.value;
    }

    setValue(value: string): IInvoiceSerie {
        this.value = value;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('value', this.Value);
        return map;
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class InvoiceSerieJSON {
    static parseDataToReceive(data: any): IInvoiceSerie{
        let invoiceSerie = new InvoiceSerie();
        invoiceSerie.ApiObject = data;
        invoiceSerie.Key = data.description ? data.description : '';
        invoiceSerie.Value = data.description ? data.description : '';
        invoiceSerie.Count = data.count ? data.count : 0;
        return invoiceSerie;
    }
}