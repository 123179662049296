import { ICcc, ICno, IContract, IDayType, IOccupation, IQuoteGroup, IRlce, ISalaryType, IWorkerCollective } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";
import { Ccc } from "./Ccc";
import { Cno } from "./Cno";
import { DayType } from "./DayType";
import { Occupation } from "./Occupation";
import { QuoteGroup } from "./QuoteGroup";
import { Rlce } from "./Rlce";
import { SalaryType } from "./SalaryType";
import { WorkerCollective } from "./WorkerCollective";

export class Contract implements IContract, IModel {
    private name: string;
    private lastName: string;
    private document: string;
    private grossCost: number;
    private startDate: Date;
    private endDate?: Date;
    private active: boolean;
    private key: string;
    private status: boolean;
    private totalTime: number;
    protected apiObject: any;
    private firstSurname: string;
    private secondSurname: string;
    private nss: string;
    private category: string;
    private undefined: boolean;
    private salaryAccordingAgreement: boolean;
    private fulltime: boolean;
    private dayType: IDayType;
    private salaryType: ISalaryType;
    private salary: number;
    private hours: number;
    private coef: number;
    private workCenter: string;
    private workCenterId: string;
    private typeId: string;
    private type: string;
    private quoteGroup: IQuoteGroup;
    private workerCollective: IWorkerCollective;
    private occupation: IOccupation;
    private cno: ICno;
    private rlce: IRlce;
    private ccc: ICcc;

    public get Ccc(): ICcc{
        return this.ccc;
    }

    public getCcc(): ICcc{
        return this.ccc;
    }

    public set Ccc(value: ICcc) {
        this.ccc = value;
    }

    public setCcc(value: ICcc): IContract {
        this.ccc = value;
        return this;
    }

    public get WorkCenterId(): string{
        return this.workCenterId;
    }

    public get TypeId(): string{
        return this.typeId;
    }

    public set WorkCenterId(value: string) {
        this.workCenterId = value;
    }

    public set TypeId(value: string) {
        this.typeId = value;
    }

    public getWorkCenterId(): string{
        return this.workCenterId;
    }

    public getTypeId(): string{
        return this.typeId;
    }

    public setWorkCenterId(value: string): IContract {
        this.workCenterId = value;
        return this;
    }

    public setTypeId(value: string): IContract {
        this.typeId = value;
        return this;
    }

    public get WorkerCollective(): IWorkerCollective {
        return this.workerCollective;
    }

    public get Occupation(): IOccupation {
        return this.occupation;
    }

    public get Cno(): ICno {
        return this.cno;
    }

    public get Rlce(): IRlce {
        return this.rlce;
    }

    public getWorkerCollective(): IWorkerCollective {
        return this.workerCollective;
    }

    public getOccupation(): IOccupation {
        return this.occupation;
    }

    public getCno(): ICno {
        return this.cno;
    }

    public getRlce(): IRlce {
        return this.rlce;
    }

    public set WorkerCollective(value : IWorkerCollective) {
        this.workerCollective = value;
    }

    public set Occupation(value : IOccupation) {
        this.occupation = value;
    }

    public set Cno(value : ICno) {
        this.cno = value;
    }

    public set Rlce(value : IRlce) {
        this.rlce = value;
    }

    public setWorkerCollective(value : IWorkerCollective): IContract {
        this.workerCollective = value;
        return this;
    }

    public setOccupation(value : IOccupation):IContract {
        this.occupation = value;
        return this;
    }

    public setCno(value : ICno):IContract {
        this.cno = value;
        return this;
    }

    public setRlce(value : IRlce):IContract {
        this.rlce = value;
        return this;
    }
    
    
    public get FirstSurname(): string{
        return this.firstSurname
    }
    
    public get SecondSurname(): string{
        return this.secondSurname
    }
    
    public get Nss(): string{
        return this.nss
    }
    
    public get Category(): string{
        return this.category
    }
    
    public get QuoteGroup(): IQuoteGroup{
        return this.quoteGroup
    }
    
    public get Undefined(): boolean{
        return this.undefined
    }
    
    public get SalaryAccordingAgreement(): boolean{
        return this.salaryAccordingAgreement
    }
    
    public get Fulltime(): boolean{
        return this.fulltime
    }
    
    public get DayType(): IDayType{
        return this.dayType
    }
    
    public get SalaryType(): ISalaryType{
        return this.salaryType
    }
    
    public get Salary(): number{
        return this.salary
    }
    
    public get Hours(): number{
        return this.hours
    }
    
    public get Coef(): number{
        return this.coef
    }

    public set FirstSurname(value: string){
        this.firstSurname = value
    }

    public set SecondSurname(value: string){
        this.secondSurname = value
    } 
    
    public set Nss(value: string){
        this.nss = value
    }

    public set Category(value: string){
        this.category = value
    }

    public set QuoteGroup(value: IQuoteGroup){
        this.quoteGroup = value
    }

    public set Undefined(value: boolean){
        this.undefined = value
    }

    public set SalaryAccordingAgreement(value: boolean){
        this.salaryAccordingAgreement = value
    }

    public set Fulltime(value: boolean){
        this.fulltime = value
    }

    public set DayType(value: IDayType){
        this.dayType = value
    }

    public set SalaryType(value: ISalaryType){
        this.salaryType = value
    }

    public set Salary(value: number){
        this.salary = value
    }

    public set Hours(value: number){
        this.hours = value
    }

    public set Coef(value: number){
        this.coef = value
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }
    
    public get ApiObject(): any {
        return this.apiObject;
    }

    constructor(name?: string, lastName?: string, document?: string, type?: string, grossCost?: number, startDate?: Date, endDate?: Date, workCenter?: string, active?: boolean, enterpriseCCC?: string, cno?: string, rlce?: string) {
        this.key = KeyGenerator.generate(15);
        this.name = name || '';
        this.lastName = lastName || '';
        this.document = document || '';
        this.type = type || '';
        this.grossCost = grossCost || 0;
        this.startDate = startDate || new Date(0);
        this.endDate = endDate || new Date(0);
        this.workCenter = workCenter || '';
        this.active = active || false;
        this.status = true;
        this.apiObject = {}
        this.totalTime = 0;
        this.firstSurname = ''
        this.secondSurname = ''
        this.nss = ''
        this.category = ''
        this.quoteGroup = new QuoteGroup()
        this.undefined = false
        this.salaryAccordingAgreement = false
        this.fulltime = false
        this.dayType = new DayType()
        this.salaryType = new SalaryType()
        this.salary = 0
        this.hours = 0
        this.coef = 0
        this.workCenterId = ""
        this.typeId = ""
        this.workerCollective = new WorkerCollective();
        this.occupation = new Occupation();
        this.cno = new Cno();
        this.rlce = new Rlce();
        this.ccc = new Ccc();
    }

    getFirstSurname(): string {
        return this.firstSurname
    }

    setFirstSurname(value: string): IContract {
        this.firstSurname = value
        return this
    }

    getSecondSurname(): string {
        return this.secondSurname
    }

    setSecondSurname(value: string): IContract {
        this.secondSurname = value
        return this
    }

    getNss(): string {
        return this.nss
    }

    setNss(value: string): IContract {
        this.nss = value
        return this
    }

    getCategory(): string {
        return this.category
    }

    setCategory(value: string): IContract {
        this.category = value
        return this
    }

    getQuoteGroup(): IQuoteGroup {
        return this.quoteGroup
    }

    setQuoteGroup(value: IQuoteGroup): IContract {
        this.quoteGroup = value
        return this
    }

    getUndefined(): boolean {
        return this.undefined
    }

    setUndefined(value: boolean): IContract {
        this.undefined = value
        return this
    }

    getSalaryAccordingAgreement(): boolean {
        return this.salaryAccordingAgreement
    }

    setSalaryAccordingAgreement(value: boolean): IContract {
        this.salaryAccordingAgreement = value
        return this
    }

    getFulltime(): boolean {
        return this.fulltime
    }

    setFulltime(value: boolean): IContract {
        this.fulltime = value
        return this
    }

    getDayType(): IDayType {
        return this.dayType
    }

    setDayType(value: IDayType): IContract {
        this.dayType = value
        return this
    }

    getSalaryType(): ISalaryType {
        return this.salaryType
    }

    setSalaryType(value: ISalaryType): IContract {
        this.salaryType = value
        return this
    }

    getSalary(): number {
        return this.salary
    }

    setSalary(value: number): IContract {
        this.salary = value
        return this
    }

    getHours(): number {
        return this.hours
    }

    setHours(value: number): IContract {
        this.hours = value
        return this
    }

    getCoef(): number {
        return this.coef
    }

    setCoef(value: number): IContract {
        this.coef = value
        return this
    }

    public get TotalTime(): number {
        return this.totalTime
    }

    public set TotalTime(value: number) {
        this.totalTime = value
    }

    public getTotalTime(): number {
        return this.totalTime
    }

    public setTotalTime(value: number): IContract {
        this.totalTime = value
        return this
    }

    public get Status(): boolean{
        return this.status
    }

    public set Status(value: boolean) {
        this.status = true
    }

    getStatus(): boolean {
        return this.status;
    }

    setStatus(value: boolean): IContract {
        this.status = value;
        return this;
    }

    getName(): string {
        return this.name
    }

    setName(value: string): IContract {
        this.name = value;
        return this
    }

    getLastName(): string {
        return this.lastName
    }

    setLastName(value: string): IContract {
        this.lastName = value;
        return this
    }

    getDocument(): string {
      return this.document
    }

    setDocument(value: string): IContract {
      this.document = value;
      return this
    }

    getType(): string {
        return this.type
    }

    setType(value: string): IContract {
        this.type = value;
        return this
    }

    getGrossCost(): number {
        return this.grossCost
    }

    setGrossCost(value: number): IContract {
        this.grossCost = value;
        return this
    }

    getStartDate(): Date {
        return this.startDate
    }

    setStartDate(value: Date): IContract {
        this.startDate = value;
        return this
    }

    getEndDate(): Date {
        return this.endDate ? this.endDate : new Date()
    }

    setEndDate(value: Date): IContract {
        this.endDate = value;
        return this
    }

    getWorkCenter(): string {
        return this.workCenter
    }

    setWorkCenter(value: string): IContract {
        this.workCenter = value;
        return this
    }

    getActive(): boolean {
        return this.active
    }

    setActive(value: boolean): IContract {
        this.active = value;
        return this
    }

    public get Name(): string {
      return this.name;
    }

    public set Name(value: string) {
      this.name = value;
    }

    public get LastName(): string {
      return this.lastName;
    }

    public set LastName(value: string) {
      this.lastName = value;
    }

    public get Document(): string {
      return this.document;
    }

    public set Document(value: string) {
      this.document = value;
    }

    public get Type(): string {
        return this.type;
    }

    public set Type(value: string) {
        this.type = value;
    }

    public get GrossCost(): number {
        return this.grossCost;
    }

    public set GrossCost(value: number) {
        this.grossCost = value;
    }

    public get StartDate(): Date {
        return this.startDate;
    }

    public set StartDate(value: Date) {
        this.startDate = value;
    }

    public get EndDate(): any {
        return this.endDate;
    }

    public set EndDate(value: any) {
        this.endDate = value;
    }

    public get WorkCenter(): string {
        return this.workCenter;
    }

    public set WorkCenter(value: string) {
        this.workCenter = value;
    }

    public get Active(): boolean {
        return this.active;
    }

    public set Active(value: boolean) {
        this.active = value;
    }

    public get Key() {
        return this.key;
    }

    public set Key(value: string){
        this.key = value;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('name', this.name);
        map.set('lastName', this.lastName);
        map.set('type', this.type);
        map.set('grossCost', this.grossCost);
        map.set('startDate', this.startDate);
        map.set('endDate', this.endDate);
        map.set('workCenter', this.workCenter);
        map.set('active', this.active);
        return map;
    }

    getSortableFields(): Map<string, any> {
        let map = new Map<string, any>();
        map.set('name', this.name);
        map.set('lastName', this.lastName);
        map.set('type', this.type);
        map.set('grossCost', this.grossCost);
        map.set('startDate', this.startDate);
        map.set('endDate', this.endDate);
        map.set('workCenter', this.workCenter);
        map.set('active', this.active);
        return map;
    }

}

export class ContractJSON {
    static parseDataToReceive(data: any, active: boolean): Contract {
        let contract = new Contract();
        contract.ApiObject = data;
        contract.GrossCost = data.amount;
        contract.EndDate = data.end_date ? data.end_date : '';
        contract.StartDate = data.start_date ? data.start_date : '';
        contract.Type = data.type;
        contract.WorkCenter = data.workplace;
        contract.Key = data.id;
        contract.Name = data.name;
        contract.TotalTime = data.time;
        contract.Active = active;
        contract.Document = data.document;
        return contract;
    }

    static parseDataToReceiveOneContract(data: any): Contract {
        let contract = new Contract();
        contract.ApiObject = data ? data : {};
        contract.Key = data.id ? data.id : KeyGenerator.generate(15);
        contract.Name = data.name ? data.name : "";
        contract.FirstSurname = data.first_surname ? data.first_surname : "";
        contract.SecondSurname = data.second_surname ? data.second_surname : "";
        contract.Document = data.document ? data.document : "";
        contract.Nss = data.nss ? data.nss : "";
        contract.StartDate = data.start_date ? new Date(data.start_date) : new Date();
        contract.EndDate = data.end_date ? new Date(data.end_date) : new Date();
        contract.WorkCenterId = data.workplace ? data.workplace : "";
        contract.TypeId = data.type ? data.type : "";
        let cno = new Cno();
        cno.Key = data.cno ? data.cno : "";
        contract.Cno = cno;
        let rlce = new Rlce();
        rlce.Key = data.rlce ? data.rlce : "";
        contract.Rlce = rlce;
        let occupation = new Occupation();
        occupation.Key = data.occupation ? data.occupation : "";
        contract.Occupation = occupation;
        let quote = new QuoteGroup();
        quote.Key = data.quote_group ? data.quote_group : "";
        contract.QuoteGroup = quote;
        let workerCollective = new WorkerCollective();
        workerCollective.Key = data.worker_collective ? data.worker_collective : "";
        contract.WorkerCollective = workerCollective;
        let ccc = new Ccc();
        ccc.Key = data.enterprise_ccc ? data.enterprise_ccc : "";
        contract.Ccc = ccc;
        return contract;
    }
}