import { Component, Input, OnInit } from '@angular/core';
import * as myGlobals from '../../../app.globals';
/*
  ICONS
  Aunque estan descagados y puestos en el css principal
  https://fonts.google.com/icons?selected=Material+Symbols+Outlined:home:FILL@0;wght@400;GRAD@0;opsz@24&icon.set=Material+Symbols&icon.size=24&icon.color=%235f6368
*/
@Component({
  selector    : 'app-icon',
  templateUrl : './icon.component.html',
  styleUrls   : ['./icon.component.scss']
})

export class IconComponent implements OnInit {
  @Input() backgroundColor: string  = '';
  @Input() border         : string  = '';
  @Input() borderRadius   : string  = '';
  @Input() color          : string  = 'black';
  @Input() padding        : string  = '';
  @Input() shape          : string  = '';
  @Input() size           : string  = '1.2rem';
  @Input() toolTip        : string  = '';
  matIcon                 : boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    // Si esta agregado al proyecto se usa mat-icon
    if(myGlobals.aon_add_icon[this.shape] !== undefined)
      this.matIcon = true;
  }

}
