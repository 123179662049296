import { ICountryAon } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { KeyGenerator } from "../utils/KeyGenerator";

export class CountryAon implements ICountryAon, IModel {
    private value: string;
    private fullName: string;
    private key: string;
    private apiObject: any;

    constructor(value?: string, fullName?: string){
        this.value = value || '';
        this.fullName = fullName || '';
        this.key = KeyGenerator.generate(15);
        this.apiObject = {}
    }

    public get Value(): string {
        return this.value;
    }

    public get FullName(): string {
        return this.fullName;
    }

    public get Key(): string {
        return this.key;
    }

    public get ApiObject(): any {
        return this.apiObject;
    }

    public set Value(value: string) {
        this.value = value;
    }
    
    public set FullName(value: string) {
        this.fullName = value;
    }

    public set Key(value: string) {
        this.key = value;
    }

    public set ApiObject(value: any) {
        this.apiObject = value;
    }

    getValue(): string {
        return this.value;
    }

    setValue(value: string): ICountryAon {
        this.value = value;
        return this;
    }

    getFullName(): string {
        return this.fullName;
    }

    setFullName(value: string): ICountryAon {
        this.fullName = value;
        return this;
    }

    getKey(): string {
        return this.key;
    }

    getFilterableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    getSortableFields(): Map<string, any> {
        throw new Error("Method not implemented.");
    }
}

export class CountryAonJSON {
    static parseDataToReceive(data: any){
        let country = new CountryAon();
        country.ApiObject = data;
        Countries.forEach((c: any) => {
            if(c.iso2 == data){
                country.FullName = c.nombre;
                country.Value = c.iso2;
                country.Key = c.iso2;
            }
        })
        return country;
    }
}

export const Countries = [{
    nombre: 'Afganistán',
    name: 'Afghanistan',
    nom: 'Afghanistan',
    iso2: 'AF',
    iso3: 'AFG',
    phoneCode: 93,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Albania',
    name: 'Albania',
    nom: 'Albanie',
    iso2: 'AL',
    iso3: 'ALB',
    phoneCode: 355,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Alemania',
    name: 'Germany',
    nom: 'Allemagne',
    iso2: 'DE',
    iso3: 'DEU',
    phoneCode: 49,
    bankIdLength: 8,
    ibanLength: 22
},
{
    nombre: 'Algeria',
    name: 'Algeria',
    nom: 'Algérie',
    iso2: 'DZ',
    iso3: 'DZA',
    phoneCode: 213,
    bankIdLength: 4,
    ibanLength: 34
    
},
{
    nombre: 'Andorra',
    name: 'Andorra',
    nom: 'Andorra',
    iso2: 'AD',
    iso3: 'AND',
    phoneCode: 376,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Angola',
    name: 'Angola',
    nom: 'Angola',
    iso2: 'AO',
    iso3: 'AGO',
    phoneCode: 244,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Anguila',
    name: 'Anguilla',
    nom: 'Anguilla',
    iso2: 'AI',
    iso3: 'AIA',
    phoneCode: '1 264',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Antártida',
    name: 'Antarctica',
    nom: 'L\'Antarctique',
    iso2: 'AQ',
    iso3: 'ATA',
    phoneCode: 672,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Antigua y Barbuda',
    name: 'Antigua and Barbuda',
    nom: 'Antigua et Barbuda',
    iso2: 'AG',
    iso3: 'ATG',
    phoneCode: '1 268',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Antillas Neerlandesas',
    name: 'Netherlands Antilles',
    nom: 'Antilles Néerlandaises',
    iso2: 'AN',
    iso3: 'ANT',
    phoneCode: 599,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Arabia Saudita',
    name: 'Saudi Arabia',
    nom: 'Arabie Saoudite',
    iso2: 'SA',
    iso3: 'SAU',
    phoneCode: 966,
    bankIdLength: 2,
    ibanLength: 24
},
{
    nombre: 'Argentina',
    name: 'Argentina',
    nom: 'Argentine',
    iso2: 'AR',
    iso3: 'ARG',
    phoneCode: 54,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Armenia',
    name: 'Armenia',
    nom: 'L\'Arménie',
    iso2: 'AM',
    iso3: 'ARM',
    phoneCode: 374,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Aruba',
    name: 'Aruba',
    nom: 'Aruba',
    iso2: 'AW',
    iso3: 'ABW',
    phoneCode: 297,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Australia',
    name: 'Australia',
    nom: 'Australie',
    iso2: 'AU',
    iso3: 'AUS',
    phoneCode: 61,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Austria',
    name: 'Austria',
    nom: 'Autriche',
    iso2: 'AT',
    iso3: 'AUT',
    phoneCode: 43,
    bankIdLength: 5,
    ibanLength: 20
},
{
    nombre: 'Azerbayán',
    name: 'Azerbaijan',
    nom: 'L\'Azerbaïdjan',
    iso2: 'AZ',
    iso3: 'AZE',
    phoneCode: 994,
    bankIdLength: 4,
    ibanLength: 28
},
{
    nombre: 'Bélgica',
    name: 'Belgium',
    nom: 'Belgique',
    iso2: 'BE',
    iso3: 'BEL',
    phoneCode: 32,
    bankIdLength: 3,
    ibanLength: 16
},
{
    nombre: 'Bahamas',
    name: 'Bahamas',
    nom: 'Bahamas',
    iso2: 'BS',
    iso3: 'BHS',
    phoneCode: '1 242',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Bahrein',
    name: 'Bahrain',
    nom: 'Bahreïn',
    iso2: 'BH',
    iso3: 'BHR',
    phoneCode: 973,
    bankIdLength: 4,
    ibanLength: 22
},
{
    nombre: 'Bangladesh',
    name: 'Bangladesh',
    nom: 'Bangladesh',
    iso2: 'BD',
    iso3: 'BGD',
    phoneCode: 880,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Barbados',
    name: 'Barbados',
    nom: 'Barbade',
    iso2: 'BB',
    iso3: 'BRB',
    phoneCode: '1 246',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Belice',
    name: 'Belize',
    nom: 'Belize',
    iso2: 'BZ',
    iso3: 'BLZ',
    phoneCode: 501,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Benín',
    name: 'Benin',
    nom: 'Bénin',
    iso2: 'BJ',
    iso3: 'BEN',
    phoneCode: 229,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Bhután',
    name: 'Bhutan',
    nom: 'Le Bhoutan',
    iso2: 'BT',
    iso3: 'BTN',
    phoneCode: 975,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Bielorrusia',
    name: 'Belarus',
    nom: 'Biélorussie',
    iso2: 'BY',
    iso3: 'BLR',
    phoneCode: 375,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Birmania',
    name: 'Myanmar',
    nom: 'Myanmar',
    iso2: 'MM',
    iso3: 'MMR',
    phoneCode: 95,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Bolivia',
    name: 'Bolivia',
    nom: 'Bolivie',
    iso2: 'BO',
    iso3: 'BOL',
    phoneCode: 591,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Bosnia y Herzegovina',
    name: 'Bosnia and Herzegovina',
    nom: 'Bosnie-Herzégovine',
    iso2: 'BA',
    iso3: 'BIH',
    phoneCode: 387,
    bankIdLength: 3,
    ibanLength: 20
},
{
    nombre: 'Botsuana',
    name: 'Botswana',
    nom: 'Botswana',
    iso2: 'BW',
    iso3: 'BWA',
    phoneCode: 267,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Brasil',
    name: 'Brazil',
    nom: 'Brésil',
    iso2: 'BR',
    iso3: 'BRA',
    phoneCode: 55,
    bankIdLength: 8,
    ibanLength: 29
},
{
    nombre: 'Brunéi',
    name: 'Brunei',
    nom: 'Brunei',
    iso2: 'BN',
    iso3: 'BRN',
    phoneCode: 673,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Bulgaria',
    name: 'Bulgaria',
    nom: 'Bulgarie',
    iso2: 'BG',
    iso3: 'BGR',
    phoneCode: 359,
    bankIdLength: 4,
    ibanLength: 22
},
{
    nombre: 'Burkina Faso',
    name: 'Burkina Faso',
    nom: 'Burkina Faso',
    iso2: 'BF',
    iso3: 'BFA',
    phoneCode: 226,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Burundi',
    name: 'Burundi',
    nom: 'Burundi',
    iso2: 'BI',
    iso3: 'BDI',
    phoneCode: 257,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Cabo Verde',
    name: 'Cape Verde',
    nom: 'Cap-Vert',
    iso2: 'CV',
    iso3: 'CPV',
    phoneCode: 238,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Camboya',
    name: 'Cambodia',
    nom: 'Cambodge',
    iso2: 'KH',
    iso3: 'KHM',
    phoneCode: 855,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Camerún',
    name: 'Cameroon',
    nom: 'Cameroun',
    iso2: 'CM',
    iso3: 'CMR',
    phoneCode: 237,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Canadá',
    name: 'Canada',
    nom: 'Canada',
    iso2: 'CA',
    iso3: 'CAN',
    phoneCode: 1,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Chad',
    name: 'Chad',
    nom: 'Tchad',
    iso2: 'TD',
    iso3: 'TCD',
    phoneCode: 235,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Chile',
    name: 'Chile',
    nom: 'Chili',
    iso2: 'CL',
    iso3: 'CHL',
    phoneCode: 56,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'China',
    name: 'China',
    nom: 'Chine',
    iso2: 'CN',
    iso3: 'CHN',
    phoneCode: 86,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Chipre',
    name: 'Cyprus',
    nom: 'Chypre',
    iso2: 'CY',
    iso3: 'CYP',
    phoneCode: 357,
    bankIdLength: 3,
    ibanLength: 28
},
{
    nombre: 'Ciudad del Vaticano',
    name: 'Vatican City State',
    nom: 'Cité du Vatican',
    iso2: 'VA',
    iso3: 'VAT',
    phoneCode: 39,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Colombia',
    name: 'Colombia',
    nom: 'Colombie',
    iso2: 'CO',
    iso3: 'COL',
    phoneCode: 57,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Comoras',
    name: 'Comoros',
    nom: 'Comores',
    iso2: 'KM',
    iso3: 'COM',
    phoneCode: 269,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Congo',
    name: 'Congo',
    nom: 'Congo',
    iso2: 'CG',
    iso3: 'COG',
    phoneCode: 242
},
{
    nombre: 'Congo',
    name: 'Congo',
    nom: 'Congo',
    iso2: 'CD',
    iso3: 'COD',
    phoneCode: 243,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Corea del Norte',
    name: 'North Korea',
    nom: 'Corée du Nord',
    iso2: 'KP',
    iso3: 'PRK',
    phoneCode: 850,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Corea del Sur',
    name: 'South Korea',
    nom: 'Corée du Sud',
    iso2: 'KR',
    iso3: 'KOR',
    phoneCode: 82,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Costa de Marfil',
    name: 'Ivory Coast',
    nom: 'Côte-d\'Ivoire',
    iso2: 'CI',
    iso3: 'CIV',
    phoneCode: 225,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Costa Rica',
    name: 'Costa Rica',
    nom: 'Costa Rica',
    iso2: 'CR',
    iso3: 'CRI',
    phoneCode: 506,
    bankIdLength: 3,
    ibanLength: 21
},
{
    nombre: 'Croacia',
    name: 'Croatia',
    nom: 'Croatie',
    iso2: 'HR',
    iso3: 'HRV',
    phoneCode: 385,
    bankIdLength: 7,
    ibanLength: 21
},
{
    nombre: 'Cuba',
    name: 'Cuba',
    nom: 'Cuba',
    iso2: 'CU',
    iso3: 'CUB',
    phoneCode: 53,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Dinamarca',
    name: 'Denmark',
    nom: 'Danemark',
    iso2: 'DK',
    iso3: 'DNK',
    phoneCode: 45,
    bankIdLength: 4,
    ibanLength: 18
},
{
    nombre: 'Dominica',
    name: 'Dominica',
    nom: 'Dominique',
    iso2: 'DM',
    iso3: 'DMA',
    phoneCode: '1 767',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Ecuador',
    name: 'Ecuador',
    nom: 'Equateur',
    iso2: 'EC',
    iso3: 'ECU',
    phoneCode: 593,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Egipto',
    name: 'Egypt',
    nom: 'Egypte',
    iso2: 'EG',
    iso3: 'EGY',
    phoneCode: 20,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'El Salvador',
    name: 'El Salvador',
    nom: 'El Salvador',
    iso2: 'SV',
    iso3: 'SLV',
    phoneCode: 503,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Emiratos Árabes Unidos',
    name: 'United Arab Emirates',
    nom: 'Emirats Arabes Unis',
    iso2: 'AE',
    iso3: 'ARE',
    phoneCode: 971,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Eritrea',
    name: 'Eritrea',
    nom: 'Erythrée',
    iso2: 'ER',
    iso3: 'ERI',
    phoneCode: 291,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Eslovaquia',
    name: 'Slovakia',
    nom: 'Slovaquie',
    iso2: 'SK',
    iso3: 'SVK',
    phoneCode: 421,
    bankIdLength: 4,
    ibanLength: 24
},
{
    nombre: 'Eslovenia',
    name: 'Slovenia',
    nom: 'Slovénie',
    iso2: 'SI',
    iso3: 'SVN',
    phoneCode: 386,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'España',
    name: 'Spain',
    nom: 'Espagne',
    iso2: 'ES',
    iso3: 'ESP',
    phoneCode: 34,
    bankIdLength: 4,
    ibanLength: 24
},
{
    nombre: 'Estados Unidos de América',
    name: 'United States of America',
    nom: 'États-Unis d\'Amérique',
    iso2: 'US',
    iso3: 'USA',
    phoneCode: 1,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Estonia',
    name: 'Estonia',
    nom: 'L\'Estonie',
    iso2: 'EE',
    iso3: 'EST',
    phoneCode: 372,
    bankIdLength: 2,
    ibanLength: 20
},
{
    nombre: 'Etiopía',
    name: 'Ethiopia',
    nom: 'Ethiopie',
    iso2: 'ET',
    iso3: 'ETH',
    phoneCode: 251,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Filipinas',
    name: 'Philippines',
    nom: 'Philippines',
    iso2: 'PH',
    iso3: 'PHL',
    phoneCode: 63,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Finlandia',
    name: 'Finland',
    nom: 'Finlande',
    iso2: 'FI',
    iso3: 'FIN',
    phoneCode: 358,
    bankIdLength: 3,
    ibanLength: 18
},
{
    nombre: 'Fiyi',
    name: 'Fiji',
    nom: 'Fidji',
    iso2: 'FJ',
    iso3: 'FJI',
    phoneCode: 679,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Francia',
    name: 'France',
    nom: 'France',
    iso2: 'FR',
    iso3: 'FRA',
    phoneCode: 33,
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Gabón',
    name: 'Gabon',
    nom: 'Gabon',
    iso2: 'GA',
    iso3: 'GAB',
    phoneCode: 241,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Gambia',
    name: 'Gambia',
    nom: 'Gambie',
    iso2: 'GM',
    iso3: 'GMB',
    phoneCode: 220,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Georgia',
    name: 'Georgia',
    nom: 'Géorgie',
    iso2: 'GE',
    iso3: 'GEO',
    phoneCode: 995,
    bankIdLength: 2,
    ibanLength: 22
},
{
    nombre: 'Ghana',
    name: 'Ghana',
    nom: 'Ghana',
    iso2: 'GH',
    iso3: 'GHA',
    phoneCode: 233,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Gibraltar',
    name: 'Gibraltar',
    nom: 'Gibraltar',
    iso2: 'GI',
    iso3: 'GIB',
    phoneCode: 350
},
{
    nombre: 'Granada',
    name: 'Grenada',
    nom: 'Grenade',
    iso2: 'GD',
    iso3: 'GRD',
    phoneCode: '1 473',
    bankIdLength: 4,
    ibanLength: 23
},
{
    nombre: 'Grecia',
    name: 'Greece',
    nom: 'Grèce',
    iso2: 'GR',
    iso3: 'GRC',
    phoneCode: 30,
    bankIdLength: 3,
    ibanLength: 27
},
{
    nombre: 'Groenlandia',
    name: 'Greenland',
    nom: 'Groenland',
    iso2: 'GL',
    iso3: 'GRL',
    phoneCode: 299,
    bankIdLength: 4,
    ibanLength: 18
},
{
    nombre: 'Guadalupe',
    name: 'Guadeloupe',
    nom: 'Guadeloupe',
    iso2: 'GP',
    iso3: 'GLP',
    phoneCode: '',
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Guam',
    name: 'Guam',
    nom: 'Guam',
    iso2: 'GU',
    iso3: 'GUM',
    phoneCode: '1 671',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Guatemala',
    name: 'Guatemala',
    nom: 'Guatemala',
    iso2: 'GT',
    iso3: 'GTM',
    phoneCode: 502,
    bankIdLength: 4,
    ibanLength: 28
},
{
    nombre: 'Guayana Francesa',
    name: 'French Guiana',
    nom: 'Guyane française',
    iso2: 'GF',
    iso3: 'GUF',
    phoneCode: '',
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Guernsey',
    name: 'Guernsey',
    nom: 'Guernesey',
    iso2: 'GG',
    iso3: 'GGY',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Guinea',
    name: 'Guinea',
    nom: 'Guinée',
    iso2: 'GN',
    iso3: 'GIN',
    phoneCode: 224,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Guinea Ecuatorial',
    name: 'Equatorial Guinea',
    nom: 'Guinée Equatoriale',
    iso2: 'GQ',
    iso3: 'GNQ',
    phoneCode: 240,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Guinea-Bissau',
    name: 'Guinea-Bissau',
    nom: 'Guinée-Bissau',
    iso2: 'GW',
    iso3: 'GNB',
    phoneCode: 245,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Guyana',
    name: 'Guyana',
    nom: 'Guyane',
    iso2: 'GY',
    iso3: 'GUY',
    phoneCode: 592,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Haití',
    name: 'Haiti',
    nom: 'Haïti',
    iso2: 'HT',
    iso3: 'HTI',
    phoneCode: 509,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Honduras',
    name: 'Honduras',
    nom: 'Honduras',
    iso2: 'HN',
    iso3: 'HND',
    phoneCode: 504,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Hong kong',
    name: 'Hong Kong',
    nom: 'Hong Kong',
    iso2: 'HK',
    iso3: 'HKG',
    phoneCode: 852,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Hungría',
    name: 'Hungary',
    nom: 'Hongrie',
    iso2: 'HU',
    iso3: 'HUN',
    phoneCode: 36,
    bankIdLength: 3,
    ibanLength: 28
},
{
    nombre: 'India',
    name: 'India',
    nom: 'Inde',
    iso2: 'IN',
    iso3: 'IND',
    phoneCode: 91,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Indonesia',
    name: 'Indonesia',
    nom: 'Indonésie',
    iso2: 'ID',
    iso3: 'IDN',
    phoneCode: 62,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Irán',
    name: 'Iran',
    nom: 'Iran',
    iso2: 'IR',
    iso3: 'IRN',
    phoneCode: 98,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Irak',
    name: 'Iraq',
    nom: 'Irak',
    iso2: 'IQ',
    iso3: 'IRQ',
    phoneCode: 964,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Irlanda',
    name: 'Ireland',
    nom: 'Irlande',
    iso2: 'IE',
    iso3: 'IRL',
    phoneCode: 353,
    bankIdLength: 4,
    ibanLength: 22
},
{
    nombre: 'Isla Bouvet',
    name: 'Bouvet Island',
    nom: 'Bouvet Island',
    iso2: 'BV',
    iso3: 'BVT',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Isla de Man',
    name: 'Isle of Man',
    nom: 'Ile de Man',
    iso2: 'IM',
    iso3: 'IMN',
    phoneCode: 44,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Isla de Navidad',
    name: 'Christmas Island',
    nom: 'Christmas Island',
    iso2: 'CX',
    iso3: 'CXR',
    phoneCode: 61,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Isla Norfolk',
    name: 'Norfolk Island',
    nom: 'Île de Norfolk',
    iso2: 'NF',
    iso3: 'NFK',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islandia',
    name: 'Iceland',
    nom: 'Islande',
    iso2: 'IS',
    iso3: 'ISL',
    phoneCode: 354,
    bankIdLength: 4,
    ibanLength: 26
},
{
    nombre: 'Islas Bermudas',
    name: 'Bermuda Islands',
    nom: 'Bermudes',
    iso2: 'BM',
    iso3: 'BMU',
    phoneCode: '1 441',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Caimán',
    name: 'Cayman Islands',
    nom: 'Iles Caïmans',
    iso2: 'KY',
    iso3: 'CYM',
    phoneCode: '1 345',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Cocos (Keeling)',
    name: 'Cocos (Keeling) Islands',
    nom: 'Cocos (Keeling',
    iso2: 'CC',
    iso3: 'CCK',
    phoneCode: 61,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Cook',
    name: 'Cook Islands',
    nom: 'Iles Cook',
    iso2: 'CK',
    iso3: 'COK',
    phoneCode: 682,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas de Åland',
    name: 'Åland Islands',
    nom: 'Îles Åland',
    iso2: 'AX',
    iso3: 'ALA',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Feroe',
    name: 'Faroe Islands',
    nom: 'Iles Féro',
    iso2: 'FO',
    iso3: 'FRO',
    phoneCode: 298,
    bankIdLength: 4,
    ibanLength: 18
},
{
    nombre: 'Islas Georgias del Sur y Sandwich del Sur',
    name: 'South Georgia and the South Sandwich Islands',
    nom: 'Géorgie du Sud et les Îles Sandwich du Sud',
    iso2: 'GS',
    iso3: 'SGS',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Heard y McDonald',
    name: 'Heard Island and McDonald Islands',
    nom: 'Les îles Heard et McDonald',
    iso2: 'HM',
    iso3: 'HMD',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Maldivas',
    name: 'Maldives',
    nom: 'Maldives',
    iso2: 'MV',
    iso3: 'MDV',
    phoneCode: 960,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Malvinas',
    name: 'Falkland Islands (Malvinas)',
    nom: 'Iles Falkland (Malvinas',
    iso2: 'FK',
    iso3: 'FLK',
    phoneCode: 500,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Marianas del Norte',
    name: 'Northern Mariana Islands',
    nom: 'Iles Mariannes du Nord',
    iso2: 'MP',
    iso3: 'MNP',
    phoneCode: '1 670',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Marshall',
    name: 'Marshall Islands',
    nom: 'Iles Marshall',
    iso2: 'MH',
    iso3: 'MHL',
    phoneCode: 692,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Pitcairn',
    name: 'Pitcairn Islands',
    nom: 'Iles Pitcairn',
    iso2: 'PN',
    iso3: 'PCN',
    phoneCode: 870,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Salomón',
    name: 'Solomon Islands',
    nom: 'Iles Salomon',
    iso2: 'SB',
    iso3: 'SLB',
    phoneCode: 677,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Turcas y Caicos',
    name: 'Turks and Caicos Islands',
    nom: 'Iles Turques et Caïques',
    iso2: 'TC',
    iso3: 'TCA',
    phoneCode: '1 649',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Ultramarinas Menores de Estados Unidos',
    name: 'United States Minor Outlying Islands',
    nom: 'États-Unis Îles mineures éloignées',
    iso2: 'UM',
    iso3: 'UMI',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Islas Vírgenes Británicas',
    name: 'Virgin Islands',
    nom: 'Iles Vierges',
    iso2: 'VG',
    iso3: 'VG',
    phoneCode: '1 284',
    bankIdLength: 4,
    ibanLength: 24
},
{
    nombre: 'Islas Vírgenes de los Estados Unidos',
    name: 'United States Virgin Islands',
    nom: 'Îles Vierges américaines',
    iso2: 'VI',
    iso3: 'VIR',
    phoneCode: '1 340',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Israel',
    name: 'Israel',
    nom: 'Israël',
    iso2: 'IL',
    iso3: 'ISR',
    phoneCode: 972,
    bankIdLength: 3,
    ibanLength: 23
},
{
    nombre: 'Italia',
    name: 'Italy',
    nom: 'Italie',
    iso2: 'IT',
    iso3: 'ITA',
    phoneCode: 39,
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Jamaica',
    name: 'Jamaica',
    nom: 'Jamaïque',
    iso2: 'JM',
    iso3: 'JAM',
    phoneCode: '1 876',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Japón',
    name: 'Japan',
    nom: 'Japon',
    iso2: 'JP',
    iso3: 'JPN',
    phoneCode: 81,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Jersey',
    name: 'Jersey',
    nom: 'Maillot',
    iso2: 'JE',
    iso3: 'JEY',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Jordania',
    name: 'Jordan',
    nom: 'Jordan',
    iso2: 'JO',
    iso3: 'JOR',
    phoneCode: 962,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Kazajistán',
    name: 'Kazakhstan',
    nom: 'Le Kazakhstan',
    iso2: 'KZ',
    iso3: 'KAZ',
    phoneCode: 7,
    bankIdLength: 3,
    ibanLength: 20
},
{
    nombre: 'Kenia',
    name: 'Kenya',
    nom: 'Kenya',
    iso2: 'KE',
    iso3: 'KEN',
    phoneCode: 254,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Kirgizstán',
    name: 'Kyrgyzstan',
    nom: 'Kirghizstan',
    iso2: 'KG',
    iso3: 'KGZ',
    phoneCode: 996,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Kiribati',
    name: 'Kiribati',
    nom: 'Kiribati',
    iso2: 'KI',
    iso3: 'KIR',
    phoneCode: 686,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Kuwait',
    name: 'Kuwait',
    nom: 'Koweït',
    iso2: 'KW',
    iso3: 'KWT',
    phoneCode: 965,
    bankIdLength: 4,
    ibanLength: 30
},
{
    nombre: 'Líbano',
    name: 'Lebanon',
    nom: 'Liban',
    iso2: 'LB',
    iso3: 'LBN',
    phoneCode: 961,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Laos',
    name: 'Laos',
    nom: 'Laos',
    iso2: 'LA',
    iso3: 'LAO',
    phoneCode: 856,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Lesoto',
    name: 'Lesotho',
    nom: 'Lesotho',
    iso2: 'LS',
    iso3: 'LSO',
    phoneCode: 266,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Letonia',
    name: 'Latvia',
    nom: 'La Lettonie',
    iso2: 'LV',
    iso3: 'LVA',
    phoneCode: 371,
    bankIdLength: 4,
    ibanLength: 21
},
{
    nombre: 'Liberia',
    name: 'Liberia',
    nom: 'Liberia',
    iso2: 'LR',
    iso3: 'LBR',
    phoneCode: 231,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Libia',
    name: 'Libya',
    nom: 'Libye',
    iso2: 'LY',
    iso3: 'LBY',
    phoneCode: 218,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Liechtenstein',
    name: 'Liechtenstein',
    nom: 'Liechtenstein',
    iso2: 'LI',
    iso3: 'LIE',
    phoneCode: 423,
    bankIdLength: 5,
    ibanLength: 21
},
{
    nombre: 'Lituania',
    name: 'Lithuania',
    nom: 'La Lituanie',
    iso2: 'LT',
    iso3: 'LTU',
    phoneCode: 370,
    bankIdLength: 5,
    ibanLength: 20
},
{
    nombre: 'Luxemburgo',
    name: 'Luxembourg',
    nom: 'Luxembourg',
    iso2: 'LU',
    iso3: 'LUX',
    phoneCode: 352,
    bankIdLength: 3,
    ibanLength: 20
},
{
    nombre: 'México',
    name: 'Mexico',
    nom: 'Mexique',
    iso2: 'MX',
    iso3: 'MEX',
    phoneCode: 52,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Mónaco',
    name: 'Monaco',
    nom: 'Monaco',
    iso2: 'MC',
    iso3: 'MCO',
    phoneCode: 377,
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Macao',
    name: 'Macao',
    nom: 'Macao',
    iso2: 'MO',
    iso3: 'MAC',
    phoneCode: 853,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Macedônia',
    name: 'Macedonia',
    nom: 'Macédoine',
    iso2: 'MK',
    iso3: 'MKD',
    phoneCode: 389,
    bankIdLength: 3,
    ibanLength: 19
},
{
    nombre: 'Madagascar',
    name: 'Madagascar',
    nom: 'Madagascar',
    iso2: 'MG',
    iso3: 'MDG',
    phoneCode: 261,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Malasia',
    name: 'Malaysia',
    nom: 'Malaisie',
    iso2: 'MY',
    iso3: 'MYS',
    phoneCode: 60,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Malawi',
    name: 'Malawi',
    nom: 'Malawi',
    iso2: 'MW',
    iso3: 'MWI',
    phoneCode: 265,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Mali',
    name: 'Mali',
    nom: 'Mali',
    iso2: 'ML',
    iso3: 'MLI',
    phoneCode: 223,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Malta',
    name: 'Malta',
    nom: 'Malte',
    iso2: 'MT',
    iso3: 'MLT',
    phoneCode: 356,
    bankIdLength: 4,
    ibanLength: 31
},
{
    nombre: 'Marruecos',
    name: 'Morocco',
    nom: 'Maroc',
    iso2: 'MA',
    iso3: 'MAR',
    phoneCode: 212,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Martinica',
    name: 'Martinique',
    nom: 'Martinique',
    iso2: 'MQ',
    iso3: 'MTQ',
    phoneCode: '',
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Mauricio',
    name: 'Mauritius',
    nom: 'Iles Maurice',
    iso2: 'MU',
    iso3: 'MUS',
    phoneCode: 230,
    bankIdLength: 6,
    ibanLength: 30
},
{
    nombre: 'Mauritania',
    name: 'Mauritania',
    nom: 'Mauritanie',
    iso2: 'MR',
    iso3: 'MRT',
    phoneCode: 222,
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Mayotte',
    name: 'Mayotte',
    nom: 'Mayotte',
    iso2: 'YT',
    iso3: 'MYT',
    phoneCode: 262,
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Micronesia',
    name: 'Estados Federados de',
    nom: 'Federados Estados de',
    iso2: 'FM',
    iso3: 'FSM',
    phoneCode: 691,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Moldavia',
    name: 'Moldova',
    nom: 'Moldavie',
    iso2: 'MD',
    iso3: 'MDA',
    phoneCode: 373,
    bankIdLength: 2,
    ibanLength: 24
},
{
    nombre: 'Mongolia',
    name: 'Mongolia',
    nom: 'Mongolie',
    iso2: 'MN',
    iso3: 'MNG',
    phoneCode: 976,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Montenegro',
    name: 'Montenegro',
    nom: 'Monténégro',
    iso2: 'ME',
    iso3: 'MNE',
    phoneCode: 382,
    bankIdLength: 3,
    ibanLength: 22
},
{
    nombre: 'Montserrat',
    name: 'Montserrat',
    nom: 'Montserrat',
    iso2: 'MS',
    iso3: 'MSR',
    phoneCode: '1 664',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Mozambique',
    name: 'Mozambique',
    nom: 'Mozambique',
    iso2: 'MZ',
    iso3: 'MOZ',
    phoneCode: 258,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Namibia',
    name: 'Namibia',
    nom: 'Namibie',
    iso2: 'NA',
    iso3: 'NAM',
    phoneCode: 264,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Nauru',
    name: 'Nauru',
    nom: 'Nauru',
    iso2: 'NR',
    iso3: 'NRU',
    phoneCode: 674,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Nepal',
    name: 'Nepal',
    nom: 'Népal',
    iso2: 'NP',
    iso3: 'NPL',
    phoneCode: 977,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Nicaragua',
    name: 'Nicaragua',
    nom: 'Nicaragua',
    iso2: 'NI',
    iso3: 'NIC',
    phoneCode: 505,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Niger',
    name: 'Niger',
    nom: 'Niger',
    iso2: 'NE',
    iso3: 'NER',
    phoneCode: 227,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Nigeria',
    name: 'Nigeria',
    nom: 'Nigeria',
    iso2: 'NG',
    iso3: 'NGA',
    phoneCode: 234,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Niue',
    name: 'Niue',
    nom: 'Niou',
    iso2: 'NU',
    iso3: 'NIU',
    phoneCode: 683,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Noruega',
    name: 'Norway',
    nom: 'Norvège',
    iso2: 'NO',
    iso3: 'NOR',
    phoneCode: 47,
    bankIdLength: 4,
    ibanLength: 15
},
{
    nombre: 'Nueva Caledonia',
    name: 'New Caledonia',
    nom: 'Nouvelle-Calédonie',
    iso2: 'NC',
    iso3: 'NCL',
    phoneCode: 687,
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Nueva Zelanda',
    name: 'New Zealand',
    nom: 'Nouvelle-Zélande',
    iso2: 'NZ',
    iso3: 'NZL',
    phoneCode: 64,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Omán',
    name: 'Oman',
    nom: 'Oman',
    iso2: 'OM',
    iso3: 'OMN',
    phoneCode: 968,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Países Bajos',
    name: 'Netherlands',
    nom: 'Pays-Bas',
    iso2: 'NL',
    iso3: 'NLD',
    phoneCode: 31,
    bankIdLength: 4,
    ibanLength: 18
},
{
    nombre: 'Pakistán',
    name: 'Pakistan',
    nom: 'Pakistan',
    iso2: 'PK',
    iso3: 'PAK',
    phoneCode: 92,
    bankIdLength: 4,
    ibanLength: 24
},
{
    nombre: 'Palau',
    name: 'Palau',
    nom: 'Palau',
    iso2: 'PW',
    iso3: 'PLW',
    phoneCode: 680,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Palestina',
    name: 'Palestine',
    nom: 'La Palestine',
    iso2: 'PS',
    iso3: 'PSE',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 29
},
{
    nombre: 'Panamá',
    name: 'Panama',
    nom: 'Panama',
    iso2: 'PA',
    iso3: 'PAN',
    phoneCode: 507,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Papúa Nueva Guinea',
    name: 'Papua New Guinea',
    nom: 'Papouasie-Nouvelle-Guinée',
    iso2: 'PG',
    iso3: 'PNG',
    phoneCode: 675,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Paraguay',
    name: 'Paraguay',
    nom: 'Paraguay',
    iso2: 'PY',
    iso3: 'PRY',
    phoneCode: 595,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Perú',
    name: 'Peru',
    nom: 'Pérou',
    iso2: 'PE',
    iso3: 'PER',
    phoneCode: 51,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Polinesia Francesa',
    name: 'French Polynesia',
    nom: 'Polynésie française',
    iso2: 'PF',
    iso3: 'PYF',
    phoneCode: 689,
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Polonia',
    name: 'Poland',
    nom: 'Pologne',
    iso2: 'PL',
    iso3: 'POL',
    phoneCode: 48,
    bankIdLength: 8,
    ibanLength: 28
},
{
    nombre: 'Portugal',
    name: 'Portugal',
    nom: 'Portugal',
    iso2: 'PT',
    iso3: 'PRT',
    phoneCode: 351,
    bankIdLength: 4,
    ibanLength: 25
},
{
    nombre: 'Puerto Rico',
    name: 'Puerto Rico',
    nom: 'Porto Rico',
    iso2: 'PR',
    iso3: 'PRI',
    phoneCode: 1,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Qatar',
    name: 'Qatar',
    nom: 'Qatar',
    iso2: 'QA',
    iso3: 'QAT',
    phoneCode: 974,
    bankIdLength: 4,
    ibanLength: 29
},
{
    nombre: 'Reino Unido',
    name: 'United Kingdom',
    nom: 'Royaume-Uni',
    iso2: 'GB',
    iso3: 'GBR',
    phoneCode: 44,
    bankIdLength: 4,
    ibanLength: 22
},
{
    nombre: 'República Centroafricana',
    name: 'Central African Republic',
    nom: 'République Centrafricaine',
    iso2: 'CF',
    iso3: 'CAF',
    phoneCode: 236,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'República Checa',
    name: 'Czech Republic',
    nom: 'République Tchèque',
    iso2: 'CZ',
    iso3: 'CZE',
    phoneCode: 420,
    bankIdLength: 4,
    ibanLength: 24
},
{
    nombre: 'República Dominicana',
    name: 'Dominican Republic',
    nom: 'République Dominicaine',
    iso2: 'DO',
    iso3: 'DOM',
    phoneCode: '1 809',
    bankIdLength: 4,
    ibanLength: 28
},
{
    nombre: 'Reunión',
    name: 'Réunion',
    nom: 'Réunion',
    iso2: 'RE',
    iso3: 'REU',
    phoneCode: '',
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Ruanda',
    name: 'Rwanda',
    nom: 'Rwanda',
    iso2: 'RW',
    iso3: 'RWA',
    phoneCode: 250,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Rumanía',
    name: 'Romania',
    nom: 'Roumanie',
    iso2: 'RO',
    iso3: 'ROU',
    phoneCode: 40,
    bankIdLength: 4,
    ibanLength: 24
},
{
    nombre: 'Rusia',
    name: 'Russia',
    nom: 'La Russie',
    iso2: 'RU',
    iso3: 'RUS',
    phoneCode: 7,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Sahara Occidental',
    name: 'Western Sahara',
    nom: 'Sahara Occidental',
    iso2: 'EH',
    iso3: 'ESH',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Samoa',
    name: 'Samoa',
    nom: 'Samoa',
    iso2: 'WS',
    iso3: 'WSM',
    phoneCode: 685,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Samoa Americana',
    name: 'American Samoa',
    nom: 'Les Samoa américaines',
    iso2: 'AS',
    iso3: 'ASM',
    phoneCode: '1 684',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'San Bartolomé',
    name: 'Saint Barthélemy',
    nom: 'Saint-Barthélemy',
    iso2: 'BL',
    iso3: 'BLM',
    phoneCode: 590,
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'San Cristóbal y Nieves',
    name: 'Saint Kitts and Nevis',
    nom: 'Saint Kitts et Nevis',
    iso2: 'KN',
    iso3: 'KNA',
    phoneCode: '1 869',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'San Marino',
    name: 'San Marino',
    nom: 'San Marino',
    iso2: 'SM',
    iso3: 'SMR',
    phoneCode: 378,
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'San Martín (Francia)',
    name: 'Saint Martin (French part)',
    nom: 'Saint-Martin (partie française)',
    iso2: 'MF',
    iso3: 'MAF',
    phoneCode: '1 599',
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'San Pedro y Miquelón',
    name: 'Saint Pierre and Miquelon',
    nom: 'Saint-Pierre-et-Miquelon',
    iso2: 'PM',
    iso3: 'SPM',
    phoneCode: 508,
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'San Vicente y las Granadinas',
    name: 'Saint Vincent and the Grenadines',
    nom: 'Saint-Vincent et Grenadines',
    iso2: 'VC',
    iso3: 'VCT',
    phoneCode: '1 784',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Santa Elena',
    name: 'Ascensión y Tristán de Acuña',
    nom: 'Ascensión y Tristan de Acuña',
    iso2: 'SH',
    iso3: 'SHN',
    phoneCode: 290,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Santa Lucía',
    name: 'Saint Lucia',
    nom: 'Sainte-Lucie',
    iso2: 'LC',
    iso3: 'LCA',
    phoneCode: '1 758',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Santo Tomé y Príncipe',
    name: 'Sao Tome and Principe',
    nom: 'Sao Tomé et Principe',
    iso2: 'ST',
    iso3: 'STP',
    phoneCode: 239,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Senegal',
    name: 'Senegal',
    nom: 'Sénégal',
    iso2: 'SN',
    iso3: 'SEN',
    phoneCode: 221,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Serbia',
    name: 'Serbia',
    nom: 'Serbie',
    iso2: 'RS',
    iso3: 'SRB',
    phoneCode: 381,
    bankIdLength: 3,
    ibanLength: 22
},
{
    nombre: 'Seychelles',
    name: 'Seychelles',
    nom: 'Les Seychelles',
    iso2: 'SC',
    iso3: 'SYC',
    phoneCode: 248,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Sierra Leona',
    name: 'Sierra Leone',
    nom: 'Sierra Leone',
    iso2: 'SL',
    iso3: 'SLE',
    phoneCode: 232,
    bankIdLength: 4,
    ibanLength: 19
},
{
    nombre: 'Singapur',
    name: 'Singapore',
    nom: 'Singapour',
    iso2: 'SG',
    iso3: 'SGP',
    phoneCode: 65,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Siria',
    name: 'Syria',
    nom: 'Syrie',
    iso2: 'SY',
    iso3: 'SYR',
    phoneCode: 963,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Somalia',
    name: 'Somalia',
    nom: 'Somalie',
    iso2: 'SO',
    iso3: 'SOM',
    phoneCode: 252,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Sri lanka',
    name: 'Sri Lanka',
    nom: 'Sri Lanka',
    iso2: 'LK',
    iso3: 'LKA',
    phoneCode: 94,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Sudáfrica',
    name: 'South Africa',
    nom: 'Afrique du Sud',
    iso2: 'ZA',
    iso3: 'ZAF',
    phoneCode: 27,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Sudán',
    name: 'Sudan',
    nom: 'Soudan',
    iso2: 'SD',
    iso3: 'SDN',
    phoneCode: 249,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Suecia',
    name: 'Sweden',
    nom: 'Suède',
    iso2: 'SE',
    iso3: 'SWE',
    phoneCode: 46,
    bankIdLength: 3,
    ibanLength: 24
},
{
    nombre: 'Suiza',
    name: 'Switzerland',
    nom: 'Suisse',
    iso2: 'CH',
    iso3: 'CHE',
    phoneCode: 41,
    bankIdLength: 5,
    ibanLength: 21
},
{
    nombre: 'Surinám',
    name: 'Suriname',
    nom: 'Surinam',
    iso2: 'SR',
    iso3: 'SUR',
    phoneCode: 597,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Svalbard y Jan Mayen',
    name: 'Svalbard and Jan Mayen',
    nom: 'Svalbard et Jan Mayen',
    iso2: 'SJ',
    iso3: 'SJM',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Swazilandia',
    name: 'Swaziland',
    nom: 'Swaziland',
    iso2: 'SZ',
    iso3: 'SWZ',
    phoneCode: 268,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Tadjikistán',
    name: 'Tajikistan',
    nom: 'Le Tadjikistan',
    iso2: 'TJ',
    iso3: 'TJK',
    phoneCode: 992,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Tailandia',
    name: 'Thailand',
    nom: 'Thaïlande',
    iso2: 'TH',
    iso3: 'THA',
    phoneCode: 66,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Taiwán',
    name: 'Taiwan',
    nom: 'Taiwan',
    iso2: 'TW',
    iso3: 'TWN',
    phoneCode: 886,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Tanzania',
    name: 'Tanzania',
    nom: 'Tanzanie',
    iso2: 'TZ',
    iso3: 'TZA',
    phoneCode: 255,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Territorio Británico del Océano Índico',
    name: 'British Indian Ocean Territory',
    nom: 'Territoire britannique de l\'océan Indien',
    iso2: 'IO',
    iso3: 'IOT',
    phoneCode: '',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Territorios Australes y Antárticas Franceses',
    name: 'French Southern Territories',
    nom: 'Terres australes françaises',
    iso2: 'TF',
    iso3: 'ATF',
    phoneCode: '',
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Timor Oriental',
    name: 'East Timor',
    nom: 'Timor-Oriental',
    iso2: 'TL',
    iso3: 'TLS',
    phoneCode: 670,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Togo',
    name: 'Togo',
    nom: 'Togo',
    iso2: 'TG',
    iso3: 'TGO',
    phoneCode: 228,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Tokelau',
    name: 'Tokelau',
    nom: 'Tokélaou',
    iso2: 'TK',
    iso3: 'TKL',
    phoneCode: 690,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Tonga',
    name: 'Tonga',
    nom: 'Tonga',
    iso2: 'TO',
    iso3: 'TON',
    phoneCode: 676,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Trinidad y Tobago',
    name: 'Trinidad and Tobago',
    nom: 'Trinidad et Tobago',
    iso2: 'TT',
    iso3: 'TTO',
    phoneCode: '1 868',
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Tunez',
    name: 'Tunisia',
    nom: 'Tunisie',
    iso2: 'TN',
    iso3: 'TUN',
    phoneCode: 216,
    bankIdLength: 2,
    ibanLength: 24
},
{
    nombre: 'Turkmenistán',
    name: 'Turkmenistan',
    nom: 'Le Turkménistan',
    iso2: 'TM',
    iso3: 'TKM',
    phoneCode: 993,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Turquía',
    name: 'Turkey',
    nom: 'Turquie',
    iso2: 'TR',
    iso3: 'TUR',
    phoneCode: 90,
    bankIdLength: 5,
    ibanLength: 26
},
{
    nombre: 'Tuvalu',
    name: 'Tuvalu',
    nom: 'Tuvalu',
    iso2: 'TV',
    iso3: 'TUV',
    phoneCode: 688,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Ucrania',
    name: 'Ukraine',
    nom: 'L\'Ukraine',
    iso2: 'UA',
    iso3: 'UKR',
    phoneCode: 380,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Uganda',
    name: 'Uganda',
    nom: 'Ouganda',
    iso2: 'UG',
    iso3: 'UGA',
    phoneCode: 256,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Uruguay',
    name: 'Uruguay',
    nom: 'Uruguay',
    iso2: 'UY',
    iso3: 'URY',
    phoneCode: 598,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Uzbekistán',
    name: 'Uzbekistan',
    nom: 'L\'Ouzbékistan',
    iso2: 'UZ',
    iso3: 'UZB',
    phoneCode: 998,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Vanuatu',
    name: 'Vanuatu',
    nom: 'Vanuatu',
    iso2: 'VU',
    iso3: 'VUT',
    phoneCode: 678,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Venezuela',
    name: 'Venezuela',
    nom: 'Venezuela',
    iso2: 'VE',
    iso3: 'VEN',
    phoneCode: 58,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Vietnam',
    name: 'Vietnam',
    nom: 'Vietnam',
    iso2: 'VN',
    iso3: 'VNM',
    phoneCode: 84,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Wallis y Futuna',
    name: 'Wallis and Futuna',
    nom: 'Wallis et Futuna',
    iso2: 'WF',
    iso3: 'WLF',
    phoneCode: 681,
    bankIdLength: 5,
    ibanLength: 27
},
{
    nombre: 'Yemen',
    name: 'Yemen',
    nom: 'Yémen',
    iso2: 'YE',
    iso3: 'YEM',
    phoneCode: 967,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Yibuti',
    name: 'Djibouti',
    nom: 'Djibouti',
    iso2: 'DJ',
    iso3: 'DJI',
    phoneCode: 253,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Zambia',
    name: 'Zambia',
    nom: 'Zambie',
    iso2: 'ZM',
    iso3: 'ZMB',
    phoneCode: 260,
    bankIdLength: 4,
    ibanLength: 34
},
{
    nombre: 'Zimbabue',
    name: 'Zimbabwe',
    nom: 'Zimbabwe',
    iso2: 'ZW',
    iso3: 'ZWE',
    phoneCode: 263,
    bankIdLength: 4,
    ibanLength: 34
}
];