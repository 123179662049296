import { IRlce } from "../aon";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class Rlce extends SimpleValue implements IRlce, IModel {}

export class RlceJSON {
    static parseDataToReceive(data: any){
        let rlce = new Rlce();
        rlce.ApiObject = data;
        rlce.Key = data.value ? data.value : "";
        rlce.Value = data.name ? data.name : "";
        return rlce;
    }
}