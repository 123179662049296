<span *ngIf="this.menuItem[0].root == true" [matMenuTriggerFor]="menu"></span>
<mat-menu
  #menu="matMenu" yPosition="above"
>
  <span class="mat-menu-list" *ngFor="let item of menuItem">
    <span *ngIf="item.children != null">
      <a mat-menu-item [matMenuTriggerFor]="submenu">
        <app-icon *ngIf="item.icon != undefined" shape="{{ item.icon }}" color="{{ item.colorIcon }}"></app-icon>
        {{item.text}}
      </a>
    </span>
    <a
      mat-menu-item
      *ngIf="item.children == null"
      [routerLink]="item.routerlink"
      (click)="item.click != undefined ? item.click() : '';"
    >
      <app-icon *ngIf="item.icon != undefined" shape="{{ item.icon }}" color="{{ item.colorIcon }}"></app-icon>
      {{item.text}}
    </a>
  </span>
</mat-menu>

<mat-menu #submenu="matMenu" class="children-menu-item">
  <div *ngFor="let item of menuItem">
    <div class="mat-menu-list" *ngFor="let subitem of item.children">
      <a
        mat-menu-item
        *ngIf="subitem.children == null"
        [routerLink]="subitem.routerlink"
        (click)="subitem.click != undefined ? subitem.click() : '';"
      >
        <app-icon *ngIf="subitem.icon != undefined" shape="{{ subitem.icon }}" color="{{ subitem.colorIcon }}"></app-icon>
        {{subitem.text}}
      </a>
    </div>
  </div>
</mat-menu>
