import { IUser } from "../interfaces/modelsInterfaces";
import { IMultipleObjectCrudRepository, ISingleObjectCrudRepository, IUserSpecificMethodsRepository } from "../interfaces/repositoryInterfaces";
import { IUserSpecificMethods } from "../interfaces/serviceInterfaces";
import { IResponse } from "../interfaces/utilitiesInterfaces";
import { User } from "../models/User";
import { ErrorResponse } from "../utils/Response";
import { Response } from "../utils/Response";
import { GenericService } from "./GenericCrudService";

export class UserService extends GenericService<User, never> implements IUserSpecificMethods {
    protected SpecificMethodsRepository: IUserSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<User> & ISingleObjectCrudRepository<User>, type: { new (): User }, SpecificMethodsRepository: IUserSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    /**
     * Recupera los datos del usuario actual.
     *
     * @return {Promise<IResponse<IUser>>} El objeto de respuesta que contiene los datos del usuario.
     */
    async getCurrentUserData(): Promise<IResponse<IUser>> {
        try {
            return new Response<IUser>(await this.SpecificMethodsRepository.getCurrentUserData());
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1401');
        }
    }

    /**
     * Actualiza los datos del usuario actual.
     *
     * @param {User} user - El objeto de usuario que contiene los datos actualizados.
     * @returns {Promise<IResponse<IUser>>} Una promesa que se resuelve en el objeto de respuesta que contiene los datos actualizados del usuario.
     */
    async updateCurrentUserData(user: User): Promise<IResponse<IUser>> {
        try {
            return new Response<IUser>(await this.SpecificMethodsRepository.updateCurrentUserData(user));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1402');
        }
    }

    /**
     * Recupera los datos del usuario por su documento.
     *
     * @param {string} document - El documento del usuario.
     * @returns {Promise<IResponse<IUser>>} El objeto de respuesta que contiene los datos del usuario.
     */
    async getUserByDocument(document: string): Promise<IResponse<IUser>> {
        try {
            return new Response<IUser>(await this.SpecificMethodsRepository.getUserByDocument(document));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1403');
        }
    }

    async updateUserPwd(oldPass: string, newPass: string): Promise<IResponse<any>> {
        try {
            return new Response<any>(await this.SpecificMethodsRepository.updateUserPwd(oldPass, newPass));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1402');
        }
    }

    async updateUser(user: IUser): Promise<IResponse<IUser>> {
        try {
            return new Response<any>(await this.SpecificMethodsRepository.updateUser(user));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('1402');
        }
    }

}
