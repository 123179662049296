import { IDocument } from "../interfaces/modelsInterfaces";
import { IDocumentSpecificMethodsRepository, IMultipleObjectCrudRepository, ISingleObjectCrudRepository } from "../interfaces/repositoryInterfaces";
import { IDocumentSpecificMethods } from "../interfaces/serviceInterfaces";
import { ICollection, IResponse } from "../interfaces/utilitiesInterfaces";
import { Document } from "../models/Document";
import { DocumentFilter } from "../utils/ModelsFilters";
import { ErrorResponse } from "../utils/Response";
import { Response } from "../utils/Response";
import { GenericService } from "./GenericCrudService";

export class DocumentService extends GenericService<Document, never> implements IDocumentSpecificMethods {
    protected SpecificMethodsRepository: IDocumentSpecificMethodsRepository;

    constructor(repository: IMultipleObjectCrudRepository<Document> & ISingleObjectCrudRepository<Document>, type: { new (): Document }, SpecificMethodsRepository: IDocumentSpecificMethodsRepository){
        super(repository, type);
        this.SpecificMethodsRepository = SpecificMethodsRepository;
    }

    /**
     * Sube un documento a la plataforma.
     *
     * @param {IDocument} document - El objeto documento que contiene la información necesaria.
     * @param {File} file - El archivo a subir.
     * @return {Promise<IResponse<boolean>>} Una promesa que se resuelve en un objeto de respuesta indicando si el documento se subió correctamente.
     */
    async uploadDocument(document: IDocument, file: File): Promise<IResponse<boolean>> {
        try {
            return new Response<boolean>(await this.SpecificMethodsRepository.uploadDocument(document, file));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0301');
        }
    }

    /**
     * Obtiene el contenido sin formato(raw) del archivo para un documento dado.
     *
     * @param {IDocument} document - El objeto documento.
     * @return {Promise<IResponse<string>>} - Una promesa que se resuelve con el contenido sin formato del archivo como una cadena.
     */
    async getRawFile(document: IDocument): Promise<IResponse<string>> {
        try {
            return new Response<string>(await this.SpecificMethodsRepository.getRawFile(document));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0304');
        }
    }

    /**
     * Obtiene un fichero comprimido con todos los documentos 
     *
     * @param {IDocument[]} document - La lista de documentos que se quiere descargar dentro del archivo comprimido.
     * @return {Promise<IResponse<any>>} - Promesa que devuelve el archivo comprimido
     */
    async getCompressedFileList(document: IDocument[]): Promise<IResponse<any>> {
        try {
            return new Response<any>(await this.SpecificMethodsRepository.getCompressedFileList(document));
        } catch (error) {
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0304');
        }
    }

    async getDocumentalCount(filter: DocumentFilter): Promise<number> {
        try{
            return this.SpecificMethodsRepository.getDocumentalCount(filter);
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0304');
        }
    }

    async getSalaryByDocument(filter: DocumentFilter): Promise<IResponse<ICollection<IDocument>>>{
        try{
            return new Response<ICollection<IDocument>>(this.SpecificMethodsRepository.getSalaryByDocument(filter));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0304');
        }
    }

    async countSalaryByDocument(filter: DocumentFilter): Promise<IResponse<number>>{
        try{
            return new Response<number>(this.SpecificMethodsRepository.countSalaryByDocument(filter));
        }catch(error){
            throw error instanceof ErrorResponse ?  error : new ErrorResponse('0304');
        }
    }

}
