import { IProductCategory } from "../interfaces/modelsInterfaces";
import { IModel } from "../interfaces/utilitiesInterfaces";
import { SimpleValue } from "./SimpleValue";

export class ProductCategory extends SimpleValue implements IProductCategory, IModel {
  constructor(value?: any){
    super(value);
    this.key = value ||'';
  }
}

export class ProductCategoryJSON {
    static parseDataToReceive(data: any){
        let category = new ProductCategory('asd');
        category.ApiObject = data;
        category.Key = data.id;
        category.Value = data.name;
        return category;
    }
}