import { Injectable } from '@angular/core';
import { ServiceFactory, ICollection, IEnterprise, IFilter, IRegistryEnterprise } from 'libraries/AonSDK/src/aon';
import { CommonService } from './common.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseService extends CommonService {

  private service = ServiceFactory.createEnterpriseService();

  constructor(private authService: AuthService, private router: Router) {
    super();
  }

  async getEnterpriseList(filter?: never): Promise<ICollection<IEnterprise>> {
    let enterprisesList =  ((await this.service.getCollection(filter)).result);

    // Si no hay ninguna empresa, se cierra la sesion
    if (enterprisesList.isEmpty()) {
      this.authService.logout();
    }

    // Si solo hay una empresa, se selecciona por defecto
    //! Pendiente de solucionar, para que solo lo haga en seleccion de empresa
    // if (enterprisesList.size() === 1) {
    //   const uniqueEnterprise: IEnterprise = enterprisesList.toArray()[0];
    //   await this.authService.setEnterprise(uniqueEnterprise);
    //   this.router.navigate(['']);
    // }

    return enterprisesList;
  }

  async getEnterprise(pkey: any): Promise<IEnterprise> {
    return (await this.service.getElement(pkey)).result;
  }

  async createEnterprise(enterprise: IEnterprise): Promise<IEnterprise> {
    return (await this.service.createElement(enterprise)).result;
  }

  async deleteEnterprise(pkey: any): Promise<boolean> {
    return (await this.service.deleteElement(pkey)).result;
  }

  async updateEnterprise(enterprise: IEnterprise): Promise<IEnterprise> {
    return (await this.service.updateElement(enterprise)).result;
  }

  async getCurrentEntepriseData(): Promise<IEnterprise> {
    return (await this.service.getCurrentEnterpriseData()).result;
  }

  async existEntepriseData(): Promise<boolean> {
    const enterprise: IEnterprise = (await this.service.getCurrentEnterpriseData()).result;
    return (enterprise.Id !== undefined);
  }

  async getCurrentEnterpriseRegistryData(): Promise<IRegistryEnterprise> {
    return (await this.service.getCurrentEnterpriseRegistryData()).result;
  }

  async updateCurrentEnterpriseData(enterprise: IEnterprise): Promise<IEnterprise> {
    return (await this.service.updateCurrentEnterpriseData(enterprise)).result;
  }

  async updateCurrentEnterpriseRegistryData(enterprise: IRegistryEnterprise): Promise<IRegistryEnterprise> {
    return (await this.service.updateCurrentEnterpriseRegistryData(enterprise)).result;
  }

  async getCurrentEnterpriseAvatar(): Promise<any> {
    return (await this.service.getCurrentEnterpriseAvatar()).result;
  }

}
